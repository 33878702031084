import React from 'react'
import { Container, Box } from "@mui/material";
import BgImageSection from '../components/BgImageSection';
import CardBox from '../components/CardBox';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import { Link } from 'react-router-dom';


const InnovationsGoSeries = () => {
	return (
		<>
			<section
            className="flex items-end justify-center relative"
            style={{ height: "70vh" }}
         >
            <img
               src="https://images.jhtassets.com/9ee06f6c261ee35f6ede93ac6f45662c04d2237b/"
               alt=""
               className="absolute top-0 left-0 w-full h-full object-cover"
            />
            <Container className="flex-col gap-5 items-center relative z-10">
               <h5 className="text-white text-3xl md:text-5xl font-bold pb-10">СЕРИЯ MATRIX GO</h5>
            </Container>
         </section>
			<section>
				<Container maxWidth="xl" className='md:!px-10 py-14'>
					<h3 className='font-black mb-5 text-3xl text-zinc-700'>ЛЕГКОСТЬ КАЖДОГО ПОДХОДА И ПОВТОРЕНИЯ</h3>
					<p className='max-w-5xl pb-6 md:pb-14 font-medium'>Приступая к разработке нашей серии Go - коллекции силовых и круговых тренажеров специально для начинающих, мы ставили перед собой цель не просто улучшить уже имеющееся оборудование. Мы хотели разработать совершенно новую серию, которая была бы необычайно простой и удобной в использовании.</p>
					<p className="text-red-600 text-2xl italic mb-5 py-10 md:py-20 max-w-4xl font-bold text-center mx-auto">Каким образом можно создать упрощенные приспособления для силовых тренировок, имеющие минимальный набор настроек, но при этом подходящие пользователям разной комплекции?</p>
				</Container>
			</section>
			<section className='my-10'>
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-zinc-700 mb-4 font-black text-3xl md:text-5xl uppercase text-center">ПРИСТУПИТЬ К ВЫПОЛНЕНИЮ ЗАДАЧИ</h3>
					<p className="mb-10 max-w-5xl text-center mx-auto">Рассмотрев все отзывы, полученные от владельцев клубов и фитнес-центров, тренеров, технических специалистов и реальных пользователей, мы поняли, что должны создать оборудование для силовых тренировок, которое не имело бы барьеров и ограничений, свойственных традиционным конструкциям.</p>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="80vh"
				img="https://images.jhtassets.com/f12abdf33a4689fc2c5a1a845c4e783a92dfb41c/"
				title="Найти правильное сопротивление"
				text="Сначала мы экспериментировали с эластичным ленточным сопротивлением, но быстрая отдача ленты могла привести к травмам или повреждению тренажера, особенно в руках новичка. Гидравлические системы затрудняли точный выбор сопротивления, ограничивали скорость, замедляли возврат и усложняли возможность повторений. И что еще важнее, ни один из этих механизмов не давал ощущения подъема с сопротивлением силе тяжести."
				contentWidth="33%"
				titleColor="#e6e5e5"
				contentRight={false}
				contentBg="#000"
				textColor="#c2c6c9"
				linkColor="#e1261c"
			/>
			<section className='pb-10 lg:p-20 mb-10'>
				<Container maxWidth="xl" className='!grid lg:grid-cols-2 gap-4'>
					<CardBox
						title="Испытания и новые проверки"
						text="В самом начале производства образцов мы пригласили самых разных пользователей для испытания наших прототипов. Нам было крайне важно проследить, насколько легко у них будет получаться начинать занятия на тренажере и покидать его после окончания. Мы также хотели выяснить, смогут ли они приступить к тренировке, получив лишь краткие инструкции, либо вообще без них."
						img='https://images.jhtassets.com/6b957bfc766e87ab35fabd12442736ae6f40f4d4/'
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
					/>
					<CardBox
						title="Рекомендации для круговых тренировок"
						text="Мы также обратились к ключевым клиентам, чтобы выяснить, как оборудование данной серии может использоваться в рамках круговой программы. Они были в восторге, так как возможность более быстрой настройки оборудования могла позволить любому пользователю проходить весь круг и тренировать все тело за меньшее количество времени."
						img='https://images.jhtassets.com/c925377181a6e6faa6d6f95392c85ed6cd62f086/'
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
					/>
				</Container>
		  	</section>
			<section
				className='my-4 lg:my-10 py-5 lg:p-20'
				style={{
					background: 'linear-gradient(171.76deg,#253746,#000)'
				}}
			>
				<Container maxWidth="xl" className="md:!px-10">
					<h3 className="text-zinc-300 mb-4 font-black text-3xl md:text-5xl uppercase text-center">НАШИ ИННОВАЦИИ</h3>
					<p className="mb-10 max-w-5xl text-center mx-auto text-zinc-300">Мы знали, что должны сосредоточиться на биомеханике, эргономике и чисто механических аспектах для решения первостепенных задач. Только после этого наши инженеры-конструкторы смогли дать волю своему воображению и создать первые цифровые эскизы серии Go.</p>
					<CardBoxMoreImage
						title="Ощущение тренировок с реальным весом"
						subtitle="В тренажерах серии Go мы применили ту же технологию создания сопротивления, которая используется в нашем избранном передовом оборудовании и имитирует ощущение подъема с сопротивлением реальной силе тяжести. За счет этого тренировки на данном оборудовании еще больше сопоставимы с опытом занятий на других силовых тренажерах. Это дополнительно помогает пользователям, достигшим начальных целей, легко перейти к следующему шагу в своих силовых тренировках."
						image="https://images.jhtassets.com/5f710207fd411ff14faab1a5a543c84ca60612d3/"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="30%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
					<Box className='!grid lg:grid-cols-2 gap-4'>
						<CardBox
							title="Минимальные настройки"
							text="За счет создания более длинных рукояток с нейтральным положением и удлиненного сиденья нам удалось избавиться от необходимости регулировки сиденья для пользователей разного роста. У нашего тренажера для тяги на низком блоке мы расположили длинные рукоятки под углом и добавили опоры для ног в нескольких положениях, что позволило отказаться как от подушки для груди, так и от регулировки сиденья. Благодаря этому практически каждый пользователь сможет просто сесть и сразу начать упражнения. Для сгибаний на бицепс мы предусмотрели точную поворотную точку, которая обеспечивает оптимальное расположение локтя и максимальный комфорт."
							img='https://images.jhtassets.com/d9797d2b10976a2faba7e9754f0f097b9aa30f95/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
						<CardBox
							title="Превосходная доступность"
							text="Из отзывов, полученных непосредственно от фитнес-клубов, мы узнали, что серия Go пользуется особой популярностью у новичков, а также пользователей старшего возраста и людей со слабой физической подготовкой. Благодаря иллюстрированным табличкам с упражнениями и минимальным настройкам практически каждый посетитель смог эффективно использовать оборудование без дополнительных инструкций."
							img='https://images.jhtassets.com/c528f9375c768b71a77e9ce3bfd7b9d0957ffad2/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
					</Box>
				</Container>
			</section>
			<section>
				<Container maxWidth="xl" className="md:!px-10">
					<CardBoxMoreImage
						title="Компактная конструкция"
						subtitle="Легкость и компактность этого оборудования превзошла наши ожидания, благодаря чему переместить его на второй этаж нашего помещения для проведения испытаний в полностью собранном виде оказалось даже проще, чем мы думали. А возможность размещения всех 10 элементов на площади всего 10,5' x 18,5' (3,2 х 5,6 м) значительно облегчает создание специализированных зон и организацию круговых тренировок."
						image="https://images.jhtassets.com/d910808ac7e93d71e56e58f3c34655c6b91680a5/"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="30%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
					<Box className='!grid lg:grid-cols-2 gap-4'>
						<CardBox
							title="Простота обслуживания"
							text="Мы также нашли способы дополнительно упростить обслуживание и ремонт, сократив до минимума время простоя для пользователей. Наши подпружиненные направляющие штанги легко и быстро демонтируются без каких-либо инструментов, а металлические кожухи невероятно легко надеваются и снимаются для быстрой очистки"
							img='https://images.jhtassets.com/756f1ebdda3a6bbba799cdaf2d28d286f78798a2/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
						<CardBox
							title="Надежность и эффективность"
							text="Придерживаясь стандартов безопасности оборудования EN957 и ASTM, а также применяя передовые технологии производства, мы создали коллекцию силовых тренажеров, успешно прошедшую все самые тщательные проверки и испытания, как и любые другие продукты, когда-либо созданные нами."
							img='https://images.jhtassets.com/85da6e42b3bc017e734322665bc7fed4779135c2/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
					</Box>
				</Container>
			</section>
			<section
				className='p-5 md:p-32'
				style={{
					background: 'url(https://images.jhtassets.com/31456797d73a12bc4b10c0fda0f961a05344a6ad/) center / cover no-repeat'
				}}
			>
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-zinc-700 mb-4 font-black text-3xl md:text-5xl uppercase text-center">СИЛА В ПРОСТОТЕ</h3>
					<p className="mb-10 max-w-5xl text-center mx-auto">Наши клиенты с нетерпением ждут возможности предложить своим посетителям, еще мало знакомым с силовыми тренировками, упрощенное оборудование данной серии, специально разработанное с учетом их уникальных потребностей. Конечно, большинство пользователей даже не заметят всех инноваций, которые мы внедрили при создании нашей новой коллекции силовых тренажеров, они просто сядут и с легкостью приступят к занятиям. Поэтому вся сила и заключается в простоте.</p>
				</Container>
			</section>
			<section>
				<div
					className="CenterContentBox py-52 text-center relative"
					style={{
						background: '#000'
					}}
				>
					<h4 className="font-black text-4xl text-zinc-200 mb-4">УЗНАЙТЕ БОЛЬШЕ</h4>
					<Box className="flex flex-wrap gap-5 justify-center">
						<Link to="/strength/go" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<span className='text-base font-black'>Перейти на страницу серии</span>
						</Link>
						<a href="https://images.jhtassets.com/aa265d9848a880b45dd07c6995c2fc49972e8ee4/" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<span className='text-base font-black'>Читать историю целиком</span>
						</a>
					</Box>
				</div>
			</section>
		</>
	)
}

export default InnovationsGoSeries