import React from "react";
import { Button, Container } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import HoverBoxAnimation from "../components/HoverBoxAnimation";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $1 from "../assets/videos/1-1.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const ActiveAging = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="activeAging-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source height="auto" width="auto" src={$1}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 flex md:flex-row flex-col items-center justify-center !bg-cover md:!bg-center !bg-top main-media-height"
        style={{
          height: "80vh",
          clipPath: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          shapeOutside: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          background:
            "url(https://images.jhtassets.com/cf18bb54b582ee7dbbfb7794ec32fcb8d4312be5/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12 md:!py-0 !pt-32 !pb-12">
          <div style={{ marginTop: "-6rem" }} className="lg:w-3/4 md:mr-16">
            <div className="font-bold text-black text-lg mb-4">
              Приверженность здоровому движению
            </div>
            <div className="text-black text-2xl md:text-5xl leading-none">
              Активный образ жизни пожилых людей и реабилитация
            </div>
            <Button
              onClick={handleOpen}
              className="mx-auto !mt-2 hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
              style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
            >
              <BsPlayCircle size={32} fill="#fff" className="mr-2" />
              СМОТРЕТЬ ВИДЕО
            </Button>
          </div>
        </Container>
      </section>
      <section className="section-2" style={{ marginTop: "-70px" }}>
        <div className="grid lg:grid-cols-2 lg:gap-8">
          <div style={{ clipPath: "polygon(0 10%,100% 5%,100% 100%,0 100%)" }}>
            <img
              className="w-full"
              src="https://images.jhtassets.com/3f17fba82534d5682226de8e04ee4bf36e077b3d/transformed/w_650,h_1000,c_fill"
              alt=""
            />
          </div>
          <div
            className="flex flex-col justify-center pl-3 md:pl-12 py-4 !bg-cover bg-center"
            style={{
              background:
                "url(https://images.jhtassets.com/f66f1965dcedf3e0c278152dbba7d0900ad0b032/transformed/w_650,h_1000,c_fill) no-repeat",
              clipPath: "polygon(0 5%,100% 0,100% 100%,0 100%)",
            }}
          >
            <p
              className="leading-9 w-11/12 mb-8 text-base"
              style={{ color: "#191919" }}
            >
              Компания Matrix верит в качество жизни, которое зависит от
              движения. Независимо от того, живут люди сами или в домах
              престарелых, если они могут легко, удобно и активно двигаться, они
              могут продолжать принимать участие в повседневной жизни (ADL).
              Именно поэтому наш портфель решений для активного движения
              включает оборудование, предназначенное для удовлетворения
              потребностей медицинских специалистов и тренеров, которые
              понимают, насколько важно движение для здорового будущего. Вы
              поймете, что все, что мы предлагаем, является универсальным,
              доступным и простым в использовании, способным мотивировать и
              бросать вызов, создавая мобильность, необходимую вашим клиентам
              для сохранения независимости, самоуважения и здорового образа
              жизни.
            </p>
          </div>
        </div>
      </section>
      <section className="section-3">
        <Container maxWidth="xl" className="md:px-16">
          <Link to="/total-solutions-partners">
            <div
              className="text-center mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase"
              style={{ color: "#4c4c4c" }}
            >
              Партнер, который будет с тобой на каждом шагу.
            </div>
          </Link>
          <p
            className="leading-7 mb-4 text-center md:w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Вы стараетесь постоянно поддерживать здоровье своих клиентов, так же
            как мы стараемся сохранять отношения с вами. Сотрудничая с Matrix,
            вы получаете полный доступ к нашему опыту, ресурсам и образцовому
            сервису на всех уровнях вашего бизнеса. Мы будем с вами сегодня,
            завтра и в будущем, чтобы помочь вам воплотить ваше видение
            здорового движения в жизнь при помощи комплексных решений, которые
            выводят ожидания на качественно новый уровень.
          </p>
          <Link
            to="/total-solutions-partners"
            className="block text-center font-bold text-red-600 hover:text-red-700"
          >
            Узнать больше /
          </Link>
          <div className="grid md:grid-cols-3 gap-6 py-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/946a62d49fdf1a6aa48a7634ce50c5322d57fe55/"
              title="Планирование помещений"
              text="Наши услуги по планированию помещений, в том числе 2-мерные и 3-мерные чертежи и рисунки, помогут вдохнуть жизнь в ваше предприятие. "
              link="Планирование помещений /"
              linkTo="/total-solutions-partners/facility-planning"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/fae78659cc238603657bfc6c08832a82e4307586/"
              title="Профессиональная установка"
              text="Наши технические эксперты будут оказывать вам поддержку с самого начала. Мы поможем вам наладить работу предприятия в кратчайшие сроки, чтобы вы смогли сконцентрироваться на своих клиентах."
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/7ddc21f514c0347d39f488867e5b773f496ef90b/"
              title="Гарантии"
              text="Наши лучшие в отрасли гарантии обеспечивают всю необходимую защиту для того, чтобы вы без сомнений выбрали продукцию Matrix и знали о том, что приобретаете одно из лучших в мире оборудований для фитнеса."
            />
          </div>
          <Link to="/education">
            <div
              className="text-center mb-4 text-3xl md:text-6xl leading-none uppercase"
              style={{ color: "#4c4c4c" }}
            >
              Bright Ideas for Better Movement
            </div>
          </Link>
          <p
            className="leading-7 mb-4 md:px-16 text-center mx-auto"
            style={{ color: "#191919" }}
          >
            Чтобы помочь клиентам жить хорошо, требуется нечто большее, чем
            просто правильные тренажеры, — требуются отличные идеи, которые
            заставляют переосмыслить опыт тренировок, чтобы сосредоточиться на
            потребностях профилактики заболеваний. Наши эксклюзивные возможности
            программирования включают широкий спектр средств – от простых
            библиотек упражнений до полной сертификации расширенных тренировок.
            Когда ваши сотрудники вооружены правильными знаниями, они могут
            сделать больше для связи с клиентами и помочь им поддерживать
            здоровый образ жизни больше, чем даже лучшие в мире тренажеры.
          </p>
          <Link
            to="/education"
            className="block text-center text-red-600 hover:text-red-700 font-bold"
          >
            Узнать больше /
          </Link>
          <div className="grid md:grid-cols-2 gap-6 py-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/ce843709f1cd8ad5c096c6ef93acb84c7b2e6ec8/"
              title="Велотренажер для рук Kranking"
              text="Велотренажер для рук Kranking идеально подходит для инвалидов-колясочников и клиентов, которые проходят реабилитацию верхней части тела и хотят развить аэробные способности, силу и выносливость. А широкие возможности интеграции позволят легко приспособить его под всех ваших клиентов так, как им удобно. "
              link="Узнать больше /"
              linkTo="/group-training/cardio/krankcycle"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/9834dfe9660fc342a2df0a5979dce3b072ebdd3b/"
              title="Connexus"
              text="Библиотека более чем из 200&nbsp;упражнений, ориентированных на кардиотренировки, развитие силы, гибкости, равновесия, координации и подвижности, поможет в проведении занятий по вашей личной программе."
              link="Подробнее /"
              linkTo="/group-training/strength-flexibility/connexus-free"
            />
          </div>
        </Container>
      </section>
      <section
        className="section-4 !bg-cover !bg-bottom"
        style={{
          background:
            "url(https://images.jhtassets.com/85831c39d0c00bb7d5e0fff0b932638e9f29565f) no-repeat",
          paddingBottom: "12rem",
        }}
      >
        <Container maxWidth="xl" className="md:!px-16">
          <div className="grid lg:grid-cols-3 gap-5 py-16">
            <div className="lg:col-span-2">
              <Link to="">
                <div
                  className="grid md:grid-cols-2 gap-6 mt-4"
                  style={{ background: "#fafafacc" }}
                >
                  <img
                    src="https://images.jhtassets.com/5cc03205019a84153de3da832c28d0e8675dde73/"
                    alt=""
                  />
                  <div className="pr-4 px-2 pt-4">
                    <div
                      className="font-bold text-2xl mb-4"
                      style={{ color: "#4c4c4c" }}
                    >
                      Интерактивная программа Virtual Active
                    </div>
                    <p className="leading-7 mb-2" style={{ color: "#191919" }}>
                      Технология реалистичного погружения Virtual Active
                      синхронизирует скорость тренировки вашего клиента с
                      красивыми видеороликами в формате высокого разрешения, в
                      которых они смогут путешествовать по легендарным городам и
                      экзотическим местам планеты.
                    </p>
                    <Link
                      to="/connected-solutions/unique-programs"
                      className="block text-red-600 hover:text-red-700 font-bold"
                    >
                      См. Virtual Active /
                    </Link>
                  </div>
                </div>
              </Link>
              <Link to="">
                <div
                  className="grid md:grid-cols-2 gap-6 mt-4"
                  style={{ background: "#fafafacc" }}
                >
                  <img
                    src="https://images.jhtassets.com/6dae57ccded77755c1014ffe0213fdb0b114b65a/"
                    alt=""
                  />
                  <div className="pr-4 px-2 pt-4">
                    <div
                      className="font-bold text-2xl mb-4"
                      style={{ color: "#4c4c4c" }}
                    >
                      Engage 360
                    </div>
                    <p className="leading-7 mb-2" style={{ color: "#191919" }}>
                      Теперь у вас появились новые мотивирующие, эффективные
                      способы связи и взаимодействия с клиентами. Портал Engage
                      360 позволяет с легкостью назначать упражнения, получать
                      обратную связь и извлекать данные для использования в
                      целях компенсации ухода.
                    </p>
                    <Link
                      to="/connected-solutions/engage360"
                      className="block text-red-600 hover:text-red-700 font-bold"
                    >
                      См. портал Engage 360 /
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div>
              <div
                className="text-3xl md:text-5xl leading-none"
                style={{ color: "#8fa2aa" }}
              >
                ПРОСТАЯ И В ТО ЖЕ ВРЕМЯ СЛОЖНАЯ ТЕХНОЛОГИЯ
              </div>
              <p className="text-zinc-700 md:text-white leading-6 my-4">
                Когда вы пытаетесь сделать тело здоровым и сохранить его таким,
                вам нужна технология, которая не будет вам мешать. Вот почему
                все наши технологии отличаются простым доступом, интуитивно
                понятны для навигации и имеют универсальные функции, которые
                дают вам свободу использовать их по-своему. Когда использовать
                технологии для расширения ваших возможностей так просто, вы
                можете сосредоточиться на том, что действительно важно — помочь
                своим клиентам делать шаги на пути к здоровью.
              </p>
              <Link
                to="/connected-solutions"
                className="block text-red-600 hover:text-red-700 font-bold"
              >
                Узнать больше /
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Container maxWidth="xl" className="md:!px-16">
        <div
          className="text-center mb-4 text-3xl md:text-6xl leading-none uppercase"
          style={{ color: "#4c4c4c" }}
        >
          ПРОЕКТЫ, КОТОРЫЕ ДВИЖУТ ВАМИ
        </div>
        <p
          className="leading-7 mb-4 px-16 text-center mx-auto"
          style={{ color: "#191919" }}
        >
          Хотите помочь своим клиентам двигаться? Наше продуманное оборудование
          делает каждый шаг и взмах проще и эффективнее, чем когда либо. В нашем
          широком ассортименте оборудования найдется все необходимое для
          улучшения буквально каждого аспекта здоровья вне зависимости от
          возраста и травм ваших клиентов. Правильное сочетание прогресса и
          увеличения интенсивности будет мотивировать ваших клиентов не
          прекращать движение вперед.
        </p>
        <div className="grid md:grid-cols-3 gap-6 py-16 lg:px-16">
          <div
            className="px-4 py-6"
            style={{
              background:
                "url(https://images.jhtassets.com/0d12dcdc94bd05485b85eb369454690d71c8cbfb/) no-repeat",
            }}
          >
            <p style={{ color: "#191919" }}>
              Наш широкий ассортимент удобного и надежного оборудования для
              кардиотренировок включает в себя все необходимое для здорового и
              естественного движения ваших клиентов.
            </p>
          </div>
          <HoverBoxAnimation
            image="https://images.jhtassets.com/909c7d779dc190a7029aeb5912a68060ceeffc79/"
            title="Беговая дорожка"
            text="Тренажер T3xm разработан для внедрения ходьбы на ранних этапах продолжительного ухода. Он отличается низкими высотой входа и начальной скоростью, эргономичными поручнями и светодиодной консолью, на которой отображается важная для клиента информация. "
            link="Узнать больше"
            linkTo="/cardio/treadmills/t3xm"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/16f88c385d8a89058a086a4d8423079d2046f532/"
            title="Эллиптический тренажер Ascent Trainer"
            text="Вход со стороны задней части и низкая высота входа обеспечивают простой доступ к кардиотренировке всего тела с малой ударной нагрузкой. Контралатеральные действия позволяют имитировать естественные, здоровые движения, задействуя различные мышцы с элементами легкой силовой тренировки. "
            link="Узнать больше"
            linkTo="/cardio/performance/ascent-trainer/touch"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/df8d6c2fd3d703cdead8f4579207208f1136851f/"
            title="Велотренажер с вертикальной посадкой"
            text="Вертикальная конструкция обеспечивает возможность кардиотренировок как стоя, так и сидя с малыми ударными нагрузками. "
            link="Узнать больше"
            linkTo="/cardio/performance/upright-cycle/touch"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/b644ec9281a10698cfe0869b265c8664a0e95848/"
            title="Велотренажер с гибридной конструкцией"
            text="Гибридная конструкция сочетает в себе комфорт горизонтального велотренажера с эффективностью вертикального, представляя собой идеальный промежуточный этап продолжительного ухода. "
            link="Узнать больше"
            linkTo="/cardio/performance/hybrid-cycle/touch"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/8e5bf428f7d639fda8729b234a63781bc1c6f3e5/"
            title="Велотренажер с горизонтальной посадкой"
            text="Горизонтальная конструкция R3xm обеспечивает усиленную поддержку и улучшенное распределение массы. Тренажер оснащается рычагами педалей с независимой регулировкой для пользователей с ограниченным диапазоном движений, а также сиденьем с функцией поворота на 160&nbsp;градусов, что позволяет удовлетворять потребности клиентов с различной физической формой. "
            link="Узнать больше"
            linkTo="/cardio/cycles/r3xm-recumbent"
          />
        </div>
      </Container>
      <section
        className="section-5 !bg-cover !bg-center"
        style={{
          background:
            "url(https://images.jhtassets.com/ea014fa0ec478f9a464c3a1b06a9022eb97b74be/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-16">
          <div className="grid md:grid-cols-3 gap-6 py-16 lg:px-16">
            <div
              className="px-4 py-6"
              style={{
                background:
                  "url(https://images.jhtassets.com/0d12dcdc94bd05485b85eb369454690d71c8cbfb/) no-repeat",
              }}
            >
              <p style={{ color: "#191919" }}>
                Выбирайте наше прочное силовое оборудование, чтобы помочь своим
                клиентам развить функциональную силу, необходимую для того,
                чтобы они могли делать то, что любят.
              </p>
            </div>
            <HoverBoxAnimation
              image="https://images.jhtassets.com/d155bc4ecb897c2a6b8900aa033c7d14860a0a9e/"
              title="Adjustable Pulley"
              text="Конструкция, отвечающая европейским стандартам, предлагает множество отправных точек: низкий начальный вес и шкив, передающий усилие в соотношении 1&nbsp;:&nbsp;2, позволят медицинским работникам разработать идеальные упражнения для каждого клиента и построить эффективную программу тренировок. "
              link="Узнать больше /"
              linkTo="/strength/single-station/md-ap-medical-adjustable-pulley"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/b358368ec780ad30b4199d976e85deca30ec41e3/"
              title="Leg Press"
              text="Конструкция, отвечающая европейским стандартам, предлагает множество регулировок и различные варианты конфигураций для разработки реабилитационных и развивающих силовых упражнений на мышцы нижней части тела. "
              link="Узнать больше /"
              linkTo="/strength/single-station/md-s70-medical-leg-press"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/00bf283bb0c7aaa112fb90ab03c434b6b7d8aa15/"
              title="Leg Extension / Leg Curl"
              text="Конструкция, отвечающая европейским стандартам, отличается простотой модификаций и регулировкой весов с небольшим шагом, что упрощает разработку упражнений, развивающих силу мышц задней поверхности бедер и квадрицепсов. "
              link="Узнать больше /"
              linkTo="/strength/single-station/md-s711-medical-leg-extension-leg-curl"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/093ac4a9c69dea491e65a3a0a46bd1bf273b0d88/"
              title="Комплекс для функционального и группового тренинга Connexus Perimeter"
              text="Открытая конструкция максимально упрощает доступ для клиентов и обеспечивает прекрасный обзор для медицинского персонала, а разнообразие возможностей для тренировки упрощает разработку упражнений, идеально подходящих для клиентов в зависимости от их потребностей и возможностей. "
              link="Узнать больше /"
              linkTo="/group-training/strength-flexibility/connexus-perimeter"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/9d792ec79db389955f3660cdc451908b1e46d326/"
              title="Функциональный тренажер"
              text="Конструкция, обеспечивающая возможность доступа на инвалидной коляске, позволяет выполнять упражнения, сидя на скамье, в кресле или на гимнастическом мяче. Идеально подходит для отработки шаблонов движений во время функциональной тренировки, в период восстановления после травм или операций. "
              link="Узнать больше /"
              linkTo="/strength/multi-station/vs-vft-functional-trainer-30"
            />
          </div>
        </Container>
      </section>
      <Container maxWidth="xl" className="md:!px-16">
        <div className="grid md:grid-cols-3 gap-6 py-16 lg:px-16">
          <div
            className="px-4 py-6"
            style={{
              background:
                "url(https://images.jhtassets.com/0d12dcdc94bd05485b85eb369454690d71c8cbfb/) no-repeat",
            }}
          >
            <p style={{ color: "#191919" }}>
              Наши эксклюзивные новые продукты предоставляют интересные
              возможности для улучшения, восстановления или оттачивания таких
              навыков, как растяжка, чувство равновесия и координация.
            </p>
          </div>
          <HoverBoxAnimation
            image="https://images.jhtassets.com/9817e30246b5292ba87d78ccce1bc621ebfbe588/"
            title="Connexus Step+"
            text="Connexus Step+&nbsp;— это компактная, универсальная степ-платформа, может использоваться как в стандартной, так и в перевернутой конфигурации. Она идеально подходит для прокачивания мышц кора, нижней части тела и кардиотренировок. "
            link="Узнать больше /"
            linkTo="/group-training/strength-flexibility/connexus-step-plus"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/5fb11475d9049719cc5efbea73834491bdca1f3d/"
            title="Тренажер Krankcycle"
            text="Отлично подходит для инвалидов-колясочников или клиентов, проходящих реабилитацию. Велотренажер для рук Krankcycle позволяет проводить кардиотренировку верхней части тела, обеспечивая равномерную нагрузку на мышцы с помощью коленчатых рычагов, которые предотвращают перегрузку доминантной руки. "
            link="Узнать больше /"
            linkTo="/group-training/cardio/krankcycle"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/a26591c56117270fa8e4dc242853bd96d041976e/"
            title="Тренажер S-Drive Performance Trainer"
            text="Нулевая начальная скорость позволяет клиентам начинать тренировку в комфортном для себя темпе и постепенно повышать интенсивность, а регулируемый ремень дает возможность выполнять боковые перемещения в соответствии со своим уровнем подготовки. "
            link="Узнать больше /"
            linkTo="/group-training/catalog?modalities=performance-trainers"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/885bf9027726bf0271766b094c0b9c6a12e9e4d7/"
            title="Гребец"
            text="Ультраплавные движения нашего гребца обеспечивают возможность проведения кардиотренировок различной интенсивности, практически не подвергая суставы нагрузке. "
            link="Узнать больше /"
            linkTo="/group-training/cardio/rower"
          />
          <HoverBoxAnimation
            image="https://images.jhtassets.com/9d792ec79db389955f3660cdc451908b1e46d326/"
            title="Функциональный тренажер"
            text="Конструкция, обеспечивающая возможность доступа на инвалидной коляске, позволяет выполнять упражнения, сидя на скамье, в кресле или на гимнастическом мяче. Идеально подходит для отработки шаблонов движений во время функциональной тренировки, в период восстановления после травм или операций. "
            link="Узнать больше /"
            linkTo=""
          />
        </div>
        <div className="flex justify-center mb-16">
          <Link
            to="/contact-us"
            className="inline-block text-white font-black text-xl md:text-2xl px-8 py-3"
            style={{ background: "#799099" }}
          >
            Оставайтесь на связи
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default ActiveAging;
