import { Container } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import { getFilter } from "../redux/actions/filterActions";

const StrengthVarsity = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/139a3cfb41f8eb2c256270eb612b0024366c12f4/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-5xl relative z-10 px-5 md:text-white">Серия Varsity</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container>
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Если вы ищете оборудование с высокими характеристиками и разумной ценой для заведений с большим потоком посетителей, то оборудование серии Varsity подойдет вам идеально. Оно обладает не только высокими характеристиками и отличной эргономики, которые уже привычно характерны для остального оборудования Matrix, но и невероятной долговечностью, позволяющей справиться с постоянным потоком посетителей.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 14)
                    dispatch(getFilter({
                        series: 14,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии Varsity"
						subtitle="В серии Varsity представлены разнообразные тренажеры с дисковым нагружением, а также два многоместных функциональных комплекса Functional Trainer."
						image="https://images.jhtassets.com/443343e255e29084bd0becd7adf530a68e3715bd/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3'>
					<CardBox
						boxHeight="650px"
						title="Нагружаемые дисками тренажеры"
						text="Различные надежные нагружаемые дисками тренажеры очень важны для того, чтобы тяжелоатлеты могли работать над основными группами мышц. Спортсмены могут легко работать с нашими тренажерами, навешивать на них грузы и снимать их. В результате они могут достигать поставленных перед собой целей и не останавливаться на достигнутом."
						img="https://images.jhtassets.com/15ad839d63a46aa0dc74ab21cc9e1685bd3f0e1d/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Универсальности и экономия места"
						text="С помощью полностью закрытых блоков грузов, расположенных под углом к натягиваемому тросу, можно создать универсальную и в то же время компактную зону для персональных тренировок."
						img="https://images.jhtassets.com/5964d72df7b18d280d7082ad8e99159b074fd388/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default StrengthVarsity