import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { createRoom } from '../redux/actions/cartActions';
import Loader from '../components/loader/Loader';

const TotalSolutionsPartnersFacilityPlanning = () => {
    const dispatch = useDispatch();
    const { roomLoading } = useSelector((state) => state.cart);
    const { roomStep } = useSelector((state) => state.cart);
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [kvadrat, setKvadrat] = useState();
    const [photo, setPhoto] = useState(null);

    const onImageChange = (file, element) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        setPhoto(file);
    };

  return (
    <section className='totalSolutionsPartnersFacilityPlanning-page'>
        <section className='section-1 flex items-end !bg-cover !bg-center' style={{background: 'url(https://images.jhtassets.com/af1b6ce3dcb0dfc4041ffffc5b9c47396cf65b96/) no-repeat', height: '60vh'}}>
            <Container maxWidth="xl" className='md:!px-16'>
                <div className='mb-16 text-5xl'><strong>Студия дизайна</strong>Matrix</div>
            </Container>
        </section>
        <Container maxWidth="xl" className='md:!px-12'>
            <p className='md:w-4/6 my-5 md:my-12' style={{ color: '#191919' }}>Студия дизайна Matrix обеспечивает доступ к широкому ряду изображений нашей продукции и другим ресурсам для планирования помещений, которые можно использовать на любом этапе проектных работ. Независимо от того, разрабатываете ли вы только концепцию помещения или создаете окончательную презентацию, мы хотим помочь вам выбрать оборудование, которое лучше всего подойдет вашему заведению.</p>
            <div className='grid lg:grid-cols-3 gap-5 my-16'>
                <div className='px-4 py-6 flex flex-col' style={{ background: 'rgba(106,115,123,.2)' }}>
                    <div className='text-3xl font-black leading-none' style={{ color: '#4c4c4c' }}>Двумерные схемы</div>
                    <p className='lg:my-auto my-4' style={{color: '#191919'}}>Создайте двумерную схему пространства вашего заведения с помощью онлайн-инструмента планирования помещений на основе технологий Icovia®.</p>
                    <a href='https://johnsonfit.icovia.com/frontend/index.html' className='block mt-auto bg-red-600 text-white text-center px-3 py-2 font-black hover:bg-red-700 duration-200 cursor-pointer'>СРЕДСТВО ПЛАНИРОВАНИЯ ПОМЕЩЕНИЙ</a>
                </div>
                <div className='lg:col-span-2'>
                    <div className='text-3xl font-black leading-none' style={{color: '#6a737b' }}>Онлайн-портал для архитекторов</div>
                    <p className='my-6' style={{color: '#191919'}}>Архитекторы и дизайнеры тренажерных залов, которые используют собственное программное обеспечение для планировки пространства, смогут найти на нашем онлайн-портале библиотеку доступных для загрузки материалов и продуктов для кардио-, силовых и групповых тренировок. При этом вы можете выбрать как двумерные схематические изображения в формате .dwg, так и трехмерные изображения в формате .3ds.</p>
                    {roomStep === 0 ?
                    <>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 md:gap-3'>
                        <TextField onChange={(e) => setName(e.target.value)} className={`!mb-5 bg-slate-100`} size='small' style={{ width: '100%'}} type='text' id="outlined-basic" label="Имя" variant="outlined" />
                        <TextField onChange={(e) => setPhone(e.target.value)} className={`!mb-5 bg-slate-100`} size='small' style={{ width: '100%'}} type='number' id="outlined-basic" label="Номер телефона" variant="outlined" />
                        <TextField onChange={(e) => setKvadrat(e.target.value)} className={`!mb-5 bg-slate-100`} size='small' style={{ width: '100%'}} type='number' id="outlined-basic" label="Квадрат" placeholder='m²' variant="outlined" />
                        <div className='bg-slate-100 relative hover:!border-black' style={{height: '40px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '8.5px 14px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', color: 'rgba(0, 0, 0, 0.6)'}}>
                            <input
                                id="photo"
                                onChange={(e) =>
                                    onImageChange(
                                    e.target.files[0],
                                    document.querySelectorAll("#avatarImage")[0]
                                    )
                                }
                                className="absolute top-0 right-0 bottom-0 left-0"
                                style={{ zIndex: "10", opacity: 0, cursor: "pointer" }}
                                type="file"
                            />
                            {photo?.name || 'Выберите файл'}
                        </div>
                    </div>
                    <button disabled={(name?.length > 0 && phone?.length > 0 && kvadrat?.length > 0 && photo !== null) ? false : true} onClick={() =>
                        dispatch(createRoom({
                            name,
                            phone,
                            kvadrat,
                            file: photo,
                        }))
                    } className={`${(name?.length > 0 && phone?.length > 0 && kvadrat?.length > 0 && photo !== null) ? 'cursor-pointer bg-red-600' : '!bg-red-100'} block md:w-max w-full ml-auto md:mt-0 mt-5 bg-red-600 text-white text-center px-6 py-3 text-lg font-black hover:bg-red-700 duration-200`}>{roomLoading ? 'Loading...' : 'Отправить'}</button>
                    </>
                    :
                    <div className='text-3xl text-center font-black' style={{color: '#4c4c4c'}}>Успешно!</div>
                    }
                    {/* <Link to='https://design.matrixfitness.com/en' className='inline-block border border-red-600 text-red-600 text-center px-6 py-2 hover:bg-indigo-50 duration-200 cursor-pointer'>ВОЙТИ</Link> */}
                </div>
            </div>
        </Container>
        <img src='https://images.jhtassets.com/a6b8b644ebdff9349fff5ed52001feff0c249c68/' className='w-full h-auto object-cover mb-12' alt='' />
        <Container maxWidth='xl' className='md:!px-16'>
            <div className='font-bold text-2xl leading-none mb-4'>Необходима дополнительная помощь?</div>
            <p style={{ color: '#191919' }}>Мы рады предоставить вам дополнительные ресурсы:</p>
            <div className='grid md:grid-cols-3 gap-4 my-4 md:my-16'>
                <div className='col-span-2'>
                    <div className='font-bold text-2xl leading-none mb-4'>Трехмерное планирование помещений</div>
                    <p style={{color: '#191919'}}>Получите лицензию на простой в использовании инструмент EcDesign для трехмерного планирования от нашего партнера, компании Easysale, сроком на один год. Он позволит вам быстро создавать планы, схемы и презентации помещений профессионального качества.</p>
                    <p style={{color: '#191919', marginTop: '4px'}}>Дополнительная информация об EcDesign: <Link to='' className='text-red-600 hover:underline hover:text-red-700'>ecdesign.se</Link></p>
                    <p style={{color: '#191919', marginTop: '4px'}}>Получение лицензии: <a href='https://www.ecdesign.se/' className='text-red-600 hover:underline hover:text-red-700'>info@ecdesign.se</a></p>
                </div>
                <div>
                    <div className='font-bold text-2xl leading-none mb-4'>Обратная связь</div>
                    <p style={{color: '#191919'}}>Свяжитесь с местным представительством компании Matrix, если у вас возникли какие-либо вопросы.</p>
                    <a href='mailto:info@ecdesign.se' className='text-red-600 hover:underline hover:text-red-700'>Воспользуйтесь нашим списком для выбора вашей страны.</a>
                </div>
            </div>
        </Container>
    </section>
  )
}

export default TotalSolutionsPartnersFacilityPlanning;