import React from 'react';
import { Link } from 'react-router-dom'

const CardBox = (props) => {
  return (
   <div className='CardBox flex flex-col'
    style={{
      background: props.contentBgColor,
      boxShadow: props.boxShadow
    }}
   >
    <img src={props.img} alt="" style={{height: '350px', objectFit: 'cover'}} />
    <div className='md:p-4 md:pb-8 px-2 pb-6' style={{
      paddingTop: props.contentPt,
      flex: '1 1 50%'
    }}>
      <h2 className={props.color + ' font-extrabold mb-4 leading-none'} style={{color: props.color, fontSize: '28px'}}>{props.title}</h2>
      <p style={{color: props.textColor}}>{props.text}</p>
      {
        props.href && props.link &&
        <a
          href={props.href ? props.href : ''}
          className='text-red-600 font-bold mt-4 flex hover:underline'
          style={{color: props.linkColor}}
        >
          {props.link}
        </a>
      }
      {
        props.link && !props.href
        ? <Link
            to={props.linkTo ? props.linkTo : ''}
            className='text-red-600 font-bold mt-4 flex hover:underline'
            style={{color: props.linkColor}}
          >
            {props.link}
          </Link>
          : ''
      }
    </div>
   </div>
  );
};

export default CardBox;