import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./_header.scss";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {
  category,
  getFilter,
  getSearch,
} from "../../redux/actions/filterActions";
import { CgMenu } from "react-icons/cg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
        color: "rgb(193, 199, 203)",
      },
    },
  },
}));
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.appendChild(
  //     !(function (f, b, e, v, n, t, s) {
  //       if (f.fbq) return;
  //       n = f.fbq = function () {
  //         n.callMethod
  //           ? n.callMethod.apply(n, arguments)
  //           : n.queue.push(arguments);
  //       };
  //       if (!f._fbq) f._fbq = n;
  //       n.push = n;
  //       n.loaded = !0;
  //       n.version = "2.0";
  //       n.queue = [];
  //       t = b.createElement(e);
  //       t.async = !0;
  //       t.src = v;
  //       s = b.getElementsByTagName(e)[0];
  //       s.parentNode.insertBefore(t, s);
  //     })(
  //       window,
  //       document,
  //       "script",
  //       "https://connect.facebook.net/en_US/fbevents.js"
  //     ),
  //     fbq("init", "1591404594698445"),
  //     fbq("track", "PageView")
  //   );
  //   const noscript = document.createElement("noscript");
  //   noscript.appendChild(
  //     <img
  //       height="1"
  //       width="1"
  //       style="display:none"
  //       src="https://www.facebook.com/tr?id=1591404594698445&ev=PageView&noscript=1"
  //     />
  //   );

  //   document.body.appendChild(script);
  // }, []);
  // const location = useLocation().pathname;
  const [filterSearch, setFilterSearch] = useState({ search: "" });
  const [filter, setFilter] = useState({
    category: window.localStorage.getItem("category_id"),
    series: window.localStorage.getItem("seria_id"),
  });
  useEffect(() => {
    dispatch(category());
    dispatch(getFilter(filter));
  }, []);
  const allCategories = useSelector((state) => state.filter.categories);
  const cardio = allCategories?.find(
    (item) => item.name === "Кардиооборудование"
  );
  const strength = allCategories?.find((item) => item.name === "Сила");
  const group = allCategories?.find(
    (item) => item.name === "Групповые тренировки"
  );
  const [dropdown, setDropdown] = useState(false);
  const [fixedNavbar, setFixedNavbar] = useState(false);
  // categories
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const [eight, setEight] = useState(false);
  const [nine, setNine] = useState(false);
  const [ten, setTen] = useState(false);

  const changeScrollNavbar = () => {
    if (window.scrollY >= 45) {
      setFixedNavbar(true);
    } else {
      setFixedNavbar(false);
    }
  };

  window.addEventListener("scroll", changeScrollNavbar);

  return (
    <div className="media-main-height" style={{ minHeight: "100px" }}>
      <header className="header bg-black flex items-center justify-between md:px-6 px-3.5 fixed w-full z-20 ">
        <Link to="" onClick={() => setDropdown(false)}>
          <img src={logo} alt="" />
        </Link>
        <ul className="header-menu xl:flex hidden menu flex flex-auto justify-center">
          <li
            onClick={(e) => setDropdown((e) => !e)}
            className={`menu-item cursor-pointer flex items-center menu-link header-dropdown ${
              fixedNavbar ? "py-5 px-8" : "py-10 px-5"
            } duration-500 flex uppercase hover:bg-white hover:text-black text-sm font-medium`}
            style={{ color: "#c1c7cb" }}
          >
            ОБОРУДОВАНИЕ ДЛЯ КЛУБА
          </li>
          <li className="menu-item">
            <Link
              to="/about-us"
              onClick={() => setDropdown(false)}
              className={`menu-link ${
                fixedNavbar ? "py-5 px-8" : "py-10 px-5"
              } duration-500 flex uppercase hover:text-white hover:bg-red-700 text-sm font-medium`}
              style={{ color: "#c1c7cb" }}
            >
              О нас
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to="/contact-us"
              onClick={() => setDropdown(false)}
              className={`menu-link ${
                fixedNavbar ? "py-5 px-8" : "py-10 px-5"
              } duration-500 flex uppercase hover:text-white hover:bg-red-700 text-sm font-medium`}
              style={{ color: "#c1c7cb" }}
            >
              ОБРАТНАЯ СВЯЗЬ
            </Link>
          </li>
        </ul>
        <div className="flex items-center">
          <Search className="md:!ml-0 md:!w-auto !w-auto !ml-3.5">
            <SearchIconWrapper>
              <SearchIcon className="header-search-icon" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Поиск"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                let newSearch = {
                  ...filterSearch,
                  search: e.target.value,
                };
                setFilterSearch(newSearch);
              }}
              onKeyPress={(e: KeyboardEvent<HTMLDivElement>) =>
                e.key == "Enter" &&
                (dispatch(getSearch(filterSearch)),
                setDropdown(false),
                navigate("/search"))
              }
            />
          </Search>
          <CgMenu
            onClick={() => setDropdown((value) => !value)}
            className="xl:hidden ml-3.5"
            color="white"
            style={{ minWidth: "30px", height: "30px" }}
          />
        </div>
        <a
          href="tel:+998951966663"
          className="xl:block hidden text-white font-bold ml-4"
        >
          +998 95 196 66 63
        </a>
        <div
          onClick={() => setDropdown(false)}
          className={`${
            dropdown === true ? "block" : "hidden"
          } fixed left-0 right-0 bottom-0 media-bg-white`}
          style={{ top: "100px" }}
        ></div>
        <div
          className={`${
            dropdown === true ? "block" : "hidden"
          } dropdown-menu absolute left-0 right-0 bg-white xl:p-6`}
          style={{ top: "100%" }}
        >
          <ul className="menu-ul lg:w-1/4 md:w-2/6 xl:ml-0 ml-auto">
            <li
              onClick={(e) => setOne((e) => !e)}
              className={`list ${one === true && "active"}`}
            >
              <div className="dropdown-title">КАРДИООБОРУДОВАНИЕ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/cardio"
                  >
                    КАРДИООБОРУДОВАНИЕ
                  </Link>
                </li>
                <div className="grid xl:grid-cols-3 lg:grid-cols-2 gap-4 overflow-y-auto">
                  <div>
                    <div>
                      <label
                        className="block text-left uppercase"
                        style={{ color: "#7f7f7f" }}
                      >
                        КОНСОЛИ
                      </label>
                      <li>
                        <Link
                          onClick={() => setDropdown(false)}
                          to="/cardio/consoles"
                        >
                          Все консоли
                        </Link>
                      </li>
                      <label
                        className="block text-left uppercase"
                        style={{ color: "#7f7f7f" }}
                      >
                        избранные консоли
                      </label>
                      <li>
                        <Link
                          onClick={() => setDropdown(false)}
                          to="/cardio/consoles/touch"
                        >
                          Консоли Touch
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => setDropdown(false)}
                          to="/cardio/consoles/premium-led"
                        >
                          Светодиодная консоль Premium LED
                        </Link>
                      </li>
                    </div>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      Серия
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/cardio/performance"
                      >
                        Серия Performance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/cardio/endurance"
                      >
                        Серия Endurance
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/cardio/lifestyle"
                      >
                        Серия Lifestyle
                      </Link>
                    </li>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      Категории продуктов
                    </label>
                    {cardio?.subCategories?.map((item) => (
                      <li
                        key={item.id}
                        onClick={() => {
                          window.localStorage.setItem(
                            "category_id",
                            Number(item.id)
                          );
                          window.localStorage.removeItem("seria_id");
                          let newFilter = {
                            ...filter,
                            category: item.id,
                          };
                          setFilter(newFilter);
                          dispatch(getFilter(newFilter));
                          setDropdown(false);
                        }}
                      >
                        <a href="/cardio/catalog">{item.name}</a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setTwo((e) => !e)}
              className={`list ${two === true && "active"}`}
            >
              <div className="dropdown-title">СИЛА</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/strength"
                  >
                    СИЛА
                  </Link>
                </li>
                <div className="grid lg:grid-cols-2 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/intelligent-training-console"
                      >
                        INTELLIGENT TRAINING CONSOLE
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/ultra"
                      >
                        Серия ULTRA
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/versa"
                      >
                        Серия VERSA
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/aura"
                      >
                        Серия AURA
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/magnum"
                      >
                        Серия MAGNUM
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/varsity"
                      >
                        Серия VARSITY
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/go"
                      >
                        Серия GO
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/strength/g1"
                      >
                        Серия G1
                      </Link>
                    </li>
                  </div>
                  <div>
                    {strength?.subCategories?.map((item) => (
                      <li
                        onClick={() => {
                          window.localStorage.setItem("category_id", item.id);
                          window.localStorage.removeItem("seria_id");
                          let newFilter = {
                            ...filter,
                            category: item.id,
                          };
                          setFilter(newFilter);
                          dispatch(getFilter(newFilter));
                        }}
                        key={item.id}
                      >
                        <a
                          onClick={() => setDropdown(false)}
                          href="/strength/catalog"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setThree((e) => !e)}
              className={`list ${three === true && "active"}`}
            >
              <div className="dropdown-title">ГРУППОВЫЕ ТРЕНИРОВКИ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/group-training"
                  >
                    ГРУППОВЫЕ ТРЕНИРОВКИ
                  </Link>
                </li>
                <div className="grid lg:grid-cols-2 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/group-training/cardio"
                      >
                        КАРДИООБОРУДОВАНИЕ
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/group-training/functional-strength"
                      >
                        Силовые упражнения и растяжка
                      </Link>
                    </li>
                  </div>
                  <div>
                    {group?.subCategories?.map((item) => (
                      <li
                        onClick={() => {
                          window.localStorage.setItem("category_id", item.id);
                          window.localStorage.removeItem("seria_id");
                          let newFilter = {
                            ...filter,
                            category: item.id,
                          };
                          setFilter(newFilter);
                          dispatch(getFilter(newFilter));
                        }}
                        key={item.id}
                      >
                        <a
                          onClick={() => setDropdown(false)}
                          href="/group-training/catalog"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setFour((e) => !e)}
              className={`list ${four === true && "active"}`}
            >
              <div className="dropdown-title">ГРУППОВЫЕ ТРЕНИРОВКИ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/education"
                  >
                    ОБУЧЕНИЕ
                  </Link>
                </li>
                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/education/matrix-ride"
                      >
                        Matrix Ride
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/education/mx4"
                      >
                        MX4
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/education/mx4-active"
                      >
                        MX4 Active
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/education/connexus"
                      >
                        Connexus
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setFive((e) => !e)}
              className={`list ${five === true && "active"}`}
            >
              <div className="dropdown-title">
                CONNECTED SOLUTIONS (ЦИФРОВЫЕ РЕШЕНИЯ)
              </div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/connected-solutions"
                  >
                    CONNECTED SOLUTIONS (ЦИФРОВЫЕ РЕШЕНИЯ)
                  </Link>
                </li>
                <div className="grid lg:grid-cols-2 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/engage360"
                      >
                        Engage 360
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/community360"
                      >
                        Community 360
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/asset-management"
                      >
                        Asset Management
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/target-training"
                      >
                        Target Training
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/sprint-8"
                      >
                        Sprint 8
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/unique-programs"
                      >
                        Уникальные программы
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/facility-specific-solutions"
                      >
                        Индивидуальные решения для тренажерных залов
                      </Link>
                    </li>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      Partnerships
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/connected-solutions/partnerships/ifit"
                      >
                        iFIT
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setSix((e) => !e)}
              className={`list ${six === true && "active"}`}
            >
              <div className="dropdown-title">РЕШЕНИЙ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/total-solutions-partners"
                  >
                    ПОСТАВЩИК КОМПЛЕКСНЫХ РЕШЕНИЙ
                  </Link>
                </li>
                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/matrixlearningcenter"
                      >
                        Matrix Learning Center
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/total-solutions-partners/facility-planning"
                      >
                        Планирование тренажерных залов
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setSeven((e) => !e)}
              className={`list ${seven === true && "active"}`}
            >
              <div className="dropdown-title">ИННОВАЦИИ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/innovations"
                  >
                    ИННОВАЦИИ
                  </Link>
                </li>
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 gap-4 overflow-y-auto">
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      КАРДИООБОРУДОВАНИЕ
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/rising-to-new-heights"
                      >
                        Ascent Trainers
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/raising-expectations"
                      >
                        Climbmill
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/performance-plus-treadmill"
                      >
                        Performance Plus Treadmill
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/virtual-training-cycle"
                      >
                        Virtual Training Cycle
                      </Link>
                    </li>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      СИЛА
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/good-break"
                      >
                        Скамья Breaker Bench
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/glute-trainer"
                      >
                        Glute Trainer
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/go-series"
                      >
                        Серия go
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/clear-field-of-vision"
                      >
                        Серия Ultra
                      </Link>
                    </li>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      ГРУППОВЫЕ ТРЕНИРОВКИ
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/high-function"
                      >
                        Connexus
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/finest-rowing-experience"
                      >
                        Гребной тренажер
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/s-drive"
                      >
                        S-Drive
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/training-cycles"
                      >
                        Велотренажеры
                      </Link>
                    </li>
                  </div>
                  <div>
                    <label
                      className="block text-left uppercase"
                      style={{ color: "#7f7f7f" }}
                    >
                      Технология
                    </label>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/ptp-story"
                      >
                        Engage 360
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/premium-led-console"
                      >
                        Светодиодная консоль Premium
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/innovations/touch-console"
                      >
                        Сенсорная консоль
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setEight((e) => !e)}
              className={`list ${eight === true && "active"}`}
            >
              <div className="dropdown-title">РЕАЛЬНЫЕ ПРИМЕРЫ РАЗМЕЩЕНИЯ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/showcases"
                  >
                    РЕАЛЬНЫЕ ПРИМЕРЫ РАЗМЕЩЕНИЯ
                  </Link>
                </li>
                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/health-clubs"
                      >
                        Фитнес-центры
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/hospitality"
                      >
                        Отели
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/athletic-performance"
                      >
                        Спортивная подготовка
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/ymca-jcc"
                      >
                        Программы YMCA и JCC
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/active-aging"
                      >
                        Центры для пожилых людей и реабилитационные центры
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/corporate"
                      >
                        Корпоративные тренажерные залы
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/showcases/multi-housing"
                      >
                        Жилые многоквартирные дома
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setNine((e) => !e)}
              className={`list ${nine === true && "active"}`}
            >
              <div className="dropdown-title">КАНАЛЫ ПРОДАЖ</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/channels"
                  >
                    КАНАЛЫ ПРОДАЖ
                  </Link>
                </li>
                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/health-clubs"
                      >
                        Фитнес-центры
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/hospitality"
                      >
                        Отели
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/athletic-performance"
                      >
                        Спортивная подготовка
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => setDropdown(false)} to="/ymca-jcc">
                        Программы YMCA и JCC
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/active-aging"
                      >
                        Центры для пожилых людей и реабилитационные центры
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/multi-housing"
                      >
                        Жилые многоквартирные дома
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <li
              onClick={(e) => setTen((e) => !e)}
              className={`list ${ten === true && "active"}`}
            >
              <div className="dropdown-title">ПОДДЕРЖКА</div>
              <div className="sub-dropdown absolute top-0 pt-6">
                <li>
                  <Link
                    className={`!text-red-600 hover:!text-white mb-6`}
                    onClick={() => setDropdown(false)}
                    to="/support"
                  >
                    ПОДДЕРЖКА
                  </Link>
                </li>
                <div className="grid grid-cols-1 gap-4 overflow-y-auto">
                  <div>
                    <li>
                      <Link
                        onClick={() => setDropdown(false)}
                        to="/support/faq"
                      >
                        FAQS
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => setDropdown(false)}
                        href="https://jhtmanuals.com/ru/rus/matrix"
                      >
                        Руководства
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
