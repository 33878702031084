import { Container } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BgImageSection from "../components/BgImageSection";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import { getFilter } from "../redux/actions/filterActions";


const StrengthUltra = () => {
	const dispatch = useDispatch();
   return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/fb8d3b3c29949d4d3e569c6404a7de67ddd9ac34/"
					className="md:absolute top-0 left-0 w-full h-full"
					alt=""
				/>
				<h3 className="md:mb-14 text-5xl relative md:mt-0 mt-2 z-10 px-5 md:text-white">Серия Ultra</h3>
			</section>
			<section className="mb-5">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Эффективность и комфорт использования, целый ряд функций и интуитивно понятная работа, самые надежные компоненты и фантастический внешний вид — серия Ultra значительно превысит ваши ожидания от силового тренировочного оборудования, помогая вам привлекать и удерживать клиентов лучше любого другого оборудования.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 4)
                    dispatch(getFilter({
                        series: 4,
                    }))
                }} href='/strength/catalog' className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии Ultra"
						subtitle="В серии Ultra представлен широкий выбор одноместных силовых тренажеров, совместимых с интеллектуальной консолью Intelligent Training Console."
						image="https://images.jhtassets.com/0f304fe571da0da3da600493b0fa69d3c03e14d1/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="700px"
				img="https://images.jhtassets.com/16499ee04f115381284d2b153ceb127220991c96"
				title="Электронный счетчик повторений"
				text="Электронный счетчик отслеживает повторения, время активности и отдыха для оптимизации тренировочного процесса."
				contentWidth="35%"
				titleColor="#fff"
				contentRight={false}
				contentBg="#e1261c"
				textColor="#fff"
				linkTo="/strength/versa"
				paddingTopNull={true}
			/>
			<section className="py-5" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Более комфортные контактные ручки"
						text="Контактные ручки Action Specific Grips имеют специальную эргономичную форму, уменьшающую давление в местах соприкосновения. А их форма, функциональность и приятные на ощупь материалы делают тренировки еще комфортнее."
						img="https://images.jhtassets.com/366a9e19f454d4eba249af0b6d20d92b429f686a/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Более привлекательный внешний вид"
						text="Благодаря одинаковой высоте блока грузов (всего 128 см) на всем оборудований серии Ultra улучшается внешний вид вашего фитнес-центра и обзорность помещений."
						img="https://images.jhtassets.com/3901701acc2ce0f99ecf2a52f830de81a2eb69f1/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
				<Container className="mt-8 mb-8" maxWidth="xl">
					<CardBoxMoreImage
						title="Intelligent Training Console (optional)"
						subtitle="Выбирая серию Ultra с Инновационной Тренировочной Консолью, вы получаете возможность мониторинга своих тренировок из любой точки мира."
						link="Learn more about Intelligent Training Console /"
						image="https://images.jhtassets.com/e974a6eab548c50630ed7e8d3aa7f031e9da8f99/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						linkTo="/connected-solutions/intelligent-training-console"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Свяжитесь с нами"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	);
};

export default StrengthUltra;
