import React from 'react';
import '../assets/scss/_contact.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import backImg from '../assets/images/optimized---05-contact-us-Ccurrent.jpg';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { createReaction } from '../redux/actions/cartActions';
import { useState } from 'react';

const cities = [
  {
    label: 'South Sudan',
  },
  {
    label: 'South Yapon',
  },
  {
    label: 'South Korea',
  },
  {
    label: 'South China',
  },
];

const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName]= useState();
  const [email, setEmail]= useState();
  const [phone, setPhone]= useState();
  const [message, setMessage]= useState();
  const [tema, setTema] = useState('');
  // const [stepState, setStepState] = useState(0);

  const { step } = useSelector((state) => state.cart);
  // useEffect(() => {
  //   if(step === 1){
  //     setStepState(1)
  //   }
  // },[step])

  const handleChange = (event) => {
    setTema(event.target.value);
  };
  const [city, setCity] = useState('EUR');

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  return (
    <section className="contact-page">
        <div className="contact-img relative">
            <img style={{minHeight: '430px', maxHeight: '80vh', width: '100%', objectFit: 'cover'}} src={backImg} alt='' />
            <div className="text-6xl absolute bottom-0 pb-12 sm:pl-12 md:font-medium">Обратная связь</div>
        </div>
        <section className="lg:p-14 px-3 py-6">
            <div className="grid lg:grid-cols-3 gap-10">
                <div className='lg:col-span-2'>
                  {step === 0 ?
                  <>
                  <label className="font-bold">Тема</label>
                  <form>
                      {/* <Box style={{width: '70%', background: '#f5f5f5', margin: '2rem 0'}}>
                          <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Выберите тему</InputLabel>
                              <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tema}
                              label='Выберите тему'
                              onChange={handleChange}
                              >
                              <MenuItem style={{fontWeight: 'bold', color: '#666'}} value={10}>ДЛЯ ВАШЕГО ДОМА: Продажи и обслуживание</MenuItem>
                              <MenuItem style={{fontWeight: 'bold', color: '#666'}} value={20}>ДЛЯ ВАШЕГО ДОМА: Запасные части и обслуживание</MenuItem>
                              <MenuItem style={{fontWeight: 'bold', color: '#666'}} value={30}>ДЛЯ ВАШЕГО ТРЕНАЖЕРНОГО ЗАЛА: Продажи и наличие</MenuItem>
                              <MenuItem style={{fontWeight: 'bold', color: '#666'}} value={40}>ДЛЯ ВАШЕГО ТРЕНАЖЕРНОГО ЗАЛА:  Запасные части и обслуживание</MenuItem>
                              </Select>
                          </FormControl>
                      </Box> */}
                      <div className="flex flex-wrap justify-between">
                          <TextField onChange={(e) => setName(e.target.value)} className='!mb-5 bg-slate-100' style={{width: '49%'}} type='text' id="outlined-basic" label="Имя *" variant="outlined" />
                          {/* <TextField className='!mb-5 bg-slate-100' style={{width: '49%'}} type='text' id="outlined-basic" label="Фамилия *" variant="outlined" /> */}
                          <TextField required onChange={(e) => setEmail(e.target.value)} className='!mb-5 bg-slate-100' style={{width: '49%'}} type='email' id="outlined-basic" label="Електронная почта" variant="outlined" />
                          <TextField onChange={(e) => setPhone(e.target.value)} className='!mb-5 bg-slate-100' style={{width: '100%'}} type='number' id="outlined-basic" label="Номер телефона *" variant="outlined" />
                          {/* <TextField className='!mb-5 bg-slate-100' style={{width: '100%'}} type='text' id="outlined-basic" label="Почтовый адрес" variant="outlined" /> */}
                          {/* <TextField className='!mb-5 bg-slate-100' style={{width: '100%'}} type='text' id="outlined-basic" label="Почтовый адрес 2" variant="outlined" /> */}
                          {/* <TextField className='!mb-5 bg-slate-100' style={{width: '49%'}} type='text' id="outlined-basic" label="Город" variant="outlined" /> */}
                          {/* <Box
                              component="form"
                              className='mb-5'
                              sx={{
                                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                              }}
                              noValidate
                              autoComplete="off"
                              style={{width: '49%', background: '#f5f5f5', height: 'max-content'}}
                            >
                              <div>
                                  <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    label="Выберите регион"
                                    value={city}
                                    onChange={handleChangeCity}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    style={{width: '100%',}}
                                  >
                                  {cities.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                  ))}
                                  </TextField>
                              </div>
                          </Box> */}
                          {/* <TextField type='text' style={{width: '49%', marginBottom: '1.2rem', background: '#f5f5f5', }} id="outlined-basic" label="Почтовый индекс *" variant="outlined" /> */}
                          <textarea onChange={(e) => setMessage(e.target.value)} style={{width: '100%', marginBottom: '1.2rem', background: '#f5f5f5', padding: '16.5px 14px', border: '1px solid #0000003b', color: '#666', fontWeight: 'bold', height: '150px'}} placeholder="Сообщение"></textarea>
                      </div>
                      <Stack spacing={2} direction="row">
                          <Button disabled={(name?.length > 0 && phone?.length > 0) ? false : true} onClick={() => dispatch(createReaction({
                            name,
                            email,
                            phone,
                            message,
                          }))} className={`${(name?.length > 0 && phone?.length > 0) ? '!bg-red-600 hover:!bg-red-700' : '!bg-red-200'}`} style={{background: '#e1261c', color: '#fff', fontSize: '14px', fontWeight: 'bold', borderRadius: '0', padding: '12px 18px'}}>Отправить</Button>
                          <Button onClick={() => window.location.reload()} style={{color: '#e1261c', fontWeight: 'bold'}}>Сброс</Button>
                      </Stack>
                  </form>
                  </>
                  :
                  <>
                  <div className='text-red-600 hover:text-red-700 hover:underline font-black mb-6 cursor-pointer block w-max' onClick={() => window.location.reload()}>Назад /</div>
                  <div className='text-4xl font-black text-center m-auto' style={{color: '#4c4c4c'}}>Спасибо!</div>
                  </>
                  }
                </div>
                <div>
                    <div style={{color: '#4c4c4c'}} className="text-xl leading-none uppercase font-bold mb-4">Официальный дистрибьютор johnson health tech в Узбекистане </div>
                    <p>
                        100128, город Ташкент
                        <br />
                        Тел: <a className="text-red-700 hover:underline hover:text-red-900" href='tel:+998951966663'>+998 95 196 66 63</a>
                        <br />
                        <a className="text-red-700 hover:underline hover:text-red-900" href='mailto:matrixfitnessuzb@gmail.com'>matrixfitnessuzb@gmail.com</a>
                    </p>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.3771714714853!2d69.26235715085365!3d41.32241107916845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b174b81cfd3%3A0x5fa4495cbcccb2b5!2sMatrix%20Fitness%20Uzbekistan!5e0!3m2!1sru!2s!4v1660981245598!5m2!1sru!2s" width="100%" height="250" className='mt-4' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    </section>
  )
}

export default Contact;