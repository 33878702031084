import axios from 'axios';

const API = 'https://admin.matrixfitness.uz/api';

const packageForm = (data) => {
  const form = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const childKey in data[key]) {
        form.append(`${key}[${childKey}]`, data[key][childKey]);
      }
    } else {
      form.append(key, data[key]);
    }
  }
  return form;
};

// const config = {
//   headers: { Authorization: API }
// }

const request = {
  getCarts: () => axios.get(`${API}/getProducts`),
  getCartDetail: (id) => axios.get(`${API}/getOneProductById/${id}`),
  category: () => axios.get(`${API}/getCategories`),
  getFilter: (params) => axios.get(`${API}/filterProducts`, { params }),
  getSearch: (params) => axios.get(`${API}/search`, { params }),
  createFeedback: (params) => axios.post(`${API}/createFeedback`, params),
  createReaction: (params) => axios.post(`${API}/createReaction`, params),
  createRoom: (params) => axios.post(`${API}/createRoom`, packageForm(params)),
}

export default request;