import React from "react";
import { Container, Button, Box } from "@mui/material";
import CardBox from "../components/CardBox";
import { BsPlayCircle } from "react-icons/bs";
import { Navigation, A11y, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $13 from "../assets/videos/1-13.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const InnovationsPerformancePlusTreadmill = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <section
        className="flex items-end justify-center relative"
        style={{ height: "70vh" }}
      >
        <img
          src="https://images.jhtassets.com/e936704040757a0f160f6f9fad10d53aab0698b7/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container className="flex-col gap-5 items-center relative z-10">
          <p className="text-white uppercase font-bold mb-5" data-aos="fade-up">
            В ЧЕМ СЕКРЕТ ПЛАСТИНЧАТОГО БЕГОВОГО ПОЛОТНА
          </p>
          <h5
            className="text-white text-3xl lg:text-5xl font-bold pb-10"
            data-aos="fade-up"
          >
            БЕГОВАЯ ДОРОЖКА PERFORMANCE PLUS
          </h5>
        </Container>
      </section>
      <section>
        <p className="max-w-5xl p-14" data-aos="fade-up">
          Matrix всегда стремится повышать качество бега как для владельцев
          наших беговых дорожек, так и для любителей фитнеса, пользующихся ими.
          Нам известно, что беговые дорожки с пластинчатым полотном обладают
          уникальными преимуществами как для своих владельцев, так и для
          пользователей. В связи с этим возникает интересный вопрос:
        </p>
        <p
          className="text-red-600 text-2xl italic mb-5 py-20 max-w-4xl font-bold text-center mx-auto"
          data-aos="fade-up"
        >
          "КАК СДЕЛАТЬ СТОЛЬ ЭФФЕКТИВНЫЕ ДОРОЖКИ БОЛЕЕ ДОСТУПНЫМИ ДЛЯ БОЛЬШЕГО
          ЧИСЛА ПОЛЬЗОВАТЕЛЕЙ И ВЛАДЕЛЬЦЕВ?"
        </p>
      </section>
      <section className="my-10">
        <Container maxWidth="xl" className="md:!px-12">
          <h3
            className="text-zinc-700 mb-4 font-black text-3xl uppercase text-center"
            data-aos="fade-up"
          >
            ИНТЕРПРЕТАЦИЯ РЕЗУЛЬТАТОВ
          </h3>
          <p className="mb-10 max-w-5xl text-center mx-auto" data-aos="fade-up">
            Чтобы ответить на этот вопрос, мы сделали то, что делаем всегда: мы
            поставили под сомнение свои старые выводы и опросили разных людей,
            использующих как традиционные беговые дорожки, так и беговые дорожки
            с пластинчатым полотном. Результаты изучения нашей командой
            преимуществ беговых дорожек с пластинчатым полотном оказались
            интригующе противоречивыми:
          </p>
          <Box className="grid lg:grid-cols-3 gap-3">
            <div data-aos="fade-right">
              <CardBox
                title="1."
                text="хотя само пластинчатое полотно требует меньше обслуживания, чем традиционное, основные компоненты беговой дорожки с пластинчатым полотном оказались слишком сложными в обслуживании из-за своего расположения непосредственно под беговой поверхностью."
                img="https://images.jhtassets.com/bf9555cb4b4e19b16842b7e64c24cdcc40e6bc89/"
                color="#4c4c4c"
                textColor="#666"
                contentBgColor="#fff"
                contentPt="40px"
              />
            </div>
            <div data-aos="fade-right">
              <CardBox
                title="2."
                text="Дорожки с пластинчатым полотном позволяют развивать более высокие скорости и подходят для тренировок профессиональных спортсменов, но производят много шума, что нежелательно для отелей или корпоративных фитнес-центров."
                img="https://images.jhtassets.com/3347ea69d41e32702035c7c85b6228e92e92edec/"
                color="#4c4c4c"
                textColor="#666"
                contentBgColor="#fff"
                contentPt="40px"
              />
            </div>
            <div data-aos="fade-right">
              <CardBox
                title="3."
                text="Беговые дорожки с пластинчатым полотном, которые доступны в спортклубах, разрабатывались для использования на спортивных объектах. Поэтому их внешний вид отталкивает менее опытных любителей бега, и такая эстетика в обычных фитнес-центрах выглядит неуместно."
                img="https://images.jhtassets.com/fd7b795377fcb1846b81f2051f4c25d136883b46/"
                color="#4c4c4c"
                textColor="#666"
                contentBgColor="#fff"
                contentPt="40px"
              />
            </div>
          </Box>
        </Container>
      </section>
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$13}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="py-20 relative"
        style={{
          background:
            "url(https://images.jhtassets.com/8792ec3a1f23fc4b1eaaf8cea176f5f5ae931856/) center / contain no-repeat fixed, linear-gradient(171.76deg,#253746,#000)",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12 relative z-10">
          <Box className="grid mb-20">
            <h4
              className="uppercase text-slate-200 mb-8 lg:px-52 font-black text-3xl lg:text-5xl text-center mx-auto"
              data-aos="fade-up"
            >
              ПРИМЕНЕНИЕ ВЫВОДОВ НА ПРАКТИКЕ
            </h4>
            <Button
              onClick={handleOpen}
              className="VideoPopupButton !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none font-bold text-xl justify-self-center"
              style={{ color: "#e1261c" }}
              data-aos="fade-up"
            >
              <BsPlayCircle size={32} fill="" className="mr-2 fill-red-700" />
              <span className="font-bold">СМОТРЕТЬ ВИДЕО</span>
            </Button>
          </Box>
          <Box className="py-10">
            <article className="md:flex pb-14 gap-6 my-10">
              <img
                className="md:w-1/2"
                src="https://images.jhtassets.com/ba2f06c8c7121726b78a49e060879cec435f29b1/"
                alt=""
                data-aos="zoom-in"
              />
              <div className="pt-5 md:pt-10 md:w-1/2 px-5" data-aos="fade-left">
                <h3 className="mb-3 text-zinc-100 text-2xl font-bold">
                  ИЗМЕНЕНИЕ ДИЗАЙНА ДЛЯ ДОЛГОВЕЧНОСТИ И УДОБСТВА ОБСЛУЖИВАНИЯ
                </h3>
                <p className="text-zinc-300">
                  Наша задача была предельно простой — нам нужно было заново
                  разработать дизайн беговой дорожки, который бы подчеркивал
                  преимущества пластинчатого полотна.
                </p>
              </div>
            </article>
            <article className="md:flex pb-14 gap-6 my-10">
              <img
                className="md:w-1/2"
                src="https://images.jhtassets.com/0684d1a5afe7cfce4a65964baff645076ada33de/"
                alt=""
                data-aos="zoom-in"
              />
              <div className="pt-5 md:pt-10 md:w-1/2 px-5" data-aos="fade-left">
                <h3 className="mb-3 text-zinc-100 text-2xl font-bold">
                  ИЗМЕНЕНИЕ ХАРАКТЕРА
                </h3>
                <p className="text-zinc-300">
                  Проектируя лучшую версию умной беговой дорожки с пластинчатым
                  полотном, наши инженеры создали планки с алюминиевыми
                  сердечниками и прорезиненным покрытием, позволяющие улучшить
                  амортизирующие свойства и долговечность.
                </p>
              </div>
            </article>
            <article className="md:flex pb-14 gap-6 my-10">
              <img
                className="md:w-1/2"
                src="https://images.jhtassets.com/85df79afdedf74f8c44b881d57f235ae41e5374a/"
                alt=""
                data-aos="zoom-in"
              />
              <div className="pt-5 md:pt-10 md:w-1/2 px-5" data-aos="fade-left">
                <h3 className="mb-3 text-zinc-100 text-2xl font-bold">
                  ПОВЫШЕНИЕ ЭФФЕКТИВНОСТИ
                </h3>
                <p className="text-zinc-300">
                  Еще одна область, которую мы планировали улучшить при
                  разработке новой беговой дорожки, — это ее общая
                  эффективность. Для этого было решено не только перенести
                  компоненты мотора в переднюю часть, но и испытать совершенно
                  новые типы компонентов.
                </p>
              </div>
            </article>
            <article className="md:flex pb-14 gap-6 my-10">
              <img
                className="md:w-1/2"
                src="https://images.jhtassets.com/98b937e8066cc6ba46ca663c74034f6d84105200/"
                alt=""
                data-aos="zoom-in"
              />
              <div className="pt-5 md:pt-10 md:w-1/2 px-5" data-aos="fade-left">
                <h3 className="mb-3 text-zinc-100 text-2xl font-bold">
                  МИНИМИЗАЦИЯ ШУМА И ВИБРАЦИИ
                </h3>
                <p className="text-zinc-300">
                  Благодаря уникальному сочетанию шарикоподшипников низкого
                  трения и нашей высокоэффективной системы привода беговая
                  дорожка Performance Plus работает плавно и бесшумно.
                </p>
              </div>
            </article>
          </Box>
        </Container>
      </section>
      <section className="py-20 relative">
        <Container maxWidth="xl" className="md:!px-10 relative z-10">
          <h4
            className="text-zinc-600 mb-8 md:px-52 font-black text-3xl lg:text-5xl text-center mx-auto"
            data-aos="fade-up"
          >
            Испытание готовой дорожки
          </h4>
          <p className="text-center">
            Мы уверены в нашей новой беговой дорожке с пластинчатым полотном и
            потому подтвердили ее эффективность научно.
          </p>
          <Box className="mt-10 md:mt-20 lg:flex items-stretch">
            <div className="w-full  lg:w-2/3">
              <div className="grid md:flex lg:h-1/2">
                <img
                  className="w-full object-cover md:w-1/3"
                  src="https://images.jhtassets.com/9819075a3751b68b0fcd1e12416290a79544173c/"
                  alt=""
                />
                <div className="p-4 md:p-10 bg-black w-full lg:w-2/3">
                  <h2 className="text-zinc-200 text-2xl font-bold mb-5">
                    <span className="text-4xl">91 %</span> ЭФФЕКТИВНОСТИ
                  </h2>
                  <p className="text-zinc-500">
                    В ходе испытаний система привода продемонстрировала
                    невероятную эффективность на уровне 91 %.
                  </p>
                </div>
              </div>
              <div className="md:flex md:h-1/2">
                <div className="p-4 md:p-10 bg-black md:w-1/2">
                  <h2 className="text-zinc-200 text-2xl font-bold">
                    <span className="text-4xl">160 000 КМ</span>С МИНИМАЛЬНЫМ
                    ОБСЛУЖИВАНИЕМ
                  </h2>
                  <p className="text-zinc-500">
                    В ходе испытаний система привода продемонстрировала
                    невероятную эффективность на уровне 91 %.
                  </p>
                </div>
                <img
                  className="w-full md:w-1/2"
                  src="https://images.jhtassets.com/af6116d0b0662908d203421ae22d8dfa02dba624/"
                  alt=""
                />
              </div>
            </div>
            <div className="w-full lg:w-1/3 md:flex lg:block">
              <img
                className="md:h-2/3 w-full"
                src="https://images.jhtassets.com/68e04427d0c068756b73f5323c05901d774a4f66/"
                alt=""
              />
              <div className="p-4 md:p-10 bg-black lg:h-1/3">
                <h2 className="text-zinc-200 text-2xl font-bold">
                  <span className="text-4xl">50 МЛН</span>ЦИКЛОВ
                </h2>
                <p className="text-zinc-500">
                  Анализ алюминиевых пластин показал, что их срок службы
                  составит внушительные 50 млн циклов.
                </p>
              </div>
            </div>
          </Box>
          <Box className="grid lg:grid-cols-2">
            <img
              className="w-full"
              src="https://images.jhtassets.com/287be24b11e5d616a4efd77d1d3b1fdc364dda1e/"
              alt=""
            />
            <div className="p-4 md:p-10 bg-black">
              <h2 className="text-zinc-200 text-2xl font-bold">
                <span className="text-4xl pr-2">6000</span>ЧАСОВЦИКЛОВ /{" "}
                <span className="text-4xl pr-2">48 000</span>KM
              </h2>
              <p className="text-zinc-500">
                Анализ алюминиевых пластин показал, что их срок службы составит
                внушительные 50 млн циклов.
              </p>
            </div>
          </Box>
        </Container>
      </section>
      <section
        className="pt-24"
        style={{
          background:
            "url(https://images.jhtassets.com/eea04484145f263d71a6ead1fa69fb7e98e4a8fc/) center / cover no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12">
          <h4 className="text-3xl mb-5 text-zinc-600 font-bold text-center">
            PERFORMANCE PLUS
          </h4>
          <h2 className="text-3xl lg:text-6xl text-slate-700 font-black text-center">
            ЕЩЕ ЛУЧШЕ
          </h2>
          <p className="py-5 text-center">
            Беговая дорожка Performance Plus совместима с пятью обновленными
            консолями, что позволяет владельцам создавать как простые и изящные
            тренажеры, так и целые цифровые комплексы с набором развлекательных
            функций.
          </p>
          {/* =========================== */}
          <Swiper
            modules={[Navigation, A11y, EffectCoverflow]}
            spaceBetween={50}
            // slidesPerView={4}
            navigation
            loop={true}
            effect={"coverflow"}
            centeredSlides={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            coverflowEffect={{
              rotate: 30,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <img
                className="w-72 mx-auto"
                src="https://images.jhtassets.com/ee633a9721f0c528d24c154ed9ed47499479984e/"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-72 mx-auto"
                src="https://images.jhtassets.com/671a7207b90165c7dab1d77e64317ea29fbaa71d/"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-72 mx-auto"
                src="https://images.jhtassets.com/af40a71fd041e3c320f5d23ab4ef238bbc368e7f/"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="w-72 mx-auto"
                src="https://images.jhtassets.com/ee633a9721f0c528d24c154ed9ed47499479984e/"
                alt=""
              />
            </SwiperSlide>
          </Swiper>
          {/* =========================== */}
          <img
            style={{
              width: "600px",
            }}
            className="mx-auto"
            src="https://images.jhtassets.com/b6e9e6f76ea1ca972724ff82122a39075265bd57/"
            alt=""
          />
        </Container>
      </section>
      <section>
        <div
          className="CenterContentBox py-52 text-center relative"
          style={{
            background: "#000",
          }}
        >
          <h4 className="font-black text-5xl text-zinc-200 mb-4">
            Добейтесь большего с Matrix
          </h4>
          <p className="py-2 md:px-32 text-zinc-200">
            Обновленные беговые дорожки с пластинчатым полотном переживут и
            затмят всех конкурентов. Эти дорожки доступны для любых
            фитнес-центров, а не только для клубов премиального класса и центров
            высшей спортивной подготовки. Однако наше новое предложение было бы
            неполным без важнейшего дополнения — наличия выбора.
          </p>
          <Box className="flex flex-wrap gap-5 justify-center">
            <Link
              to="/cardio/performance-plus/treadmill/touch-xl"
              className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200"
            >
              <span className="text-base font-black">
                Узнать больше на странице продукта
              </span>
            </Link>
            <a
              href="https://images.jhtassets.com/82073bfc81e7577ddd118a1f9e295931a005b16a/"
              className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200"
            >
              <span className="text-base font-black">
                Читать историю целиком
              </span>
            </a>
          </Box>
        </div>
      </section>
    </>
  );
};

export default InnovationsPerformancePlusTreadmill;
