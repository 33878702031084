import React from "react";
import { Container } from "@mui/system";
import { Button } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import { BsBoxArrowInDownRight } from "react-icons/bs";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $10 from "../assets/videos/1-10.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const EducationMx4 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section className="educationMx4-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$10}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 relative flex flex-col justify-end !bg-cover !bg-center"
        style={{
          height: "80vh",
          background:
            "url(https://images.jhtassets.com/921b36a7f01582a3332c98f0cc5d94eb6abb1326/) no-repeat",
        }}
      >
        <Container>
          <div className="text-2xl lg:text-6xl text-center text-white pb-16">
            Система тренировок MX4
          </div>
        </Container>
        <Button
          onClick={handleOpen}
          className="group !text-base !font-black !absolute !bottom-0 w-max !mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
          style={{
            color: "#e1261c",
            fontWeight: "bold",
            fontSize: "20px",
            transform: "translate(-50%,50%)",
            left: "50%",
            right: "50%",
          }}
        >
          <BsPlayCircle
            size={32}
            fill="#e1261c"
            className="group-hover:!fill-white duration-200 mr-2"
          />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </section>
      <Container className="py-16">
        <div
          className="text-center text-3xl font-black my-6 leading-none"
          style={{ color: "#4c4c4c" }}
        >
          Вдохновляйте ваших клиентов. Обеспечьте им результат. И заработайте на
          этом.
        </div>
        <div className="leading-6 text-center" style={{ color: "#191919" }}>
          Если вы хотите получать высокую прибыль и выделить свои групповые
          тренировки так, чтобы они привлекали новых и удерживали постоянных
          клиентов, вам необходима система тренировок MX4. Только система MX4
          сочетает в себе эксклюзивное оборудование Matrix и оригинальные
          программные решения «под ключ», что позволяет формировать уникальные
          групповые тренировки, в которые участники будут готовы вкладывать
          время и деньги.
        </div>
      </Container>
      <Container maxWidth="xl" className="mb-5 lg:mb-16">
        <div className="relative mb-5 lg:mb-16">
          <img
            src="https://images.jhtassets.com/cc0b879c72a983131e5418601d25d212944b5976"
            className="object-cover"
            alt=""
          />
          <div className="p-4 lg:p-16 bg-zinc-600 lg:bg-transparent lg:absolute top-0 left-0 w-full lg:w-1/2">
            <div className="text-xl leading-none text-white font-black mb-6">
              Программирование для всех. И для каждого.
            </div>
            <div className="text-white leading-6">
              Наши оригинальные программы «под ключ» разработаны для
              комплексного улучшения многих аспектов фитнеса: это упражнения для
              сердечно-сосудистой системы, мощь, сила и выносливость. Программу
              можно модифицировать под клиента с любым уровнем возможностей и
              под группы любого размера, с легкостью масштабируя ее с ростом
              популярности.
            </div>
          </div>
          <div
            className="w-full lg:w-1/3 lg:mt-16 mr-16 lg:absolute right-0 top-0 p-3 lg:p-8"
            style={{ background: "#c2c6c9" }}
          >
            <div
              className="text-2xl font-bold mb-4"
              style={{ color: "#4c4c4c" }}
            >
              Курс функциональных тренировок для небольших групп (Small Group
              Functional Training)
            </div>
            <p style={{ color: "#333436" }}>
              В рамках нашего сертифицированного курса тренировок для небольшой
              группы наши тренеры{" "}
              <Link
                to=""
                className="text-red-600 font-bold hover:underline hover:text-red-700"
              >
                Certified Master Trainer
              </Link>{" "}
              приедут к вам, чтобы обучить вас и ваших тренеров всему, что
              необходимо для того, чтобы помочь клиентам улучшить общее
              состояние организма, развить свои способности и в конечном итоге
              оптимизировать свою физическую форму так, как они никогда не
              считали возможным.
            </p>
            <img
              className="mt-12 w-full"
              src="https://images.jhtassets.com/deacddb1e5e32e1050419f9b5c7179308c2366b7/"
              alt=""
            />
          </div>
        </div>
      </Container>
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <Container className="py-16">
        <div
          className="text-center text-3xl font-black mt-5 lg:mt-16 lg:pt-16 mb-6 leading-none"
          style={{ color: "#4c4c4c" }}
        >
          На базе эксклюзивного оборудования Matrix
        </div>
        <div className="leading-6 text-center" style={{ color: "#191919" }}>
          В системе тренировок MX4 используется эксклюзивное оборудование
          Matrix, такое как Connexus Functional Training System, S-Drive
          Performance Trainer, Rower и Krankcycle. Эти уникальные инструменты
          можно использовать практически в любых комбинациях, что делает
          групповые тренировки яркими и интересными и заставляет ваших клиентов
          возвращаться вновь и вновь.
        </div>
      </Container>
      <section
        className="section-2 py-16 !bg-cover !bg-bottom"
        style={{
          background:
            "url(https://images.jhtassets.com/021039119d73d6cab3bf0a926ce895778960f012) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-16 !py-12">
          <div className="grid md:grid-cols-2 gap-4">
            <div className="text-white leading-6">
              Connexus идеально подходит для функциональных тренировок, в ходе
              которых участники выполняют упражнения с отягощением, сопоставимым
              по нагрузке с повседневной активностью. Connexus — это
              максимальная гибкость, практически неограниченные средства
              расширения и возможность моментальной обратной связи для
              совершенствования формы пользователя. Благодаря широкому выбору
              мест крепления можно использовать множество дополнительных
              принадлежностей и тренировочных модулей, что позволяет формировать
              уникальные тренировки для группы с любым уровнем подготовки и
              любыми потребностями.
            </div>
            <div className="grid lg:grid-cols-2 gap-6">
              <div
                className="flex flex-col items-center p-6"
                style={{ background: "#ffffffe6" }}
              >
                <img
                  src="https://images.jhtassets.com/c831ce35db12f6b19e0852644f9f44a30263e0ae/transformed/w_200"
                  alt=""
                />
                <Link
                  to=""
                  className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
                >
                  Connexus Free /
                </Link>
              </div>
              <div
                className="flex flex-col items-center p-6"
                style={{ background: "#ffffffe6" }}
              >
                <img
                  src="https://images.jhtassets.com/df1f51758efc8eced9c1052615239bdfe58102b2/transformed/w_200"
                  alt=""
                />
                <Link
                  to=""
                  className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
                >
                  Connexus Perimeter /
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-3 py-16">
        <Container>
          <div
            className="font-bold text-2xl text-center"
            style={{ color: "#4c4c4c" }}
          >
            Добавьте любую из следующих линеек в ваш тренажерный зал с системой
            MX4.
          </div>
          <div className="grid md:grid-cols-3 gap-4 py-12">
            <div className="flex flex-col items-center p-2">
              <img
                src="https://images.jhtassets.com/63d26d06cc240f1be8a81827c783a157cb33ee04/transformed/w_300"
                alt=""
              />
              <Link
                to=""
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                S-Drive /
              </Link>
            </div>
            <div className="flex flex-col items-center p-2">
              <img
                src="https://images.jhtassets.com/0315206538a339dca08040a938359bfce1bc4613/transformed/w_300"
                alt=""
              />
              <Link
                to=""
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                Rower /
              </Link>
            </div>
            <div className="flex flex-col items-center p-2">
              <img
                src="https://images.jhtassets.com/c4240e2d400bc86fbf16c33605aaa88517b1e57f/transformed/w_200"
                alt=""
              />
              <Link
                to=""
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                Krankcycle /s
              </Link>
            </div>
          </div>
        </Container>
        <Container maxWidth="xl" className="md:!px-12">
          <CardBoxMoreImage
            title="—оставьте расписание своих тренировок"
            subtitle="”знайте о новых интересных способах составлени¤ расписани¤ тренировок по программе MX4 Active! Ќаши профессионально разработанные тренировки подойдут люд¤м с любой физической подготовкой и возможност¤ми†Ч как старшей возрастной группы, занимающимс¤ по программе MX4 Active Aging, так и утратившим физическую форму взрослым люд¤м в рамках программы MX4 Active Living."
            image="https://images.jhtassets.com/ade5daba848c8e14cb47ee0ed07ae896c2d3c6fc/"
            link="Узнать больше/"
            contentBgColor="#000"
            contentRight={false}
            contentWidth="30%"
            contentTitleColor="#e6e5e5"
            contentSubtitleColor="#c2c6c9"
            contentLinkColor="#fff"
          />
        </Container>
      </section>
      <section className="section-4 py-16 !bg-cover !bg-bottom relative">
        <img
          src="https://images.jhtassets.com/2c639fa6731318f55daa8d7c9de921e38a7dd874/"
          className="lg:absolute top-0 left-0 w-full h-full object-cover"
          style={{ zIndex: "-1" }}
          alt=""
        />
        <Container
          maxWidth="xl"
          className="md:!px-12 py-5 relative lg:bg-transparent bg-neutral-700"
        >
          <div className="w-full lg:w-5/12 ">
            <div className="text-3xl font-black leading-none mb-10 text-white lg:text-zinc-700">
              ОБУЧЕНИЕ У ТРЕНЕРОВ CERTIFIED MASTER TRAINER
            </div>
            <p className="text-white leading-5 mb-16">
              Наши сертифицированные мастер-тренеры Certified Master Trainer
              приедут к вам на объект, чтобы обучить вас и ваших тренеров всему,
              что необходимо для того, чтобы помочь клиентам улучшить общее
              состояние организма, развить свои способности и оптимизировать
              физическую форму с помощью MX4. Узнайте, как наши профессиональные
              тренеры, заряженные на результат, уже кардинально изменили мир
              фитнеса.
            </p>
            <div
              className="flex flex-col items-center px-16 py-10"
              style={{ background: "#ffffffe6" }}
            >
              <a
                href="mailto:info@matrixfitness.com?subject=MX4%20Group%20Training%20Certification&body=I%20am%20interested%20in%20learning%20more%20about%20bringing%20Master%20Trainers%20to%20our%20facility%20to%20start%20an%20MX4%20small%20group%20training%20certification."
                className="flex px-6 py-2 text-white bg-red-600 hover:bg-red-700 duration-200 font-black"
              >
                Свяжитесь с нами
                <BsBoxArrowInDownRight
                  className="fill-white"
                  style={{ transform: "rotate(-90deg)" }}
                  size={22}
                />
              </a>
              <p className="text-black leading-6 text-center mt-4">
                Чтобы доставить систему MX4 в ваш зал и начать сертификацию
                вашей программы MX4 Small Group Training, отправьте запрос по
                электронной почте уже сегодня.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default EducationMx4;
