import React from 'react';
import './_loader.scss';

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        zIndex: "5",
      }}
    >
      <div
        className="loadingio-spinner-eclipse-0yeinnyikbjq"
      >
        <div className="ldio-49gy765oit4">
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Loader