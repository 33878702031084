import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import HoverBoxAnimation from '../components/HoverBoxAnimation';
import { IconButton } from '@mui/material';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/ceafcb645f473b57cf894606e3a8072b848ec6af/',
            },
            {
                image: 'https://images.jhtassets.com/e3a88b06bcb962c74cf6e311be5fe74e086b9be2/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/dcbe4e56e6f65433229c9bfd1d6f849f8f2d4f34/',
            },
            {
                image: 'https://images.jhtassets.com/fdaf5c6136b16f944b2e944284df2d4270ddd234/',
            },
            {
                image: 'https://images.jhtassets.com/aa56b45e15575efe3c1135ce01e98761a76dd7e7/',
            },
            {
                image: 'https://images.jhtassets.com/95ca6118b2ffe13bdbea4efc3803bb9ce0100cd5/',
            },
            {
                image: 'https://images.jhtassets.com/86f20b87a8d7541e75df0515be79b8611b809091/',
            },
            {
                image: 'https://images.jhtassets.com/6a9ccaeb6015e91905119dd1e4acd03fce38ab8e/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/f6b80f91519b1df3425714f46caa90694587461a/',
            },
            {
                image: 'https://images.jhtassets.com/cadbbf9e1da771089506e4587f980170835deac3/',
            },
            {
                image: 'https://images.jhtassets.com/781e5b254814584f017b945adef3d96241723e1b/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/ceafcb645f473b57cf894606e3a8072b848ec6af/transformed/h_558,w_440,c_fill',
        title: 'Marana',
        subtitle: 'Encantada at Dove Mountain',
        name: 'США',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/dcbe4e56e6f65433229c9bfd1d6f849f8f2d4f34/transformed/h_558,w_440,c_fill',
        title: 'Atlanta',
        subtitle: '77 12th Street Apartments',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/f6b80f91519b1df3425714f46caa90694587461a/transformed/h_558,w_440,c_fill',
        title: 'Sunnyvale',
        subtitle: 'Renaissance Apartments',
        name: 'США',
    },
];

const MultiHousing = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='multiHousing-page'>
        <section className='section-1 flex items-center justify-center md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)', shapeOutside: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/1e052a6015c596001501c28e5c9e89a125607924/) no-repeat'}}>
            <Container maxWidth="xl" className="md:!px-12 md:!py-0 !py-32">
                <div style={{marginTop: '-6rem'}} className="lg:w-2/5 ml-auto md:mr-16">
                    <div className='font-bold text-white text-lg mb-4'>Многоквартирные дома</div>
                    <div className='text-white text-3xl md:text-5xl leading-none'>Ваш фитнес-партнер</div>
                </div>
            </Container>
        </section>
        <section className='section-2' style={{ marginTop: '-70px' }}>
            <div className='grid md:grid-cols-2 md:gap-8'>
                <div style={{ clipPath: 'polygon(0 10%,100% 5%,100% 100%,0 100%)' }}>
                    <img className='w-full' src='https://images.jhtassets.com/048638d1c662de55ef155d4f876c5358cc865a32/' alt='' />
                </div>
                <div className='flex flex-col justify-center pl-12 py-4 !bg-cover bg-center' style={{ background: 'url(https://images.jhtassets.com/9607885458d2c0063db1545b654af8d8e367bd89/) no-repeat', clipPath: 'polygon(0 5%,100% 0,100% 100%,0 100%)'}}>
                    <p className='leading-9 w-11/12 mb-8 text-base' style={{color: '#191919'}}>Выбирая Matrix, вы получаете не только лучшее в мире оборудование, но гораздо больше. Вы приобретаете партнера, способного привлечь клиентов и сделать их постоянными посетителями. Наши опытные эксперты помогут вам подобрать необходимые тренажеры с учетом размера вашего помещения, бюджета и потребностей. Результат, вне сомнения, понравится вашим посетителям. Характеристики и срок службы нашего оборудования соответствуют качеству тренажеров в ведущих фитнес-клубах. Кроме того, если у вас появятся новые идеи, мы с готовностью вас поддержим и предоставим все необходимое. Вместе мы сделаем ваш фитнес-клуб мощным центром притяжения для местного сообщества.</p>
                </div>
            </div>
        </section>
        <section className='section-3'>
            <Container maxWidth="xl" className="md:!px-16">
                <Link to='/total-solutions-partners'><div className='text-center md:mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>Служим вашим интересам, превосходим ожидания</div></Link>
                <p className='leading-7 mb-4 text-center w-3/4 mx-auto' style={{color: '#191919'}}>Вы стремитесь сделать жизнь людей лучше, а значит, вам нужен достойный партнер, который разделяет ваши убеждения. Выбирая компанию Matrix, вы получите не только лучшее оборудование для фитнеса, но и надежные партнерские отношения, ориентированные на совместный успех.</p>
                <Link to='/total-solutions-partners' className='block w-max mx-auto mb-12 text-red-600 font-bold hover:uppercase hover:text-red-700'>Узнать больше /</Link>
                <div className='grid md:grid-cols-3 gap-6 pb-5 md:pb-16 lg:px-16'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/946a62d49fdf1a6aa48a7634ce50c5322d57fe55/'
                        title='Планирование помещений'
                        text='Мы учтем особенности вашего помещения, бюджет и идеи и поможем с точностью реализовать вашу фитнес-концепцию. '
                        link='Планирование помещений /'
                        href="https://www.matrixfitness.com/ru/rus/total-solutions-partners/facility-planning"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/30dc1b62789ecc7c3ee0e443c07efb29ad7a8e87/'
                        title='Группа специалистов по продажам и команда поддержки'
                        text='Наши специалисты по продажам и команда поддержки помогут вам создать фитнес-центр в соответствии с вашими индивидуальными потребностями.'
                        linkTo=""
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/fae78659cc238603657bfc6c08832a82e4307586/'
                        title='Отдел по работе с клиентами'
                        text='Компания Matrix обеспечивает высочайший уровень устранения неисправностей после первого обращения и оперативную работу, а современная консольная технология значительно облегчает процесс дистанционной диагностики.'
                        linkTo=""
                    />
                </div>
                <div className='text-center mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>ТРЕНАЖЕРЫ ДЛЯ КАЖДОГО</div>
                <p className='leading-7 mb-4 text-center w-3/4 mx-auto' style={{color: '#191919'}}>Каждый тренажер Matrix отвечает трем критериям — мощность, долговечность и эстетичность. Динамические характеристики наших тренажеров не уступают оборудованию других фитнес-клубов, в которых занимаются ваши жильцы. Высококачественные детали, предназначенные для долговременного использования при тяжелых нагрузках, отличаются износостойкостью и реже требуют замены или ремонта. Изысканный дизайн тренажеров придаст вашему фитнес-центру неповторимый облик.</p>
                <div className='grid md:grid-cols-2 gap-6 py-8 md:py-16 lg:px-16'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/066c02f3d4a495b6d227d55983300e70b6571800/'
                        title='Кардио'
                        text='От интенсивных пробежек до тренировок с низкой нагрузкой&nbsp;— мы предлагаем широкий выбор эффективных и простых в использовании кардиотренажеров, с которыми каждый клиент сможет выполнять необходимые упражнения. '
                        link='Кардио /'
                        linkTo="/cardio"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/1f98474d5006e942da7bedf1480847d4a5b4c6bf/'
                        title='Сила'
                        text='Требуется ли вам универсальный тренировочный комплекс с индивидуальными параметрами, компактная модель или самое долговечное оборудование, у нас вы найдете все, что нужно, и даже больше. '
                        linkTo="/strength"
                    />
                </div>
            </Container>
        </section>
        <section className='section-4 !bg-cover !bg-bottom' style={{ background: 'url(https://images.jhtassets.com/0177acaf83a1585aebb99ac11b719592273d4071) no-repeat', paddingBottom: '12rem' }}>
            <Container maxWidth='xl' className='md:!px-16'>
                <div className='grid lg:grid-cols-3 gap-5 py-5 lg:py-16'>
                    <div className='lg:col-span-2'>
                        <Link to='/connected-solutions/sprint-8'>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/9d74df56d740d702e468671d7eeb303446a2339c/' alt='' />
                                <div className='pr-4 px-2 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Sprint 8</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Познакомьте ваших посетителей с программой высокой нагрузки Sprint 8. Это 20 минут интенсивных упражнений на велотренажере, беговой дорожке или орбитреке. Благодаря этому программу можно включить даже в самый плотный распорядок дня, а эффективность программы доказана наукой.</p>
                                    <Link to='/connected-solutions/sprint-8' className='block text-red-600 hover:text-red-700 font-bold'>Sprint 8 /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to='/connected-solutions/asset-management'>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/abcbeaf93a4f4b0e01df77181fc590f36a0a155f/' alt='' />
                                <div className='pr-4 px-2 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Asset Management</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Asset Management автоматически следит за тренажерами и выполняет функции технического обслуживания, контроля состояния и управления оборудованием.</p>
                                    <Link to='/connected-solutions/asset-management' className='block text-red-600 hover:text-red-700 font-bold'>Asset Management /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to='/connected-solutions/unique-programs'>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/f5378735bdbbdd65937ffa8de6991f0534376303/' alt='' />
                                <div className='pr-4 px-2 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Система виртуальных ландшафтов Virtual Active</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Технология реалистичного погружения Virtual Active синхронизирует скорость тренировки с красивыми видеороликами в высоком разрешении. Ваши клиенты смогут путешествовать по легендарным городам и посетить во время занятий самые экзотические уголки планеты.</p>
                                    <Link to='/connected-solutions/unique-programs' className='block text-red-600 hover:text-red-700 font-bold'>Virtual Active /</Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-2xl md:text-5xl leading-none text-white lg:text-zinc-700'>ПОДКЛЮЧИТЕСЬ К МИРУ ЦИФРОВЫХ РЕШЕНИЙ.</div>
                        <p className='leading-6 my-4 text-white lg:text-zinc-900 '>Если вы хотите создать непревзойденные условия для спортивных занятий, вы сможете добиться желаемого с помощью Matrix Connected Solutions. Узнайте, как наша система цифровых решений может стать эффективным инструментом для смелого и яркого воплощения в реальность вашего видения.</p>
                    </div>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className='md:!px-16'>
            <Link to='/showcases/multi-housing'><div className='text-center md:mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>Нам доверяют во всем мире</div></Link>
            <p className='leading-7 mb-4 text-center lg:w-3/4 mx-auto' style={{color: '#191919'}}>Компания Matrix гордится тем, что ее оборудование установлено в самых лучших многоквартирных домах по всему миру и приносит пользу их жильцам.</p>
            <Link to='/showcases/multi-housing' className='font-bold text-red-600 hover:text-red-700 text-center block'>Узнать больше о фитнес-клубах /</Link>
            <div className='grid md:grid-cols-3 gap-6 lg:mt-16 mb-12'>
                {boxes?.map(box => (
                    <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                    <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                        <MdPhotoLibrary fill='#4f8493' size={32} />
                    </IconButton>
                    <img className='h-full w-full' src={box.image} alt='' />
                    <div className='center-box-absolute p-2'>
                        <div className='center-box-title'>{box.title}</div>
                        <div className='center-box-subtitle'>{box.subtitle}</div>
                        <div className='center-box-name'>{box.name}</div>
                    </div>
                </div>
                ))
                }
            </div>
        </Container>
        {modal &&
            <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                <Swiper
                    hashNavigation={{
                    watchState: true,
                    }}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper showcases-swiper"
                    style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                >
                    {handleModal?.gallery?.map((image,idx) => (
                        <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                    ))}
                </Swiper>
            </div>
        }
        <section className='section-6 !bg-cover' style={{ background: 'url(https://images.jhtassets.com/4d16d137535c375ee4431b874aaa88d5660e1f28) no-repeat' }}>
            <Container maxWidth="xl" className="md:!px-16 !py-16">
                <div className='grid lg:grid-cols-3 gap-8 py-16'>
                    <div className='lg:col-span-2'>
                        <Link to='/group-training/catalog?modalities=rowers&series=cardio'>
                            <img src='https://images.jhtassets.com/73ecafe7e0266b9a0cf43e28d25455b4d68611d8/' alt='' />
                            <div className='font-bold text-center text-xl' style={{color: '#4c4c4c'}}>Гребные тренажеры</div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-3xl md:text-6xl leading-none' style={{color: '#4c4c4c'}}>Превосходное место для тренировок</div>
                        <p className='my-4' style={{color: '#191919'}}>Посмотрите на наши самые популярные модели премиум-класса и узнайте, как в сотрудничестве с компанией Matrix вы можете создать уникальное фитнес-пространство, подходящее вашему дому и имиджу.</p>
                    </div>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16" style={{marginTop: '-5%'}}>
            <div className='grid md:grid-cols-2 gap-8 pb-12'>
                <Link to='/' className='grid md:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/f03c9eed4e7a6d660562fcc58c2d80cefca2a9e6/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Функциональный комплекс Versa</div>
                </Link>
                <Link to='/' className='grid md:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/2c1d3fc2a219cfdd5dedd00e78a0d064fe6bc53f/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Connexus Compact</div>
                </Link>
            </div>
        </Container>
    </section>
  )
}

export default MultiHousing;