import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";
import ScrollToTop from "./layout/Header/ScrollTop";
import AboutUs from "./views/AboutUs";
import CardioConsoles from "./views/CardioConsoles";
import Contact from "./views/Contact";
import Home from "./views/Home";
import Strength from "./views/Strength";
import StrengthUltra from "./views/StrengthUltra";
import StrengthVersa from "./views/StrengthVersa";
import StrengthAura from "./views/StrengthAura";
import StrengthMagnum from "./views/StrengthMagnum";
import StrengthVarsity from "./views/StrengthVarsity";
import StrengthGo from "./views/StrengthGo";
import StrengthG1 from "./views/StrengthG1";
import IntelligentTrainingConsole from "./views/IntelligentTrainingConsole";
import ConsolesTouch from "./views/ConsolesTouch";
import ConsolesPremiumLed from "./views/ConsolesPremiumLed";
import CardioPerformance from "./views/CardioPerformance";
import CardioEndurance from "./views/CardioEndurance";
import CardioLifestyle from "./views/CardioLifestyle";
import CardioCatalog from "./views/CardioCatalog";
import Cardio from "./views/Cardio";
import Education from "./views/Education";
import GroupTraining from "./views/GroupTraining";
import GroupTrainingCardio from "./views/GroupTrainingCardio";
import GroupTrainingFunctionalStrength from "./views/GroupTrainingFunctionalStrength";
import ConnectedSolutionsFacilitySpecificSolutions from "./views/ConnectedSolutionsFacilitySpecificSolutions";
import ConnectedSolutionsPartnershipsIfit from "./views/ConnectedSolutionsPartnershipsIfit";
import ConnectedSolutions from "./views/ConnectedSolutions";
import ConnectedSolutionsEngage360 from "./views/ConnectedSolutionsEngage360";
import ConnectedSolutionsCommunity360 from "./views/ConnectedSolutionsCommunity360";
import ConnectedSolutionsAssetManagement from "./views/ConnectedSolutionsAssetManagement";
import ConnectedSolutionsTargetTraining from "./views/ConnectedSolutionsTargetTraining";
import ConnectedSolutionsSprint8 from "./views/ConnectedSolutionsSprint8";
import ConnectedSolutionsUniquePrograms from "./views/ConnectedSolutionsUniquePrograms";
import EducationMatrixRide from "./views/EducationMatrixRide";
import EducationMx4 from "./views/EducationMx4";
import EducationMx4Active from "./views/EducationMx4Active";
import EducationConnexus from "./views/EducationConnexus";
import Innovations from "./views/Innovations";
import InnovationsRisingToNewHeights from "./views/InnovationsRisingToNewHeights";
import InnovationsRaisingExpectations from "./views/InnovationsRaisingExpectations";
import InnovationsPerformancePlusTreadmill from "./views/InnovationsPerformancePlusTreadmill";
import InnovationsVirtualTrainingCycle from "./views/InnovationsVirtualTrainingCycle";
import InnovationsGoodBreak from "./views/InnovationsGoodBreak";
import InnovationsGluteTrainer from "./views/InnovationsGluteTrainer";
import InnovationsClearFieldOfVision from "./views/InnovationsClearFieldOfVision";
import InnovationsGoSeries from "./views/InnovationsGoSeries";
import Showcases from "./views/Showcases";
import ShowcasesHealthClubs from "./views/ShowcasesHealthClubs";
import Support from "./views/Support";
import SupportFaq from "./views/SupportFaq";
import Channels from "./views/Channels";
import HealthClubs from "./views/HealthClubs";
import Hospitality from "./views/Hospitality";
import AthleticPerformance from "./views/AthleticPerformance";
import YmcaJcc from "./views/YmcaJcc";
import ActiveAging from "./views/ActiveAging";
import MultiHousing from "./views/MultiHousing";
import ShowcasesHospitality from "./views/ShowcasesHospitality";
import ShowcasesAthleticPerformance from "./views/ShowcasesAthleticPerformance";
import ShowcasesYmcaJcc from "./views/ShowcasesYmcaJcc";
import ShowcasesActiveAging from "./views/ShowcasesActiveAging";
import ShowcasesMultiHousing from "./views/ShowcasesMultiHousing";
import TotalSolutionsPartners from "./views/TotalSolutionsPartners";
import TotalSolutionsPartnersFacilityPlanning from "./views/TotalSolutionsPartnersFacilityPlanning";
import InnovationsHighFunction from "./views/InnovationsHighFunction";
import InnovationsFinestRowingExperience from "./views/InnovationsFinestRowingExperience";
import InnovationsSDrive from "./views/InnovationsSDrive";
import InnovationsTrainingCycles from "./views/InnovationsTrainingCycles";
import InnovationsPtpStory from "./views/InnovationsPtpStory";
import InnovationsPremiumLedConsole from "./views/InnovationsPremiumLedConsole";
import InnovationsTouchConsole from "./views/InnovationsTouchConsole";
import CardioDetail from "./views/CardioDetail";
import StrengthDetail from "./views/StrengthDetail";
import GroupDetail from "./views/GroupDetail";
import StrengthCatalog from "./views/StrengthCatalog";
import GroupCatalog from "./views/GroupCatalog";
import SearchPage from "./views/SearchPage";
import PrivacyPolicy from "./views/PrivacyPolicy";

const App = () => {
  Aos.init({
    once: true,
    duration: 1000,
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <div className="page-wrapper">
        <Routes path="/">
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/cardio/:id" element={<CardioDetail />} />
          <Route path="/strength/:id" element={<StrengthDetail />} />
          <Route path="/group-training/:id" element={<GroupDetail />} />
          <Route path="/strength" element={<Strength />} />
          <Route path="/strength/ultra" element={<StrengthUltra />} />
          <Route path="/strength/versa" element={<StrengthVersa />} />
          <Route path="/strength/aura" element={<StrengthAura />} />
          <Route path="/strength/magnum" element={<StrengthMagnum />} />
          <Route path="/strength/varsity" element={<StrengthVarsity />} />
          <Route path="/strength/go" element={<StrengthGo />} />
          <Route path="/strength/g1" element={<StrengthG1 />} />
          <Route
            path="/strength/intelligent-training-console"
            element={<IntelligentTrainingConsole />}
          />
          <Route path="/group-training" element={<GroupTraining />} />
          <Route
            path="/group-training/cardio"
            element={<GroupTrainingCardio />}
          />
          <Route
            path="/group-training/functional-strength"
            element={<GroupTrainingFunctionalStrength />}
          />
          <Route path="/connected-solutions" element={<ConnectedSolutions />} />
          <Route
            path="/connected-solutions/engage360"
            element={<ConnectedSolutionsEngage360 />}
          />
          <Route
            path="/connected-solutions/community360"
            element={<ConnectedSolutionsCommunity360 />}
          />
          <Route
            path="/connected-solutions/asset-management"
            element={<ConnectedSolutionsAssetManagement />}
          />
          <Route
            path="/connected-solutions/target-training"
            element={<ConnectedSolutionsTargetTraining />}
          />
          <Route
            path="/connected-solutions/sprint-8"
            element={<ConnectedSolutionsSprint8 />}
          />
          <Route
            path="/connected-solutions/unique-programs"
            element={<ConnectedSolutionsUniquePrograms />}
          />
          <Route
            path="/connected-solutions/facility-specific-solutions"
            element={<ConnectedSolutionsFacilitySpecificSolutions />}
          />
          <Route
            path="/connected-solutions/partnerships/ifit"
            element={<ConnectedSolutionsPartnershipsIfit />}
          />
          <Route path="/innovations" element={<Innovations />} />
          <Route
            path="/innovations/rising-to-new-heights"
            element={<InnovationsRisingToNewHeights />}
          />
          <Route
            path="/innovations/raising-expectations"
            element={<InnovationsRaisingExpectations />}
          />
          <Route
            path="/innovations/performance-plus-treadmill"
            element={<InnovationsPerformancePlusTreadmill />}
          />
          <Route
            path="/innovations/virtual-training-cycle"
            element={<InnovationsVirtualTrainingCycle />}
          />
          <Route
            path="/innovations/good-break"
            element={<InnovationsGoodBreak />}
          />
          <Route
            path="/innovations/glute-trainer"
            element={<InnovationsGluteTrainer />}
          />
          <Route
            path="/innovations/clear-field-of-vision"
            element={<InnovationsClearFieldOfVision />}
          />
          <Route
            path="/innovations/go-series"
            element={<InnovationsGoSeries />}
          />
          {/* Catalog */}
          <Route path="/cardio/catalog" element={<CardioCatalog />} />
          <Route path="/strength/catalog" element={<StrengthCatalog />} />
          <Route path="/group-training/catalog" element={<GroupCatalog />} />
          {/* Catalog */}
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/cardio" element={<Cardio />} />
          <Route path="/cardio/consoles" element={<CardioConsoles />} />
          <Route path="/cardio/consoles/touch" element={<ConsolesTouch />} />
          <Route
            path="/cardio/consoles/premium-led"
            element={<ConsolesPremiumLed />}
          />
          <Route path="/cardio/performance" element={<CardioPerformance />} />
          <Route path="/cardio/endurance" element={<CardioEndurance />} />
          <Route path="/cardio/lifestyle" element={<CardioLifestyle />} />
          <Route path="/education" element={<Education />} />
          <Route
            path="/education/matrix-ride"
            element={<EducationMatrixRide />}
          />
          <Route path="/education/mx4" element={<EducationMx4 />} />
          <Route
            path="/education/mx4-active"
            element={<EducationMx4Active />}
          />
          <Route path="/education/connexus" element={<EducationConnexus />} />
          <Route path="/showcases" element={<Showcases />} />
          <Route
            path="/showcases/health-clubs"
            element={<ShowcasesHealthClubs />}
          />
          <Route path="/support" element={<Support />} />
          <Route path="/support/faq" element={<SupportFaq />} />
          <Route path="/channels" element={<Channels />} />
          <Route path="/health-clubs" element={<HealthClubs />} />
          <Route path="/hospitality" element={<Hospitality />} />
          <Route
            path="/athletic-performance"
            element={<AthleticPerformance />}
          />
          <Route path="/ymca-jcc" element={<YmcaJcc />} />
          <Route path="/active-aging" element={<ActiveAging />} />
          <Route path="/multi-housing" element={<MultiHousing />} />
          <Route
            path="/showcases/hospitality"
            element={<ShowcasesHospitality />}
          />
          <Route
            path="/showcases/athletic-performance"
            element={<ShowcasesAthleticPerformance />}
          />
          <Route path="/showcases/ymca-jcc" element={<ShowcasesYmcaJcc />} />
          <Route
            path="/showcases/active-aging"
            element={<ShowcasesActiveAging />}
          />
          <Route
            path="/showcases/corporate"
            element={<ShowcasesActiveAging />}
          />
          <Route
            path="/showcases/multi-housing"
            element={<ShowcasesMultiHousing />}
          />
          <Route
            path="/total-solutions-partners"
            element={<TotalSolutionsPartners />}
          />
          <Route
            path="/total-solutions-partners/facility-planning"
            element={<TotalSolutionsPartnersFacilityPlanning />}
          />
          <Route
            path="innovations/high-function"
            element={<InnovationsHighFunction />}
          />
          <Route
            path="innovations/finest-rowing-experience"
            element={<InnovationsFinestRowingExperience />}
          />
          <Route path="innovations/s-drive" element={<InnovationsSDrive />} />
          <Route
            path="innovations/training-cycles"
            element={<InnovationsTrainingCycles />}
          />
          <Route
            path="innovations/ptp-story"
            element={<InnovationsPtpStory />}
          />
          <Route
            path="innovations/premium-led-console"
            element={<InnovationsPremiumLedConsole />}
          />
          <Route
            path="innovations/touch-console"
            element={<InnovationsTouchConsole />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
