import { Container, Box } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'
import { getFilter } from '../redux/actions/filterActions'


const GroupTrainingCardio = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '460px'}}>
				<img
					src="https://images.jhtassets.com/bb99a39e4e9b2aea02c6b171b59ec321081f08ca/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 text-3xl lg:text-5xl relative z-10 px-5 md:text-white">Групповые кардиотренировки</h3>
			</section>
			<section>
				<Container>
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Фирменные кардиопрограммы — это основа групповых тренировок, и мы предложим вам решения, которые подойдут для клиентов любого уровня подготовки. Ваши клиенты смогут испытать себя на прочность с помощью тренажеров S-Drive и S-Force Performance Trainers, выбирая разные режимы интенсивности. Используйте в своих тренировках наши гребные тренажеры — и вы получите кардиопрограмму с малой ударной нагрузкой, укрепляющую все тело. Предложите своим клиентам тренажер для верхней части тела Krankcycle, который поможет развить аэробную выносливость, укрепить мышцы и организм в целом. С нашей интересной новинкой Training Cycles вы сможете проводить доступные групповые велотренировки для более опытных клиентов.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 11)
                    dispatch(getFilter({
                        series: 11,
                    }))
                }} href="/group-training/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Посмотреть все кардиотренажеры для групповых тренировок</span></a>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="700px"
				img="https://images.jhtassets.com/6701197d3494b0f46d022c3476c62417de09ea00/"
				contentWidth="450px"
				contentRight={false}
				contentBg="#e1261c"
				title="Новое слово в групповых велотренировках"
				titleColor="#f5f5f5"
				text="Наши высокотехнологичные велотренажеры с оригинальными программами вдохнут новую жизнь в ваши велотренировки. Не отступайте от своих целей: яркие цвета помогут клиентам и инструкторам оценить нагрузку, будь то ватты, пульс, число оборотов в минуту, дистанция или калории."
				textColor="#f9d2d0"
				paddingTopNull={true}
			/>
			<section className='p-4 lg:p-14' style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container maxWidth="xl">
					<div className='grid lg:grid-cols-2 gap-3 mb-7 w-full'>
						<CardBox
							title="Интенсивность"
							text="Тренажер S-Drive Performance Trainer позволит разнообразить практически любую программу групповых тренировок. Вы сможете без труда установить этот тренажер с автономным питанием, а благодаря его непревзойденной многофункциональности клиенты получат доступ к самому широкому спектру интенсивных упражнений, включая высокоинтенсивные интервальные тренировки, тренировки с силовыми санями, тренировки на сопротивление и многие другие."
							img="https://images.jhtassets.com/d4554e715d3a06c9246c6fdcfdca8899e46121a7/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="50px"
						/>
						<CardBox
							title="Продуманная интенсивность"
							text="Тренажер S-Force Performance Trainer сочетает в себе специально разработанную схему движения, две активные позиции и магнитное сопротивление для наращивания скорости и мощности, что выводит тренировки HIIT на новый уровень. Используйте S-Force для тренировок в небольших группах или индивидуальных занятий, предлагая клиентам программу, обладающую малой ударной нагрузкой и высокой интенсивностью."
							img="https://images.jhtassets.com/94cadee0bff8d629402ab00938f1d18b9539f4fd/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="50px"
						/>
					</div>
					<CardBoxMoreImage
						title="Лучший гребной спорт на суше"
						subtitle="Воспользуйтесь растущей популярностью групповых гребных тренировок, дополнив свою программу нашим гребным тренажером со стильным и продуманным дизайном. Этот компактный, но не уступающий в эффективности тренажер легко разместится между специализированными секциями в вашем зале, и клиенты смогут чередовать тренировки с отягощением или функциональные упражнения с более легкой кардиотренировкой, укрепляющей все тело."
						image="https://images.jhtassets.com/1497da8ae77e06d23b1a930f40a6bc7b43a2026f/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="30%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default GroupTrainingCardio