import React from "react";
import { Button, Container } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $9 from "../assets/videos/1-9.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const EducationConnexus = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section className="educationConnexus-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$9}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 relative flex flex-col justify-end !bg-cover !bg-center"
        style={{
          height: "80vh",
          background:
            "url(https://images.jhtassets.com/a9219abf9b0eeb58d43f6707c10126ceed7743d1/) no-repeat",
        }}
      >
        <Container>
          <div className="text-3xl lg:text-6xl text-center text-white pb-16">
            Программы для системы Connexus
          </div>
        </Container>
        <Button
          onClick={handleOpen}
          className="group !text-base !font-black !absolute !bottom-0 w-max !mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
          style={{
            color: "#e1261c",
            fontWeight: "bold",
            fontSize: "20px",
            transform: "translate(-50%,50%)",
            left: "50%",
            right: "50%",
          }}
        >
          <BsPlayCircle
            size={32}
            fill="#e1261c"
            className="group-hover:!fill-white duration-200 mr-2"
          />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </section>
      <Container>
        <div
          className="text-center leading-6 py-16"
          style={{ color: "#191919" }}
        >
          Главным преимуществом серии Connexus является ее универсальность. Ваши
          инструкторы могут использовать уже готовые программы тренировок,
          разрабатывать собственные программы занятий на основе громадной
          библиотеки упражнений или же совмещать оба эти варианта. Поскольку
          тренировки на тренажерах Connexus легко подстраивать под конкретный
          уровень физической подготовки и индивидуальные цели, они походят всем
          пользователям, которые хотят увеличить силу и улучшить гибкость.
          Узнайте больше о предлагаемой линейке продукции, посетив{" "}
          <Link
            to=""
            className="inline-block font-bold text-red-600 hover:text-red-700 hover:underline"
          >
            страницу оборудования Connexus.
          </Link>
        </div>
      </Container>
      <Container maxWidth="xl" className="md:!px-12">
        <div className="grid md:grid-cols-3 gap-6 mt-12">
          <div>
            <img
              src="https://images.jhtassets.com/977ddebd705a5e8c7760d347e42b938fe4ad0ad7/"
              alt=""
            />
            <div
              className="font-bold text-xl my-4"
              style={{ color: "#4c4c4c" }}
            >
              TRX
            </div>
            <p className="leading-6" style={{ color: "#191919" }}>
              Тренировки с собственным весом и подвесной системой TRX позволяют
              одновременно повышать силу, гибкость и ловкость и улучшать чувство
              равновесия. Разработанный главой отдела по обучению TRX Suspension
              Training, модуль Connexus TRX позволяет ставить сложные задачи и
              достигать высоких результатов на функциональных тренировках, на
              которых используется оборудование Connexus с подвесной системой
              TRX. Модуль Connexus TRX — это прогрессивная четырехнедельная
              программа с двумя оригинальными недельными частями для тренировок
              небольших групп и персональных тренировок.
            </p>
            <Link
              to="https://matrixlearningcenter.com/eng/exercise-library/#mlc_exercise_filter1=.connexus-tool.trx-connexus"
              className="inline-block mt-4 text-red-600 hover:underline hover:text-red-700 font-bold"
            >
              Подробнее /
            </Link>
          </div>
          <div>
            <img
              src="https://images.jhtassets.com/b24b1662ec66ad42f3f0359f88c6b8ed3fb3b2bd/"
              alt=""
            />
            <div
              className="font-bold text-xl my-4"
              style={{ color: "#4c4c4c" }}
            >
              Эспандеры и мячи
            </div>
            <p className="leading-6" style={{ color: "#191919" }}>
              Пользователи могут повышать абсолютную силу с помощью эспандеров и
              взрывную силу с помощью слэмболов, которые заставляют тело
              работать как единое целое. Модуль работы с эспандерами и мячами от
              Matrix для оборудования Connexus — это прогрессивная
              четырехнедельная программа с тремя оригинальными недельными
              частями, позволяющая повышать абсолютную и взрывную силу без
              использования тяжелых грузов. Программа подходит для тренировок
              небольших групп и персональных тренировок, а ее интенсивность
              можно легко уменьшать или увеличивать.
            </p>
            <Link
              to="https://matrixlearningcenter.com/eng/exercise-library/#mlc_exercise_filter1=.connexus-tool.slam-ball-connexus"
              className="inline-block mt-4 text-red-600 hover:underline hover:text-red-700 font-bold"
            >
              Подробнее /
            </Link>
          </div>
          <div>
            <img
              src="https://images.jhtassets.com/ff3d5d526a77fffb85817679cb20d3c9fb0ea4e1/"
              alt=""
            />
            <div
              className="font-bold text-xl my-4"
              style={{ color: "#4c4c4c" }}
            >
              Бокс
            </div>
            <p className="leading-6" style={{ color: "#191919" }}>
              Модуль бокса позволяет проводить силовые тренировки, в которых
              задействуется абсолютно все тело: мышцы ног, бедер (в том числе
              задней поверхности бедра), кора, косые мышцы живота, а также мышцы
              спины, плеч, груди и рук. Модуль бокса от Matrix для оборудования
              Connexus — это прогрессивная четырехнедельная программа с тремя
              оригинальными недельными частями, позволяющая повышать абсолютную
              и взрывную силу путем выполнения ударов с эспандерами. Программа
              подходит для тренировок небольших групп и персональных тренировок,
              а ее интенсивность можно легко уменьшать или увеличивать.
            </p>
            <Link
              to="https://matrixlearningcenter.com/eng/exercise-library/#mlc_exercise_filter1=.connexus-tool.boxing-connexus"
              className="inline-block mt-4 text-red-600 hover:underline hover:text-red-700 font-bold"
            >
              Подробнее /
            </Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-4 my-16 pt-12">
          <div className="pr-4">
            <div
              className="font-bold text-2xl mb-4"
              style={{ color: "#4c4c4c" }}
            >
              Библиотека
            </div>
            <p className="leading-6 mb-2" style={{ color: "#191919" }}>
              Получите доступ к полному перечню упражнений Connexus, что
              позволит вашим инструкторам создавать собственные оригинальные
              программы и схемы тренировок. Эту библиотеку также можно
              использовать для изменения существующих программ путем подбора
              альтернативных упражнений для уменьшения или увеличения
              интенсивности тренировок в зависимости от умений и уровня
              физической подготовки пользователей. Благодаря этому инструкторы
              могут подстраивать ход выполнения групповых тренировок под
              конкретные возможности людей, присутствующих на занятии.
            </p>
            <Link
              to="https://matrixlearningcenter.com/eng/exercise-library/#mlc_exercise_filter1=.connexus-tool"
              className="inline-block mt-4 text-red-600 hover:underline hover:text-red-700 font-bold"
            >
              Подробнее /
            </Link>
          </div>
          <div>
            <img
              src="https://images.jhtassets.com/f757d0b4349566499db716615b818ae5b37ec90e/"
              alt=""
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EducationConnexus;
