import React, { useState } from 'react';
import { Container } from '@mui/system';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IconButton } from '@mui/material';
import '../assets/scss/_showcases.scss';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/e3c32bf568ab1ba3634b60cc58fa797ce81da16e/',
            },
            {
                image: 'https://images.jhtassets.com/59ed07d7a97bef4167f9742ad0ebc7446b510841/',
            },
            {
                image: 'https://images.jhtassets.com/1f5849e7959d5c056d6ec85541fb6862921a89da/'
            },
            {
                image: 'https://images.jhtassets.com/bf093300923913dc81b6ffe5b5cadbafffcaaefa/'
            },
            {
                image: 'https://images.jhtassets.com/f850b5fb6e4e4354a7d1e49c8a5cc63cac01922c/',
            },
        ]
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/3286cceb20967c4fca5bbc9e0c4ed7005ecf3f6c/',
            },
            {
                image: 'https://images.jhtassets.com/4240a39726c5c2305c1a9356118c877673c4b306/',
            },
            {
                image: 'https://images.jhtassets.com/25088a8202994813381009021a4b273e4eb27823/',
            },
            {
                image: 'https://images.jhtassets.com/a1c994674befb6548c995d73bbbde16a083c0ef9/',
            },
            {
                image: 'https://images.jhtassets.com/e4e8ab3ef8595ca0ee8bedb52961f2cd6eeee709/',
            },
        ]
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/cce615655270571d142fdc15eb20029150cdfe9d/',
            },
            {
                image: 'https://images.jhtassets.com/a276b306640c9809c316b94e8ca3ae608cc116b9/',
            },
            {
                image: 'https://images.jhtassets.com/78854aaa718aeedfb0856c4463c3bfe5b0b58a4f/',
            },
            {
                image: 'https://images.jhtassets.com/034baccbb7e8b5d536dd15f041afb9545d7ea4d6/',
            },
            {
                image: 'https://images.jhtassets.com/29e81a059b4405e7aa41abab2977cc1497527015/',
            },
        ]
    },
    {
        id: 4,
        gallery: [
            {
                image: 'https://images.jhtassets.com/679111f99eb50d364b8b0a539d47bf3cfcf843d8/',
            },
            {
                image: 'https://images.jhtassets.com/45ed9edc24f519e6c46ce645e3d2f3a5914d725b/',
            },
            {
                image: 'https://images.jhtassets.com/3b768dda1e087505c431b79e8f2415c2e00d3906/',
            },
            {
                image: 'https://images.jhtassets.com/ac4393febfb31d89165de8bb9f03201e64fa0999/',
            },
            {
                image: 'https://images.jhtassets.com/603fc9d8b8ddc7182d75303f517f53c32ce3d69b/',
            },
            {
                image: 'https://images.jhtassets.com/71a8e5db9d9c915ae9bb2656a253c3238354a19f/',
            },
        ]
    },
    {
        id: 5,
        gallery: [
            {
                image: 'https://images.jhtassets.com/db987805c041e9d4bb787db8a3e8fa7eeb6cc7da/',
            },
            {
                image: 'https://images.jhtassets.com/5187da062890b165ca5ca2e08a4bfc9c77765410/',
            },
            {
                image: 'https://images.jhtassets.com/87328291d92bfd0e83c229d6cc124eb400b9e7ba/',
            },
            {
                image: 'https://images.jhtassets.com/e1709d59d4922bbe68c3166d90a3e7e61fa6150c/',
            },
        ]
    },
    {
        id: 6,
        gallery: [
            {
                image: 'https://images.jhtassets.com/44ffad54e78f8ff11019236b3087756a087591d4/',
            },
            {
                image: 'https://images.jhtassets.com/68bd97f3a084a2f4d2a026279076cc597fc8d224/',
            },
            {
                image: 'https://images.jhtassets.com/027f436fa465ac6eaeefba22cb13a372539e6c9a/',
            },
            {
                image: 'https://images.jhtassets.com/12fa9f2b3860002de1ed231c57b7afadbabd306e/',
            },
            {
                image: 'https://images.jhtassets.com/c696e10df7fc751e35e3f6e713cc23690b687052/',
            },
            {
                image: 'https://images.jhtassets.com/3e6f810f381ccbd743d858e4f15a86c6f55aff57/',
            },
        ]
    },
    {
        id: 7,
        gallery: [
            {
                image: 'https://images.jhtassets.com/602ad5f1c81fecd0a2da58ed848738c444bc167c/',
            },
            {
                image: 'https://images.jhtassets.com/88adaa098ca32db439dbc29e842b2eb3d0419df7/',
            },
            {
                image: 'https://images.jhtassets.com/188255159aaaf308b6243c027f0da78de8164f51/',
            },
            {
                image: 'https://images.jhtassets.com/e599ad11069102c9246e33bc8b120de090cdfdcc/',
            },
            {
                image: 'https://images.jhtassets.com/c5e1b443c95078b4d8a1fb31215667004952fee4/',
            },
            {
                image: 'https://images.jhtassets.com/10d182cc9378729f7095d0404dd3d94c8e953c66/',
            },
        ]
    },
    {
        id: 8,
        gallery: [
            {
                image: 'https://images.jhtassets.com/33ca1f341e714ca24d49ca8d588ad53c5430b2df/',
            },
            {
                image: 'https://images.jhtassets.com/7cfbfad1e837e7a45d01ceaf8714b7e1b97eb1bb/',
            },
            {
                image: 'https://images.jhtassets.com/9c72dcc9b37895038e4479a022650005c7138c85/',
            },
            {
                image: 'https://images.jhtassets.com/2a830e35f19e8466c38790b9c465e94667b88390/',
            },
            {
                image: 'https://images.jhtassets.com/8c441dc39c46985b74ee7b830b6ca7c2d3e87be2/',
            },
            {
                image: 'https://images.jhtassets.com/fc65f9ffcf8255ad9df89338375639914e80af06/',
            },
            {
                image: 'https://images.jhtassets.com/20c74776b69606c148ab0d9336c86a9d3ce7e0e0/',
            },
            {
                image: 'https://images.jhtassets.com/4d5c8b6c544c412b82c232d4443e85fe27ac16fa/',
            },
            {
                image: 'https://images.jhtassets.com/6ba0afa0be622ae5291a9711a3873f36aba9c27c/',
            },
            {
                image: 'https://images.jhtassets.com/2525e1ef0bb72c41159694cb54ce806c7a0b6e87/',
            },
        ]
    },
    {
        id: 9,
        gallery: [
            {
                image: 'https://images.jhtassets.com/a4bab4afcc950b9dcb9368c568cb1b989c19709a/',
            },
            {
                image: 'https://images.jhtassets.com/827a69b32ebdcf3b28c4c3e8af76849a5dc84550/',
            },
            {
                image: 'https://images.jhtassets.com/0a0924382f21a1941f2f03ee1a23f8ddc852c6e7/',
            },
            {
                image: 'https://images.jhtassets.com/770868b1908c4bd4cf18edb84106bec6ce15ce3a/',
            },
            {
                image: 'https://images.jhtassets.com/49e222c32b3cbcf4292effe88cca982a255229da/',
            },
            {
                image: 'https://images.jhtassets.com/36015250e74609a3bc89e122153ee6fb7c3f432a/',
            },
            {
                image: 'https://images.jhtassets.com/dcbc67486306b36fb2c3b5855147fd813a29e8bd/',
            },
            {
                image: 'https://images.jhtassets.com/27973b00f8a8d8b70c05540a00ba928a11215fca/',
            },
            {
                image: 'https://images.jhtassets.com/c0e75df2b0844f533c239a2a89f02a5c7b077f30/',
            },
            {
                image: 'https://images.jhtassets.com/00f0953c805890de7824f2ec76e1d7eae1e357c4/',
            },
        ]
    },
    {
        id: 10,
        gallery: [
            {
                image: 'https://images.jhtassets.com/4addff9c9ab7296a41175646bf2d3c1e40b59591/',
            },
            {
                image: 'https://images.jhtassets.com/a019e66686c42c91418856cede8597bb02d6a0a4/',
            },
            {
                image: 'https://images.jhtassets.com/c3246804a21ed05be136f4442bc4a412fc60edc2/',
            },
            {
                image: 'https://images.jhtassets.com/ba7f9c29a3a2f719281cabf085d009bdf7d1202f/',
            },
            {
                image: 'https://images.jhtassets.com/36075ac88c3ba489b553cef9827c12c62de2570d/',
            },
            {
                image: 'https://images.jhtassets.com/2978702105cb4f290a22b3320d7c1fd08ecfd190/',
            },
            {
                image: 'https://images.jhtassets.com/fa67193b13f68d1d82c267e619766308f45ad059/',
            },
            {
                image: 'https://images.jhtassets.com/030c35665d064061d7d8b5b515e8ba1d43656b3e/',
            },
        ]
    },
    {
        id: 11,
        gallery: [
            {
                image: 'https://images.jhtassets.com/fc43a473c8d82d740134f892aed1c64770c048d4/',
            },
            {
                image: 'https://images.jhtassets.com/cfc094e7ad0778ae122082a2cc867fbcbcc56cf6/',
            },
            {
                image: 'https://images.jhtassets.com/354f4aab970b811e2795c1c0c45ab38f9c8504db/',
            },
            {
                image: 'https://images.jhtassets.com/1a20e8bd7c8be36672657b82241d138e8de62f79/',
            },
            {
                image: 'https://images.jhtassets.com/d3dc2a0ba9a3e12f25fdecb4b5521ab3cc9039fd/',
            },
            {
                image: 'https://images.jhtassets.com/2d1edc3e0f10a7d34298ca496efad15449334f8f/',
            },
        ]
    },
    {
        id: 12,
        gallery: [
            {
                image: 'https://images.jhtassets.com/2acd7e01556a820a94b6e0169a5dc7b7d857c5cf/',
            },
            {
                image: 'https://images.jhtassets.com/250160675a72d87a73cbcb3383021a97e5c1ca70/',
            },
            {
                image: 'https://images.jhtassets.com/353eca1c8cf2ec4b128e09d824b0276825325fb3/',
            },
            {
                image: 'https://images.jhtassets.com/3963573346cda179af3611b534ed65620e613221/',
            },
            {
                image: 'https://images.jhtassets.com/302a2dc51af18c014aae130e0d12af288ba11a9a/',
            },
            {
                image: 'https://images.jhtassets.com/fc7ae7d3cea22209901ecc1481e938646ad4ae74/',
            },
            {
                image: 'https://images.jhtassets.com/417f3135d861b2645a00ee8beacc3ef22a23f23a/',
            },
            {
                image: 'https://images.jhtassets.com/155bf9e63f48725cf6d0531ae20450f2c57c4e24/',
            },
            {
                image: 'https://images.jhtassets.com/ecb8659a061abd205e86cb370fffec22d4169e27/',
            },
            {
                image: 'https://images.jhtassets.com/db92ce2f41518838ad5ad32065ce0e4513731024/',
            },
        ]
    },
]

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/e3c32bf568ab1ba3634b60cc58fa797ce81da16e/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'World Class ЛЕНИНСКИЙ',
        name: 'Россия',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/3286cceb20967c4fca5bbc9e0c4ed7005ecf3f6c/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'World Class АМИНЬЕВСКИЙ',
        name: 'Россия',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/cce615655270571d142fdc15eb20029150cdfe9d/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'Ohana Fitness',
        name: 'Россия',
    },
    {
        id: 4,
        image: 'https://images.jhtassets.com/679111f99eb50d364b8b0a539d47bf3cfcf843d8/transformed/h_558,w_558,c_fill',
        title: 'Иркутск',
        subtitle: 'World Class Lite',
        name: 'Россия',
    },
    {
        id: 5,
        image: 'https://images.jhtassets.com/db987805c041e9d4bb787db8a3e8fa7eeb6cc7da/transformed/h_558,w_558,c_fill',
        title: 'Janów',
        subtitle: 'FitFabric',
        name: 'Poland',
    },
    {
        id: 6,
        image: 'https://images.jhtassets.com/44ffad54e78f8ff11019236b3087756a087591d4/transformed/h_558,w_558,c_fill',
        title: 'Tavares',
        subtitle: 'Living Lean Fitness',
        name: 'США',
    },
    {
        id: 7,
        image: 'https://images.jhtassets.com/602ad5f1c81fecd0a2da58ed848738c444bc167c/transformed/h_558,w_558,c_fill',
        title: 'Monterrey',
        subtitle: 'Studio24',
        name: 'Mexico',
    },
    {
        id: 8,
        image: 'https://images.jhtassets.com/33ca1f341e714ca24d49ca8d588ad53c5430b2df/transformed/h_558,w_558,c_fill',
        title: 'Dubai',
        subtitle: 'METROFITT',
        name: 'Middle East',
    },
    {
        id: 9,
        image: 'https://images.jhtassets.com/a4bab4afcc950b9dcb9368c568cb1b989c19709a/transformed/h_558,w_558,c_fill',
        title: 'Menziken',
        subtitle: '2TE Etage',
        name: 'Швейцария',
    },
    {
        id: 10,
        image: 'https://images.jhtassets.com/4addff9c9ab7296a41175646bf2d3c1e40b59591/transformed/h_558,w_558,c_fill',
        title: 'Vught',
        subtitle: 'Health City',
        name: 'Нидерланды',
    },
    {
        id: 11,
        image: 'https://images.jhtassets.com/fc43a473c8d82d740134f892aed1c64770c048d4/transformed/h_558,w_558,c_fill',
        title: 'Highland Ranch',
        subtitle: 'Chuze Fitness',
        name: 'США',
    },
    {
        id: 12,
        image: 'https://images.jhtassets.com/2acd7e01556a820a94b6e0169a5dc7b7d857c5cf/transformed/h_558,w_558,c_fill',
        title: 'Munjeong',
        subtitle: 'Spowell',
        name: 'Korea',
    },
]


const ShowcasesHealthClubs = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='showcasesHealthClubs-page'>
        <section className='section-1 flex items-center justify-center md:!bg-cover !bg-contain md:!bg-center !bg-top md:py-0 pb-32 pt-12 main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white text-6xl text-center leading-none'>Фитнес-центры</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-4xl leading-none' style={{color: '#4c4c4c'}}>Лучший выбор вариантов</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesHealthClubs;