const INITIAL_STATE = {
    list: [],
    loading: false,
    message: null,
    data: {},
    feedback: [],
    reaction: {},
    room: {},
    step: 0,
    roomStep: 0,
}

export default (state = INITIAL_STATE, {type, payload, ...rest}) => {
    switch(type) {
        case 'fetch_carts_start':
            return {...state, loading: true, message: ''};
        case 'fetch_carts_error':
            return {...state, message: payload, loading: false};
        case 'fetch_carts_success':
            return {
                ...state,
                loading: false,
                message: '',
                list: payload.data,
            };

        case 'fetch_cart_detail_start':
            return {...state, loading: true, message: ''};
        case 'fetch_cart_detail_error':
            return {...state, message: payload, loading: false};
        case 'fetch_cart_detail_success':
            return {
                ...state,
                loading: false,
                data: payload.data,
            };

        case 'create_feedback_start':
            return {...state, loading: true, message: ''};
        case 'create_feedback_error':
            return {...state, message: payload, loading: false};
        case 'create_feedback_success':
            return {
                ...state,
                loading: false,
                feedback: payload.data,
            };

        case 'create_reaction_start':
            return {...state, step: 0, loading: true, message: ''};
        case 'create_reaction_error':
            return {...state, step: 1, message: payload, loading: false};
        case 'create_reaction_success':
            return {
                ...state,
                loading: false,
                reaction: payload.data,
                step: 1,
            };

        case 'create_room_start':
            return {...state, roomStep: 0, roomLoading: true, message: ''};
        case 'create_room_error':
            return {...state, roomStep: 0, message: payload, roomLoading: false};
        case 'create_room_success':
            return {
                ...state,
                roomLoading: false,
                room: payload.data,
                roomStep: 1,
            };

        case "sidebar_toggle":
            return { ...state, ...rest };

        default: return state;
    }
}