import { Container } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'
import { getFilter } from '../redux/actions/filterActions'


const StrengthGo = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/c7fdbc356d07c72250d56354420b207cde2b270b/"
					className="md:absolute top-0 left-0 w-full h-full"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-5xl relative z-10 px-5 md:text-white">Go Series</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Теперь с помощью нашей простой в использовании и компактной серии Go вы можете привлечь к занятиям клиентов, еще мало знакомых с силовыми тренировками и круговыми программами. Минимальные требования к регулировке, малый начальный вес и малые приращения создают комфортную атмосферу для всех желающих, а компактные размеры, установка встык и небольшая высота блока подойдут для любого пространства и бюджета.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 9)
                    dispatch(getFilter({
                        series: 9,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Все продукты серии Go</span></a>
					<CardBoxMoreImage
						title="Линейка"
						subtitle="Серия Go предлагает полный ассортимент оборудования для силовых и круговых тренировок с целым рядом отдельных блоков для основных групп мышц."
						image="https://images.jhtassets.com/32395287a3f6b46441abf20cda5f1874cf80de68/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="500px"
				img="https://images.jhtassets.com/6d2d333d7d7ef0915c5e72625497f6052d4f84d6/"
				title="Непревзойденное удобство в использовании"
				text="На большой табличке цветовой маркировкой наглядно показаны начальная и конечная позиции тела при выполнении упражнений и выделены активные группы мышц. В предусмотренных местах для хранения можно аккуратно разместить гаджеты, бутылки с водой и полотенца."
				contentWidth="35%"
				titleColor="#fff"
				contentRight={false}
				contentBg="#e1261c"
				textColor="#fff"
				paddingTopNull={true}
			/>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Упрощенное обслуживание"
						text="Прочный металлический кожух выдерживает жесткие условия эксплуатации и надежно крепится с помощью всего двух болтов, что значительно упрощает обслуживание. Направляющие стержни можно снимать без использования инструментов, что обеспечивает быстрое и легкое обслуживание."
						img="https://images.jhtassets.com/0b62944963ef896859203b9235d64e21d7ba9ced/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Для вашего зала и ваших клиентов"
						text="Продукты серии Go занимают маленькую площадь, а их конструкция допускает установку встык, что упрощает трафик и позволяет максимально эффективно использовать имеющееся пространство. Каждый отдельный блок имеет небольшой начальный вес и требует минимальной регулировки или же не требует ее вовсе, поэтому клиенты могут сразу приступать к занятиям."
						img="https://images.jhtassets.com/8ccc9c1443a5695b359795a676c56bbc2009a1d0/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default StrengthGo