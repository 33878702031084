import React from "react";
import { Container, Box } from "@mui/material";
import BgImageSection from "../components/BgImageSection";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import { Link } from "react-router-dom";


const Innovations = () => {
   return (
      <>
         <section
            className="flex md:flex-row flex-col items-end justify-center relative main-media-height"
            style={{ height: "70vh" }}
         >
            <img
               src="https://images.jhtassets.com/1ff32b766b1ccc167a6025fbffb3d2979f35dfdb/"
               alt=""
               className="md:absolute top-0 left-0 w-full h-full object-cover"
            />
            <Container className="!flex flex-col gap-5 items-center justify-center text-center relative z-10">
               <h5 className="md:text-white text-5xl font-bold py-10">
                  Инновации
               </h5>
            </Container>
         </section>
         <BgImageSection
            sectionHeight="80vh"
            img="https://images.jhtassets.com/16cce7a1d4298fbc23c3ce755e3bcd9c4ac70503/"
            title="Вера в лучшее"
            text="Мы обращаемся к владельцам фитнес-клубов, персональным тренерам, техническим специалистам и индивидуальным пользователям, чтобы понять, как сделать наше оборудование лучше и удовлетворить имеющиеся потребности. Мы не ждем, пока вы сами придете к нам с ответом. Ведь это часть нашей работы. Мы знаем, что нужно делать, только благодаря тому, что прислушиваемся к вашему мнению и знаем о ваших проблемах. Мы экспериментируем до тех пора, пока не найдем полноценного решения. Узнайте больше о том, как зарождались наши революционные разработки, и поверьте в лучшее."
            contentWidth="33%"
            titleColor="#e6e5e5"
            contentRight={false}
            contentBg="#000"
            textColor="#c2c6c9"
            linkColor="#e1261c"
         />
			<section className="mb-20">
				<Container maxWidth="xl" className="md:!px-12">
					<p className="text-red-600 text-2xl italic mb-5 py-5 lg:py-20 max-w-3xl font-bold text-center mx-auto">Самые грандиозные и передовые идеи Matrix создаются благодаря людям. И это не просто люди. Это самые важные люди для Matrix, с которых начинаются все наши инновации, - наши клиенты.</p>
					<Box>
						<h3 className="uppercase text-zinc-700 mb-4 font-black text-3xl text-center">ПРОДУКЦИЯ + ПРОЦЕСС + ЛЮДИ</h3>
						<p className="mb-10 max-w-3xl text-center mx-auto">Мы обращаемся к владельцам фитнес-клубов, персональным тренерам, техническим специалистам и индивидуальным пользователям, чтобы понять, как нам улучшить существующие продукты и внедрить новые для удовлетворения имеющихся потребностей. Мы не ждем, пока вы сами придете к нам с ответом. Ведь это часть нашей работы. Мы знаем, что нужно делать, благодаря тому, что прислушиваемся к вашему мнению и вашим проблемам, после чего мы приступаем к поискам решений, которое действительно работают.</p>
						<Box className='grid lg:grid-cols-3 gap-3'>
							<CardBox
								title="Мы слушаем"
								text="Мы уверены, что на первом этапе создания инноваций мы должны не делать, а слушать. Мы прислушиваемся к мнениям и предложениям о нашем оборудовании и оборудовании конкурентов. Мы задаем вопросы и анализируем ответы, чтобы понять суть проблемы. В конечном итоге все полученные нами ответы и отзывы воплощаются при создании усовершенствованного оборудования и новых прототипов."
								img="https://images.jhtassets.com/b6f4071c4c0274af93b21d9798355c4f6a429be1/"
								color="#4c4c4c"
								textColor="#666"
								contentBgColor="#fff"
								contentPt="40px"
							/>
							<CardBox
								title="Мы экспериментируем"
								text="Мы не можем быть уверены, что нашли лучшее решение, пока не исключим все те, которые недостаточно хороши. Не каждая идея проходит весь путь до создания прототипа. Не каждый прототип оказывается в зале. Только лучшие. Мы в компании Matrix знаем, что некоторые наши попытки окажутся неудачными. Если добиваешься успеха каждый раз - значит ставишь перед собой слишком низкие цели."
								img="https://images.jhtassets.com/541651a0f0a0b27e32dd437491002885e5805c3b/"
								color="#4c4c4c"
								textColor="#666"
								contentBgColor="#fff"
								contentPt="40px"
							/>
							<CardBox
								title="Мы добиваемся успеха"
								text="В результате этого процесса мы получаем усовершенствованное оборудование или совершенно новый тренажер. Беговую дорожку, устанавливающую новые стандарты. Новые инструменты для развития скорости и силы. Велотренажер, переворачивающий представления о виртуальной среде тренировок. Этот список может и будет продолжаться, потому что Matrix не остановит поиски новых и лучших способов удовлетворения потребностей наших клиентов."
								img="https://images.jhtassets.com/a38eadc1df04095580e1275dfbfcd040eb8d287c/"
								color="#4c4c4c"
								textColor="#666"
								contentBgColor="#fff"
								contentPt="40px"
							/>
						</Box>
					</Box>
				</Container>
			</section>
			<section className="mb-20">
				<Container maxWidth="xl" className="md:!px-12">
					<h4
						className="uppercase text-zinc-700 mb-8  lg:px-52 font-black text-3xl lg:text-5xl text-center mx-auto"
					>
						АССОРТИМЕНТ ИННОВАЦИОННЫХ ПРОДУКТОВ
					</h4>
					<h3 className="text-zinc-700 mb-4 font-black text-xl text-center">Инновации в кардиотренировках</h3>
					<p className="mb-10 max-w-3xl text-center mx-auto">Наше инновационное кардиооборудование не просто заставляет сердце биться быстрее. Ознакомьтесь с некоторыми из способов, которые позволили нам вдохнуть новую жизнь в кардиотренировки с помощью интеллектуальных функций, делающих занятия более комфортными, увлекательными, эффективными и взаимосвязанными.</p>
					<CardBoxMoreImage
						title="Беговая дорожка Performance Plus"
						subtitle="Мы постоянно стремимся повышать качество занятия бегом на наших беговых дорожках, как для владельцев залов, где они установлены, так и для использующих их любителей фитнеса. Нам известно, что беговые дорожки с пластинчатым полотном создают уникальные преимущества и для владельцев, и для пользователей, поэтому мы решили привнести этот уровень производительности в кардиозону."
						image="https://images.jhtassets.com/1d79075b6fbedcc9d89457228c95b2a1d4a0c783/"
						link="Узнать больше /"
						contentBgColor="#e1261c"
						contentRight={true}
						contentWidth="30%"
						contentLinkColor="#fff"
						contentTitleColor="#f5f5f5"
						contentSubtitleColor="#f9d2d0"
						linkTo="/innovations/performance-plus-treadmill"
					/>
					<Box className="grid lg:grid-cols-2">
						<CardBox
							title="Гребные тренажеры"
							text="Мы знали, что за 20 лет, прошедшие с выпуска нашего оригинального стационарного гребного тренажера, гребля значительно эволюционировала, став частью круговых программ, зон для кардиотренировок, специализированных занятий греблей, а также тренировочных комплексов для первоклассных спортсменов. Перед нашей командой разработчиков стояла задача спроектировать гребной тренажер, бескомпромиссно отвечающий требованиям всех сфер применения."
							img="https://images.jhtassets.com/c5e14cf559bbde0390ec2a82854b71512f890b0c/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="40px"
							linkTo="/innovations/finest-rowing-experience"
						/>
						<CardBox
							title="Тренажер S-Drive Performance"
							text="Мы приступили к разработке тренажера S-Drive Performance Trainer, когда обнаружили, что для круговых тренировок и для объектов спортивной подготовки необходимо одно и то же - инструмент, позволяющий пользователям выполнять 30-секундные спринтерские броски с высокой интенсивностью интервальных тренировок (HIIT). Узнайте о том, как мы создали новый тип оборудования для интенсивных и захватывающих упражнений, позволяющий легко начинать и завершать занятия в рамках самых динамичных тренировочных условий."
							img="https://images.jhtassets.com/bb3a445195e5b7a652550be96322a2f4a5214359/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="40px"
							linkTo="/innovations/s-drive"
						/>
					</Box>
				</Container>
			</section>
			<section
				className="py-20"
				style={{
					background: 'linear-gradient(171.76deg,#253746,#000)'
				}}
			>
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-slate-200 mb-4 font-black text-3xl text-center">Силовые инновации</h3>
					<p className="text-zinc-200 mb-10 max-w-3xl text-center mx-auto">Силовые тренировки нужны всем, чтобы оставаться в форме, поэтому мы так старались сделать их доступными практически для каждого. Узнайте о том, как мы создавали высокотехнологичный дизайн для повышения результативности ваших клиентов и создания новой эстетики ваших тренажерных залов.</p>
					<CardBoxMoreImage
						title="Серия GO"
						subtitle="Приступая к разработке наших силовых и круговых тренажеров специально для начинающих, мы ставили перед собой цель не просто улучшить уже имеющееся оборудование. Мы хотели разработать совершенно новую серию, которая была бы необычайно простой и удобной в использовании. Но каким образом можно создать оборудование, имеющее минимальный набор настроек, и при этом подходящее пользователям разной комплекции?"
						image="https://images.jhtassets.com/a5c95cfa41f4790044eb6056efa7b354c7c8abe1/"
						link="Узнать больше /"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="30%"
						contentLinkColor="#fff"
						contentTitleColor="#f5f5f5"
						contentSubtitleColor="#c2c6c9"
						linkTo="/innovations/go-series"
					/>
					<Box className="grid lg:grid-cols-2">
						<CardBox
							title="Серия Ultra"
							text="Создавая оборудование серии Ultra, мы пересмотрели все представления о том, как должно выглядеть оборудование для силовых тренировок, как оно должно работать, и как его можно усовершенствовать. В результате мы получили серию, способную улучшить вид и общую эстетику тренажерного зала, а также предложить совершенно новые увлекательные возможности для силовых тренировок."
							img="https://images.jhtassets.com/889b58b2ca1d64d5bc5aa70ba8e2498caca19d7c/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="40px"
							linkTo="/innovations/clear-field-of-vision"
						/>
						<CardBox
							title="Тренажер для ягодичных мышц Glute Trainer"
							text="Методики тренировки ягодичных мышц существуют не первый день и становятся все более популярными на фоне стремления спортсменов и любителей фитнеса непрерывно повышать свой потенциал и совершенствовать свое тело. Но никогда еще процесс проработки этой группы мышц не был таким комфортным, доступным и эффективным, как сегодня, благодаря нашей новой разработке. Узнайте больше о том, как мы создали более интеллектуальный и стабильный подход к работе над ягодичными мышцами."
							img="https://images.jhtassets.com/8ac72f87a468765e4bf6e4023341fb8da38391cf/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="40px"
							linkTo="/innovations/glute-trainer"
						/>
					</Box>
				</Container>
			</section>
			<section className="py-20">
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-zinc-700 mb-4 font-black text-2xl text-center">Инновации в групповых тренировках</h3>
					<p className="mb-10 max-w-3xl text-center mx-auto">Ничто не может сравниться с соревновательным и товарищеским духом групповых тренировок, также как ничто не сравнится с нашими инновационными решениями для них. Положитесь на наши достижения, создавая новые захватывающие и объединяющие программы, ради которых посетители будут возвращаться к вам снова и снова.</p>
					<Box className="grid lg:grid-cols-2">
						<CardBox
							title="Гребные тренажеры"
							text="Функциональные тренировки еще никогда не были так популярны, а групповые занятия как нельзя лучше повышают лояльность посетителей. Оборудование Connexus легко объединяет эти два направления в единую гибкую и доступную систему функционального тренинга, идеально подходящую для вашего объекта. Узнайте больше о том, как нам удалось взять все лучшее от обоих направлений."
							img="https://images.jhtassets.com/241cd58a0a44452c7b5635991265e38a3f76106c/"
							link="Узнать больше /"
							color="#e6e5e5"
							textColor="#c2c6c9"
							contentBgColor="#000"
							linkColor="hsla(0,0%,100%,.8)"
							contentPt="40px"
							linkTo="/innovations/high-function"
						/>
						<CardBox
							title="Тренажер S-Drive Performance"
							text="Мы задавали вопросы. Мы изучали каждый аспект велотренировок. Мы экспериментировали с различными конструктивными решениями. Несмотря на успех наших прошлых совместных разработок велотренажеров, в душе мы знали, что можем лучше. В результате мы создали серию тренировочного оборудования, привлекательного для самого широкого круга пользователей и способного повсеместно вдохнуть новую жизнь в занятия на велотренажерах."
							img="https://images.jhtassets.com/c6ca55c3cf4c5a0f1dd0d8cefd711cfa21c19a48/"
							link="Узнать больше /"
							color="#e6e5e5"
							textColor="#c2c6c9"
							contentBgColor="#000"
							linkColor="hsla(0,0%,100%,.8)"
							contentPt="40px"
							linkTo="/innovations/training-cycles"
						/>
					</Box>
				</Container>
			</section>
			<section
				className="py-20"
				style={{
					background: 'linear-gradient(171.76deg,#253746,#000)'
				}}
			>
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-slate-200 mb-4 font-black text-3xl text-center">Технологические инновации</h3>
					<p className="text-zinc-200 mb-10 max-w-3xl text-center mx-auto">Технологии являются неотъемлемой частью современного тренировочного процесса, а наши инновации делают его лучше, чем когда-либо. Узнайте, как мы разработали просто потрясающие консоли и эксклюзивные программы, чтобы вывести ваши тренировки на новый уровень.</p>
					<CardBoxMoreImage
						title="Консоли Touch и Touch XL"
						subtitle="Несмотря на то, что компания Matrix поставляет сенсорные консоли уже на протяжении многих лет, мы решили, что именно начало нового десятилетия является наилучшим моментом для качественного технологического прорыва в данной области. Узнайте, как мы создали удобную тренировочную консоль с интерфейсом на базе приложений, повторяющим привычные смартфоны, которая постоянно совершенствуется за счет бесплатного обновления программного обеспечения."
						image="https://images.jhtassets.com/a554799408e8bc7c04df783639556aea0f72da53/"
						link="Узнать больше /"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="30%"
						contentLinkColor="#fff"
						contentTitleColor="#f5f5f5"
						contentSubtitleColor="#c2c6c9"
						linkTo="/innovations/touch-console"
					/>
					<Box className="grid lg:grid-cols-2">
						<CardBox
							title="Светодиодная консоль Premium"
							text="Когда в процессе усовершенствования нашей линейки кардиотренажеров мы получили шанс проанализировать, какими могут и должны быть все светодиодные консоли, мы сразу же ухватились за эту возможность. Но как именно нам удалось создать более привлекательный дисплей за счет всего лишь нескольких ярких точек и кнопок?"
							img="https://images.jhtassets.com/60dda9379916f0fc078f8d8939b7a8958c7e147f/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="40px"
							linkTo="/innovations/premium-led-console"
						/>
						<CardBox
							title="Engage 360"
							text="Чтобы сделать персональные тренировки более индивидуальными, мы стремились создать систему, в первую очередь позволяющую инструкторам и посетителям работать так, как им нравится, используя любимые приложения, гаджеты и носимые устройства. Сегодня система Engage 360 помогает тренерам взаимодействовать с клиентами в режиме 24/7, чтобы назначать тренировки, планировать результаты, контролировать питание, наблюдать за активностью и делать многое другое, переосмысливая значение и возможности персонального тренинга."
							img="https://images.jhtassets.com/8fedf9235f5ccc88ab4a306bce496567aeb80aba/"
							link="Узнать больше /"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="40px"
							linkTo="/innovations/premium-led-console"
						/>
					</Box>
				</Container>
			</section>
			<section>
				<div
					className="CenterContentBox py-52 text-center relative"
					style={{
						background: '#000'
					}}
				>
					<h4 className="font-black text-3xl text-gray-600 mb-4" style={{color: '#e6e5e5'}}>Изучить все инновации</h4>
					<p className="text-gray-500 py-2 px-32" style={{color: '#c2c6c9'}}>
						Если вы верите в лучшее, узнайте, что могут сделать наши комплексные решения и ориентированные на клиента инновации для вашего объекта. От оснащения кардиозоны до взаимодействия с клиентами и подведения финансовых результатов - нет ничего, что мы не могли бы улучшить, работая вместе.
					</p>
					<Box className="flex flex-wrap gap-5 justify-center">
						<Link to="/cardio/catalog" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<img className="mr-2 inline-block w-6" src="https://images.jhtassets.com/b982b58c7d62061742bc1250667a9004e3b12aaa/" alt="" />
							<span className='text-base font-black'>Кардиооборудование</span>
						</Link>
						<Link to="/strength/catalog" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<img className="mr-2 inline-block w-6" src="https://images.jhtassets.com/b982b58c7d62061742bc1250667a9004e3b12aaa/" alt="" />
							<span className='text-base font-black'>Силовое оборудование</span>
						</Link>
						<Link to="/group-training/catalog" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<img className="mr-2 inline-block w-6" src="https://images.jhtassets.com/b982b58c7d62061742bc1250667a9004e3b12aaa/" alt="" />
							<span className='text-base font-black'>Оборудование для групповых тренировок</span>
						</Link>
					</Box>
				</div>
			</section>
      </>
   );
};

export default Innovations;
