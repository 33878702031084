import React from 'react'
import { Container, Box } from '@mui/material'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CenterContentBox from '../components/CenterContentBox'

const ConnectedSolutionsFacilitySpecificSolutions = () => {
	return (
		<>
			<section className="flex items-end justify-center relative" style={{height: '50vh'}}>
				<img
					src="https://images.jhtassets.com/010bbe5541dd81fb552b94c8909b80b6826347e2/"
					alt=""
					className='absolute top-0 left-0 w-full h-full object-cover'
				/>
            <Container className='flex flex-col gap-5 items-center justify-center text-center relative z-10' style={{display: 'flex'}}>
					<h5 className='text-white uppercase font-bold'>СПЕЦИАЛИЗИРОВАННЫЕ РЕШЕНИЯ</h5>
					<p className='text-2xl lg:text-5xl uppercase text-white mb-10'>СОЗДАНО ДЛЯ ВЕДЕНИЯ БИЗНЕСА</p>
            </Container>
        	</section>
			<section>
				<Container maxWidth="xl">
					<p className="leading-6 py-12 text-center">В зависимости от вида фитнес-центра, которым вы управляете, некоторые из наших специализированных решений могут подойти и вашим участникам, и для достижения ваших целей. Воспользуйтесь RFID, чтобы оптимизировать спортивный опыт, цифровым ТВ, если вы хотите добавить еще немного развлечений, удаленным аудио, чтобы координировать подключенные большие экраны, или IPTV и Pro:Idiom, если вы хотите вести коммерческую деятельность в гостиничной сфере.</p>
				</Container>
			</section>
			<section>
				<Container maxWidth="xl" className='mb-10'>
					<BgImageSection
						sectionHeight="70vh"
						img="https://images.jhtassets.com/717b45c80b4057e6068ad5739a92db0fa17283b9/"
						title="RFID"
						text="RFID — это разумный способ оптимизировать физические упражнения ваших участников. Простые теги радиочастоты делают регистрацию, загрузку программы и отслеживание тренировок полностью бесконтактными и фактически автоматическими. Участники могут использовать RFID с вашими имеющимися совместимыми с RFID торговыми автоматами, киосками и центрами продаж в помещении для быстрых покупок фирменного товара, закусок, напитков и т. д. С помощью RFID каждое взаимодействие ваших участников простым и беспроблемным."
						contentWidth="33%"
						titleColor="#4c4c4c"
						contentRight={false}
						contentBg="hsla(0,0%,100%,.9)"
						textColor="#666"
						linkColor="#e1261c"
					/>
				</Container>
				<Container maxWidth="xl" className='mb-10'>
					<BgImageSection
						sectionHeight="70vh"
						img="https://images.jhtassets.com/9b568940ffbedf6e8a87b329f0bd978aa12e04aa/"
						title="Партнерские интеграции"
						text="Наша технологическая инфраструктура позволяет двустороннее общение между существующими тренировками и платформами отслеживания и нашими решениями. Это значит, что вы можете продолжать пользоваться тем, что вам подходит сейчас. Ваши участники могут получать задания для тренировок и отслеживать прогресс, данные о котором доступны на разных решениях.Прекрасно то,что участники могут использовать тот же логин, который они уже используют на вашем объекте, поэтому использование будет поистине безукоризненным."
						contentWidth="33%"
						titleColor="#4c4c4c"
						contentRight={false}
						contentBg="transparent"
						textColor="#666"
						linkColor="#e1261c"
					/>
				</Container>
			</section>
			<section className='py-14'>
				<Container maxWidth="xl">
					<h3 className='pb-2 text-zinc-600 font-black text-3xl text-center'>ТЕЛЕВИДЕНИЕ</h3>
					<Box className='grid lg:grid-cols-3 gap-3'>
						<CardBox
							title="ЦИФРОВОЕ ТВ"
							text="Поддерживайте мотивацию пользователей и помогайте им достигать спортивных целей с помощью съемного дисплея с диагональю 41 см (16 дюймов). Мы предлагаем только лучшие технологии в области дисплеев, поэтому участники не смогут найти более яркое и четкое изображение где-либо еще. А кронштейн FITCONNEXION позволит удобно разместить все шнуры питания, кабели и элементы управления консолью."
							img="https://images.jhtassets.com/54350ee640549905d5a7e468cec7acc2a3155299/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
						/>
						<CardBox
							title="IPTV"
							text="Отели могут синхронизировать свои развлекательные доски, чтобы гости могли просматривать на своем кардиотренажере те же программы, что и в своих комнатах, комнате отдыха и в баре отеля. IPTV позволяет не только унифицировать отображение информации для постояльцев, но и эффективно управлять этим процессом."
							img="https://images.jhtassets.com/35c30c478ec1a0093a221a812eac19ecad06d08d/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
						/>
						<CardBox
							title="СОВМЕСТИМОСТЬ С PRO:IDIOM"
							text="Разработанный для гостиничной индустрии, Pro:Idiom зашифровывает видеосигналы высокого разрешения, чтобы защитить поставщиков содержимого от пиратства. С нашей консолью, совместимой с Pro:Idiom, и настройками цифрового ТВ постояльцы могут приостановить свой любимый фильм или телепередачу в своей комнате и продолжить смотреть с того же места в фитнес-центре отеля."
							img="https://images.jhtassets.com/5a35364711469459a51f8a20894e3447a6ff40d4/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
						/>
					</Box>
				</Container>
			</section>
			<section>
				<Container maxWidth="xl">
					<BgImageSection
						sectionHeight="60vh"
						img="https://images.jhtassets.com/0850fc1dddd1520b4ad834d5c3f2ee5eff1a6c31/"
						title="УДАЛЕННОЕ АУДИО"
						text="Если вы используете несколько больших подключенных телевизионных устройств, мы можем повысить качество звукового опыта участника. Наши радиоприемники обеспечивают безупречный звук, чтобы предоставить вашим клиентам синхронизированный звуковой и визуальный опыт."
						contentWidth="33%"
						titleColor="#4c4c4c"
						contentRight={false}
						contentBg="transparent"
						textColor="#666"
						linkColor="#e1261c"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="ПРИСОЕДИНЯЙТЕСЬ К FACILITY-SPECIFIC SOLUTIONS СЕГОДНЯ"
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
				/>
			</section>
		</>
	)
}

export default ConnectedSolutionsFacilitySpecificSolutions