import React from 'react';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import '../assets/scss/_cardio_3page.scss';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import CenterContentBox from '../components/CenterContentBox';
import { useDispatch } from 'react-redux';
import { getFilter } from '../redux/actions/filterActions';

const CardioLifestyle = () => {
    const dispatch = useDispatch();
  return (
    <section className='cardio-page'>
        <section className='section-1 py-16 md:px-12 relative flex flex-col justify-end md:!bg-cover md:!bg-center !bg-contain !bg-top main-media-height' style={{height: '70vh', background: 'url(https://images.jhtassets.com/f338af7366246f716987485db925e602aca015d4/) no-repeat' }}>
            <Container maxWidth="xl">
                <div className='md:text-white md:text-6xl'><strong className='font-bold'>Серия</strong> Lifestyle</div>
            </Container>
        </section>
        <section className='section-2 py-16 md:px-12'>
            <Container maxWidth="xl" className="mb-16 md:!px-0">
                <div className='leading-7 w-3/4 mb-6' style={{color: '#333'}}>Серия Lifestyle, созданная специально для фитнес центров в многоквартирных домах, небольших отелей, корпоративных программ, общественных организаций и других подобных объектов, идеально впишется в ваше пространство, бюджет и концепцию. Простые характеристики помогут каждому пользователю приступить к регулярным тренировкам, независимо от опыта и уровня подготовки. Единый дизайн сделает интерьер вашего фитнес центра привлекательным для всех посетителей.</div>
                <a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 3)
                    dispatch(getFilter({
                        series: 3,
                    }))
                }} href='/cardio/catalog' className='inline-block px-4 py-3 bg-red-600 hover:bg-red-700 duration-200 text-white font-black'>Все продукты серии Lifestyle</a>
            </Container>
            <CardBoxMoreImage
                title="Линейка оборудования"
                subtitle="Серия кардиотренажеров Lifestyle включает беговую дорожку, лестницу-эскалатор, эллиптический эргометр, вертикальный велоэргометр и горизонтальный велоэргометр."
                image="https://images.jhtassets.com/75273455ddc7c6775d02c2be3a04526a54dd1f57/"
                contentBgColor="#000"
                contentRight={true}
                contentWidth="25%"
                contentTitleColor="#e6e5e5"
                contentSubtitleColor="#c2c6c9"
                contentLinkColor="#861711"

            />
        </section>
        <section className='section-4 py-16 md:px-12' style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
            <Container maxWidth="xl" className="md:!px-0">
                <div className='grid lg:grid-cols-2 gap-6 mb-16'>
                    <div>
                        <img src='https://images.jhtassets.com/74b6c90e3fd2edc009d2ddf7ef2616f13efe3f42/' alt='' />
                        <div className='px-6 py-12' style={{background: '#fff'}}>
                            <div className='font-black text-3xl leading-none mb-6' style={{color: '#4c4c4c'}}>Доступность каждому</div>
                            <p className='leading-6' style={{color: '#666', fontSize: '15px'}}>Благодаря упрощенному управлению каждый сможет с легкостью приступить к тренировкам, даже не имея опыта обращения с тренажерами.</p>
                        </div>
                    </div>
                    <div>
                        <img src='https://images.jhtassets.com/b347592c609d5c5307ad66e84e5259dce0f2cfdd/' alt='' />
                        <div className='px-6 py-12' style={{background: '#fff'}}>
                            <div className='font-black text-3xl leading-none mb-6' style={{color: '#4c4c4c'}}>Удобство размещения</div>
                            <p className='leading-6' style={{color: '#666', fontSize: '15px'}}>Платформы с автономным питанием позволяют свободно размещать оборудование даже на небольшой площади (подключение к электросети требуется только для беговых дорожек и лестниц-эскалаторов).</p>
                        </div>
                    </div>
                </div>
                <CardBoxMoreImage
                    title="Консоли Matrix Cardio"
                    subtitle="Оснащение вашего оборудования подходящей консолью может сделать его простым и приятным в использовании, либо же полностью цифровым, с богатым выбором опций и развлечений. Консоли с подключением к Wi-Fi обеспечат вам доступ к нашим самым эффективным сетевым решениям, таким как Engage 360, Community 360 и Asset Management."
                    image="https://images.jhtassets.com/8b52d387a7c904745934139abca0529f1949e508/"
                    link="Узнать больше о консолях /"
                    contentBgColor="#c2c6c9"
                    contentRight={true}
                    contentWidth="25%"
                    contentTitleColor="#4c4c4c"
                    contentSubtitleColor="#666"
                    contentLinkColor="#e1261c"
                    linkTo='/cardio/consoles'
                />
            </Container>
        </section>
        <section className='section-5 py-16'>
            <CenterContentBox
                title="Добейтесь большего с Matrix"
                subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
                link="Обратная связь"
                titleColor="#4C4C4F"
                subtitleColor="#191919"
                linkTo="/about-us"
            />
        </section>
    </section>
  )
}

export default CardioLifestyle;