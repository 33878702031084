import React from 'react'
import { Container, Box } from "@mui/material";

const InnovationsClearFieldOfVision = () => {
	return (
		<>
			<section className="flex items-end justify-center relative" style={{height: '50vh'}}>
				<img
					src="https://images.jhtassets.com/15e3ac8c1c017151d337c2e38cb3cb8b998870b8/"
					alt=""
					className='absolute top-0 left-0 w-full h-full object-cover'
				/>
        	</section>
			<section className='pb-10'>
				<Container maxWidth="xl" className="md:!px-10 lg:!flex mt-10">
					<Box className='lg:w-2/3'>
						<h2 className='text-4xl font-black mb-5 text-zinc-700'>Свободное поле зрения</h2>
						<h5 className='font-bold text-2xl mb-5 text-zinc-700'>Чтобы освободить поле зрения, нам пришлось уменьшить высоту блоков грузов.</h5>
						<div className='text-zinc-800'>
							<p className='py-2'>Наверное, все занимались в классическом зале для силовых тренировок с огромным количеством рычагов, соединений и рукояток, которые мешают при каждом движении. Для большинства тренажерных залов размещение оборудование становится настоящей головоломкой: поле зрения пользователей и инструкторов почти всегда перекрыто, да и добиться привлекательного внешнего вида помещения при этом нереально.</p>
							<p className='py-2'>Это обычная проблема, и у компании Matrix есть необычное решение — серия Ultra.</p>
							<p className='py-2'>Наши специалисты в области промышленного дизайна полностью переосмыслили подход к проектированию однопозиционных тренажеров. Это проявилось в придании более обтекаемой формы и уменьшении высоты блока грузов до 122 см для всех тренажеров серии. В результате значительно улучшился внешний вид оборудования и повысилась его эффективность, благодаря чему улучшается внешний вид и повышается эффективность и вашего зала для силовых тренировок.</p>
							<p className='py-2'>Но внешний вид, конечно же, был не единственным улучшением. Контактные ручки Action Specific Grips специальной эргономичной формы уменьшают давление в местах соприкосновения и делают оборудование серии Ultra более комфортным в использовании. А электронный счетчик повторений упрощает учет данных о тренировке. Кроме того, пользователи могут регулировать массу грузов в положении сидя, а встроенные приспособления для хранения принадлежностей позволяют удобно разместить бутылки с водой, полотенца или другие принадлежности.</p>
							<p className='py-2'>Это был всеобъемлющий процесс разработки, в ходе которого мы учли абсолютно все. Мы проработали все идеи по поводу того, как оборудование должно выглядеть, как оно должно функционировать и какие улучшения можно внести. И в результате нам удалось существенно поднять планку стандартов в области тренажеров и снова подтвердить веру в то, что всегда можно придумать что-то лучшее. Иногда нам просто нужно придумать что-то невероятное, а затем воплотить наши мечты в реальность.</p>
						</div>
					</Box>
					<Box className='lg:w-1/3 pt-14 px-3'>
						<img className='my-5' src="https://images.jhtassets.com/dd6a0d1203ebb980ebcb9e31b9e9393b48e9339f/" alt="" />
					</Box>
				</Container>
			</section>
		</>
	)
}

export default InnovationsClearFieldOfVision