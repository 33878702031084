import request from "../../helpers/request";

export const getCarts = () => (dispatch) => {
    dispatch({type: 'fetch_carts_start'})

    request
      .getCarts()
      .then(({data}) => {
        dispatch({type: 'fetch_carts_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'fetch_carts_error'})
      })
};

export const getCartDetail = (id) => (dispatch) => {
    dispatch({type: 'fetch_cart_detail_start', payload: id})

    request
      .getCartDetail(id)
      .then(({data}) => {
        dispatch({type: 'fetch_cart_detail_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'fetch_cart_detail_error'})
      })
}

export const createFeedback = (params) => (dispatch) => {
    dispatch({type: 'create_feedback_start', payload: params})

    request
      .createFeedback(params)
      .then(({data}) => {
        dispatch({type: 'create_feedback_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'create_feedback_error'})
      })
}

export const createReaction = (params) => (dispatch) => {
    dispatch({type: 'create_reaction_start', payload: params})

    request
      .createReaction(params)
      .then(({data}) => {
        dispatch({type: 'create_reaction_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'create_reaction_error'})
      })
}

export const createRoom = (params) => (dispatch) => {
    dispatch({type: 'create_room_start', payload: params})

    request
      .createRoom(params)
      .then(({data}) => {
        dispatch({type: 'create_room_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'create_room_error'})
      })
}