import React from 'react'
import { Container, Box } from '@mui/material'
import CardBox from '../components/CardBox'
import CenterContentBox from '../components/CenterContentBox'

const ConnectedSolutionsTargetTraining = () => {
	return (
		<>
			<section
				className='relative flex md:flex-row flex-col items-end justify-center main-media-height'
				style={{height: '450px'}}
			>
				<img
					src="https://images.jhtassets.com/e8b23fd9e08b08a3023c51acbecb248c53d78fb0/"
					className='md:absolute top-0 left-0 w-full h-full md:object-cover object-contain'
					alt=""
				/>
				<p className="text-2xl md:text-5xl relative z-10 md:pb-12 md:text-white w-full text-center md:mt-0 mt-2">Целевые тренировки</p>
			</section>
			<Container maxWidth="xl" className='text-center py-10 lg:py-20'>
				<h3 className='text-zinc-600 font-black uppercase text-3xl mb-6'>МОТИВАЦИЯ. ИЗМЕРЕНИЕ ПРОГРЕССА. УНИКАЛЬНЫЙ ОПЫТ.</h3>
				<p className='text-zinc-600 md:w-2/3 mx-auto'>Эффективные занятия с группами, разработка непревзойденных круговых тренировок или консультирование спортсменов, стремящихся раздвинуть границы своих возможностей, — какая бы задача перед вами ни стояла, вы можете вдохновить своих подопечных на новые успехи с помощью ярких цветов, привлекательной графики и персональных тренировок, приносящих реальные результаты. В зависимости от своего помещения и целевой аудитории вы можете выбрать тренажер CXP Target Training Cycle, RXP Rower или S-Drive Power. Целевые тренировки — это уникальный и эксклюзивный продукт Matrix.</p>
			</Container>
			<section>
				<Container maxWidth="xl" className='!flex justify-center flex-wrap gap-y-10'>
					<article className='text-center px-3 lg:px-8 lg:w-1/3 md:w-1/2'>
						<img src="https://images.jhtassets.com/5cedffba554e928e593b76ca90e4e3b1ff0af4e6/" className='h-96 mx-auto' alt="" />
						<h4 className='text-zinc-800 font-bold text-2xl py-6'>Частота сердечных сокращений</h4>
						<p className='text-zinc-600 text-sm'>В рамках целевой тренировки с использованием показателя частоты сердечных сокращений (ЧСС) точно определяются зоны ЧСС, соответствующие уровню физической подготовки каждого пользователя, при этом цветная подсветка показывает текущую интенсивность упражнения, регулируя его темп.</p>
					</article>
					<article className='text-center px-3 lg:px-8 lg:w-1/3 md:w-1/2'>
						<img src="https://images.jhtassets.com/4011715db37edadafd51e87f67e2a0f0643a544a/" className='h-96 mx-auto' alt="" />
						<h4 className='text-zinc-800 font-bold text-2xl py-6'>Ватты</h4>
						<p className='text-zinc-600 text-sm'>При выборе индивидуального параметра ваттов программа подталкивает пользователей по максимуму задействовать силу мышц, отображая на дисплее прилагаемые усилия. Яркая подсветка поддерживает тренирующихся и помогает контролировать нагрузку.</p>
					</article>
					<article className='text-center px-3 lg:px-8 lg:w-1/3 md:w-1/2'>
						<img src="https://images.jhtassets.com/431aa17ae8c38b408313373be5f5a544b7d6b41d/" className='h-96 mx-auto' alt="" />
						<h4 className='text-zinc-800 font-bold text-2xl py-6'>Расстояние</h4>
						<p className='text-zinc-600 text-sm'>При целевой тренировке на основе расстояния пользователи устанавливают протяженность дистанции, которую они хотят преодолеть с учетом уровня физической подготовки. При помощи интуитивно понятной цветной подсветки участники тренировки смогут легко следить за процессом, а программа будет побуждать их двигаться дальше к новым вершинам, о достижении которых они раньше и не мечтали.</p>
					</article>
					<article className='text-center px-3 lg:px-8 lg:w-1/3 md:w-1/2'>
						<img src="https://images.jhtassets.com/1267a2a8a2134d301c5b6092ae3b6ad27840334c/" className='h-96 mx-auto' alt="" />
						<h4 className='text-zinc-800 font-bold text-2xl py-6'>Расстояние</h4>
						<p className='text-zinc-600 text-sm'>При целевой тренировке на основе расстояния пользователи устанавливают протяженность дистанции, которую они хотят преодолеть с учетом уровня физической подготовки. При помощи интуитивно понятной цветной подсветки участники тренировки смогут легко следить за процессом, а программа будет побуждать их двигаться дальше к новым вершинам, о достижении которых они раньше и не мечтали.</p>
					</article>
					<article className='text-center px-3 lg:px-8 lg:w-1/3 md:w-1/2'>
						<img src="https://images.jhtassets.com/4e50322b8808833a12c2e5e5df6134c19bff86c3/" className='h-96 mx-auto' alt="" />
						<h4 className='text-zinc-800 font-bold text-2xl py-6'>Расстояние</h4>
						<p className='text-zinc-600 text-sm'>При целевой тренировке на основе расстояния пользователи устанавливают протяженность дистанции, которую они хотят преодолеть с учетом уровня физической подготовки. При помощи интуитивно понятной цветной подсветки участники тренировки смогут легко следить за процессом, а программа будет побуждать их двигаться дальше к новым вершинам, о достижении которых они раньше и не мечтали.</p>
					</article>
				</Container>
			</section>
			<section className='my-8'>
				<Container maxWidth="xl">
					<div className='lg:flex' style={{background: '#141414'}}>
						<Box className='lg:w-1/2 md:p-14 p-6'>
							<h4 className='text-3xl font-black text-white mb-5'>Цветная индикация прилагаемых усилий</h4>
							<p className='text-zinc-400'>В светодиодной подсветке дисплея для целевых тренировок применяются три интуитивно понятных цвета, отражающие прилагаемые усилия с учетом индивидуальных параметров: синий — тренировка еще не достигла заданного уровня интенсивности, зеленый — приближение к цели, и красный — пользователь превысил целевой показатель. Инструкторы смогут легко руководить тренировкой как начинающих, так и опытных членов группы, гибко адаптируя нагрузку в зависимости от их персональных задач.</p>
						</Box>
						<img
							className='lg:w-1/2'
							src="https://images.jhtassets.com/aca0247147e76c1dedd08261ca123d0eab6004ed/"
							alt=""
						/>
					</div>
				</Container>
			</section>
			<section className='relative my-14'>
				<img
					className='absolute top-0 left-0 w-full'
					src="https://images.jhtassets.com/dc9483af1027f5894c57fa203736b43930206051/"
					alt=""
				/>
				<Container maxWidth="xl" className='z-10 relative'>
					<h4 className='text-center md:text-white text-3xl py-14 font-black'>Оборудование для целевых тренировок</h4>
					<Box className='grid lg:grid-cols-3 gap-6'>
						<CardBox
							title="Велотренажер CXP Target Training Cycle"
							text="Велотренажер CXP Target Training Cycle упрощает руководство группой продвинутого уровня подготовки, члены которой работают над индивидуальными целями. Тренировочные сессии направлены на разные параметры и построены таким образом, что члены группы сохраняют энергию до конца занятия и могут видеть свой прогресс с каждым поворотом педалей. При помощи функции оценки субмаксимальной частоты сердечных сокращений и тестов функциональной пороговой мощности (FTP) продолжительностью 20 и 8 минут можно определить правильность выбранного режима езды для каждого пользователя в зависимости от его физических возможностей."
							link="Представляем велотренажер CXP Target Training Cycle /"
							img='https://images.jhtassets.com/d3a018fa0cc998b30c97ab2ecf74164e50b8cfac/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#e0e0e0"
							contentPt="50px"
							boxShadow="0 10px 15px -5px rgb(0 0 0 / 30%)"
							href="/"
						/>
						<CardBox
							title="RXP Rower"
							text="Гребной тренажер RXP Rower повышает эффективность гребли, так как позволяет отслеживать показатели действительно значимые для этого вида упражнений. Помимо этого, вы можете создавать эксклюзивные программы круговых тренировок, ориентированные на проработку разных параметров, чтобы разнообразить занятие. Оценка субмаксимальной частоты сердечных сокращений, степ-тест, отрезки 500 и 2000 метров помогают выйти на индивидуальный уровень нагрузки, оптимально соответствующий подготовке конкретного пользователя. Благодаря этому в одной группе могут заниматься как опытные любители гребли, так и те, кто пробует себя в этом виде впервые."
							link="Представляем гребной тренажер RXP Rower /"
							img='https://images.jhtassets.com/7735f9b1947a0b350551da52199acda7043ef8e1/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#e0e0e0"
							contentPt="50px"
							boxShadow="0 10px 15px -5px rgb(0 0 0 / 30%)"
							href="/"
						/>
						<CardBox
							title="Тренажеры S-Drive Power Performance Trainer"
							text="Тренажер S-Drive Power предназначен для профессиональных спортсменов и тренеров и дает возможность проводить тренировки на основе продвинутых параметров и точной обратной связи, которые позволят улучшить выносливость и взрывную силовую мощь и довести физическую форму до идеала. Оценка субмаксимальной частоты сердечных сокращений помогает пользователям контролировать уровень подготовки, создавая условия для планомерного движения к поставленным спортивным целям."
							link="Представляем тренажер S-Drive Power /"
							img='https://images.jhtassets.com/15c0f69e2ba1704aa3345ee3006f92997340b305/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#e0e0e0"
							contentPt="50px"
							boxShadow="0 10px 15px -5px rgb(0 0 0 / 30%)"
							linkTo="/"
						/>
					</Box>
				</Container>
			</section>
			<section className='bg-stone-600'>
				<Container maxWidth="xl">
					<Box className='py-10 text-center max-w-4xl mx-auto mb-10'>
						<h3 className='uppercase text-white text-2xl lg:text-4xl font-black mb-6'>ОРИГИНАЛЬНЫЕ ПРОГРАММЫ ЦЕЛЕВЫХ ТРЕНИРОВОК ОТ MATRIX</h3>
						<p className='text-gray-300'>Выберите велотренажер CXP Target Training Cycle, гребной тренажер RXP Rower или беговой тренажер S-Drive Power в соответствии потребностями вашего спортзала и клиентов. При выборе любого решения вы получаете доступ к эксклюзивным программам целевых тренировок, разработанным экспертами Matrix.</p>
					</Box>
					<Box className="grid lg:grid-cols-3 py-5">
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/331bc3fbcf645fed22247a52a993cf1ecb285c3f/" alt="" />
							<p className="text-2xl uppercase relative z-10">ПРОГРАММА MATRIX RIDE НА ВЕЛОТРЕНАЖЕРАХ CXP</p>
						</article>
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/59d58c2f12ab62b1499baf8a6a8520564e0bc59c/" alt="" />
							<p className="text-2xl uppercase relative z-10">ОРИГИНАЛЬНЫЕ ТРЕНИРОВКИ НА ГРЕБНОМ ТРЕНАЖЕРЕ RXP</p>
						</article>
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/24834e95899de822355fea39de6bd47a24dfa1f3/" alt="" />
							<p className="text-2xl uppercase relative z-10">ПРОТОКОЛЫ ТРЕНИРОВКИ СКРОСТИ НА ТРЕНАЖЕРАХ S-DRIVE POWER</p>
						</article>
					</Box>
				</Container>
				<h4 className='text-white text-4xl font-black mb-6 text-center'>Connected Solutions / Цифровые решения</h4>
				<Container maxWidth="xl" className='!grid md:grid-cols-3 gap-6'>
					<CardBox
						title="Community 360"
						text="Сеть контроля тренировок Community 360 позволяет участникам собирать данные фитнес-приложений в едином персонализированном интерфейсе, органично включая ваш спортзал в их жизнь. Наша открытая платформа поможет вам оптимизировать бизнес благодаря специализированным функциям управления спортзалом, отчетам об активности посетителей, эффективным промоакциям и бонусам лояльности и множеству других преимуществ."
						link="Узнать больше /"
						img='https://images.jhtassets.com/204d2cfe2bc8b686a38a34932a27a5e203cf7c32/'
						color="#e6e5e5"
						textColor="#c2c6c9"
						contentBgColor="transparent"
						contentPt="50px"
						linkColor="hsla(0,0%,100%,.8)"
						linkTo="/connected-solutions/community360"
					/>
					<CardBox
						title="Engage 360"
						text="Портал Engage 360 позволяет тренерам расширить круг клиентов, представляя уникальный образ вашего бренда и привлекая в спортзал новых посетителей. Объедините Engage 360 с сетью Community 360 и консолью для целевых тренировок Target Training Console в комплексное решение, чтобы укрепить лояльность посетителей и тем самым обеспечить прибыльность бизнеса на годы вперед."
						link="Узнать больше /"
						img='https://images.jhtassets.com/2799109f7b54d890a49829bc8309196bc204f402/'
						color="#e6e5e5"
						textColor="#c2c6c9"
						contentBgColor="transparent"
						contentPt="50px"
						linkColor="hsla(0,0%,100%,.8)"
						linkTo="/connected-solutions/engage360"
					/>
					<CardBox
						title="Радиочастотная идентификация (RFID)"
						text="Решение RFID использует простые метки радиочастотной идентификации, при помощи которых вы превратите регистрацию посетителей, загрузку программ, отслеживание тренировок и даже спонтанные покупки в спортзале в полностью бесконтактный, почти автоматический процесс. Благодаря RFID любые силовые тренировки станут простым и продуктивным занятием, что, безусловно, повысит доходность вашего бизнеса."
						link="Узнать больше /"
						img='https://images.jhtassets.com/e56e59ad449a8aba1e664313a5e62f78e0b65384/'
						color="#e6e5e5"
						textColor="#c2c6c9"
						contentBgColor="transparent"
						contentPt="50px"
						linkColor="hsla(0,0%,100%,.8)"
						linkTo="/connected-solutions/facility-specific-solutions"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Откройте для себя преимущества консоли для целевых тренировок Target Training Console уже сегодня"
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default ConnectedSolutionsTargetTraining