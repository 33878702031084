import React from "react";
import { Container, Button, Box } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import CenterContentBox from "../components/CenterContentBox";
import HoverBoxAnimation from "../components/HoverBoxAnimation";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $7 from "../assets/videos/1-7.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const ConnectedSolutionsSprint8 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$7}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="flex items-end justify-center relative"
        style={{ height: "70vh" }}
      >
        <img
          src="https://images.jhtassets.com/1e593967ea5e704ba0196fc2054b05ee8cc127ae/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container
          className="flex flex-col gap-5 items-center justify-center text-center relative z-10"
          style={{ display: "flex" }}
        >
          <h5 className="text-white uppercase font-bold">SPRINT 8</h5>
          <p className="text-2xl lg:text-5xl uppercase text-white">
            МАКСИМАЛЬНЫЙ РЕЗУЛЬТАТ. МИНИМАЛЬНЫЕ ВРЕМЕННЫЕ ЗАТРАТЫ.
          </p>
          <Button
            onClick={handleOpen}
            className="VideoPopupButton mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none font-bold text-xl relative top-8"
            style={{ color: "#e1261c" }}
          >
            <BsPlayCircle size={32} fill="" className="mr-2 fill-red-700" />
            <span className="font-bold">СМОТРЕТЬ ВИДЕО</span>
          </Button>
        </Container>
      </section>
      <section className="my-8 lg:my-20">
        <Container maxWidth="xl">
          <p className="py-8 lg:px-20 text-zinc-600 text-center">
            Ознакомьте ваших участников с нашей эксклюзивной программой Sprint 8
            и они будут возвращаться снова и снова. Разработанная Филом
            Кэмпбеллом (Phil Campbell) (M.S., M.A., FACHE, ASCM-PT), Sprint 8
            выходит за рамки обычной высокоинтенсивной интервальной тренировки,
            чтобы дать участникам видимый результат.
          </p>
          <img
            className="my-10"
            src="https://images.jhtassets.com/7faefd782afe19daede5aaffd5832f8e8fe31252/"
            alt=""
          />
          <Box className="py-3 lg:py-10 text-center max-w-4xl mx-auto">
            <h3 className="uppercase text-zinc-700 text-2xl lg:text-4xl font-black mb-6">
              РЕЗУЛЬТАТИВНОСТЬ НАУЧНО ДОКАЗАНА
            </h3>
          </Box>
          <Box className="grid lg:grid-cols-3 py-4">
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/6cb30dfa494c7533f41c9d7e36bb55704cf680b9/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">ЭФФЕКТИВНО</p>
            </article>
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/6ba2850f03cb76d7a419f9dbb1b5a7ff7b497128/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">БЫСТРО</p>
            </article>
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/b024a3b394f57c3fced538ca1505fdd4442eb227/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">ПРОСТО</p>
            </article>
          </Box>
        </Container>
        <p className="text-center">
          Изучите подробно, что Sprint 8 может дать вашим участникам на
          Sprint8.com.
        </p>
      </section>
      <section>
        <Container maxWidth="xl">
          <Box className="py-10 text-center max-w-4xl mx-auto">
            <h3 className="uppercase text-zinc-700 text-2xl lg:text-4xl font-black mb-6">
              РЕЗУЛЬТАТИВНОСТЬ НАУЧНО ДОКАЗАНА
            </h3>
          </Box>
          <Box className="!grid lg:grid-cols-3 gap-6">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/00e86ebe28343e5fee152ff68da1b755079f90d7/"
              title="ИССЛЕДОВАНО И ПОДТВЕРЖДЕНО"
              text="Фил Кэмпбелл (Phil Campbell) это известный спортивный тренер и признанный автор программ Sprint 8 Cardio Protocol и Ready, Set, Go! Synergy Fitness."
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/2059796d9b6b9b8000f84928ed5199b4f00280a2/"
              title="О ФИЛЕ КЭМПБЕЛЛЕ (PHIL CAMPBELL)"
              text="Узнайте больше о научном подходе, который делает Sprint 8 самый эффективной и быстрой из доступных тренировок."
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/42de686247947846cce4a5b88e4b8edd0be20349/"
              title="УКРЕПИТЕ ЛОЯЛЬНОСТЬ КЛИЕНТОВ С ПОМОЩЬЮ SPRINT 8"
              text="Разработанная с приоритетом на результат, за которым участники будут возвращаться снова и снова, программа Sprint 8 Training Guide предоставляет важную информацию для успеха тренировки., Set, Go! Synergy Fitness."
            />
          </Box>
        </Container>
      </section>
      <section>
        <CenterContentBox
          title="ПРИСОЕДИНЯЙТЕСЬ К SPRINT 8"
          link="Обратная связь"
          titleColor="#4C4C4F"
          subtitleColor="#191919"
          linkTo="/contact-us"
        />
      </section>
    </>
  );
};

export default ConnectedSolutionsSprint8;
