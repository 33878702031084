import React from 'react'
import { useSelector } from 'react-redux';
import { Container } from '@mui/system'
import { Link } from 'react-router-dom';
import Loader from '../components/loader/Loader';

const SearchPage = () => {
    const searchList = useSelector((state) => state.filter.searchList);
    const { searchLoading } = useSelector((state) => state.filter);

  return (
    <Container maxWidth="xl" className="md:!px-16 !px-2 md:!py-16 !py-6">
        <div className='md:px-16'>
        {searchLoading && <Loader />}
        {searchList?.length > 0 && <div className='md:text-3xl leading-none text-xl font-black md:pb-4 pb-2 md:mb-4 mb-2 border-b border-black' style={{color: '#4c4c4c'}}>Результаты поиска: {searchList?.length > 0 && searchList?.length}</div>}
        {!searchLoading &&
            searchList?.length > 0 ?
            searchList?.map(item => (
                <div>
                    {item.category === 'Кардиооборудование' &&
                    <Link to={`/cardio/${item.id}`} className="text-3xl font-black leading-none hover:underline" style={{color: '#4c4c4c'}}>{item.name}</Link>
                    }
                    {item.category === 'Сила' &&
                    <Link to={`/strength/${item.id}`} className="text-3xl font-black leading-none hover:underline" style={{color: '#4c4c4c'}}>{item.name}</Link>
                    }
                    {item.category === 'Групповые тренировки' &&
                    <Link to={`/group-training/${item.id}`} className="text-3xl font-black leading-none hover:underline" style={{color: '#4c4c4c'}}>{item.name}</Link>
                    }
                    <div className='uppercase text-sm' style={{color: '#999'}}>{item.productConsole}</div>
                    <p className='leading-7 py-4 md:text-lg' style={{color: '#666'}}>{item.description}</p>
                </div>
            ))
            :
            "Продукты не найдены!"
        }
        </div>
    </Container>
  )
}

export default SearchPage