import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import ProductItem from '../components/ProductItem'
import "../assets/scss/_radio_button.scss"
import { getFilter } from '../redux/actions/filterActions'
import "../assets/scss/_checkbox.scss"
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import Loader from '../components/loader/Loader';

const ClassName = "CheckBox text-zinc-600 text-sm font-bold pl-6 flex items-center"


const StrengthCatalog = () => {
	const dispatch = useDispatch();
	let category_id = window.localStorage.getItem('category_id');
	let seria_id = window.localStorage.getItem('seria_id');
	const [searchParams, setSearchParams] = useSearchParams();
	const { filterLoading } =  useSelector((state) => state.filter);
	const [filter, setFilter] = useState({
		category: category_id,
		series: seria_id,
	});
	useEffect(() => {
		dispatch(getFilter(filter))
	},[])
	const categories = useSelector((state) => state.filter.categories);
	const cardioCategory = categories?.find(item => item.name === 'Сила');
	const filters = useSelector((state) => state.filter.list);
	const filtersProduct = filters.filter(obj => {
		return obj.category === 'Сила';
	})

	return (
		<section className='flex md:flex-row flex-col' style={{background: 'linear-gradient(180deg,#fff,#f0f1f1)'}}>
			<aside className='CatalogSidebar bg-white md:py-14 py-8 2xl:mr-12 pl-4 2xl:pl-14 xl:w-3/12 2xl:w-1/3 md:w-4/12'>
				<h2 className="CatalogSidebar-title mb-10 md:text-4xl text-2xl text-zinc-700 font-bold">Сила</h2>
				{cardioCategory?.concoles?.length > 0 &&
				<Box className="CatalogSidebarBox mb-6">
					<h4 className='text-zinc-600 font-bold text-lg leading-none mb-3'>Консоли силового оборудования</h4>
					{cardioCategory?.concoles?.map(item => (
						<label onChange={(e) => {
							let newFilter = {
								...filter,
								console: e.target.value,
							}
							setSearchParams(newFilter)
							setFilter(newFilter)
							dispatch(getFilter(newFilter))
						}} key={item.id} className={`RadioButton text-zinc-600 text-sm font-bold pl-6 flex items-center`}>
							<input value={item.id} type="radio" name='name' className={`hidden`} />
							<span className="flex items-center">{item.name}</span>
						</label>
					))}
				</Box>
				}
				{cardioCategory?.series?.length > 0 &&
				<Box className="CatalogSidebarBox mb-6">
					<h4 className='text-zinc-600 font-bold text-lg leading-none mb-3'>Серия</h4>
					{cardioCategory?.series?.map(item => (
						<label
							key={item.id}
							className = {ClassName}
							onChange={(e) => {
								window.localStorage.setItem('seria_id', item.id);
								let newFilter = {
									...filter,
									series: e.target.value
								}
								setSearchParams(newFilter)
								setFilter(newFilter)
								dispatch(getFilter(newFilter))
							}}
						>
							<input defaultChecked={Number(seria_id) === item.id} type="radio" value={item.id} name='seria' className='hidden' />
							<span className="flex items-center">{item.name}</span>
						</label>
					))}
				</Box>
				}
				{cardioCategory?.subCategories?.length > 0 &&
				<Box className="CatalogSidebarBox mb-6">
					<h4 className='text-zinc-600 font-bold text-lg leading-none mb-3'>Категории продуктов</h4>
					{cardioCategory?.subCategories?.map(item => (
						<label
							key={item.id}
							className = {ClassName}
							onChange={(e) => {
								window.localStorage.setItem('category_id', item.id)
								let newFilter = {
									...filter,
									series: window.localStorage.getItem('seria_id'),
									category: e.target.value,
								}
								setSearchParams(newFilter)
								setFilter(newFilter)
								dispatch(getFilter(newFilter))
							}}
						>
							<input defaultChecked={Number(category_id) === item.id} type="radio" value={item.id} name='category' className='hidden' />
							<span className="flex items-center">{item.name}</span>
						</label>
					))}
				</Box>
				}
			</aside>
			<div className="CatalogProducts lg:ml-4 mb-12 w-2/3 md:mt-28 grid xl:grid-cols-4 md:grid-cols-2" style={{width: '-webkit-fill-available', gridTemplateRows: 'max-content'}}>
			{filterLoading && <div className='xl:col-span-4 md:col-span-2'><Loader /></div>}
				{!filterLoading &&
				filtersProduct?.map(item => (
					<ProductItem
						title={item?.name}
						img={item?.image}
						link={`/strength/${item?.id}`}
						// link={`${item?.category === 'Кардиооборудование' ? `/cardio/${item?.name}` : `/strength/${item?.name}`}`}
						bgColor='#fff'
						item={item.related_consoles}
					/>
				))}
			</div>
			{/* // <div className="CatalogProducts lg:ml-4 mb-12 w-2/3 md:mt-28 grid xl:grid-cols-4 md:grid-cols-2" style={{width: '-webkit-fill-available'}}>
			// 	<div className='md:text-left text-center md:mt-0 mt-6'>Нет результатов</div>
			// </div> */}
		</section>
	)
}

export default StrengthCatalog