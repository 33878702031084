import React from "react";
import { Container } from "@mui/system";
import { Button } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $11 from "../assets/videos/1-11.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const EducationMx4 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <section className="educationMx4-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$11}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 relative flex flex-col justify-end !bg-cover !bg-center"
        style={{
          height: "80vh",
          background:
            "url(https://images.jhtassets.com/f6bf6fbf8b926b27e8081e055134b6efb0de769a/) no-repeat",
        }}
      >
        <Container>
          <div className="text-2xl lg:text-6xl text-center text-white pb-16">
            Система тренинга MX4 Active
          </div>
        </Container>
        <Button
          onClick={handleOpen}
          className="group !text-base !font-black !absolute !bottom-0 w-max !mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
          style={{
            color: "#e1261c",
            fontWeight: "bold",
            fontSize: "20px",
            transform: "translate(-50%,50%)",
            left: "50%",
            right: "50%",
          }}
        >
          <BsPlayCircle
            size={32}
            fill="#e1261c"
            className="group-hover:!fill-white duration-200 mr-2"
          />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </section>
      <Container className="py-16">
        <div
          className="text-center text-3xl font-black my-6 leading-none"
          style={{ color: "#4c4c4c" }}
        >
          Мотивирующий тренировочный процесс для людей любого возраста и уровня
          подготовки
        </div>
        <div className="leading-6 text-center" style={{ color: "#191919" }}>
          Только система MX4 Active сочетает в себе эксклюзивное оборудование
          Matrix с оригинальными программными решениями, которые позволяют
          пожилым и малоподвижным взрослым людям выполнять движения в
          соответствии с их возможностями. Каждый участник тренировки откроет
          для себя вдохновляющие, дающие почувствовать уверенность физические
          упражнения, которые приносят ощутимый результат и возбуждают желание
          продолжать работать над собой.
        </div>
      </Container>
      <Container maxWidth="xl" className="mb-5 lg:mb-16">
        <div className="relative mb-16">
          <img
            src="https://images.jhtassets.com/e9508b11bcf3189a4ecd0c14a78948fde46eb81c/"
            className="object-cover"
            alt=""
          />
          <div className="p-4 lg:p-16 bg-zinc-600 lg:absolute top-0 left-0 w-full lg:w-1/2">
            <div className="text-xl leading-none text-white font-black mb-6">
              Программирование для всех. И для каждого.
            </div>
            <div className="text-white leading-6">
              26 комплексных тренировочных курсов, разработанных на основе
              результатов научных исследований, включают в себя ряд щадящих
              упражнений, не оказывающих значительных воздействий на поясничную
              область и способствующих естественному прогрессу участника
              благодаря фокусированию на хорошо знакомых движениях и
              повторениях. Участникам курса не придется становиться на колени
              или спускаться на пол. Во многих упражнениях для обеспечения
              равновесия участника в качестве опоры используются три точки.
              Такие упражнения более безопасны для здоровья. Наши программные
              решения позволяют выполнять оценку готовности участников курса к
              выполнению новых заданий и обладают функциями хореографической
              разминки и восстановления.
            </div>
          </div>
          <div
            className="w-full lg:w-1/3 lg:mt-16 mr-16 lg:absolute right-0 top-0 p-8"
            style={{ background: "#c2c6c9" }}
          >
            <div
              className="text-2xl font-bold mb-4"
              style={{ color: "#4c4c4c" }}
            >
              Курс функционального тренинга (Functional Training) для малых
              групп
            </div>
            <p style={{ color: "#333436" }}>
              В рамках аккредитованного тренировочного курса для небольшой
              группы наши{" "}
              <Link
                to=""
                className="text-red-600 font-bold hover:text-red-700 hover:underline"
              >
                Certified Master Trainers
              </Link>{" "}
              готовы выехать к вам. Наши специалисты проведут обучение ваших
              тренеров согласно комплексной программной методике, а также
              расскажут о том, как мотивировать людей старшего возраста и
              малоподвижных взрослых участников на создание позитивной атмосферы
              в группе, способствующей повышению ответственности и
              инициативности в достижении поставленных целей.
            </p>
            <img
              className="mt-12 w-full"
              src="https://images.jhtassets.com/deacddb1e5e32e1050419f9b5c7179308c2366b7/"
              alt=""
            />
          </div>
        </div>
      </Container>
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <br className="hidden lg:block" />
      <Container className="py-16">
        <div
          className="text-center text-3xl font-black mt-5 lg:mt-16 lg:pt-16 mb-6 leading-none"
          style={{ color: "#4c4c4c" }}
        >
          Разработано для людей старшей возрастной категории и малоподвижных
          взрослых людей
        </div>
        <div className="leading-6 text-center" style={{ color: "#191919" }}>
          В системе MX4 Active используется эксклюзивное оборудование Matrix,
          такое как Connexus Functional Training System, S-Drive Performance
          Trainer, Rower (гребец) и Krankcycle. Эти универсальные щадящие
          тренажеры могут использовать люди с любым уровнем подготовки, и любой
          участник тренировки, закончив упражнения, обязательно почувствует
          прогресс. Более того, вы непременно захотите вернуться снова и снова.
        </div>
      </Container>
      <Container
        maxWidth="xl"
        className="md:!px-12 !py-16 !bg-cover !bg-center"
        style={{
          background:
            "url(https://images.jhtassets.com/c5db4e9f80140fcdf5b83247cbd83448258c6b32/) no-repeat",
        }}
      >
        <div className="lg:flex justify-between">
          <div className="lg:w-2/6">
            <img
              src="https://images.jhtassets.com/ffa48dc18f8b5828e49574c2b303bc5c7527bfed/"
              alt=""
            />
          </div>
          <div className="lg:w-7/12">
            <div className="text-xl font-bold text-white leading-none mb-6">
              Планируйте расписание с максимальной эффективностью
            </div>
            <p className="leading-8 text-white">
              С помощью системы MX4 Active вы откроете для себя новые
              эффективные способы организации расписания тренировок, что
              позволит увеличить рентабельность бизнеса. И это не будет зависеть
              от вашей целевой аудитории: пожилые люди, занимающиеся по
              программе MX4 Active Aging, малоподвижные взрослые (программа MX4
              Active Living) или смешанные группы. Занятия по программе MX4
              Active Aging можно проводить в утренние часы, когда люди пожилого
              возраста не заняты делами. Вечер идеален для программы MX4 Active
              Living. В любом случае вы сможете использовать одно и то же
              оборудование, программы и помещение для различных групп
              тренирующихся.
            </p>
          </div>
        </div>
      </Container>
      <Container className="py-16">
        <div
          className="text-center text-3xl font-black mb-6 leading-none"
          style={{ color: "#4c4c4c" }}
        >
          На базе эксклюзивного оборудования Matrix
        </div>
        <div className="leading-6 text-center" style={{ color: "#191919" }}>
          Оригинальные комплексные программные решения, реализованные в наших
          продуктах, обеспечивают стабильность, функциональную надежность,
          универсальность и позволяют поддерживать сердечно-сосудистую систему в
          здоровом состоянии. С помощью этих решений пожилые и малоподвижные
          взрослые люди могут легко выполнять любимые упражнения. Можно изменять
          программу в зависимости от физических кондиций и количества участников
          и легко масштабировать систему с ростом ее популярности.
        </div>
      </Container>
      <section
        className="section-2 py-16 !bg-cover !bg-center"
        style={{
          background:
            "url(https://images.jhtassets.com/4485bc2b7ac7c6c2fc097e904f8f23954d158ad0/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-16 !py-12">
          <div className="grid lg:grid-cols-2 gap-4">
            <div>
              <div className="text-xl font-bold text-white mb-6">
                CONNEXUS: ядро системы MX4 Active
              </div>
              <div className="text-white leading-6">
                Универсальная масштабируемая система Connexus позволяет
                пользователям выполнять упражнения с весовой нагрузкой, которые
                отражают повседневные действия человека. Во время тренировки
                инструктор использует текущие показатели обратной связи с целью
                немедленной коррекции формы. Благодаря широкому разнообразию
                мест крепления можно использовать множество дополнительных
                принадлежностей и тренировочных модулей, позволяющих добиваться
                прогресса с учетом потребностей и возможностей людей пожилого
                возраста или малоподвижных взрослых людей.
              </div>
            </div>
            <div className="grid lg:grid-cols-2 gap-6">
              <div
                className="flex flex-col items-center p-6"
                style={{ background: "#ffffffe6" }}
              >
                <img
                  src="https://images.jhtassets.com/41981050c2d6b86925629cb21e6a2087bbe912eb/transformed/w_200"
                  alt=""
                />
                <Link
                  to="/group-training/strength-flexibility/connexus-compact"
                  className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
                >
                  Connexus Compact /
                </Link>
              </div>
              <div
                className="flex flex-col items-center p-6"
                style={{ background: "#ffffffe6" }}
              >
                <img
                  src="https://images.jhtassets.com/df1f51758efc8eced9c1052615239bdfe58102b2/transformed/w_200"
                  alt=""
                />
                <Link
                  to="/group-training/strength-flexibility/connexus-perimeter"
                  className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
                >
                  Connexus Perimeter /
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-3 py-16">
        <Container>
          <div
            className="font-bold text-2xl text-center"
            style={{ color: "#4c4c4c" }}
          >
            Используйте эти тренажеры, чтобы получить максимально возможный
            эффект от использования комплекса MX4 Active и повысить результаты
            пользователей.###
          </div>
          <div className="grid md:grid-cols-4 gap-4 py-12">
            <div className="flex flex-col items-center p-2">
              <img
                style={{ height: "200px" }}
                src="https://images.jhtassets.com/63d26d06cc240f1be8a81827c783a157cb33ee04/transformed/w_300"
                alt=""
              />
              <Link
                to="/group-training/cardio/s-drive-power"
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                S-Drive /
              </Link>
            </div>
            <div className="flex flex-col items-center p-2">
              <img
                style={{ height: "200px" }}
                src="https://images.jhtassets.com/0315206538a339dca08040a938359bfce1bc4613/transformed/w_300"
                alt=""
              />
              <Link
                to="/group-training/cardio/rower"
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                Rower /
              </Link>
            </div>
            <div className="flex flex-col items-center p-2">
              <img
                style={{ height: "200px" }}
                src="https://images.jhtassets.com/c4240e2d400bc86fbf16c33605aaa88517b1e57f/transformed/w_200"
                alt=""
              />
              <Link
                to="/group-training/strength-flexibility/connexus-step-plus"
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                Krankcycle /
              </Link>
            </div>
            <div className="flex flex-col items-center p-2">
              <img
                style={{ height: "200px" }}
                src="https://images.jhtassets.com/df481c1b72b5222a396d381a41003c76aa2ba6c0/transformed/w_300"
                alt=""
              />
              <Link
                to=""
                className="inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-2"
              >
                Step+ /
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-4 py-5 lg:py-16 !bg-cover !bg-bottom lg:h-screen relative">
        <img
          src="https://images.jhtassets.com/2c639fa6731318f55daa8d7c9de921e38a7dd874/"
          className="lg:absolute top-0 left-0 w-full h-full object-cover"
          alt=""
        />
        <Container
          maxWidth="xl"
          className="md:!px-12 relative lg:bg-transparent bg-neutral-700"
        >
          <div className="w-full py-5 lg:w-5/12">
            <div className="font-black text-3xl mb-6 text-white lg:text-blue-700">
              ОБУЧЕНИЕ У ТРЕНЕРОВ CERTIFIED MASTER TRAINERS
            </div>
            <p className="text-white leading-7">
              Сертифицированные инструкторы нашей компании (Certified Master
              Trainers) готовы отправиться в ваш зал и обучить вас и ваших
              тренеров методам, которые позволят мотивировать пожилых участников
              и малоподвижных взрослых людей начать тренировки с использованием
              системы MX4 Active.
            </p>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default EducationMx4;
