import React from 'react'
import { Container } from '@mui/material'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'


const ConnectedSolutionsPartnershipsIfit = () => {
	return (
		<>
			<section className="flex items-end justify-center relative" style={{height: '70vh'}}>
				<img
					src="https://images.jhtassets.com/5db24e6cabc5ecbaeb2d7af51ef6f14f52f0d0a3/"
					alt=""
					className='absolute top-0 left-0 w-full h-full object-cover'
				/>
            <Container className='flex flex-col gap-5 items-center justify-center text-center relative z-10' style={{display: 'flex'}}>
					<img
						src="https://images.jhtassets.com/c36dbddd14e6831ac084c5d2b3abfed157f53b9b/"
						alt=""
						style={{
							maxWidth: '300px'
						}}
					/>
					<h5 className='text-white uppercase font-bold py-5'>Для создания вдохновляющих тренировок с эффектом полного погружения ничто не подойдет лучше, чем кардиооборудование с сервисом iFIT®, являющимся мировым лидером в сфере интерактивных онлайн технологий для тренировок и создания контента.</h5>
            </Container>
        	</section>
			<BgImageSection
				sectionHeight="60vh"
				img="https://images.jhtassets.com/2557b767211c37f42c57a7f65f35e6bbc5af3f24/"
				title="15 ИНТЕРАКТИВНЫХ ТРЕНИРОВОК"
				text="С помощью iFIT любители спорта смогут получить доступ к 15 занятиям в студии и тренировкам со всего мира на самом различном кардиооборудовании."
				contentWidth="33%"
				titleColor="#4c4c4c"
				contentRight={true}
				contentBg="#fff"
				textColor="#666"
				linkColor="#e1261c"
			/>
			<section className="lg:p-10" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container maxWidth="xl" className='!grid lg:grid-cols-2 gap-3'>
					<CardBox
						boxHeight="650px"
						title="Синхронные и увлекательные"
						text="Тренировки позволяют автоматически адаптировать скорость, нагрузку и наклон, чтобы пользователи могли синхронно выполнять указания инструктора. Теперь каждый пользователь сможет легко выполнять упражнения под руководством тренера."
						img="https://images.jhtassets.com/03e5e50244becf327e2c7be73118f31ab084a7ef/"
						color="#f5f5f5"
						textColor="#f9d2d0"
						contentBgColor="#e1261c"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Новые и вдохновляющие"
						text="Благодаря регулярным обновлениям ваши клиенты всегда найдут для себя что-то новое и привлекательное, чтобы каждый раз сохранять вдохновение для следующей кардиотренировки."
						img="https://images.jhtassets.com/da1060a78bd75a4730279c27c60d2d3e8b43e25b/"
						color="#f5f5f5"
						textColor="#f9d2d0"
						contentBgColor="#e1261c"
						contentPt="50px"
					/>
				</Container>
				<Container maxWidth="xl" className="mt-8 mb-8">
					<CardBoxMoreImage
						title="Реальные ощущения занятий в студии"
						subtitle="Увлекательные программы iFIT на лучших в отрасли экранах высокого разрешения создают эффект присутствия и погружают пользователя в происходящее, создавая полное ощущение участия в студийных тренировках по своему собственному расписанию."
						image="https://images.jhtassets.com/1fae8de89aed0de41cda9ca708ff5690d61d5c9a/"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
					<CardBoxMoreImage
						title="Тренировки без границ"
						subtitle="Съемка тренировок проводилась более чем в 50 странах, на всех семи континентах, благодаря чему любители спорта смогут исследовать мир, не покидая ваш тренажерный зал."
						image="https://images.jhtassets.com/8507c5f386ac42cdcc4d3a2fcabb9f273fe8c565/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
					<CardBoxMoreImage
						title="Вперед по улице"
						subtitle="Используя карты Google Maps™ и службу просмотра улиц Street View, пользователи могут создавать свои собственные маршруты. Они смогут исследовать знаменитые города, побывать на великолепных побережьях или посетить свой родной город, не покидая ваш тренажерный зал. Нагрузка и наклон будут автоматически адаптироваться в соответствии с рельефом местности."
						image="https://images.jhtassets.com/9ea570383b03ae8bc6788a8969a2893ee99da2e2/"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="70vh"
				img="https://images.jhtassets.com/971059a930d18cfda97ebd3118c35f72eee89831/"
				title="Только лучшие тренеры"
				text="Руководили тренировками более 180 самых вдохновляющих инструкторов со всего мира, включая обладателей золотых олимпийских медалей, участников соревнований по сверхмарафону, триатлону, профессиональных маунтинбайкеров и многих других."
				contentWidth="33%"
				titleColor="#4c4c4c"
				contentRight={true}
				contentBg="#fff"
				textColor="#666"
				linkColor="#e1261c"
			/>
			<section className='p-5 lg:p-14'>
				<article className='lg:flex pb-14 gap-6'>
					<img
						className='lg:w-3/5'
						src="https://images.jhtassets.com/923b8df02ca5effa5239bd710d0eee97d410f30c/"
						alt=""
					/>
					<div className='pt-10'>
						<h3 className='mb-6 text-zinc-600 text-2xl font-bold'>Безграничный доступ</h3>
						<p className='text-zinc-700'>Пользователи, которые хотят получить больше от использования сервиса iFIT, смогут подключить свою личную учетную запись прямо в вашем тренажерном зале. Это откроет им доступ к еще большему количеству захватывающих интерактивных тренировок, а также к самым энергичным занятиям вживую с Live Workouts, которые можно выполнять каждый день.</p>
					</div>
				</article>
			</section>
			<section>
				<CenterContentBox
					title="ВСЕ ВИДЫ КАРДИО"
					link="Все оборудование, совместимое с iFit"
					subtitle="Какой бы способ ни выбрали ваши клиенты, чтобы заставить свое сердце биться сильнее, они всегда найдут подходящую им тренировку iFIT. Сервис iFIT доступен для беговых дорожек, велотренажеров с горизонтальной и вертикальной посадкой, а также с гибридной конструкцией, эллиптических тренажеров Suspension Elliptical, эллипсов с изменяемой длиной шага Ascent Trainer и лестниц-эскалаторов от Matrix с консолями Touch и Touch XL. iFIT входит в стандартную комплектацию наших велотренажеров с виртуальной средой тренировок Virtual Training Cycle."
					bgImage="https://images.jhtassets.com/61ac5dcd5e224ee509e61dcdce02364048affd56/"
					titleColor="#e6e5e5"
					subtitleColor="#c2c6c9"
					linkTo="/cardio/catalog?consoles=touch"
				/>
			</section>
			<section className='p-5 lg:p-14'>
				<article className='lg:flex pb-14 gap-6'>
					<img
						className='lg:w-3/5'
						src="https://images.jhtassets.com/d72b40d20cda3ed341c268fc2ecd832a9c857d8b/"
						alt=""
					/>
					<div className='pt-10'>
						<h3 className='mb-6 text-zinc-600 text-2xl font-bold'>УЗНАТЬ ВСЕ ОБ iFIT</h3>
						<p className='text-zinc-700'>Сделайте занятия еще более увлекательными и интерактивными, чем когда-либо, предложив доступ к iFIT в вашей кардиозоне.</p>
					</div>
				</article>
			</section>
		</>
	)
}

export default ConnectedSolutionsPartnershipsIfit