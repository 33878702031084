import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
  return (
    <section className='support-page'>
        <section className='section-1 flex md:flex-col justify-end !bg-cover !bg-center main-media-height' style={{background: 'url(https://images.jhtassets.com/7e20bf7be890bd1b3d907ec4289687c5794c9706/) no-repeat', height: '70vh'}}>
            <Container className="md:!pt-0 pt-16">
                <div className='text-white text-center font-bold text-lg'>Лучшая в отрасли поддержка клиентов</div>
                <div className='text-white text-center leading-none text-3xl md:text-6xl pb-16 pt-4'>Поддержка</div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-12 !py-16">
            <div className='leading-6 mb-12 md:w-8/12' style={{color: '#333'}}>Получите необходимую помощь разнообразных ресурсов поддержки клиентов. Вы можете получить необходимую помощь в любое время, чтобы вернуться к тренировкам и достижению своих целей.</div>
            <div className='grid md:grid-cols-2 gap-6'>
                <div>
                    <div className='relative'>
                        <img src='https://images.jhtassets.com/e9449181237baf36f6fe351b5513060255cb40d4/' alt='' />
                        <Link to='/support/faq' className='absolute bottom-0 w-full p-2 font-bold text-xl text-white cursor-pointer hover:underline hover:decoration-red-700' style={{background: '#a5acb2'}}>Часто задаваемые вопросы</Link>
                    </div>
                    <div className='px-6 pt-10 pb-14'>
                        <div className='leading-7 mb-4' style={{color: '#191919'}}>Возникли вопросы? Ответ уже может быть в разделе часто задаваемых вопросов.</div>
                        <Link to='/support/faq' className='font-bold hover:underline hover:!text-red-700' style={{color: '#a5acb2'}}>Подробнее /</Link>
                    </div>
                </div>
                <div>
                    <div className='relative'>
                        <img src='https://images.jhtassets.com/c347b1419871a87903df3958de3e1a14d5053685/' alt='' />
                        <Link to='/support/preventive-maintenance' className='absolute bottom-0 w-full p-2 font-bold text-xl text-white cursor-pointer hover:underline hover:decoration-red-700' style={{background: '#a5acb2'}}>Профилактическое техобслуживание</Link>
                    </div>
                    <div className='px-6 pt-10 pb-14'>
                        <div className='leading-7 mb-4' style={{color: '#191919'}}>Увеличьте срок службы вашего оборудования Matrix благодаря надлежащему обслуживанию. В наших руководствах по профилактическому обслуживанию содержатся все необходимые инструкции.</div>
                        <Link to='/support/preventive-maintenance' className='font-bold hover:underline hover:!text-red-700' style={{color: '#a5acb2'}}>Подробнее /</Link>
                    </div>
                </div>
                <div>
                    <div className='relative'>
                        <img src='https://images.jhtassets.com/1eadee5ca41802ba1aaf7001a109b3038eafd972/' alt='' />
                        <Link to='https://jhtmanuals.com/ru/rus/matrix' className='absolute bottom-0 w-full p-2 font-bold text-xl text-white cursor-pointer hover:underline hover:decoration-red-700' style={{background: '#a5acb2'}}>Руководства пользователя</Link>
                    </div>
                    <div className='px-6 pt-10 pb-14'>
                        <div className='leading-7 mb-4' style={{color: '#191919'}}>Не беспокойтесь о том, что руководств можно потерять. Для вашего удобства руководства Matrix всегда доступны онлайн.</div>
                        <Link to='https://jhtmanuals.com/ru/rus/matrix' className='font-bold hover:underline hover:!text-red-700' style={{color: '#a5acb2'}}>Подробнее /</Link>
                    </div>
                </div>
                <div>
                    <div className='relative'>
                        <img src='https://images.jhtassets.com/b78bcdab8835687dbab8deb45f667bcd423a118f/' alt='' />
                        <div className='absolute bottom-0 w-full p-2 font-bold text-xl text-white cursor-pointer' style={{background: '#a5acb2'}}>Гарантии</div>
                    </div>
                    <div className='px-6 pt-10 pb-14'>
                        <div className='leading-7 mb-4' style={{color: '#191919'}}>Компания Matrix Fitness предоставляет гарантии на всю продукцию, в том числе кардиооборудование и силовые тренажеры. Гарантии сроком на два года распространяются на операции по замене деталей и работы по сервисному обслуживанию. Возможность продления гарантии доступна квалифицированным клиентам. Свяжитесь с местным авторизованным дистрибьютором компании Matrix для получения подробных сведений.</div>
                    </div>
                </div>
            </div>
        </Container>
    </section>
  )
}

export default Support;