import React from "react";
import { Link } from 'react-router-dom';
import "../assets/scss/_CardBoxMoreImage.scss"

const CardBoxMoreImage = (props) => {
   return (
		<div className="CardBoxMoreImage relative lg:flex">
			<img
				src={props.image} 
				className="CardBoxMoreImage-image lg:absolute top-0 left-0 w-full lg:h-full object-cover"
				alt=""
			/>
			<div
				className='CardBoxMoreImage-content relative z-10 py-10 lg:py-20 px-6'
				style={{
					background: props.contentBgColor,
					marginLeft: props.contentRight === true ? 'auto' : '',
					width: props.contentWidth
				}}
			>
				<div className="CardBoxMoreImage-title text-2xl md:text-3xl font-extrabold mb-2"
					style={{
						color: props.contentTitleColor
					}}
				>
					{props.title}
				</div>
				<div className="CardBoxMoreImage-subtitle text-sm py-5 leading-6"
					style={{
						color: props.contentSubtitleColor
					}}
				>
					{props.subtitle}
				</div>
				{
					props.link ?
						<Link to={props.linkTo ? props.linkTo : ''} className="CardBoxMoreImage-link font-semibold block hover:underline"
							style={{
								color: props.contentLinkColor
							}}
						>{props.link}</Link>
						: ''
				}

			</div>
		</div>
	);
};

export default CardBoxMoreImage;
