import { Container } from '@mui/system';
import React from 'react';
import HoverBoxAnimation from '../components/HoverBoxAnimation';

const Showcases = () => {
  return (
    <section className='showcases-page'>
        <section
            className='section-1 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height md:py-0 py-32'
            style={{
                height: '80vh',
                clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',
                background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'
            }}
        >
        </section>
        <section className='section-2'>
            <Container>
                <div className='text-center mt-16 font-bold mb-4 lg:px-16 text-3xl lg:text-5xl leading-none' style={{color: '#4c4c4c'}}>ВЕРЬТЕ В ЛУЧШИЕ ВАРИАНТЫ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-2 gap-6 pb-5 md:pb-16 lg:px-16'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/047c50f59ab90b76a16586e37554e323a66f72f4/'
                        title='ФИТНЕС-ЦЕНТРЫ'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/health-clubs"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/7450712cc133ed486558920f4d5ff19fda315a05/'
                        title='Гостиницы'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/hospitality"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/8a6a8bb78cfd8dcb990d89c46505bc71fe689bff/'
                        title='Спорт и образование'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/athletic-performance"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/4625ab83400a2fad73be031546fa4dad592b3774/'
                        title='YMCA/JCC'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/ymca-jcc"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/ca8657812f2373b708ee7222c87d500fd1da105b/'
                        title='Активный образ жизни пожилых людей и реабилитация'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/active-aging"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/0cef8da3d05fefb90fd640e5285fee359165b5ef/'
                        title='Коммерческие объекты'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/corporate"
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/e03b03a7787051a6f688042c6fe4165d1e5d3847/'
                        title='Multi-housing'
                        link='Learn more /'
                        linkTo="/ru/rus/showcases/multi-housing"
                    />
                </div>
            </Container>
        </section>
    </section>
  )
}

export default Showcases;