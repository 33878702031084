import React from 'react'
import logo from "../../assets/images/logo.png"
import { Link } from 'react-router-dom';
import { BsTelegram } from 'react-icons/bs';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div
          className="footer-main grid px-6 py-4 gap-5 md:grid-cols-2 md:px-14"
          style={{background: 'linear-gradient(180deg,#24292c,#000)'}}
        >
          <div className="footer-main-menu footer-menu grid content-center">
            <div className="footer-social pb-5 flex gap-5">
              <a href="https://youtube.com/user/MatrixFitnessUS" target="_blank"><img src="https://images.jhtassets.com/bf9e63eb3ed7eb46562d8a131be9d9433ea48f47/" alt="" /></a>
              <a href="https://www.facebook.com/matrixfitnessuzb" target="_blank"><img src="https://images.jhtassets.com/e20afbfcd703a0cb20021823e861432b928dcfdb/" alt="" /></a>
              <a href="https://instagram.com/matrixfitnessuzb?igshid=YmMyMTA2M2Y=" target="_blank"><img src="https://images.jhtassets.com/13c5a953aa4e9c989f0aca4f1f507e23c40dd01c/" alt="" /></a>
              <a href="https://t.me/MATRIXFITNESSUZB" target="_blank">
                <BsTelegram color='#fff' size={40} style={{width: '40px', height: '40px'}} />
              </a>
            </div>
            <Link to="/total-solutions-partners/facility-planning" className="footer-menu-link uppercase text-white hover:text-red-600 hover:underline">ПЛАНИРОВАНИЕ ПОМЕЩЕНИЙ</Link>
          </div>
          <div className="footer-main-right grid">
            <Link to="/" className='inline-block ml-auto'>
              <img src={logo} alt="" className='footer-logo py-10' />
            </Link>
            <div className="footer-info text-right text-gray-400">
              <p>Г. Ташкент</p>
              <p>Шайхантахурский район</p>
              <p>улица Лабзак 3а</p>
            </div>
          </div>
        </div>
        <div
          className="footer-bottom flex items-center py-5 gap-5 flex-wrap px-6 lg:px-14"
          style={{background: '#191919'}}
        >
          <div className="footer-bottom-phone w-64">
            <a href="tel:+998951966663" className='text-white font-bold'>+998 95 196 66 63</a>
          </div>
          <div className="footer-bottom-menu flex-auto flex flex-wrap gap-5 lg:justify-center">
            <Link to="/privacy-policy" className='text-gray-400 hover:text-red-700 hover:underline font-bold md:px-4 border-r-black'>Политика конфиденциальности</Link>
            <Link to="/contact-us" className='text-gray-400 hover:text-red-700 hover:underline font-bold md:px-4'>Обратная связь</Link>
          </div>
          <p className='text-gray-500 font-bold'>© 2022 Matrix Fitness</p>
        </div>
      </footer>
    </>
  )
}

export default Footer;