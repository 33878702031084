import { Container } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import { getFilter } from "../redux/actions/filterActions";


const StrengthMagnum = () => {
	const dispatch = useDispatch();
   return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/95b5f0d6b08570a4233054cb5478fdc080df5ecb/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-5xl relative z-10 px-5 md:text-white">Серия Magnum</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Для профессиональных спортсменов нет выбора лучше, чем серия Magnum сочетающая в себе непревзойденную эффективность и выдающуюся долговечность. Дополнительные варианты оборудования, такие как скамьи Breaker Bench и стойки MEGA, помогают спортсменам раскрывать свой потенциал новыми эффективными способами, а благодаря безупречной конструкции это оборудование выдерживает самые жесткие условия эксплуатации.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 7)
                    dispatch(getFilter({
                        series: 7,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии Magnum"
						subtitle="Серия Magnum — это линейка надежных тренажеров с дисковым нагружением, свободными весами, стеллажами и платформами, а также множеством дополнительных компонентов."
						image="https://images.jhtassets.com/f5a53154ba87797f102081b391cd8676d9d886c7/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Силовые скамьи Breaker Bench"
						text="Простые, оригинальные и абсолютно революционные, силовые скамьи Breaker Bench позволяют поднимать штангу без помощи страхующего. Кроме того, при использовании этих скамей снижается нагрузка на суставы в момент начала подъема штанги."
						img="https://images.jhtassets.com/e6006e8afc0d690d56c85177951a02219180f58f/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Нагружаемые дисками тренажеры"
						text="Различные надежные нагружаемые дисками тренажеры очень важны для того, чтобы профессиональные спортсмены могли работать над основными группами мышц и полностью раскрыть свой потенциал. Спортсмены могут легко работать с нашими тренажерами, навешивать на них грузы и снимать их. В результате они могут достигать поставленных перед собой конкретных целей и не останавливаться на достигнутом."
						img="https://images.jhtassets.com/3fdcf1c4bb7ad4fe7d476c2fc8ac7070ae1ff18d/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
				<Container className="mt-8 mb-8" maxWidth="xl">
					<CardBoxMoreImage
						title="Силовая рама Double Mega Rack"
						subtitle="Благодаря широчайшему набору функций эта силовая конструкция, состоящая из двух силовых рам, позволяет тренироваться на одной площади большему количеству человек. Кроме того, благодаря неограниченным вариантам настройки в соответствии с конкретными требованиями пользователей она является отличным выбором для групповых функциональных и силовых тренировок."
						image="https://images.jhtassets.com/4ff9c8aa85b1e3c76a77a1436eafb0ed8c253592/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="30%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						className="px-5"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	);
};

export default StrengthMagnum;
