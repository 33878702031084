import React from 'react'
import { Container } from '@mui/system';
import {Link } from 'react-router-dom'
import CardBoxMoreImage from '../components/CardBoxMoreImage';

const InnovationsPremiumLedConsole = () => {
  return (
    <section className='innovationsPremiumLedConsole-page'>
        <section className='section-1 !bg-cover !bg-center flex items-end' style={{background: 'url(https://images.jhtassets.com/46ab7ba487add8d3bf2bbf3cdfd6b5d85afba446/) no-repeat', height: '70vh'}}>
            <Container maxWidth="xl" className="md:!px-16">
                <div className='text-5xl font-bold text-white leading-none mb-16'>СВЕТОДИОДНАЯ КОНСОЛЬ PREMIUM</div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='w-2/3 mt-16'>
                <div className='uppercase text-3xl font-black mb-4 leading-none' style={{color: '#4c4c4c'}}>СВЕТ НОВЫХ ВОЗМОЖНОСТЕЙ</div>
                <p className='font-bold' style={{color: '#333'}}>Иногда идеи для инноваций приходят оттуда, откуда никто не ожидал. Вот как это произошло при разработке нашей светодиодной консоли Premium. Когда в процессе усовершенствования нашей линейки кардиотренажеров мы получили возможность проанализировать, какими могут и должны быть все светодиодные консоли, мы сразу же ухватились за эту возможность.</p>
            </div>
        </Container>
        <Container>
            <div className='text-center italic text-red-700 font-bold text-2xl leading-tight my-16 py-16'>Как нам удалось создать более привлекательный дисплей с использованием всего лишь нескольких ярких точек и кнопок?</div>
            <div className='text-5xl font-black leading-none text-center' style={{color: '#4c4c4c'}}>ПАРАДОКС СВЕТОДИОДНЫХ ТЕХНОЛОГИЙ</div>
            <p className='text-center mt-6 mb-12' style={{color: '#191919'}}>Мы быстро поняли, что светодиодные технологии в фитнес-индустрии практически не развивались уже более 10 лет. Примерно в то же время мы начали пользоваться интерфейсами с сенсорным экраном в смартфонах и планшетах, и вскоре консоли с сенсорными экранами появились и в сфере фитнеса. Тем не менее более 50 % консолей, приобретенных фитнес-клубами, все еще оставались светодиодными из-за их доступности, простоты в использовании и очень наглядного отображения информации. Одним словом, они просто нормально работали. Однако мы решили, что они могли бы работать еще лучше.</p>
        </Container>
        <section className='section-2 !bg-cover !bg-center md:pb-16' style={{ background: 'url(https://images.jhtassets.com/4dac3bc59b6235e711912dc11d47bdad24e343e3/) no-repeat' }}>
            <Container maxWidth="xl" className="md:!px-16 !py-16">
                <div className='w-1/3 bg-white px-10 py-16 md:mb-16'>
                    <div className='text-3xl font-black' style={{color: '#4c4c4c'}}>НАШИ ИННОВАЦИИ</div>
                    <p className='mt-4' style={{color: '#666'}}>В результате анализа отзывов наших клиентов мы поняли, что в действительности они хотели бы пользоваться более привлекательным светодиодным дисплеем с более подробной информацией, при этом таким же простым в использовании, чтобы можно было просто встать на тренажер и сразу же начать тренировку. От этого мы и отталкивались.</p>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='grid grid-cols-2 gap-6 mt-16'>
                <div>
                    <img src='https://images.jhtassets.com/83c52e6b0da56798f76e7b3687333fbd66995b1b/' alt='' />
                    <div className='px-6 py-16' style={{background: '#e1261c', minHeight: '400px'}}>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#f5f5f5'}}>Новая концепция света и цвета</div>
                        <p style={{color: '#f9d2d0'}}>Специалисты нашего отдела исследований и разработок превзошли все ожидания и представили дисплей с невероятным разрешением 8000 пикселей. Что еще более важно, они обнаружили, что за последние 10 лет многоцветные светодиоды стали значительно более совершенными, более яркими и долговечными. Благодаря этому инженеры смогли добавить в нашу консоль новые цветовые сигналы, которые отображают информацию интуитивно понятным образом и сразу же привлекают взгляд. Кроме того, более высокий уровень детализации на дисплее позволил улучшить отображение данных и удобство использования нашей эксклюзивной программы Sprint 8 HIIT.</p>
                    </div>
                </div>
                <div>
                    <img src='https://images.jhtassets.com/5d429325e5a0c2368c7732afbfb2f2f6f01156cd/' alt='' />
                    <div className='px-6 py-16' style={{background: '#e1261c', minHeight: '400px'}}>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#f5f5f5'}}>Простое управление</div>
                        <p style={{color: '#f9d2d0'}}>После получения отзывов реальных пользователей на разные версии нашего нового интерфейса мы значительно упростили его дизайн. Индикаторы с круговой шкалой, которые были опробованы ранее в фитнес-индустрии и оказались неудобными, были сразу же исключены из дизайна. Более удобной оказалась простая конфигурация кнопок, как на клавиатуре банкоматов. Этот интуитивно понятный дизайн пришелся по душе почти всем владельцам тренажерных залов и конечным пользователям, а близкое расположение кнопок напоминало уже знакомые интерфейсы сенсорных устройств.</p>
                    </div>
                </div>
            </div>
            <div className='mt-16'>
                <CardBoxMoreImage
                    title="Возможности сенсорного экрана и простота светодиодной консоли"
                    subtitle='При усовершенствовании аппаратной части в процессе разработки с учетом особенностей разных культур мы также занимались созданием пользовательского интерфейса именно для фитнес-индустрии. Основные проблемы были очевидны: как лучше всего использовать пространство на дисплее с разрешением 8000 пикселей? Какой шрифт какого размера подойдет лучше всего? Какие цвета и графическое оформление нужно использовать? Постепенно мы пришли к тому, что в нашем интерфейсе должны быть объединены удобство сенсорного экрана и простота светодиодной консоли.'
                    image="https://images.jhtassets.com/5f0375be594da6f7aaad4d5792cc08dba90e0f6a/"
                    contentBgColor="#000"
                    contentRight={true}
                    contentWidth="25%"
                    contentTitleColor="#e6e5e5"
                    contentSubtitleColor="#c2c6c9"
                />
            </div>
            <div className='mt-16'>
                <CardBoxMoreImage
                    title="Невероятные возможности"
                    subtitle="Мы точно знали, что у нас получилось что-то особенное, когда на глобальных фитнес-конференциях предоставляли первые образцы клиентам, привыкшим использовать светодиодные консоли. Они легко освоили навигацию в новом дизайне без инструкций. Им очень понравилось яркое, отчетливое и красочное отображение информации и возможность добавлять на экран собственную фирменную символику и логотипы. И они были особенно впечатлены значительно усовершенствованной технологией, которую тем не менее они могли себе позволить."
                    image="https://images.jhtassets.com/6f6c4bd515faea13acb2fdd9404d80b5c9dd7a97/"
                    contentBgColor="#000"
                    contentRight={false}
                    contentWidth="25%"
                    contentTitleColor="#e6e5e5"
                    contentSubtitleColor="#c2c6c9"
                />
            </div>
        </Container>
        <Container>
            <div className='mt-16 pt-12 font-black text-5xl leading-none text-center' style={{color: '#4c4c4c'}}>ПРИНЦИПИАЛЬНО НОВАЯ СВЕТОДИОДНАЯ КОНСОЛЬ</div>
            <p className='text-center my-6 mx-auto w-10/12'>Наша светодиодная консоль Premium предоставляет любителям фитнеса усовершенствованные возможности традиционных светодиодных панелей и разноцветное отображение информации в высоком разрешении, благодаря которому четко видно все, что касается тренировки. Консоль совместима со всеми нашими кардиотренажерами новых серий, подключается к часам Apple и беспроводным устройствам для отслеживания сердечных сокращений, а также совместима с некоторыми продуктами линейки Connected Solutions от Matrix. Таким образом, наша светодиодная консоль Premium воплотила в себе самые смелые идеи.</p>
        </Container>
        <section className='section-2 flex items-center justify-center py-16 !bg-cover !bg-bottom' style={{ background: 'url(https://images.jhtassets.com/31456797d73a12bc4b10c0fda0f961a05344a6ad/) no-repeat', minHeight: '70vh' }}>
            <img src='https://images.jhtassets.com/1274f78df299383a9c135702d61c204d9b20095d/' alt='' />
        </section>
        <section className='section-3 py-16' style={{background: '#000'}}>
            <Container>
                <div className='my-10 text-center'>
                    <div className='text-5xl leading-none mb-16 font-black' style={{color: "#e6e5e5"}}>УЗНАЙТЕ БОЛЬШЕ</div>
                    <Link to='/cardio/consoles' className='inline-block text-white w-max mx-auto bg-red-600 hover:bg-red-700 duration-200 cursor-pointer py-3 px-6 font-black'>Перейти на страницу консоли</Link>
                </div>
            </Container>
        </section>
    </section>
  )
}

export default InnovationsPremiumLedConsole;