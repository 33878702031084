import { Container } from '@mui/system'
import React from 'react'

const InnovationsTrainingCycles = () => {
  return (
    <section className='innovationsTrainingCycles-page'>
        <section className='section-1'>
            <img src='https://images.jhtassets.com/1ba2a8aaafa066025f30780eab477d9211569771/' style={{height: '70vh', width: '-webkit-fill-available', objectFit: 'cover'}} alt='' />
        </section>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='grid grid-cols-3 gap-4 my-16'>
                <div className='col-span-2'>
                    <div className='text-4xl leading-none mb-4 font-black' style={{color: '#f0b323'}}>Постоянно совершенствуемые велотренажеры</div>
                    <p className='mb-6' style={{color: '#333'}}>Для успешного бизнеса в любой отрасли верна одна непреложная истина: «Стоять на месте — это шаг назад» - эта истина верна для любого бизнеса. Именно поэтому, несмотря на очевидные успехи в разработке решений по indoor cycle в прошлом, мы приняли решение создать нечто новое. Весь 2015 год был посвящен изучению и определению потребностей самых значимых для нас людей – ваших клиентов. После длительного исследования различных форм тренировок и тестирования конструкции, мы разработали линейку велотренажеров, которые призваны расширить круг любителей indoor cycle и вдохнуть новую жизнь в велотренировки по всему миру.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Факторы мотивации пользователей</div>
                    <p className='mb-6' style={{color: '#333'}}>Самым главным при разработки новой линейки велотренажеров для нас был ответ на вопрос – что заставляет людей садиться на велотренажер. Проведя опрос среди участников, часть которых ходила на групповые тренировки, а часть нет, мы быстро обнаружили, что ключевой элемент для создания новых ощущений от тренировки — это ряд измеряемых показателей. Во время групповой тренировки одни клиенты уделяли пристальное внимание затраченной энергии в ваттах, а другие с большим интересом отслеживали частоту пульса, сожженные калории или дистанцию. Для тех, кто предпочитал тренироваться самостоятельно, важным показателем оказалась величина потраченного времени. Но самым важным для нас стало то, что люди желают видеть свой прогресс в количественных показателях. Мы поняли, что должны создать такой тренажер indoor cycle, который поможет отслеживать все перечисленные данные.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Дальнейшие исследования</div>
                    <p className='mb-6' style={{color: '#333'}}>Как только мы осознали это, перед нами встал новый вопрос – какой должна быть оптимальная конструкция тренажера? Для этого были проведены практические исследования различных велотренажёров в которых приняли участие люди различных типов телосложения и разных уровней физических возможностей.</p>
                    <p className='mb-6' style={{color: '#333'}}>Самоочевидным результатом оказалось, что конструкция тренажеров для indoor cycle нуждается в значительных усовершенствованиях. <br />
Во-первых, то, что хорошо подходило для пользователя с ростом 190 см, далеко не всегда соответствовало потребностям пользователей с ростом 160 см.</p>
                    <p className='mb-6' style={{color: '#333'}}>Во-вторых, каждый хочет, чтобы велотренажеры были прочными и устойчивыми, отсюда вывод, что необходимо искать баланс между дополнительным весом и необходимостью перемещать тренажер. В-третьих, регулировочные механизмы часто использовались интуитивно, ими было трудно управлять и они были ненадежны.</p>
                    <p className='mb-6' style={{color: '#333'}}>В-четвертых, рукоятки как правило хорошо служат только для какого-то одного положения рук при тренировке, при изменении тренировочной задачи, а следовательно, и положения рук комфорт исчезал. <br />
В-пятых, управление сопротивлением, а равно как и дополнительные опции, такие как держатели для бутылок с водой, часто были неудобно расположены.</p>
                    <p className='mb-6' style={{color: '#333'}}>И наконец, большое и мягкое сиденье часто оказывалось далеко не самым комфортным.</p>
                    <p className='mb-6' style={{color: '#333'}}>Все перечисленные данные оказались крайне важными для определения параметров оборудования, которые будут соответствовать требованиям клиента, но они никак не помогали решить те проблемы, на которые нам указывали сервисные специалисты, несущие ответственность за поддержание в рабочем состоянии велотренажеров в фитнес-центрах с большим потоком посетителей.</p>
                    <p className='mb-6' style={{color: '#333'}}>Наша команда углубилась в исследование, которое завершилось только после того, как мы удостоверились, что обнаружили и решили все недостатки.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Совершенствование каждой детали</div>
                    <p className='mb-6' style={{color: '#333'}}>Проведя исследование эргономики и изучив точки соприкосновения человека с тренажером, мы смогли разработать более усовершенствованную конструкцию.</p>
                    <p className='mb-6' style={{color: '#333'}}>Прежде всего, мы использовали узкий Q-фактор, который способствует оптимальному положению бедер и колен, а также создает ощущения реальной комфортной езды на велосипеде.</p>
                    <p className='mb-6' style={{color: '#333'}}>Регулировка тренажера позволяет создать параметры для пользователей с разным ростом - от 147 см до 203 см.</p>
                    <p className='mb-6' style={{color: '#333'}}>Мы создали эргономичный руль с возможностью различного хвата для разных стилей езды, при этом бутылка с водой всегда находится под рукой. Рама нашего тренажера сохраняет устойчивое положение независимо от интенсивности тренировки.</p>
                    <p className='mb-6' style={{color: '#333'}}>Изменение сопротивления теперь осуществляется по одному нажатию пальца на рычаг, который находится в естественном поле зрения пользователя.</p>
                    <p className='mb-6' style={{color: '#333'}}>Новая конструкция педалей усовершенствовала положение ступни. Ремешки педалей позволяют быстро зафиксировать ногу и приступить к тренировке.</p>
                    <p className='mb-6' style={{color: '#333'}}>Что касается одной из самых важных точек соприкосновения человека и велотренажера, нам удалось создать идеальную форму сидения, которое способствует значительному снижению давления. Мы были обрадованы тем фактом, что многие пользователи сами начали рассказывать о том, насколько оно комфортно.</p>
                    <p className='mb-6' style={{color: '#333'}}>Наша работа по совершенствованию конструкции была продолжена вплоть до того момента, пока мы на сто процентов не убедились, что наш велотренажер имеет непревзойденную эргономику, регулировку, комфорт и простоту в использовании.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Производство</div>
                    <p className='mb-6' style={{color: '#333'}}>Завершив научно-исследовательские разработки, мы приступили к новой задаче – запустить массовое производство. Частично на помощь нам пришел опыт посещение производственного объекта компании Toyota. Соединив труд высококвалифицированных технических специалистов с автоматической сборкой и сваркой, мы смогли достичь новых высот в производстве качественной продукции.</p>
                    <p className='mb-6' style={{color: '#333'}}>Благодаря роботизированной сварке мы обеспечиваем однородность каждого шва, что создает самую устойчивую и прочную на ощупь раму в фитнес-индустрии.</p>
                    <p className='mb-6' style={{color: '#333'}}>Нам удалось выстроить бизнес-процессы таким образом, что заводы обеспечивают непрерывное производство велотренажеров 24 часа в сутки и 7 дней в неделю.</p>
                    <p className='mb-6' style={{color: '#333'}}>Помимо этого мы внедрили новый метод нанесения краски, который обеспечивает лучшую степень защиты сварного шва от коррозии, что значительно повышает устойчивость и износостойкость рамы.</p>
                    <p className='mb-6' style={{color: '#333'}}>Объединив свои усилия с компанией Velo, признанным производителем высококачественных сидений для велотренажеров, нам удалось создать самые комфортные сиденья в отрасли.</p>
                    <p className='mb-6' style={{color: '#333'}}>Как и все, что мы производим, наши велотренажеры тестировались на соответствие самым жестким международным стандартам, их сборка производилась исключительно с использованием деталей, которые мы сами разрабатываем и оцениваем, что позволяет нам гарантировать превосходное качество.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Простота сервисного обслуживания</div>
                    <p className='mb-6' style={{color: '#333'}}>Создать велотренажер, который бы одинаково соответствовал потребностям владельцев бизнеса, тренеров, пользователей и работников сервисного обслуживания – это непростая задача, но наши устремления не оставляют нам выбора.</p>
                    <p className='mb-6' style={{color: '#333'}}>Разработав новую линейку велотренажеров, мы уделили внимание простоте сервисного обслуживания, за что отвечают наши специалисты сервисных центров по всему миру. Обсуждение различных деталей их работы и требований к оборудованию позволило нам создать тренажеры с максимальным сроком службы, даже при экстремально интенсивном использовании.</p>
                    <p className='mb-6' style={{color: '#333'}}>Маховик наших тренажеров оснащен защитным кожухом, который препятствует проникновению пота, чистящих материалов и пыли в движущиеся компоненты, что продлевает срок службы продукта и позволяет предотвратить еще не появившиеся проблемы. Быстросъемные эксплуатационные панели, расположенные по бокам тренажера, оснащены единственным фиксатором, благодаря чему технические специалисты без каких-либо затруднений получают доступ к внутренним компонентам. Более того, технические специалисты могут снять основной кожух, без отсоединения педалей.</p>
                    <p className='mb-6' style={{color: '#333'}}>Наша цельная приводная система с ремнем Flexonic, не требующим дополнительных регулировок, защищена от деформации и обеспечивает длительную надежную работу.</p>
                    <p className='mb-6' style={{color: '#333'}}>Система магнитного сопротивления почти полностью устраняет внутреннее трение, что значительно увеличивает срок службы велотренажера.</p>
                    <p className='mb-6' style={{color: '#333'}}>Мы уделяем значительное внимание сервисному обслуживанию. Благодаря простой и эффективной конструкции наши велотренажеры крайне легко обслуживать даже в фитнес-центрах с большим потоком посетителей.</p>
                    <div className='font-bold text-2xl leading-none mb-4' style={{color: '#4c4c4c'}}>Яркий стимул для велотренировок</div>
                    <p className='mb-6' style={{color: '#333'}}>Как только мы убедились, что нам удалось создать самый лучший indoor cycle тренажер в фитнес-индустрии, перед нами возникла новая задача - разработать соответствующую консоль, которая бы позволила пользователям отслеживать важные показатели, стимулирующие их продолжать заниматься.</p>
                    <p className='mb-6' style={{color: '#333'}}>Наш высококачественный дисплей с питанием от внутреннего генератора (доступен на велотренажерах CXP) обеспечивает интуитивно понятную навигацию и фиксирует все данные тренировок.</p>
                    <p className='mb-6' style={{color: '#333'}}>Дух товарищества и дух соперничества, которые присутствуют на тренировках с использованием консоли, значительно улучшает общие впечатления от занятий.</p>
                    <p className='mb-6' style={{color: '#333'}}>Благодаря встроенному тестированию FTP и определению максимального количества ударов в минуту, пользователи могут устанавливать индивидуальные цели, идеально соответствующие их уровню физических возможностей.</p>
                    <p className='mb-6' style={{color: '#333'}}>Светодиодная обводная вставка, использующая яркие цвета, сигнализирует пользователям о характере прогресса, в соответствии с выбранным типом тренировки - HIIT, ватты, калории, количество оборотов в минуту или частоту пульса.</p>
                    <p className='mb-6' style={{color: '#333'}}>Наши велотренажеры оснащены датчиками немецкого производства, которые тестировался на «Формуле-1». Велотренажеры способны измерять производство энергии в ваттах с погрешностью 2 %, благодаря чему пользователи, серьезно занимающиеся велоспортом, получают более точные показатели тренировки, а новички — более четкое отражение прогресса.</p>
                    <p className='mb-6' style={{color: '#333'}}>Используя наши авторские программы, тренеры смогут ставить разнообразные цели, что поможет поддерживать оживленность занятий и создаст такую атмосферу групповых тренировок, которая заставит посетителей приходить снова и снова.s</p>
                </div>
                <div>
                    <img className='mt-16' src='https://images.jhtassets.com/aaef13814b537842b2fe0dd18998109135987d1c/' alt='' />
                    <img className='mt-8' src='https://images.jhtassets.com/803df0751886be41cd9b74a0792002e6bb289910/' alt='' />
                    <img className='mt-8' src='https://images.jhtassets.com/12821f5edc61925b936f248e6e400fb5a9a89571/' alt='' />
                </div>
            </div>
        </Container>
    </section>
  )
}

export default InnovationsTrainingCycles