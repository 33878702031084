import { Container } from '@mui/system';
import React from 'react';
import CardBox from '../components/CardBox';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import CenterContentBox from '../components/CenterContentBox';
import BgImageSection from '../components/BgImageSection'

const Cardio = () => {
  return (
    <section className='cardio-page'>
        <section className='section-1 flex flex-col justify-end !bg-cover !bg-center' style={{height: '80vh', background: 'url(https://images.jhtassets.com/799ee7970b51e0322075f118afc5a89b968b8816/)  no-repeat'}}>
            <Container>
                <div className='text-2xl md:text-6xl text-center text-white pb-8 md:pb-16'>КАРДИООБОРУДОВАНИЕ</div>
            </Container>
        </section>
        <section className='section-2 py-16'>
            <Container maxWidth="xl" className="md:!px-8">
                <div className='lg:w-3/4 mb-16'>
                    <div className='text-2xl leading-none mb-6' style={{color: '#4c4c4c'}}>БЕСКОНЕЧНЫЕ ВОЗМОЖНОСТИ</div>
                    <p className='leading-7' style={{color: '#333'}}>От вашего решения зависит многое, поэтому мы упростили для вас выбор правильного варианта, подходящего для вашего бюджета, пространства и ваших клиентов. Тренажеры из наших новых линеек кардиооборудования могут оснащаться разными моделями модернизированных консолей, создавая вместе уникальные комбинации эффективности, развлечений и взаимодействия. Выбор в пользу Matrix очевиден, когда речь идет о достижении ваших целей. И вы знаете, что это мудрый выбор.</p>
                </div>
                <CardBoxMoreImage
                    title="Консоли Matrix Cardio"
                    subtitle="Оснащение вашего оборудования подходящей консолью может сделать его простым и приятным в использовании, либо же полностью цифровым, с богатым выбором опций и развлечений. Консоли с подключением к Wi-Fi обеспечат вам доступ к нашим самым эффективным сетевым решениям, таким как Engage 360, Community 360 и Asset Management."
                    image="https://images.jhtassets.com/42715e80bd68f7fb2045328b31593ae66db5c493/"
                    link="Узнать больше о консолях /"
                    contentBgColor="linear-gradient(180deg,#fff,#f0f1f1)"
                    contentRight={false}
                    contentWidth="25%"
                    contentTitleColor="#4c4c4c"
                    contentSubtitleColor="#666"
                    contentLinkColor="#861711"
                    linkTo="/cardio/consoles"
                />
            </Container>
        </section>
        <BgImageSection
            sectionHeight="80vh"
            img="https://images.jhtassets.com/c7018ac2d6d6b04890222e3749cea289cef53598/"
            contentWidth="33%"
            contentRight={true}
            contentBg="#e1261c"
            title="Серия Performance"
            titleColor="#f5f5f5"
            text="Уникальный ассортимент оборудования премиум-класса Matrix удовлетворит потребности самого требовательного бизнеса. Серия Performance отличается утонченной эстетикой, идеально спроектированной конструкцией, готовой к интенсивной работе, эксклюзивными функциями для более эффективных и интеллектуальных тренировок, а также долговечностью даже в самых тяжелых условиях."
            textColor="#f9d2d0"
            link="Узнать больше о серии Performance /"
            linkBgRed={true}
            linkTo="/cardio/performance"
        />
        <section className='section-4 py-16' style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
            <Container maxWidth="xl" className="md:!px-8">
                <CardBoxMoreImage
                    title="Серия Endurance"
                    subtitle="Matrix знает, как максимально использовать ресурсы вашего бизнеса с нашей серией оборудования Endurance. Серия тренажеров Endurance обладает важнейшими свойствами и функциями, необходимыми для качественных тренировок, а также надежностью при самом интенсивном использовании и опциями, позволяющими достичь нужного результата в рамках вашего бюджета."
                    image="https://images.jhtassets.com/a8d0edc6874b70094d24900f47fca6ae3e83b4c5/"
                    link="Узнать больше о серии Endurance /"
                    linkTo='/cardio/endurance'
                    contentBgColor="linear-gradient(180deg,#fff,#f0f1f1)"
                    contentRight={false}
                    contentWidth="25%"
                    contentTitleColor="#4c4c4c"
                    contentSubtitleColor="#666"
                    contentLinkColor="#861711"
                />
                <br />
                <br />
                <CardBoxMoreImage
                    title="Серия Lifestyle"
                    subtitle="Если фитнес всего лишь небольшая, но важная часть вашего бизнеса, Matrix может предложить вам кардиооборудование, подходящее для вашего пространства, бюджета и бренда. Тренажеры серии Lifestyle выбирают за их надежную конструкцию, эффективность, сочетающуюся с простотой, а также единый дизайн, привлекательный для любых посетителей."
                    image="https://images.jhtassets.com/413204f67d779ce7d03adc370b4e96d28721e956/"
                    link="Узнать больше о серии Lifestyle /"
                    linkTo='/cardio/lifestyle'
                    contentBgColor="#c2c6c9"
                    contentRight={true}
                    contentWidth="25%"
                    contentTitleColor="#4c4c4c"
                    contentSubtitleColor="#666"
                    contentLinkColor="#861711"
                />
            </Container>
            <Container>
                <CenterContentBox
                    title="Ознакомьтесь с полным ассортиментом нашего кардиооборудования"
                    subtitle="В нашем полном ассортименте высококлассных кардиотренажеров есть все, чтобы воплотить в жизнь любые ваши идеи, независимо от того, связан ли ваш бизнес с фитнесом полностью или частично."
                    link="Посмотреть все кардиооборудование"
                    linkTo='/cardio/catalog'
                    titleColor="#e6e6e5"
                    subtitleColor="#c2c6c9"
                />
            </Container>
        </section>
        <section className='section-5 py-16'>
            <Container maxWidth="xl" className="md:!px-8">
                <div className='grid gap-4 mt-6 lg:mt-16 lg:grid-cols-2'>
                    <CardBox
                        title="Достигайте своих целей"
                        text="У нас есть множество способов помочь вам усовершенствовать и переосмыслить свой бизнес. Узнайте, как использовать преимущества нашей системы Connected Solutions, чтобы повысить лояльность и вовлеченность ваших клиентов, выделиться на фоне конкурентов, максимизировать окупаемость инвестиций, повысить доходы и привлечь новых посетителей."
                        img="https://images.jhtassets.com/e6c7eb1229dfa86e2812fb0a31fcbd267fc82108/"
                        link="Достигайте своих целей /"
                        linkTo='/connected-solutions'
                        linkColor="#ffffffcc"
                        color="#e6e5e5"
                        textColor="#c2c6c9"
                        contentBgColor="#000"
                        contentPt="50px"
                    />
                    <CardBox
                        title="Connected Solutions / Цифровые Решения"
                        text="Наша мощная открытая платформа - больше чем просто сочетание оборудования, программ и средств связи. Она представляет собой полностью интегрированную цифровую среду, позволяющую вам лучше управлять оборудованием, инструктировать и мотивировать клиентов, а также продвигать свой уникальный бренд."
                        img="https://images.jhtassets.com/bb4f7ac465946047729ad0a521b901445c47c14d/"
                        link="Узнать больше /"
                        linkTo='/connected-solutions'
                        linkColor="#ffffffcc"
                        color="#e6e5e5"
                        textColor="#c2c6c9"
                        contentBgColor="#000"
                        contentPt="50px"
                    />
                </div>
            </Container>
            <Container>
                <CenterContentBox
                    title="Получите больше с Matrix"
                    subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
                    link="Свяжитесь с нами"
                    titleColor="#4c4c4c"
                    subtitleColor="#191919"
                />
            </Container>
        </section>
    </section>
  )
}

export default Cardio;