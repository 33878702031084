import { Container } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import BgImageSection from '../components/BgImageSection';

const EducationMatrixRide = () => {
  return (
    <section className='educationMatrixRide-page'>
        <section
            className='section-1 flex flex-col justify-end !bg-cover !bg-center'
            style={{
                height: '70vh',
                background: 'url(https://images.jhtassets.com/c37b92225b5ce55523abd45268ae0fbcafbf77c0/) no-repeat'
            }}
        >
            <Container>
                <div className='text-6xl text-center text-white pb-8'>Matrix Ride</div>
            </Container>
        </section>
        <Container className='py-16'>
            <div className='text-center text-xl md:text-3xl font-black my-6 leading-none' style={{color: '#4c4c4c'}}>ПРОГРАММИРОВАНИЕ ТОЧНЫХ ПОКАЗАТЕЛЕЙ MATRIX RIDE. ЗАХВАТЫВАЮЩИЕ ПОЕЗДКИ.</div>
            <div className='leading-6 text-center' style={{color: '#191919'}}>Matrix Ride дает возможность тренерам вывести групповые тренировки на новый уровень, который привлечет более широкий спектр участников. Наша эксклюзивная программа сочетает энергию классических групповых велотренировок с высокоточными силовыми тренировками, интегрируя метрики для соревнующихся классов, которые приводят к поддающимся оценке результатам.</div>
        </Container>
        <Container maxWidth="xl">
            <div className='relative mb-16'>
                <img src='https://images.jhtassets.com/519ae9c832b847157fcf3a1c15f6c0d1d43703a3/' alt='' />
                <div className='p-4 lg:p-16 bg-zinc-600 lg:bg-transparent lg:absolute top-0 left-0 w-full lg:w-1/2'>
                    <div className='text-3xl leading-none text-white font-black mb-6'>Новый виток групповых велотренировок</div>
                    <div className='text-white leading-6'>Matrix Ride — это эксклюзивная разработка для велотренажеров Matrix Training Cycles. Наши программы используют лучшие практики групповых велотренировок за 25 лет и позволяют по-новому стимулировать участников показателями, которые важны для них.</div>
                </div>
                <div className='w-full lg:w-1/3 lg:mt-16 mr-16 lg:absolute right-0 top-0 p-3 lg:p-8' style={{background: '#c2c6c9'}}>
                    <div className='text-red-600 text-2xl font-bold mb-4'>СЕРТИФИКАЦИЯ MATRIX RIDE</div>
                    <p style={{color: '#666'}}>Наш аккредитованный курс обучения групповым тренировкам включает в себя мастер-классы, проводимые тренерами Certified Master Trainers от Matrix, образовательные ресурсы, планы уроков и многое другое, давая тренерам все, что нужно для создания лучших в индустрии групповых велотренировок, которые выделят ваш клуб среди остальных.</p>
                    <img className='mt-12' src='https://images.jhtassets.com/7acfcc5f05f4e90322897e830811089ea82128a8/' alt='' />
                </div>
            </div>
        </Container>
        <section className='section-2 py-4 lg:py-16'>
            <Container maxWidth="xl" className='md:!px-12'>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div>
                        <div className='text-xl text-red-600 mb-6 font-bold'>ВЕЛОТРЕНАЖЕР CXP TARGET TRAINING CYCLE</div>
                        <p className='leading-6' style={{color: '#191919'}}>Дайте занимающимся тот опыт, ради которого они захотят вернуться, благодаря тренажеру CXP Target Training Cycle, который спроектирован так, чтобы отслеживать персональные показатели тренировки, такие как ватты, частота сердцебиения, частота вращения, расстояние и калории. Высокопроизводительная конструкция, требующая минимального технического обслуживания, включает в себя цветной светодиодный индикатор целевых показателей, интуитивно понятное консольное управление, широкий спектр программ тренировок, воспроизводимое магнитное напряжение, оптимизированную эргономику и легкую регулировку по четырем направлениям с быстрым сенсорным управлением.</p>
                        <div className='mt-12 font-bold text-xl mb-6' style={{color: '#4c4c4c'}}>МАСТЕР-КЛАСС CXP</div>
                        <p style={{color: '#666'}}>Этот восьмичасовой тренинг создан, чтобы помочь вам по максимуму использовать потенциал велотренажеров CXP Target Training Cycles. Его проводит тренер Matrix Master Trainer, который научит ваших тренеров использовать наш уникальный дисплей целевых показателей для групповых сайкл-тренировок. Они научатся выполнять FTP и тестирование околомаксимальной частоты сердцебиения, чтобы помогать участникам занятий устанавливать персональные цели, использовать цветной светодиодный индикатор целевых показателей, чтобы максимизировать усилия участников в диапазоне заметных показателей, разрабатывать спектр основанных на показателях целей, позволяющих участникам тренировки сохранять бодрость, и создавать впечатляющую атмосферу для занятий, привносящую дух товарищества и соревновательности.</p>
                        <Link to='/' className='inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-6'>Узнать больше /</Link>
                    </div>
                    <div className='flex flex-col items-center lg:p-16'>
                        <img src='https://images.jhtassets.com/bd16835a87fb5f72a4fc9869ec54fa12945f7479/' alt='' />
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 gap-4 mt-16'>
                    <div className='flex flex-col items-center lg:p-16'>
                        <img src='https://images.jhtassets.com/2f0449515cd12cccf67ccb5323655c458807c61a/' alt='' />
                    </div>
                    <div>
                        <div className='text-xl text-red-600 mb-6 font-bold'>ВЕЛОТРЕНАЖЕР CXM TRAINING CYCLE</div>
                        <p className='leading-6' style={{color: '#191919'}}>Наш велотренажер CXM Training Cycle вдыхает новую жизнь в групповые занятия на велотренажерах, привлекая участников легкими для понимания результатами измерения и премиальным дизайном. Интуитивно понятная жидкокристаллическая консоль с фоновой подсветкой позволяет отслеживать ватты, частоту вращения, сопротивление, расстояние и калории. Высококачественная конструкция, требующая минимального технического обслуживания, включает в себя воспроизводимое магнитное сопротивление, оптимизированную эргономику и регулировку в четырех направлениях с быстрым сенсорным управлением.</p>
                        <div className='mt-12 font-bold text-xl mb-6' style={{color: '#4c4c4c'}}>МАСТЕР-КЛАСС CXM</div>
                        <p style={{color: '#666'}}>Выберите наш высокоточный велотренажер CXM Training Cycle, и вы получите возможность принять участие в шестичасовом тренинге, сфокусированном на показателях, который проводится тренером Matrix Master Trainer на вашей территории и который мы горячо рекомендуем. Ваши тренеры научатся тому, как максимально эффективно использовать премиальный дизайн нашей конструкции и отслеживающий показатели жидкокристаллический дисплей, а также тому, как быстро выполнять настройки, эффективно переходить из одного положения движения в другое, как настраивать велотренажеры в соответствии с разными типами тела и многому другому.</p>
                        <Link to='/' className='inline-block text-red-600 hover:underline hover:text-red-700 font-bold mt-6'>Узнать больше /</Link>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-3 pt-16 !bg-cover !bg-center' style={{background: 'url(https://images.jhtassets.com/1869568b349f5cd439c9fbb3529e6bf22320bba3/) no-repeat'}}>
            <Container maxWidth="xl" className='md:!px-16'>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div>
                        <div className='text-xl text-red-600 mb-6 font-bold'>ВЕЛОТРЕНАЖЕР CXC TRAINING CYCLE</div>
                        <p className='leading-6' style={{color: '#191919'}}>Сделайте групповые занятия популярнее, чем когда-либо, с помощью нашего мощного и удобного велотренажера CXC Training Cycle. Он обладает воспроизводимым магнитным сопротивлением, оптимизированной эргономикой, легкой регулировкой по четырем направлениям с быстрым сенсорным управлением и памятью, чтобы тренировочные упражнения, социальные сети или развлекательные ресурсы всегда были у вас перед глазами. Добавьте опциональную беспроводную жидкокристаллическую консоль для отслеживания базовых показателей.</p>
                        <div className='mt-12 font-bold text-xl mb-6' style={{color: '#4c4c4c'}}>МАСТЕР-КЛАСС ПО ОСНОВАМ ЗАНЯТИЙ НА ВЕЛОТРЕНАЖЕРАХ</div>
                        <p style={{color: '#666'}}>Этот опциональный трехчасовой тренинг проводится тренером Matrix Master Trainer и охватывает основы успешного проведения сайкл-тренировок с использованием велотренажеров CXC. Тренеры научатся правильно настраивать циклы, переходить между положениями для движения и регулировать циклы для соответствия потребностям каждого участника тренировки.</p>
                    </div>
                    <div className='flex flex-col items-center lg:p-16'>
                        <img src='https://images.jhtassets.com/e356a36b32c6b477d2dea7c8f2d2bef2eebc3a78/' alt='' />
                    </div>
                </div>
            </Container>
        </section>
        <BgImageSection
            sectionHeight="500px"
            img="https://images.jhtassets.com/96b0df716d2727dadca76692e105d3ce5479218e/"
            title="ОБУЧЕНИЕ У ТРЕНЕРОВ CERTIFIED MASTER TRAINERS"
            text="Наши тренеры Certified Master Trainers на вашей территории проведут обучение ваших тренеров тому, как использовать новые велотренажеры для проведения групповых занятий, на которые будут быстро занимать места."
            contentWidth="33%"
            titleColor="#e6e5e5"
            contentRight={false}
            contentBg="#000"
            textColor="#c2c6c9"
            linkColor="#fff"
            paddingTopNull={true}
        />
        <section className='py-16'>
            <Container>
                <div className='text-3xl font-black leading-none mb-12 text-center' style={{color: "#4c4c4c"}}>ЧТОБЫ НАЧАТЬ ЗАНЯТИЯ И ПОЛУЧИТЬ ТРЕНАЖЕР MATRIX RIDE, НАПИШИТЕ НАМ СЕГОДНЯ.</div>
                <Link to='/contact-us' className='flex w-max mx-auto bg-red-600 text-white text-center hover:bg-red-700 font-black duration-200 px-6 py-2'>Свяжитесь с нами</Link>
            </Container>
        </section>
    </section>
  )
}

export default EducationMatrixRide;