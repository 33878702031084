import { Container, Button, Box } from "@mui/material";
import React from "react";
import { BsPlayCircle } from "react-icons/bs";
import BgImageSection from "../components/BgImageSection";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $6 from "../assets/videos/1-6.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const ConnectedSolutionsEngage360 = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$6}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="flex items-end justify-center relative main-media-height"
        style={{ height: "70vh" }}
      >
        <img
          src="https://images.jhtassets.com/81b43d451f385fe09480e2a2f1dc3d3a53bf5adf/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container
          className="flex flex-col gap-5 items-center justify-center text-center relative z-10"
          style={{ display: "flex" }}
        >
          <p className="text-3xl">
            ENGAGE <span className="text-red-600">360</span>
          </p>
          <Button
            onClick={handleOpen}
            className="VideoPopupButton mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none font-bold text-xl relative top-8"
            style={{ color: "#e1261c" }}
          >
            <BsPlayCircle size={32} fill="" className="mr-2 fill-red-700" />
            <span className="font-bold">СМОТРЕТЬ ВИДЕО</span>
          </Button>
        </Container>
      </section>
      <section className="py-20">
        <Container className="text-center">
          <h3 className="text-zinc-600 font-black uppercase text-xl lg:text-3xl mb-6">
            СВЯЗЬ ДЛЯ ТРЕНИРОВОК. НАСТОЯЩИЕ ВЗАИМООТНОШЕНИЯ. ПРЕВОСХОДНЫЕ
            РЕЗУЛЬТАТЫ.
          </h3>
          <p className="text-zinc-600">
            Engage 360 позволит вашим сотрудникам получить доступ к постоянно
            пополняющейся библиотеке тренировок, создать собственную библиотеку,
            назначать индивидуальные и групповые тренировки, воодушевлять
            пользователей, контролировать результаты, управлять расписаниями и
            отслеживать вовлеченность участников, чтобы они возвращались снова и
            снова. Главное преимущество Engage 360 заключается в том, что вы
            сами сможете выбирать, как приложение будет использоваться для ваших
            клиентов - в качестве побуждения к регистрации, бонуса для клиентов
            более высокого уровня или средства для виртуальных тренировок.
          </p>
        </Container>
        <Box className="px-3 lg:px-20 my-10">
          <CardBoxMoreImage
            title="Гибкий и эффективный способ коммуникации"
            subtitle="Ваши инструкторы смогут назначать тренировки и отправлять слова поддержки пользователям одним нажатием кнопки, независимо от того, где они тренируются - в вашем зале или за его пределами. А пользователи получат возможность предоставлять мгновенную обратную связь и данные о ежедневной активности, чтобы помочь инструкторам контролировать показатели и совершенствовать тренировочный процесс."
            image="https://images.jhtassets.com/a249350ce0b65e9840f60a0dff5001d3528f046f/"
            contentBgColor="#000"
            contentRight={true}
            contentWidth="25%"
            contentTitleColor="#e6e5e5"
            contentSubtitleColor="#c2c6c9"
          />
        </Box>
        <Container className="text-center">
          <h3 className="text-zinc-600 font-black uppercase text-3xl mb-6">
            Увеличение охвата
          </h3>
          <p className="text-zinc-600">
            Engage 360 поможет вам привлечь еще больше посетителей, чем
            когда-либо. Теперь ваши инструкторы смогут назначать персональные
            тренировки с учетом индивидуальных целей пользователя, а также
            привлекать новую аудиторию, предлагая групповые тренировки
            участникам с аналогичными целями в фитнесе.
          </p>
          <img
            src="https://images.jhtassets.com/45152a3631cb7bcdaf226ea4cf9a5834f1bc2312/"
            className="mt-8"
            alt=""
          />
        </Container>
      </section>
      <section>
        <Container className="py-10 !grid gap-6">
          <CardBoxMoreImage
            title="Доступ к библиотеке тренировок"
            subtitle="Тренеры смогут получить доступ к обширным библиотекам тренировок для формирования программы тренировок, полностью соответствующей целям клиента. Изображения и видео с инструкциями можно легко передавать пользователям, направляя их на пути к здоровому и красивому телу."
            image="https://images.jhtassets.com/b2e3361d5bf1d1415854fe82fd6fec71dc6af163/"
            contentBgColor="#000"
            contentRight={true}
            contentWidth="30%"
            contentTitleColor="#e6e5e5"
            contentSubtitleColor="#c2c6c9"
          />
          <CardBoxMoreImage
            title="Обмен данными со сторонними приложениями"
            subtitle="Возможность обмена данными с другими приложениями и носимыми устройствами позволяет объединить все показатели пользователя в одном месте, что дает возможность тренеру получить полное представление о состоянии здоровья клиента, чтобы помочь ему выбрать цель и контролировать результат."
            image="https://images.jhtassets.com/6a305b136e068284a61541eb3992c3f4b89d4a3d/"
            contentBgColor="#000"
            contentRight={false}
            contentWidth="30%"
            contentTitleColor="#e6e5e5"
            contentSubtitleColor="#c2c6c9"
          />
          <CardBoxMoreImage
            title="Все данные одним взглядом"
            subtitle="Благодаря универсальному входу в систему по xID тренеры могут видеть все заметки, записи и отзывы своих клиентов в одном месте, что упрощает административные задачи и стимулирует ответственность за лучшие результаты."
            image="https://images.jhtassets.com/dcbf57bc695118722b76f0cef4997d8235baed9e/"
            contentBgColor="#c2c6c9"
            contentRight={true}
            contentWidth="30%"
            contentTitleColor="#4c4c4c"
            contentSubtitleColor="#666"
          />
        </Container>
      </section>
      <section className="px-3 lg:px-20 mb-10">
        <h3 className="text-zinc-600 font-black uppercase text-3xl mb-6 text-center">
          Уникальные связи, создающие преимущества
        </h3>
        <Container maxWidth="xl" className="!grid lg:grid-cols-2 gap-4">
          <CardBox
            title="Участие при помощи встроенного обмена сообщениями"
            text="Встроенная функция обмена сообщениями предлагает вашим инструкторам новый привлекательный способ оперативного взаимодействия с клиентами. Она также позволяет клиентам получать профессиональную помощь от тренера в полном соответствии с индивидуальными целями и расписанием."
            img="https://images.jhtassets.com/7a5147e445d13e40b42c428b68d0b0dcfd652196/"
            color="#4c4c4c"
            textColor="#666"
            contentBgColor="#c2c6c9"
            contentPt="50px"
          />
          <CardBox
            title="Уведомлениям о зоне риска для удержания клиентов"
            text="Если пользователь пропускает назначенные тренировки, не назначает новые или не посещал ваш фитнес-центр уже несколько дней, это говорит о снижении заинтересованности. В этом случае вы будете получать уведомления о зоне риска, которые помогут быстро принять меры для удержания клиента."
            img="https://images.jhtassets.com/befd09e1db6893a181d00c707a73ac412ff12f14/"
            color="#4c4c4c"
            textColor="#666"
            contentBgColor="#c2c6c9"
            contentPt="50px"
          />
        </Container>
      </section>
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/0d2346a5fa921264c3f30bbf6bf3e1266dec1cd2/"
        title="Новые способы увеличения прибыли"
        text="Вы можете предлагать тренировки в качестве виртуального способа занятий фитнесом, дополнения к членству категории «премиум», в качестве вводного специального курса для резкого увеличения числа новых клиентов, как отдельные занятия или в сочетании с рекламными акциями, по своему собственному усмотрению."
        contentWidth="30%"
        titleColor="#4c4c4c"
        contentRight={false}
        contentBg="#c2c6c9"
        textColor="#666"
      />
    </>
  );
};

export default ConnectedSolutionsEngage360;
