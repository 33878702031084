import { Container, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../assets/scss/_intelligent_training_console.scss"

const IntelligentTrainingConsole = () => {
   return (
		<>
			<div className='relative flex md:flex-row flex-col items-end justify-center main-media-height' style={{
				height: '450px'
			}}>
				<img
					src="https://images.jhtassets.com/871eb2ad30600ff1f629cd0c1ea242b6d55300fb/"
					className='md:absolute top-0 left-0 w-full h-full object-cover'
					alt=""
				/>
				<p className="text-3xl md:text-5xl md:mt-0 mt-4 text-center relative z-10 pb-12 md:text-white">INTELLIGENT TRAINING CONSOLE</p>
			</div>
			<section className="py-10">
				<Container>
					<h3 className="py-5 uppercase text-3xl font-black text-center" style={{color: '#4c4c4c'}}>ПОВЫШЕНИЕ ПЛАНКИ С ПОМОЩЬЮ БОЛЕЕ ПРОДУМАННОЙ СИЛОВОЙ ТРЕНИРОВКИ</h3>
					<p className="text-center">Выбрав тренажер серии Ultra с тренировочной консолью Intelligent Training Console, вы придаете вашему клубу новые конкурентные преимущества, которые отличают его ото всех других. Силовая тренировка, интегрированная с сетевыми возможностями, позволяет новичкам легко приступить к занятиям, а опытным любителям фитнеса даст возможность по новому взглянуть на силовой тренинг. Вместе с Intelligent Training Console ваши клиенты определяют цели занятий, получают методические рекомендации по их поэтапному достижению и сравнивают свои результаты с запланированным прогрессом, указанным в программе.</p>
				</Container>
			</section>
			<section className="py-16" style={{background: 'url(https://images.jhtassets.com/50cee321011fb0a9ddb1a944049ad83823dce4e6/) center / cover no-repeat'}}>
				<Container className="md:!px-12" maxWidth="xl">
					<h3 className="py-5 uppercase text-xl md:text-3xl font-black" style={{color: '#4c4c4c'}}>ЦЕЛЕОРИЕНТИРОВАННЫЕ ПРОГРАММЫ</h3>
					<Box className="md:flex items-center">
						<p className="">Наши программы тренировок рассчитаны на 8–12 недель занятий, в ходе которых вы сможете ощутить реальный прогресс. Используя эффективные методики, программы призваны помочь как новичкам, так и опытным любителям силовых тренировок улучшить свою физическую форму. Наши программы тренировок соответствуют трем типам целей – «Endurance/Развитию выносливости», «Build /Наращивание силы» и «Наращивание мышечной массы/Strength».</p>
						<img className="md:w-80 mt-5 md:mt-0" src="https://images.jhtassets.com/b106cf170737048c4d246ee15f8f4c6095bf22a0/" alt="" />
					</Box>
					<Box className="p-5 md:p-14 pb-10">
						<div className="text-center hide-mobile md:hidden">
							<article className="mb-5">
								<img
									className="grayscale mx-auto"
									src="https://images.jhtassets.com/f6dce0e0a5d3702c07829a0083404182b3db83d9/"
									alt=""
								/>
								<p>В программе пользователи получат руководство для наращивания сухой мышечной массы, которого можно достичь благодаря выполнению большего количества повторений с меньшим весом. Эта программа идеально подходит как новичкам силовых тренировок, так и тем, кто хочет дополнить ими свои кардиотренировки.</p>
							</article>
							<article className="mb-5">
								<img
									className="grayscale mx-auto"
									src="https://images.jhtassets.com/4a99f66a43dff1361bfacc636f085e479021d6e1/"
									alt=""
								/>
								<p>Для достижения цели наращивания мышечной массы пользователям назначается меньше повторений с большим весом. Эта программа подходит как новичкам силовых тренировок, так и опытным тренерам, желающим еще больше нарастить свои мышцы.</p>
							</article>
							<article>
								<img
									className="grayscale mx-auto"
									src="https://images.jhtassets.com/b004a843f67b91bfea13a39029cebe3f9db4bca5/"
									alt=""
								/>
								<p>В ней пользователи получают инструкции выполнять меньше повторений с удержанием большего веса в течение большего периода времени. Эта программа идеально подходит людям, желающим нарастить крепкую сухую мышечную массу.</p>
							</article>
						</div>

						<Tabs className="hidden md:block">
							<div className='py-5 mb-5'>
								<TabList className='flex justify-center gap-2'>
									<Tab className='border-0 outline-none grayscale cursor-pointer intelligent-tab'>
										<img src="https://images.jhtassets.com/f6dce0e0a5d3702c07829a0083404182b3db83d9/" alt="" />
									</Tab>
									<Tab className='border-0 outline-none grayscale cursor-pointer intelligent-tab'>
										<img src="https://images.jhtassets.com/4a99f66a43dff1361bfacc636f085e479021d6e1/" alt="" />
									</Tab>
									<Tab className='border-0 outline-none grayscale cursor-pointer intelligent-tab'>
										<img src="https://images.jhtassets.com/b004a843f67b91bfea13a39029cebe3f9db4bca5/" alt="" />
									</Tab>
								</TabList>
							</div>
							<TabPanel>
								<p className="font-medium text-xl">В программе пользователи получат руководство для наращивания сухой мышечной массы, которого можно достичь благодаря выполнению большего количества повторений с меньшим весом. Эта программа идеально подходит как новичкам силовых тренировок, так и тем, кто хочет дополнить ими свои кардиотренировки.</p>
							</TabPanel>
							<TabPanel>
								<p className="font-medium text-xl">Для достижения цели наращивания мышечной массы пользователям назначается меньше повторений с большим весом. Эта программа подходит как новичкам силовых тренировок, так и опытным тренерам, желающим еще больше нарастить свои мышцы.</p>
							</TabPanel>
							<TabPanel>
								<p className="font-medium text-xl">В ней пользователи получают инструкции выполнять меньше повторений с удержанием большего веса в течение большего периода времени. Эта программа идеально подходит людям, желающим нарастить крепкую сухую мышечную массу.</p>
							</TabPanel>
						</Tabs>
					</Box>
					<Box className="grid lg:grid-cols-2 gap-3 py-10">
						<article className="">
							<div className="relative flex items-end"  style={{height: '270px'}}>
								<img
									src="https://images.jhtassets.com/4f36aba3c34f63e708be8a95f90a01dca5776017/"
									alt=""
									className="absolute top-0 left-0 w-full h-full"
								/>
								<h4 className="relative z-10 text-2xl font-medium text-white pb-12 px-6 flex-auto">Сохранение результатов занятий</h4>
							</div>
							<p className="p-4">
								История занятий позволяет пользователям быстро просмотреть результаты предыдущих занятий, а равно и общий прогресс. Подобный обзор позволяет им поддерживать мотивацию.
							</p>
						</article>
						<article className="relative ">
							<div className="relative flex items-end"  style={{height: '270px'}}>
								<img
									src="https://images.jhtassets.com/a5312c711b7b892d758e94c0392796e7bab21130/"
									alt=""
									className="absolute top-0 left-0 w-full h-full"
								/>
								<h4 className="relative z-10 text-2xl font-medium text-white pb-12 px-6 flex-auto">Программа взрывных повторений</h4>
							</div>
							<p className="p-4">
							Новая программа взрывных повторений направлена не только на силовую составляющую, но и на скорость. Такой подход позволяет позволит получать результаты быстрее.
							</p>
						</article>
					</Box>
				</Container>
			</section>
			<section className="md:p-16 pb-10" style={{background: 'url(https://images.jhtassets.com/25abc7a8570fa3c91fba4aefacc1ba3ea42d6e21/) center / cover no-repeat'}}>
				<Container maxWidth="xl">
					<h3 className="py-5 uppercase text-3xl font-black text-center" style={{color: '#e6e5e5'}}>УЛУЧШЕННЫЕ ПЕРСОНАЛЬНЫЕ ОЩУЩЕНИЯ</h3>
					<p className="text-center text-white mb-8">Как только ваши участники испытают новые ощущения от улучшенных силовых тренировок с использованием тренировочной консоли Intelligent Training Console, им больше не захочется тренироваться в другом месте.</p>
					<Box className="grid lg:grid-cols-3 gap-5 py-5">
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/993809f056bca92fb10b36ba2430e13e3c395bdb/" alt="" />
							<p className="text-2xl uppercase relative z-10">ВХОД В СИСТЕМУ ДЛЯ ДВУХ ПОЛЬЗОВАТЕЛЕЙ</p>
						</article>
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/9817c01377dbf2c544900267ca951284f70d648e/" alt="" />
							<p className="text-2xl uppercase relative z-10">БЕСКОНТАКТНЫЙ ВХОД В СИСТЕМУ</p>
						</article>
						<article className="relative flex items-center justify-center text-center text-white" style={{height: '460px'}}>
							<img className="absolute top-0 left-0 w-full h-full object-cover" src="https://images.jhtassets.com/6e0db7dad545adb71a8bc9ae7571a18b5f818ee3/" alt="" />
							<p className="text-2xl uppercase relative z-10">ДЕМОНСТРАЦИОННЫЕ ВИДЕОРОЛИКИ</p>
						</article>
					</Box>
					<Box className="grid lg:grid-cols-2 relative z-10">
						<div className="px-3">
							<img src="https://images.jhtassets.com/44e1875f254feae1fe8d91250a89fb0c79dc61b7/" alt="" />
						</div>
						<div className="px-3">
							<h4 className="text-lg uppercase mb-4 text-white font-semibold">НАСТРОЙКА СИДЕНЬЯ</h4>
							<p className="mb-4 text-white">С сохраняемыми настройками сиденья пользователям будет легко обеспечить эргономичность каждой тренировки.</p>
							<div className="grid grid-cols-2 gap-3">
								<div className="cursor-pointer">
									<h2 className="pb-5 text-white font-bold text-7xl">3</h2>
									<hr className="border-t-2 mb-2" style={{borderTopColor: '#447f95'}} />
									<p className="uppercase text-white text-right">ПОЛОЖЕНИЕ 1</p>
								</div>
								<div className="cursor-pointer">
									<h2 className="pb-5 text-white font-bold text-7xl">1</h2>
									<hr className="border-t-2 mb-2" style={{borderTopColor: '#447f95'}} />
									<p className="uppercase text-white text-right">ПОЛОЖЕНИЕ 2</p>
								</div>
							</div>
						</div>
					</Box>
					<Box className="p-3 lg:px-12 py-8 relative">
						<img
							className="lg:absolute top-0 left-0 w-full lg:h-full object-cover"
							src="https://images.jhtassets.com/a9de451b2389f96479f4278640bd3625a403e783/"
							alt=""
						/>
						<div className="relative z-10 md:p-5 lg:w-1/3">
							<img src="https://images.jhtassets.com/5db8174614b815237f4ac8e06b26c37c0daa82a7/" alt="" />
							<h3 className="mb-4 text-white font-bold text-2xl">Engage 360</h3>
							<p className="text-white">Подключите тренировочную консоль Intelligent Training Console к персональному тренерскому порталу Engage 360, и тренеры смогут создавать авторские программы силовых тренировок, назначать существующие тренировки, проверять прогресс и многое другое, что позволит значительно улучшить их взаимодействие с участниками.</p>
							<Link to='/rusconnected-solutions/engage360' className="my-2 font-bold hover:underline" style={{color: '#23537f'}}>Подробнее /</Link>
						</div>
					</Box>
				</Container>
			</section>
			<section className="py-5" style={{background: 'url(https://images.jhtassets.com/7aa5d78dcb1915f14db466281de6db19e234ff2a/) center/ cover no-repeat'}}>
				<Box className="grid md:grid-cols-2 gap-3">
					<img src="https://images.jhtassets.com/d9119463d492e2eea34f1096ad653a72437c721a/" alt="" />
					<div className="px-3">
						<h3 className="py-5 uppercase text-3xl font-black" style={{color: '#4c4c4c'}}>ПРОДВИГАЙТЕ ВАШ БРЕНД</h3>
						<p className="">Интерфейс консоли Intelligent Training Console легко настроить таким образом, чтобы там отображался ваш логотип, что создает дополнительные факторы лояльности клиентов вашему бренду.</p>
					</div>
				</Box>
				<Container className="py-5">
					<h3 className="py-5 uppercase text-3xl font-black text-center" style={{color: '#4c4c4c'}}>ПОЛНЫЙ ДОСТУП КО ВСЕМ СЕТЕВЫМ ВОЗМОЖНОСТЯМ</h3>
					<p className="text-center">Наша эксклюзивная тренировочная консоль Intelligent Training Console позволяет синхронизировать силовые тренировки с существующим программным решениям. Благодаря ей вы можете предоставить пользователям уникальные ощущения комфорта во время тренировочного процесса. Консоль Intelligent Training Console посредством WiFi, Ethernet или RFID легко подключается к персональному тренерскому порталу Engage 360, сети Community 360 и системе Asset Management.</p>
				</Container>
			</section>
			<section>
				<div
					style={{
						background: 'url(https://images.jhtassets.com/33a593d07abbb4841267ea9f03a8e44b7e3d96e0/) center / cover no-repeat',
						height: '430px'
					}}
				></div>
				<Box className="grid md:grid-cols-2 lg:grid-cols-4 gap-3 p-4 md:p-14">
					<article className="px-3">
						<img className="mx-auto mb-3" style={{height: '120px'}} src="https://images.jhtassets.com/ffb3ee04a659413deb70f65c157fa6ab806c0d01/" alt="" />
						<h3 className="text-blue-700 font-bold py-3" style={{}}>COMMUNITY 360</h3>
						<p className="py-3">Сеть Community 360 предоставляет своим пользователям доступ к личным спортивным данным, результатам и целям, синхронизируя их с любимыми приложениями и устройствами, что позволяет вашему клубу стать центром спортивной жизни. Открытая платформа Community 360 дает возможность управлять оборудованием, отслеживать активность клиентов, создавать продуманные рекламные акции, программы стимулирования и многого другое, что способствует повышению эффективности вашего бизнеса.</p>
						<Link to="/rusconnected-solutions/engage360" className="my-5 text-blue-700 font-semibold hover:underline hover:text-blue-900">Подробнее /</Link>
					</article>
					<article className="px-3">
						<img className="mx-auto mb-3" style={{height: '120px'}} src="https://images.jhtassets.com/438d3c655de84dea72a61d538ff8136e921f4b08/" alt="" />
						<h3 className="text-blue-700 font-bold py-3" style={{}}>COMMUNITY 360</h3>
						<p className="py-3">Сеть Community 360 предоставляет своим пользователям доступ к личным спортивным данным, результатам и целям, синхронизируя их с любимыми приложениями и устройствами, что позволяет вашему клубу стать центром спортивной жизни. Открытая платформа Community 360 дает возможность управлять оборудованием, отслеживать активность клиентов, создавать продуманные рекламные акции, программы стимулирования и многого другое, что способствует повышению эффективности вашего бизнеса.</p>
						<Link to="/rusconnected-solutions/engage360" className="my-5 text-blue-700 font-semibold hover:underline hover:text-blue-900">Подробнее /</Link>
					</article>
					<article className="px-3">
						<img className="mx-auto mb-3" style={{height: '120px'}} src="https://images.jhtassets.com/e180b3ce3b55af6a988535291c1df97f03f5a1c1/" alt="" />
						<h3 className="text-blue-700 font-bold py-3" style={{}}>COMMUNITY 360</h3>
						<p className="py-3">Сеть Community 360 предоставляет своим пользователям доступ к личным спортивным данным, результатам и целям, синхронизируя их с любимыми приложениями и устройствами, что позволяет вашему клубу стать центром спортивной жизни. Открытая платформа Community 360 дает возможность управлять оборудованием, отслеживать активность клиентов, создавать продуманные рекламные акции, программы стимулирования и многого другое, что способствует повышению эффективности вашего бизнеса.</p>
						<Link to="/rusconnected-solutions/engage360" className="my-5 text-blue-700 font-semibold hover:underline hover:text-blue-900">Подробнее /</Link>
					</article>
					<article className="px-3">
						<img className="mx-auto mb-3" style={{height: '120px'}} src="https://images.jhtassets.com/84b159d7b6da77229a3fe4485c77a1810fde15b2/" alt="" />
						<h3 className="text-blue-700 font-bold py-3" style={{}}>COMMUNITY 360</h3>
						<p className="py-3">Сеть Community 360 предоставляет своим пользователям доступ к личным спортивным данным, результатам и целям, синхронизируя их с любимыми приложениями и устройствами, что позволяет вашему клубу стать центром спортивной жизни. Открытая платформа Community 360 дает возможность управлять оборудованием, отслеживать активность клиентов, создавать продуманные рекламные акции, программы стимулирования и многого другое, что способствует повышению эффективности вашего бизнеса.</p>
						<Link to="/rusconnected-solutions/engage360" className="my-5 text-blue-700 font-semibold hover:underline hover:text-blue-900">Подробнее /</Link>
					</article>
				</Box>
				<div className="py-8  text-center">
					<h3 className="py-4 uppercase text-zinc-800 font-bold md:text-2xl">ВОСПОЛЬЗУЙТЕСЬ ПРЕИМУЩЕСТВАМИ КОНСОЛИ <br /> INTELLIGENT TRAINING CONSOLE СЕГОДНЯ</h3>
					<Link to="/contact-us" className="my-2 mx-auto block p-3 bg-blue-600 hover:bg-blue-900 text-white text-xs font-bold w-max">ОБРАТНАЯ СВЯЗЬ</Link>
				</div>
			</section>
		</>
	);
};

export default IntelligentTrainingConsole;
