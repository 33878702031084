import { Button } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../assets/scss/_cardio_3page.scss";
import BgImageSection from "../components/BgImageSection";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { getFilter } from "../redux/actions/filterActions";
import $3 from "../assets/videos/1-3.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const CardioPerformance = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="cardio-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$3}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 py-16 md:px-12 relative flex flex-col justify-end md:!bg-cover md:!bg-center !bg-contain !bg-top main-media-height"
        style={{
          height: "70vh",
          background:
            "url(https://images.jhtassets.com/86d07ede575df6a9a4a45d326af82abe4441b299/) no-repeat",
        }}
      >
        <Container maxWidth="xl">
          <div className="text-white md:text-6xl">
            <strong className="font-bold">Серия</strong> Performance
          </div>
        </Container>
        <Button
          onClick={handleOpen}
          className="cardio-button !duration-200 !absolute right-0 md:left-auto left-0 !bg-white !px-4 !py-3 !font-black !text-base hover:!bg-red-700 !text-red-700 hover:!text-white !rounded-none !mr-8"
          style={{ bottom: "-25px" }}
        >
          <BsPlayCircle size={32} fill="#b91c1c" className="mr-3" />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </section>
      <section className="section-2 py-16 md:px-12">
        <Container maxWidth="xl" className="mb-16 md:!px-0">
          <div className="leading-7 md:w-3/4 mb-6" style={{ color: "#333" }}>
            Созданные для интенсивной эксплуатации и первоклассных тренировок в
            лучших фитнес центрах по всему миру тренажеры серии Performance
            позволяют сделать кардиотренировки уникальными. Благодаря улучшенным
            точкам контакта тренировки становятся более эффективными и
            рациональными, приводя в восторг посетителей и превосходя их
            ожидания. Ощутимая легкость в обслуживании всего оборудования серии
            Performance и его исключительная долговечность сводят к минимуму
            вынужденные простои, позволяя день за днем, в течение многих лет,
            привлекать постоянный поток любителей фитнеса.
          </div>
          <a
            onClick={() => {
              window.localStorage.removeItem("category_id");
              window.localStorage.setItem("seria_id", 1);
              dispatch(
                getFilter({
                  series: 1,
                })
              );
            }}
            href="/cardio/catalog"
            className="inline-block px-4 py-3 bg-red-600 hover:bg-red-700 duration-200 text-white font-black"
          >
            Все продукты серии Performance
          </a>
        </Container>
        <CardBoxMoreImage
          title="Линейка оборудования"
          subtitle="Кардиообрудование премиального качества для коммерческих залов включает две беговые дорожки, лестницу-эскалатор, эллипс с изменяемой длиной шага, эллипс с изменяемой длиной шага для нижней части тела, эллиптический эргометр, гибридный велоэргометр, вертикальный велоэргометр и горизонтальный велоэргометр."
          image="	https://images.jhtassets.com/d7582e748d97dff35ee369501199338ef5fbf863/"
          contentBgColor="#000"
          contentRight={true}
          contentWidth="25%"
          contentTitleColor="#e6e5e5"
          contentSubtitleColor="#c2c6c9"
          contentLinkColor="#861711"
        />
      </section>
      <BgImageSection
        sectionHeight="550px"
        img="https://images.jhtassets.com/9f92b6385479761519a217be221a5ea58cb50482/"
        title="Улучшенные точки контакта"
        text="Улучшенная эргономика точек контакта, таких как сидение, рукоятки, герметичные кнопки, а также интеллектуальная система управления, сделают даже долгие, напряженные тренировки более комфортными, удобными и интуитивно понятными."
        contentWidth="33%"
        titleColor="#f5f5f5"
        contentRight={false}
        contentBg="#e1261c"
        textColor="#f2d9d0"
        paddingTopNull={true}
      />
      <section
        className="section-4 py-16 md:px-12"
        style={{ background: "linear-gradient(171.76deg,#253746,#000)" }}
      >
        <Container maxWidth="xl" className="md:!px-0">
          <div className="grid lg:grid-cols-2 gap-6 mb-16">
            <div>
              <img
                src="https://images.jhtassets.com/21726ef72501f1f75559440c531b1423fb023bd4/"
                alt=""
              />
              <div className="px-6 py-12" style={{ background: "#fff" }}>
                <div
                  className="font-black text-3xl leading-none mb-6"
                  style={{ color: "#4c4c4c" }}
                >
                  Непревзойденная надежность
                </div>
                <p
                  className="leading-6"
                  style={{ color: "#666", fontSize: "15px" }}
                >
                  Надежность наших компонентов протестирована на соответствие
                  самым высоким международным стандартам, что позволяет им
                  годами выдерживать максимальные нагрузки и обеспечивать
                  эффективность, достойную даже первоклассных спортсменов.
                </p>
              </div>
            </div>
            <div>
              <img
                src="https://images.jhtassets.com/eee1c8888405ab54b87f37a260d28fbc560f9cf5/"
                alt=""
              />
              <div className="px-6 py-12" style={{ background: "#fff" }}>
                <div
                  className="font-black text-3xl leading-none mb-6"
                  style={{ color: "#4c4c4c" }}
                >
                  Удобство обслуживания
                </div>
                <p
                  className="leading-6"
                  style={{ color: "#666", fontSize: "15px" }}
                >
                  Ряд улучшенных характеристик, таких как быстрый доступ к
                  компонентам и модульная конструкция, позволяют упростить
                  процедуры обслуживания и сократить время простоев, повышая
                  удовлетворенность ваших клиентов.
                </p>
              </div>
            </div>
          </div>
          <CardBoxMoreImage
            title="Консоли Matrix Cardio"
            subtitle="Оснащение вашего оборудования подходящей консолью может сделать его простым и приятным в использовании, либо же полностью цифровым, с богатым выбором опций и развлечений. Консоли с подключением к Wi-Fi обеспечат вам доступ к нашим самым эффективным сетевым решениям, таким как Engage 360, Community 360 и Asset Management."
            image="https://images.jhtassets.com/8b52d387a7c904745934139abca0529f1949e508/"
            link="Узнать больше о консолях /"
            contentBgColor="#c2c6c9"
            contentRight={true}
            contentWidth="25%"
            contentTitleColor="#4c4c4c"
            contentSubtitleColor="#666"
            contentLinkColor="#e1261c"
            linkTo="/cardio/consoles"
          />
        </Container>
      </section>
      <section className="section-5 py-16">
        <CenterContentBox
          title="Добейтесь большего с Matrix"
          subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
          link="Обратная связь"
          titleColor="#4C4C4F"
          subtitleColor="#191919"
          linkTo="/about-us"
        />
      </section>
    </section>
  );
};

export default CardioPerformance;
