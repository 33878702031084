import React, { useState } from 'react';
import { Container } from '@mui/system';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IconButton } from '@mui/material';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/6f6344bee9a93b7d3d1cba28c3c4bc6b82157adc/',
            },
            {
                image: 'https://images.jhtassets.com/decff7ac41b0aaa2e692e25744ac69e19fec45f7/',
            },
            {
                image: 'https://images.jhtassets.com/4f9bc221737625a5e6a2f5e0cbb9a7b162c162dd/',
            },
            {
                image: 'https://images.jhtassets.com/9c6a2f6e7f36c5166b425defda876d986956cdd4/',
            },
            {
                image: 'https://images.jhtassets.com/600044c51f60e991af71d6b2a1f6bd8afc436014/',
            },
            {
                image: 'https://images.jhtassets.com/a3dcce1a00dfb74cb8581284e3278c2460e9950b/',
            },
            {
                image: 'https://images.jhtassets.com/8c1665a4c71c0bc7fac18c37a1d58476543a3664/',
            },
            {
                image: 'https://images.jhtassets.com/d30142c3d15ea876b7d3dce333820c6e6f8ee060/',
            },
            {
                image: 'https://images.jhtassets.com/4199da5a6070056f5a18c30b06340623ed898f63/',
            },
            {
                image: 'https://images.jhtassets.com/6ad1978cbf711e2e09ac1323086b25729af1932c/',
            },
            {
                image: 'https://images.jhtassets.com/9cc5f4251e73704289cc538847150e250f7ba25a/',
            },
            {
                image: 'https://images.jhtassets.com/1f5bada7bfd5200abce2340cac3245d7ec37064d/',
            },
            {
                image: 'https://images.jhtassets.com/ae9e982d04ee5d905eebd48fcfd8bcb4b441a0d1/',
            },
            {
                image: 'https://images.jhtassets.com/a97207ba91dc38e9dcb83f68e0b7c67fc31560ac/',
            },
            {
                image: 'https://images.jhtassets.com/b07889772ae5c4e22cb6def7df9fc042656acab3/',
            },
            {
                image: 'https://images.jhtassets.com/302dcd0e021e05d2d38f61908dac4a3ea4d7e254/',
            },
            {
                image: 'https://images.jhtassets.com/652283f4c11a0a5b032319e7d5246b7808a4ab4b/',
            },
            {
                image: 'https://images.jhtassets.com/96a5c17570c6693f5fed517187521e3b606d0de5/',
            },
            {
                image: 'https://images.jhtassets.com/9d756bce8e587bafb514f63b128f509a0f1f3ad8/',
            },
            {
                image: 'https://images.jhtassets.com/5e61a66938de4410912df52d49aa45a295140e83/',
            },
            {
                image: 'https://images.jhtassets.com/79eae1797cd6176ef22b17e42f2a12e7b5da7a6a/',
            },
            {
                image: 'https://images.jhtassets.com/467c450cc25e5a12097038899038c5592f09e45a/',
            },
            {
                image: 'https://images.jhtassets.com/646d0b5c1c918ab134a47b08124a01e1f40ac995/',
            },
            {
                image: 'https://images.jhtassets.com/12f109876e3a794ee82e82ed7ef2f3a3cf7e8783/',
            },
            {
                image: 'https://images.jhtassets.com/1ecdd5566581d88ed61d0e5461b59e2437f96c82/',
            },
            {
                image: 'https://images.jhtassets.com/6a6dd8e63446b33ea2539a672249e5b64f659a1e/',
            },
            {
                image: 'https://images.jhtassets.com/b02f52dae5cc00fbab87b4075f246698dc0a385b/',
            },
            {
                image: 'https://images.jhtassets.com/4af17fa8cc31b7e3643d9af664f99582b42e47a5/',
            },
            {
                image: 'https://images.jhtassets.com/129a44e7eb7e985eae56fd0d80106f6737a6ce3e/',
            },
            {
                image: 'https://images.jhtassets.com/e079afe959a55aa724eabc4fcf1c2f7674ec0ff7/',
            },
            {
                image: 'https://images.jhtassets.com/50c36be7b85999ec84d6621480e0993a21102d25/',
            },
            {
                image: 'https://images.jhtassets.com/50ee4de6ecf5e17da04bf4256a1c8220f81b0139/',
            },
            {
                image: 'https://images.jhtassets.com/4c8e26ab03224c3c5551781601e80b42ba9da060/',
            },
            {
                image: 'https://images.jhtassets.com/e961d9424b22a66a233269229cccd1bb441fe728/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/92544e18a1ee3fa685d6972954e5b31ae49827e8/',
            },
            {
                image: 'https://images.jhtassets.com/2b8145ce882f5d4e1fff8e281317088d398c182e/',
            },
            {
                image: 'https://images.jhtassets.com/6d2c29f2e111d2196fc10e2921900098cd55a89e/',
            },
            {
                image: 'https://images.jhtassets.com/44c812ca4c8ae3ecc347c3bede4f6a8fa974f880/',
            },
            {
                image: 'https://images.jhtassets.com/0675e168485b4a6450af578eb64d183d2461a637/',
            },
            {
                image: 'https://images.jhtassets.com/e8d7cbeb95c19f94f5321166076a5955d305393a/',
            },
            {
                image: 'https://images.jhtassets.com/36e1b4c7b23051980fbecd07b4a38fbc2c90a298/',
            },
            {
                image: 'https://images.jhtassets.com/94b904f07576437e19c9967be46717080650cb6f/',
            },
            {
                image: 'https://images.jhtassets.com/182544a28e1d23bcd24fc7435a6f11bc33221f4e/',
            },
            {
                image: 'https://images.jhtassets.com/55fcb07ba1009a99185a444c10000bcbb684237e/',
            },
            {
                image: 'https://images.jhtassets.com/7aabce1a496acb75f5bc0b1a0eea3d626d974fc2/',
            },
            {
                image: 'https://images.jhtassets.com/ad3017b9bb6e9d5348513f7f99cb5cf432816514/',
            },
            {
                image: 'https://images.jhtassets.com/6e93a818221c4633e8b57c840371520365bb6eaf/',
            },
            {
                image: 'https://images.jhtassets.com/cb4993b43ae15117d755a5edffd3861947c4fc34/',
            },
            {
                image: 'https://images.jhtassets.com/34daf161f926cfab652fa7463d1645fc566539ee/',
            },
            {
                image: 'https://images.jhtassets.com/1f50c99ef77ec71a1ec822a97aa60750e242fcb4/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/053772dbcd6c9693d2903f7613db7c6101a4df41/',
            },
            {
                image: 'https://images.jhtassets.com/e4c92152aaf959ca67986d1ef96e0f0e37903693/',
            },
            {
                image: 'https://images.jhtassets.com/512a8d90dbb2ea66d808704244e8b04e9d16c3ce/',
            },
            {
                image: 'https://images.jhtassets.com/7f5841afca993c5b8ef8d8a20c83a4be17270100/',
            },
            {
                image: 'https://images.jhtassets.com/aa5319c77959ca75390b984a5901924e2dad2771/',
            },
            {
                image: 'https://images.jhtassets.com/4f117afa8be130d8227cf006ca5d1686405008ea/',
            },
        ],
    },
    {
        id: 4,
        gallery: [
            {
                image: 'https://images.jhtassets.com/dd608384a44ee5fc08ed6283bb5e026c3832f967/',
            },
            {
                image: 'https://images.jhtassets.com/224c5eec37f6eb758e9aca90af849815553b762a/',
            },
            {
                image: 'https://images.jhtassets.com/28ac0b32387a4723da577ed48551a87453e37d2c/',
            },
            {
                image: 'https://images.jhtassets.com/fecc142a686effafc74632628dc00e3080717342/',
            },
            {
                image: 'https://images.jhtassets.com/37c35a07ca00cd11c95f2969eb60b64e4738b033/',
            },
        ],
    },
    {
        id: 5,
        gallery: [
            {
                image: 'https://images.jhtassets.com/533f1128fbf0a841194d7bb312913020377cd5ae/',
            },
            {
                image: 'https://images.jhtassets.com/d52667f65901fabaf65b85ed99cb866cf6160fb5/',
            },
            {
                image: 'https://images.jhtassets.com/02d65e1e61c96fe6f6854fe8fbb5aa792a541e1d/',
            },
        ],
    },
    {
        id: 6,
        gallery: [
            {
                image: 'https://images.jhtassets.com/47023f38792694c44e0941d53e04e0a6d4c83ae8/',
            },
            {
                image: 'https://images.jhtassets.com/997405b2a8a01e811592a30eeb331435ebd0928d/',
            },
            {
                image: 'https://images.jhtassets.com/562fce7ea80150b97393ffb8fb5d9e710c8be3b9/',
            },
            {
                image: 'https://images.jhtassets.com/7f9f84a4b144dccc22bf5a38bbd0a44bfb56c1f2/',
            },
            {
                image: 'https://images.jhtassets.com/3a1bdb75c08e76506c6ed7f82aa0d6cf0eefe809/',
            },
        ],
    },
    {
        id: 7,
        gallery: [
            {
                image: 'https://images.jhtassets.com/7cc8e12f312d5ec6c12e8c20be77d260dc01896f/',
            },
            {
                image: 'https://images.jhtassets.com/bb80de43c38aa79ce87598753945a3750e9ce294/',
            },
        ],
    },
    {
        id: 8,
        gallery: [
            {
                image: 'https://images.jhtassets.com/6941c1346e9508e22c3973d407e86f294285f333/',
            },
            {
                image: 'https://images.jhtassets.com/8bdc094f7ab1de93977fd67f85698e5332a317f3/',
            },
            {
                image: 'https://images.jhtassets.com/5475ea2df446d8b63c9e46807a5c390a19941e45/',
            },
            {
                image: 'https://images.jhtassets.com/94fdf529e867f97230238add3dc9a5e8018c17b4/',
            },
            {
                image: 'https://images.jhtassets.com/8f977417e2e4d1cc2c6d6f69c3062c4f63262ebb/',
            },
            {
                image: 'https://images.jhtassets.com/68eb7b335e691a9b5e7c4687423e5c67273a1ed7/',
            },
            {
                image: 'https://images.jhtassets.com/fa33b57fed16ae51ec8dad732964fbef47972e9c/',
            },
            {
                image: 'https://images.jhtassets.com/b2d90baeaeb2d3373d5e79eb633bbd306bd508ca/',
            },
            {
                image: 'https://images.jhtassets.com/6135de3c10232f359d530ff97da3207c94b07b25/',
            },
            {
                image: 'https://images.jhtassets.com/3a46c0f4db515f435aa03a7b3488f1a72060570d/',
            },
            {
                image: 'https://images.jhtassets.com/0ef7554c8a72c8e3f49de5d00daadd0eae921d2f/',
            },
            {
                image: 'https://images.jhtassets.com/8f53f2a75727980150960644074cd062b10b7936/',
            },
        ],
    },
    {
        id: 9,
        gallery: [
            {
                image: 'https://images.jhtassets.com/3e374f80bfcf3ee1a1f00f7a4920a2a6d1bcdcda/',
            },
            {
                image: 'https://images.jhtassets.com/56d0a568d8e5c3087d649619c7ec2b32a5177373/',
            },
            {
                image: 'https://images.jhtassets.com/ca9aca79503deebc2ff7c7271043337951577475/',
            },
            {
                image: 'https://images.jhtassets.com/1dd92cc28f7c811b921031bf48c97aede01bf2f7/',
            },
        ],
    },
    {
        id: 10,
        gallery: [
            {
                image: 'https://images.jhtassets.com/a0f967b3c1ae50235554959d8288809d2036df31/',
            },
            {
                image: 'https://images.jhtassets.com/934aac20d73e5e9e1a723acd22121c673777b9b2/',
            },
            {
                image: 'https://images.jhtassets.com/76fc17e11e465133f95a1ccd18a27affd33036ae/',
            },
            {
                image: 'https://images.jhtassets.com/8ad91613a649e04f07bbe2b7a04ee4218ae2fa2e/',
            },
            {
                image: 'https://images.jhtassets.com/1764eb3532b426707e74ed59a4e283bcb3373e3c/',
            },
        ],
    },
    {
        id: 11,
        gallery: [
            {
                image: 'https://images.jhtassets.com/99af2fade1b6c3cf8d08bc719349f5ed0b9f2316/',
            },
            {
                image: 'https://images.jhtassets.com/acd710f394918a155644d858c125c6b6588197f2/',
            },
            {
                image: 'https://images.jhtassets.com/66ff2d823293443dd547ca4140d74414fe0145de/',
            },
            {
                image: 'https://images.jhtassets.com/b8c54799edda5a9852a092e7540603cba5b29bbd/',
            },
            {
                image: 'https://images.jhtassets.com/9133555dde3bae16abbddb597d3c683ad91e400c/',
            },
            {
                image: 'https://images.jhtassets.com/9ed0ca69067269a6e2d93cd9f01d739090bd3a25/',
            },
            {
                image: 'https://images.jhtassets.com/96548484516e72f8dd8880b408cd0f33b7945ed8/',
            },
            {
                image: 'https://images.jhtassets.com/994fdb9f19c68c2053a0c1d62705041dbc267f13/',
            },
            {
                image: 'https://images.jhtassets.com/8f1c0743835c8776c60b7683fbedc13151fa614f/',
            },
            {
                image: 'https://images.jhtassets.com/cc551f0246f1e0ebfacd442b8582cb38ee66033d/',
            },
            {
                image: 'https://images.jhtassets.com/5aab2d8729c3719b44d5f809436c5c7ecc0ea9eb/',
            },
            {
                image: 'https://images.jhtassets.com/e60f7757bdc68dc7646a57360d7349b72b02c911/',
            },
            {
                image: 'https://images.jhtassets.com/f4cd9e276a0e9a36396d3925874f6a6efa133552/',
            },
            {
                image: 'https://images.jhtassets.com/369dc9ace2e5eb29d14ca381af9b62dfa52e6673/',
            },
            {
                image: 'https://images.jhtassets.com/06aa41f2464fea4e41d80bee8faaa04d82e2de6f/',
            },
        ],
    },
    {
        id: 12,
        gallery: [
            {
                image: 'https://images.jhtassets.com/99de82006c3e47f9cb40d363a4244ff281c3cf9f/',
            },
            {
                image: 'https://images.jhtassets.com/b510fc97980855866ab66847d5e08d490a2ba743/',
            },
            {
                image: 'https://images.jhtassets.com/0394de873028b3055a8916fd2e7fa83ec2474546/',
            },
            {
                image: 'https://images.jhtassets.com/ba4d59bc3306039aeb2155d576c922896c8003fe/',
            },
            {
                image: 'https://images.jhtassets.com/743b9875bc20e25bbd5ce339ec45e794b602b7b8/',
            },
            {
                image: 'https://images.jhtassets.com/c11e061f6fec493217a501f427c092c3af6802b3/',
            },
            {
                image: 'https://images.jhtassets.com/c899b488cac315344009b928709e557047020d36/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/6f6344bee9a93b7d3d1cba28c3c4bc6b82157adc/transformed/h_558,w_440,c_fill',
        title: 'Cologne',
        subtitle: 'Hotel Lindner',
        name: 'Германия',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/92544e18a1ee3fa685d6972954e5b31ae49827e8/transformed/h_558,w_440,c_fill',
        title: 'Madison',
        subtitle: 'AC Hotel',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/053772dbcd6c9693d2903f7613db7c6101a4df41/transformed/h_558,w_440,c_fill',
        title: 'Chicago',
        subtitle: 'Renaissance',
        name: 'США',
    },
    {
        id: 4,
        image: 'https://images.jhtassets.com/dd608384a44ee5fc08ed6283bb5e026c3832f967/transformed/h_558,w_558,c_fill',
        title: 'Cannes',
        subtitle: 'Hotel Majestic',
        name: 'Франция',
    },
    {
        id: 5,
        image: 'https://images.jhtassets.com/533f1128fbf0a841194d7bb312913020377cd5ae/transformed/h_558,w_558,c_fill',
        title: 'Chicago',
        subtitle: 'Loews Chicago',
        name: 'США',
    },
    {
        id: 6,
        image: 'https://images.jhtassets.com/47023f38792694c44e0941d53e04e0a6d4c83ae8/transformed/h_558,w_558,c_fill',
        title: 'Seoul',
        subtitle: 'Courtyard by Marriott',
        name: 'Korea',
    },
    {
        id: 7,
        image: 'https://images.jhtassets.com/7cc8e12f312d5ec6c12e8c20be77d260dc01896f/transformed/h_558,w_558,c_fill',
        title: 'Minneapolis',
        subtitle: 'JW Marriott Mall of America',
        name: 'США',
    },
    {
        id: 8,
        image: 'https://images.jhtassets.com/6941c1346e9508e22c3973d407e86f294285f333/transformed/h_558,w_558,c_fill',
        title: 'Shanghai',
        subtitle: 'Pullman – Shanghai South',
        name: 'China',
    },
    {
        id: 9,
        image: 'https://images.jhtassets.com/3e374f80bfcf3ee1a1f00f7a4920a2a6d1bcdcda/transformed/h_558,w_558,c_fill',
        title: 'Antibes',
        subtitle: 'Hotel Du Cap-Eden-Roc',
        name: 'Франция',
    },
    {
        id: 10,
        image: 'https://images.jhtassets.com/a0f967b3c1ae50235554959d8288809d2036df31/transformed/h_558,w_558,c_fill',
        title: 'Las Vegas',
        subtitle: 'Four Seasons',
        name: 'США',
    },
    {
        id: 11,
        image: 'https://images.jhtassets.com/99af2fade1b6c3cf8d08bc719349f5ed0b9f2316/transformed/h_558,w_558,c_fill',
        title: 'Santorini',
        subtitle: 'Santo Maris Oia',
        name: 'Greece',
    },
    {
        id: 12,
        image: 'https://images.jhtassets.com/99de82006c3e47f9cb40d363a4244ff281c3cf9f/transformed/h_558,w_558,c_fill',
        title: 'Bremen',
        subtitle: 'Hotel Elements Pure',
        name: 'Германия',
    },
];

const ShowcasesHospitality = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='showcasesHealthClubs-page'>
        <section className='section-1 flex items-center justify-center md:py-0 pt-12 pb-32 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white text-6xl text-center leading-none'>Отели</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-4xl leading-none' style={{color: '#4c4c4c'}}>ПРИЯТНЫЕ И ПРАВИЛЬНЫЕ ТРЕНИРОВКИ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesHospitality;