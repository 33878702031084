import { Button } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/_consoles_premium_led.scss';
import BgImageSection from '../components/BgImageSection';
import CardBox from '../components/CardBox';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import HeaderBox from '../components/HeaderBox';
import $15 from '../assets/videos/1-15.mp4';

const ConsolesPremiumLed = () => {
  return (
    <section className='consolesPremiumLed-page'>
        <HeaderBox
            fade='fade-up'
            video={$15}
            center='bottom-center'
            title="Светодиодная консоль Premium LED"
            text="Предложите своим клиентам первоклассные тренировки с притягательным интерфейсом светодиодных дисплеев, установив в своем зале наши консоли Premium LED. Яркий и четкий дисплей высокого разрешения отобразит всю интересующую информацию о тренировках с помощью понятных текстовых и числовых показателей. Интуитивно понятный дизайн обеспечит легкий доступ к эксклюзивным программам, а подключение к WiFi позволит воспользоваться преимуществами нашей мощной системы Connected Solutions. Совместимы со всем кардиооборудованием серий Performance, Endurance и Lifestyle."
            image="https://images.jhtassets.com/968d4f12b8f3d05bd8ea887d51a95fcb5b7ca449/"
        />
        <section className='section-1 py-16'>
            <Container>
                <div className='grid md:grid-cols-2 gap-8'>
                    <div className='px-16'><img src='https://images.jhtassets.com/7e6b3e5024b8e50081ecb98e16187b570d527629/' alt='' /></div>
                    <div className='pt-8'>
                        <div className='font-black text-3xl mt-4' style={{color: '#4c4c4c'}}>Четкие, яркие, лучше, чем когда-либо</div>
                        <p className='py-6 pr-12' style={{color: '#191919'}}>Сделайте тренировки более наглядными и увлекательными благодаря полному спектру цветов, притягательной графике, а также использованию элементов фирменного стиля и оригинальных логотипов вашего бизнеса.</p>
                        <div className='flex'>
                            <img src='https://images.jhtassets.com/479a227ec90db7a08775b598954f9858a4d2594f/' alt='' />
                            <div>
                                <strong className='font-black text-lg'>8000</strong>
                                <span>px</span>
                                <div className='text-sm font-bold'>МНОГОЦВЕТНЫЙ СВЕТОДИОДНЫЙ ДИСПЛЕЙ</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-2 py-12 md:px-12'>
            <Container maxWidth="xl">
                <div className='grid md:grid-cols-2 gap-6'>
                    <div>
                        <img src='https://images.jhtassets.com/5163c01b2ebbba153a1ae8b6d90701cea1fea1c0/' alt='' />
                        <div className='px-6 py-12' style={{background: '#000'}}>
                            <div className='font-black text-3xl leading-none mb-6' style={{color: '#e6e5e5'}}>Удобная навигация</div>
                            <p className='leading-7' style={{color: '#c2c6c9'}}>Интуитивно понятный интерфейс поможет каждому легко приступить к тренировкам и получить доступ к эксклюзивным программам, таким как наша высокоинтенсивная интервальная спринтерская программа Sprint 8.</p>
                        </div>
                    </div>
                    <div>
                        <img src='https://images.jhtassets.com/b01f5fa0161525686cc30ba5e3d7f066a292a8f1/' alt='' />
                        <div className='px-6 py-12' style={{background: '#000'}}>
                            <div className='font-black text-2xl lg:text-3xl leading-none mb-6' style={{color: '#e6e5e5'}}>Детализированные показатели тренировок</div>
                            <p className='leading-7' style={{color: '#c2c6c9'}}>Консоль Premium LED отобразит всю интересующую информацию о тренировках с помощью крупных, ярких и понятных текстовых и числовых показателей.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-3'>
            <div className='grid lg:grid-cols-2'>
                <div className='grid-1 flex flex-col justify-end'>
                    <div className='p-6' style={{background: 'linear-gradient(180deg,rgba(0,0,0,.6),#000)'}}>
                        <div className='leading-none text-white font-black text-3xl'>Максимальные результаты. Минимальное время.</div>
                        <p className='leading-7 py-6' style={{color: '#c2c6c9'}}>Захватывающая LED версия нашей интенсивной спринтерской программы Sprint 8 помогает задействовать естественный гормон роста человека для ускоренного сжигания жира и наращивая мышц за короткую 20-минутную тренировку.</p>
                        <Link to='' className='inline-block bg-red-600 hover:bg-red-700 text-white font-bold px-6 py-3 duration-200 text-sm'>Узнать больше</Link>
                    </div>
                </div>
                <div className='grid-2 flex flex-col justify-end'>
                    <div className='p-6 flex flex-col items-end' style={{background: 'linear-gradient(180deg,rgba(0,0,0,.6),#000)'}}>
                        <div className='leading-none text-white font-black text-3xl text-right'>Сделайте подъем запоминающимся событием</div>
                        <p className='leading-7 py-6 text-right' style={{color: '#c2c6c9'}}>Превратите тренировки на степпере или лестнице-эскалаторе в подъем на самую верхнюю точку знаменитых мировых достопримечательностей. Ваши клиенты не захотят останавливаться, даже достигнув вершины.</p>
                        <Link to='' className='inline-block bg-red-600 hover:bg-red-700 text-white font-bold px-6 py-3 duration-200 text-sm'>Узнать больше</Link>
                    </div>
                </div>
            </div>
        </section>
        <BgImageSection
            sectionHeight="80vh"
            img="https://images.jhtassets.com/4c2724b470473f3a6d97ea4d537e9caaa6954ef2/"
            contentWidth="35%"
            contentRight={true}
            contentBg="#fff"
            title="Используйте силу своего бренда"
            titleColor="#4c4c4c"
            text="Наша простая и привлекательная консоль Premium LED позволяет также продвигать ваш бренд и использовать другие средства, делающие ваш объект уникальным и узнаваемым. Благодаря интерфейсу с элементами фирменного стиля ваши клиенты не пропустят ваши специальные предложения или интересные события, что откроет вам доступ к новым каналам поступления доходов."
            textColor="#666"
        />
        <section className='section-5 py-16 md:px-12'>
            <Container maxWidth="xl">
                <div className='font-black text-3xl text-center mb-6' style={{color: '#4c4c4c'}}>Создано с заботой о ваших клиентах</div>
                <CardBoxMoreImage
                    title="Подключение к Apple Watch"
                    subtitle="Наши консоли Premium LED могут подключаться к Apple Watch для более точного отслеживания тренировок и объединения пользовательских данных (с входом через RFID в качестве опции)."
                    image="https://images.jhtassets.com/2857665501ad1b9eaf987d0bf764c6b5c6aafce7/"
                    contentBgColor="#000"
                    contentRight={false}
                    contentWidth="25%"
                    contentTitleColor="#e6e5e5"
                    contentSubtitleColor="#c2c6c9"
                    contentLinkColor="#fff"
                />
                <div className='grid lg:grid-cols-2 gap-6 mt-16'>
                    <CardBox
                        title="Встроенный вентилятор"
                        text="Изменения в конструкции нашей консоли Premium LED позволили добавить новый мощный вентилятор. Это стало еще одним дополнительным способом добавить свежести и легкости занятиям на тренажерах со светодиодной консолью."
                        img="https://images.jhtassets.com/dfd5a04c4264a2179c36fd431e0fa94d8a9dc6c7/"
                        color="#4c4c4c"
                        textColor="#666"
                        contentBgColor="#f5f5f5"
                        contentPt="50px"
                    />
                    <CardBox
                        title="Подставка для устройств"
                        text="Широкая подставка позволяет легко разместить смартфон, планшет или даже последний бестселлер. Это помогает клиентам делать свои тренировки индивидуальными и использовать собственный контент, чтобы сохранять мотивацию и продолжать движение."
                        img="https://images.jhtassets.com/c894409286d412f1d725c8057db97c454789678e/"
                        color="#4c4c4c"
                        textColor="#666"
                        contentBgColor="#f5f5f5"
                        contentPt="50px"
                    />
                </div>
                <div className='grid lg:grid-cols-3 mt-12'>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/ed7b4df63e9f6f7ebec88cb131e3b88e8cb87b18/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Подключение Bluetooth</div>
                        <p className='leading-6' style={{color: '#191919'}}>Соединение Bluetooth позволяет пользователям легко подключать их любимые устройства контроля сердечных сокращений для получения точных показателей тренировки. Это превосходный способ поддерживать темп для достижения поставленных целей и стремления к новым.</p>
                    </div>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="700" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="700" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/5314c116e64cb6217e1451137d746e636a33359d/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Вход с помощью xID</div>
                        <p className='leading-6' style={{color: '#191919'}}>Единая точка регистрации пользователей в системе с помощью xID - это простой способ оптимизации тренировочного процесса. При каждом входе в систему персональные настройки загружаются автоматически, чтобы клиент мог сразу же приступить к тренировке в соответствии с его индивидуальными потребностями.</p>
                    </div>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="1100" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="1100" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/5a2ecd2eae164d7b3e1cd51a2b5748c2ad3a399d/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Логин RFID</div>
                        <p className='leading-6' style={{color: '#191919'}}>Удобные метки RFID делают регистрацию в системе, загрузку программ и отслеживание тренировок полностью бесконтактными и в сущности автоматическими. Клиенты могут использовать RFID даже в имеющихся торговых автоматах, поддерживающих данную технологию, а также в киосках и точках продаж на объекте.</p>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-6 py-16 md:px-12' data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0">
            <Container maxWidth="xl">
                <div className='md:w-4/5 mx-auto'>
                    <div className='font-black text-3xl text-center mt-4 leading-none' style={{color: '#e6e5e5'}}>Связь. Гибкость. Индивидуальный подход. Это <br className='md:block none' /> будущее фитнеса.</div>
                    <p className='leading-7 my-4 text-center' style={{color: '#c2c6c9'}}>Консоли с подключением к WiFi идеально работают с нашими эффективными цифровыми решениями Connected Solutions, такими как Engage 360, Community 360, Asset Management и другими.</p>
                    <Link to='' className='hover:underline text-center font-bold block' style={{color: '#ffffffcc'}}>Ознакомьтесь с нашими технологиями Connected Solutions /</Link>
                </div>
                <div className='grid lg:grid-cols-2 gap-4 mt-12'>
                    <div>
                        <div className='font-black text-3xl leading-none mb-4' style={{color: '#e6e5e5'}}>Помогаем вашим продуктам найти своих потребителей</div>
                        <p className='leading-7' style={{color: '#c2c6c9'}}>Предлагаемые вами цифровые технологии подключений помогают вашим клиентам сохранять настрой и мотивируют возвращаться к вам снова, а наши решения позволяют легко воспользоваться ими из любого места. Пользователи смогут принимать участие в ваших групповых тренировках, использовать специальные приложения и получать доступ к занятиям от ваших поставщиков вдохновляющего контента, находясь как в вашей кардиозоне, так и у себя дома. Это позволит вам расширить границы своего бренда и привлекать пользователей нового типа, использующих ваш контент за пределами ваших фитнес-клубов. И это только малая часть того, как мы можем помочь вашим продуктам найти своих потребителей, чтобы изменить ваши представления о способах взаимодействия с клиентами и финансовом результате вашего бизнеса в конечном итоге.</p>
                    </div>
                    <div><img src='https://images.jhtassets.com/769c663080135114f0c39ddee73a68a815d282d9/' alt='' /></div>
                </div>
            </Container>
        </section>
        <section className='section-7 py-16 md:px-12'>
            <Container maxWidth="xl">
                <CardBoxMoreImage
                    title="Подключение ТВ"
                    subtitle="Наша светодиодная консоль Premium LED использует для подключения дополнительных телевизионных экранов тот же провод, что и для самой консоли, поэтому теперь с помощью всего одного провода вы сможете разнообразить процесс тренировок новыми развлекательными ресурсами. Одно из главных преимуществ нашего нового дизайна состоит в том, что он позволяет отказаться от свисающих шнуров и придать вашей кардиозоне более эстетичный вид."
                    image="https://images.jhtassets.com/707406fd0622d59e32a0450fcb6a7b7bbc7d8d67/"
                    contentBgColor="#000"
                    contentRight={true}
                    contentWidth="25%"
                    contentTitleColor="#e6e5e5"
                    contentSubtitleColor="#c2c6c9"
                    contentLinkColor="#fff"
                />
            </Container>

        </section>
        <section className='section-8 py-16'>
            <Container>
                <div className='font-black text-3xl text-center mt-16' style={{color: '#4c4c4c'}}>Ознакомьтесь со светодиодной консолью Premium LED</div>
                <p className='text-center mt-6 w-3/4 mx-auto' style={{color: '#191919'}}>Посмотрите нашу интерактивную демонстрацию, чтобы узнать о работе нашей светодиодной консоли Premium LED и обо всех преимуществах, которые она может дать вашему объекту.</p>
                <iframe title='not' className='mx-auto mt-10' width='80%' height='520px' src='https://embedder.wirewax.com/8207753'></iframe>
            </Container>
        </section>
        <BgImageSection
            sectionHeight="500px"
            img="https://images.jhtassets.com/ecedaf371227132f724ddc4cb45629df07c0a3ad/"
            title="Свет новых возможностей"
            text="Когда в процессе усовершенствования нашей линейки кардиотренажеров наша команда разработчиков получила шанс проанализировать, какими могут и должны быть все светодиодные консоли, они сразу же ухватились за эту возможность. Узнайте все о наших новшествах в конструкции светодиодной консоли здесь."
            contentWidth="35%"
            link="Узнать больше /"
            linkTo="/innovations/premium-led-console"
            linkColor="#e1261c"
            titleColor="#4c4c4c"
            contentRight={false}
            contentBg="#fff"
            textColor="#4c4c4c"
            animation={true}
            paddingTopNull={true}
        />
        <section
            className='section-10 py-16'
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0"
        >
            <Container maxWidth="xl">
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div className='pt-12'>
                        <div className='font-bold text-2xl mb-6' style={{color: '#4c4c4c'}}>Установите консоли Premium LED в вашем зале</div>
                        <p className='leading-7' style={{color: '#191919'}}>Совместимы со всем кардиооборудованием серий Performance, Endurance и Lifestyle.</p>
                        <Link to='/contact-us'><Button className='!rounded-none !text-sm !my-4 !bg-red-600 hover:!bg-red-700 !duration-200 !font-black !p-4 !text-white'>Запросить коммерческое предложение</Button></Link>
                        <Link to='' className='inline-block font-black text-red-600 hover:underline hover:text-red-800'>Все совместимое с консолями оборудование /</Link>
                    </div>
                    <div><img src='https://images.jhtassets.com/6d269c5e344880a5a50f78a9ba93e5a234fed0c3/' alt='' /></div>
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ConsolesPremiumLed;