import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowDropleft } from 'react-icons/io';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { createFeedback, getCartDetail } from '../redux/actions/cartActions';
import { HiOutlineZoomIn, HiOutlineZoomOut } from 'react-icons/hi';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import HoverBoxAnimation from '../components/HoverBoxAnimation';
import CenterContentBox from '../components/CenterContentBox';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import ProductItem from '../components/ProductItem';
import '../assets/scss/_detail.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  background: '#000',
  boxShadow: 24,
  zIndex: 2,
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid transparent',
    color: '#fff',
    borderRadius: 0,
  },
}));

const GroupDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [zoom, setZoom] = useState(false)
    const [page, setPage] = useState(1);
    const [characterTab, setCharacterTab] = useState(1);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // info
    const [name, setName] = useState();
    const [lastName, setLastName] = useState();
    const [gmail, setGmail] = useState();
    const [country, setCountry] = useState();
    const [index, setIndex] = useState();
    const [number, setNumber] = useState();
    const [message, setMessage] = useState();
    const [imageState, setImageState] = useState();

    useEffect(() => {
      dispatch(getCartDetail(id))
      setImageState()
    },[id])

    const detail = useSelector((state) => state.cart.data);

    // with id
    const id_1 = detail?.details_of_product?.find(item => item?.slide === 1);
    const id_2 = detail?.details_of_product?.find(item => item?.slide === 2);
    const id_3 = detail?.details_of_product?.find(item => item?.slide === 3);
    const id_4 = detail?.details_of_product?.find(item => item?.slide === 4);
    const id_5 = detail?.details_of_product?.find(item => item?.slide === 5);
    const id_6 = detail?.details_of_product?.find(item => item?.slide === 6);
    const id_7 = detail?.details_of_product?.find(item => item?.slide === 7);
    const id_8 = detail?.details_of_product?.find(item => item?.slide === 8);
    const id_9 = detail?.details_of_product?.find(item => item?.slide === 9);
    const id_10 = detail?.details_of_product?.find(item => item?.slide === 10);
    const id_11 = detail?.details_of_product?.find(item => item?.slide === 11);
    const id_12 = detail?.details_of_product?.find(item => item?.slide === 12);
    const id_13 = detail?.details_of_product?.find(item => item?.slide === 13);
    const id_14 = detail?.details_of_product?.find(item => item?.slide === 14);
    const id_15 = detail?.details_of_product?.find(item => item?.slide === 15);

  return (
    <section className='cardio-detail' key={id}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}
        >
          <Box sx={style} style={{padding: '60px'}}>
          <span onClick={handleClose} style={{zIndex: '21343423'}} className="fixed top-0 right-0 mr-6 mt-3 text-4xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
            <div className='grid grid-cols-3 gap-10'>
              <div>
                <div className='text-3xl font-black leading-none' style={{color: "#e6e5e5"}}>Запросить коммерческое предложение</div>
                <p className='leading-7 my-4' style={{color: '#c2c6c9'}}>Пожалуйста, заполните форму, чтобы региональный представитель Matrix Fitness мог связаться с вами.</p>
              </div>
              <div className='col-span-2'>
                <div className=' grid grid-cols-2 gap-x-3 gap-y-8'>
                  <TextField onChange={(e) => setName(e.target.value)} style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Имя*" variant="outlined" />
                  {/* <TextField onClick={(e) => setLastName(e.target.value)} style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Фамилия*" variant="outlined" /> */}
                  <TextField onChange={(e) => setGmail(e.target.value)} style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Электронная почта*" variant="outlined" />
                  {/* <TextField onClick={(e) => setCountry(e.target.value)} style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Страна*" variant="outlined" /> */}
                  {/* <TextField onClick={(e) => setIndex(e.target.value)} style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Индекс*" variant="outlined" /> */}
                  <TextField onChange={(e) => setNumber(e.target.value)} className='col-span-2' style={{background: '#f5f5f5'}} sx={{ input: { fontWeight: 'bold' } }} id="outlined-basic" label="Телефон*" variant="outlined" />
                  <textarea onChange={(e) => setMessage(e.target.value)} className='col-span-2' style={{width: '-webkit-fill-available', background: '#f5f5f5', padding: '16.5px 14px', border: '1px solid #0000003b', color: '#666', fontWeight: 'bold'}} placeholder="Сообщение *"></textarea>
                </div>
                <div className='flex items-center mt-8'>
                  <button disabled={(name?.length > 0 && number?.length > 0) ? false : true} onClick={() => {
                    dispatch(createFeedback({
                      name: name,
                      email: gmail,
                      phone: number,
                      message: message,
                      product_id: detail.id,
                    }))
                    handleClose()
                  }} className={`font-black text-white py-3 px-5 bg-red-600 hover:bg-red-700 ${(name?.length > 0 && number?.length > 0) ? 'bg-red-600 hover:bg-red-700' : 'bg-red-200'} duration-200`}>ОТПРАВИТЬ</button>
                  <div onClick={handleClose} className='ml-6 text-lg text-slate-400 hover:underline hover:text-white cursor-pointer font-black'>Отмена /</div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <Container maxWidth="xl">
        <div className='flex items-center justify-between py-2'>
          {/* <div className='flex items-center font-black text-red-700 hover:underline cursor-pointer hover:text-red-900'>
            <IoMdArrowDropleft className='mr-2' size={20} />
            Назад/
          </div> */}
          <div className='flex items-center ml-auto'>
            <div onClick={() => setPage(1)} className={`detail-block-text ${page === 1 && 'active'}`}>Обзор</div>
            <div className={`text-gray-700 mx-4`}>|</div>
            <div onClick={() => setPage(2)} className={`detail-block-text  ${page === 2 && 'active'}`}>Характеристики</div>
          </div>
        </div>
      </Container>
      {page === 1 &&
      <>
      <section className='section-1 !bg-cover !bg-top' style={{ background: 'url(https://images.jhtassets.com/eea04484145f263d71a6ead1fa69fb7e98e4a8fc) no-repeat' }}>
          <Container maxWidth="xl">
            <div className='flex xl:flex-row flex-col justify-between md:px-12 md:py-8'>
              <div className='xl:w-7/12 w-full relative'>
                <div onClick={() => setZoom(boolean => !boolean)} className='absolute left-0 top-0 rounded p-2 hover:!bg-gray-500 cursor-pointer' style={{ background: '#999', zIndex: '2' }}>
                  {zoom === !true ?
                    <HiOutlineZoomIn color='white' size={36} />
                  :
                    <HiOutlineZoomOut color='white' size={36} />
                  }
                </div>
                <div className='overflow-hidden pb-12'>
                  <img className={`mx-auto duration-200 ${zoom === true ? 'scale-150' : 'scale-100'}`} style={{ maxWidth: '100%', height: '450px' }} src={imageState || detail?.image} alt='' />
                </div>
                <div className='flex xl:justify-start justify-center overflow-x-auto'>
                  {detail?.images?.map(item => (
                    <div key={item.id} className='border-2 border-red-600 mr-5 hover:border-black duration-200 cursor-pointer' style={{ height: '60px', width: '60px' }}>
                      <img onClick={() => setImageState(item?.path)} className='h-full w-full object-contain' src={item?.path} alt='' />
                    </div>
                  ))}
                </div>
              </div>
              <div className='xl:w-5/12 w-full xl:mt-0 mt-4 flex flex-col h-max' style={{ background: 'linear-gradient(180deg,#fff,#f0f1f1)', boxShadow: '20px 20px 30px -5px rgba(35,39,42,.15)!important' }}>
                <div className='md:px-7 md:py-7 py-6'>
                  <div className='console md:text-3xl text-2xl font-black leading-none' style={{ color: '#4c4c4c' }}>{detail?.name}</div>
                  <div className='md:text-xl text-xs md:mt-0 mt-2 uppercase' style={{ color: '#666' }}>{detail?.console}</div>
                  <div className='text-end uppercase text-sm' style={{ color: '#999' }}>{detail?.info_console}</div>
                  <div className='py-6 leading-relaxed' style={{ color: '#191919' }}>{detail?.description}</div>
                  {/* {detail?.related_consoles?.length > 0 &&
                    <div className='' style={{ color: '#666' }}>Имеющиеся в наличии консоли/</div>
                  }
                  <div className='flex overflow-x-auto my-4'>
                    {detail?.related_consoles?.map(item => (
                      <HtmlTooltip
                        key={item.id}
                        arrow
                        placement='top'
                        title={
                        <>
                        <Typography color="#e2e2e2" className='!text-2xl'>{item?.name}</Typography>
                          <b className='!text-sm'>{item?.description}</b>
                        </>
                        }>
                          <div onClick={() => navigate(`/cardio/${item?.name}`)} className='py-2 mx-4 border-t-2 border-black hover:border-red-700'>
                            <img style={{ height: '60px', width: '60px' }} src={item?.console_image} alt='' />
                          </div>
                      </HtmlTooltip>
                    ))}
                  </div> */}
                </div>
                <div className='text-end mt-auto' style={{ background: '#333' }}>
                  <Button onClick={handleOpen} style={{background: '#e1261c'}} className='!text-white md:!px-8 md:!py-10 !px-0 md:!w-auto !w-full !py-6 md:!text-base !text-xs !font-black hover:!bg-red-700'>
                    ЗАПРОСИТЬ КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className='section-accordion py-16'>
        </section>
        {id_2 &&
        <section className='section-335' style={{background: 'linear-gradient(180deg,#000,#464647)'}}>
          <Container maxWidth="xl" className="md:!px-12">
            <div className='flex lg:flex-row flex-col justify-between'>
              <div className='xl:w-2/3 lg:w-3/6 flex'>
                <img src={id_1?.image} alt='' />
              </div>
              <div className='xl:w-2/6 lg:w-3/6 xl:px-12 px-4 xl:py-16 py-6 xl:mb-0 mb-4 lg:px-6 lg:py-10 h-max' style={{background: '#e1261c'}}>
                <div className='text-3xl font-black mb-4' style={{color: '#f5f5f5'}}>{detail?.name}</div>
                <div onClick={() => setCharacterTab(1)} className={`${characterTab === 1 && '!underline !text-white'} font-black px-4 py-2 cursor-pointer hover:underline duration-200`} style={{color: '#ffffff80'}}>МЕТРИЧЕСКИЕ ЕДИНИЦЫ</div>
                <div onClick={() => setCharacterTab(2)} className={`${characterTab === 2 && '!underline !text-white'} font-black px-4 py-2 mb-8 cursor-pointer hover:underline duration-200`} style={{color: '#ffffff80'}}>БРИТАНСКИЕ ЕДИНИЦЫ</div>
                {characterTab === 1 &&
                <>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_1?.title}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ ПОВЕРХНОСТЬ</div> */}
                  </div>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_1?.link}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ ПОВЕРХНОСТЬ</div> */}
                  </div>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_1?.description}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ 1</div> */}
                  </div>
                </>
                }
                {characterTab === 2 &&
                <>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_2?.title}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ ПОВЕРХНОСТЬ</div> */}
                  </div>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_2?.link}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ ПОВЕРХНОСТЬ</div> */}
                  </div>
                  <div className='mb-2'>
                    <div className='text-xl font-bold text-white lowercase' style={{color: '#f9d2d0'}}>{id_2?.description}</div>
                    {/* <div className='uppercase' style={{color: '#f9d2d0', fontSize: '13px'}}>БЕГОВАЯ 1</div> */}
                  </div>
                </>
                }
                <div onClick={() => setPage(2)} className='text-white font-black leading-none hover:underline cursor-pointer mt-8' style={{fontSize: '15px'}}>Дополнительные характеристики /</div>
              </div>
            </div>
          </Container>
        </section>
        }
        {id_3 &&
        <section className='section-4'>
          <div className='grid xl:grid-cols-4 lg:grid-cols-2 grid-detail'>
            <div className='grid-box-detail'>
              <HoverBoxAnimation
                image={id_3?.image}
                title={id_3?.title}
                text={id_3?.description}
              />
            </div>
            <div className='grid-box-detail'>
              <HoverBoxAnimation
                image={id_4?.image}
                title={id_4?.title}
                text={id_4?.description}
              />
            </div>
            <div className='grid-box-detail'>
              <HoverBoxAnimation
                image={id_5?.image}
                title={id_5?.title}
                text={id_5?.description}
              />
            </div>
            <div className='grid-box-detail'>
              <HoverBoxAnimation
                image={id_6?.image}
                title={id_6?.title}
                text={id_6?.description}
              />
            </div>
          </div>
        </section>
        }
        {id_7 &&
        <section className='section-5' style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
          <Container maxWidth="xl" className="md:!px-12 !py-16">
            <CardBoxMoreImage
              title={id_7?.title}
              subtitle={id_7?.description}
              image={id_7?.image}
              contentBgColor="#e1261c"
              contentRight={false}
              contentWidth="25%"
              contentTitleColor="#f5f5f5"
              contentSubtitleColor="#f9d2d0"
            />
            <div className='grid lg:grid-cols-2'>
              {id_8 &&
               <div className='grid lg:grid-rows-2 relative' style={{background: '#c2c6c9'}}>
                  <img className='h-full' src={id_8?.image} alt='' />
                  <div className='px-5 py-16'>
                    <div className='text-3xl mb-5 font-black leading-none' style={{color: '#4c4c4c'}}>{id_8?.title}</div>
                    <p style={{fontSize: '15px', color: '#666'}}>{id_8?.description}</p>
                  </div>
               </div>
              }
              {id_9 &&
               <div className='grid lg:grid-rows-2 relative' style={{background: '#c2c6c9'}}>
                  <img className='h-full' src={id_9?.image} alt='' />
                  <div className='px-5 py-16'>
                    <div className='text-3xl mb-5 font-black leading-none' style={{color: '#4c4c4c'}}>{id_9?.title}</div>
                    <p style={{fontSize: '15px', color: '#666'}}>{id_9?.description}</p>
                  </div>
               </div>
              }
            </div>
          </Container>
        </section>
        }
        {id_10 &&
        <section className='section-35 !bg-cover !bg-center pb-16' style={{background: `url(${id_10?.image}) no-repeat`}}>
          <Container maxWidth="xl" className="md:!px-12 !py-16">
            <div className='xl:w-1/3 bg-black h-full px-8 py-14 mb-16'>
              <div className='text-3xl font-black mb-6 leading-none' style={{color: '#e6e5e5'}}>{id_10?.title}</div>
              <p className='leading-6' style={{color: '#c2c6c9', fontSize: '15px'}}>{id_10?.description}</p>
              <Link to={id_10?.link} className="block font-black leading-none hover:underline mt-4" style={{color: '#ffffffcc'}}>Узнать больше</Link>
            </div>
          </Container>
        </section>
        }
        <Container maxWidth="xl" className="md:!px-12">
          <div className='grid xl:grid-cols-3 lg:grid-cols-2'>
            {id_11 &&
            <div className='md:mt-16 mt-4'>
              <img style={{height: '300px', width: '100%', objectFit: 'cover'}} src={id_11?.image} alt='' />
              <div className='md:px-6 md:py-10 py-4'>
                <div className='text-3xl font-black mb-4 leading-none' style={{color: '#4c4c4c'}}>{id_11?.title}</div>
                <p style={{color: '#666', fontSize: '15px'}}>{id_11?.description}</p>
                {id_11?.link !== null &&
                  <Link to={`${id_11?.link}`} className="block text-red-600 hover:text-red-700 hover:underline font-black mt-6">Узнать больше /</Link>
                }
              </div>
            </div>
            }
            {id_12 &&
            <div className='md:mt-16 mt-4'>
              <img style={{height: '300px', width: '100%', objectFit: 'cover'}} src={id_12?.image} alt='' />
              <div className='md:px-6 md:py-10 py-4'>
                <div className='text-3xl font-black mb-4 leading-none' style={{color: '#4c4c4c'}}>{id_12?.title}</div>
                <p style={{color: '#666', fontSize: '15px'}}>{id_12?.description}</p>
                {id_12?.link !== null &&
                  <Link to={`${id_12?.link}`} className="block text-red-600 hover:text-red-700 hover:underline font-black mt-6">Узнать больше /</Link>
                }
              </div>
            </div>
            }
            {id_13 &&
            <div className='md:mt-16 mt-4'>
              <img style={{height: '300px', width: '100%', objectFit: 'cover'}} src={id_13?.image} alt='' />
              <div className='md:px-6 md:py-10 py-4'>
                <div className='text-3xl font-black mb-4 leading-none' style={{color: '#4c4c4c'}}>{id_13?.title}</div>
                <p style={{color: '#666', fontSize: '15px'}}>{id_13?.description}</p>
                {id_13?.link !== null &&
                  <Link to={`${id_13?.link}`} className="block text-red-600 hover:text-red-700 hover:underline font-black mt-6">Узнать больше /</Link>
                }
              </div>
            </div>
            }
          </div>
        </Container>
        <section className='section-3'>
          <Container maxWidth="xl" className="md:!px-12 !py-12">
            <div className='grid xl:grid-cols-2'>
              {id_14 &&
               <div className='relative grid xl:grid-rows-2'>
                  <img src={id_14?.image} className="h-full" alt='' />
                  <div className='px-5 py-16' style={{height: '-webkit-fill-available', background: '#e1261c'}}>
                    <div className='text-3xl mb-5 font-black' style={{color: '#e6e5e5'}}>{id_14?.title}</div>
                    <p style={{fontSize: '15px', color: '#c2c6c9'}}>{id_14?.description}</p>
                  </div>
               </div>
              }
              {id_15 &&
               <div className='relative grid xl:grid-rows-2'>
                  <img src={id_15?.image} className="h-full" alt='' />
                  <div className='px-5 py-16' style={{height: '-webkit-fill-available', background: '#e1261c'}}>
                    <div className='text-3xl mb-5 font-black' style={{color: '#e6e5e5'}}>{id_15?.title}</div>
                    <p style={{fontSize: '15px', color: '#c2c6c9'}}>{id_15?.description}</p>
                  </div>
               </div>
              }
            </div>
          </Container>
        </section>
        <section className='section-7 !bg-cover !bg-center' style={{ background: 'url(https://images.jhtassets.com/361f1d1e4e0a9f7baa07356a32abc93ee459775a/) no-repeat' }}>
          <CenterContentBox
              title="Добейтесь большего с Matrix"
              subtitle='Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня.'
              titleColor="#4c4c4c"
              subtitleColor="#191919"
              link='Обратная связь'
              linkTo='/contact-us'
          />
        </section>
        {detail?.related_products?.length > 0 &&
        <Container className="md:!px-16">
          <div className='text-3xl font-black text-center leading-none' style={{color: '#4c4c4c'}}>Сопутствующая продукция</div>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-4 py-12'>
            {detail?.related_products?.map(item => (
              <ProductItem
                title={item?.name}
                img={item?.image}
                link={`/group-training/${item?.id}`}
                bgColor='#fff'
              />
            ))}
          </div>
        </Container>
        }
      </>
      }
      {page === 2 &&
      <section className='section-character relative'>
        <img className='absolute left-0 right-0 top-0 w-full h-auto' style={{zIndex: '-1'}} src='https://images.jhtassets.com/eea04484145f263d71a6ead1fa69fb7e98e4a8fc' alt='' />
        <Container maxWidth="xl" className="lg:!px-16 !pt-12">
          <div className='grid lg:grid-cols-3 gap-4'>
            <div className='lg:col-span-2'>
              <div className='md:text-4xl text-2xl font-black' style={{color: '#4c4c4c'}}>{detail?.name}</div>
              <div className='uppercase text-xl mb-4 mt-2'>{detail?.info_console}</div>
            </div>
            <div className=''>
              <div className='flex items-center'>
                <img src='https://images.jhtassets.com/3d714565d36c525b9f44e2f3037671140b7f65f7/' alt='' />
                <a href={`${detail?.document}`} target="_blank" className='font-bold text-red-600 hover:text-red-700 hover:underline cursor-pointer ml-4'>Брошюра продукта</a>
              </div>
            </div>
          </div>
        </Container>
        <Container maxWidth="xl" className="lg:!px-16 !pb-12">
          {detail?.characteristics?.map(item => (
            <div key={item?.id} className={`grid lg:grid-cols-4 gap-12 border-b pt-12`} style={{borderColor: '#333'}}>
              <div>
              <div className='md:text-3xl text-xl font-bold leading-none' style={{color: "#4c4c4c"}}>{item?.name}</div>
              </div>
              <div className='lg:col-span-3'>
                {item?.subCharacteristics?.map(sub => (
                  <div key={sub?.id} className='grid md:grid-cols-3 md:gap-12'>
                    <div className='uppercase md:pb-12 pb-2 font-bold leading-6'>{sub?.name}</div>
                    <div className='md:col-span-2 leading-6 md:pb-12 pb-6'>{sub?.infoCharactiristic?.info}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Container>
      </section>
      }
    </section>
  )
}

export default GroupDetail;