import React, { useState } from 'react';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import HoverBoxAnimation from '../components/HoverBoxAnimation';
import { IconButton } from '@mui/material';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/e3c32bf568ab1ba3634b60cc58fa797ce81da16e/',
            },
            {
                image: 'https://images.jhtassets.com/59ed07d7a97bef4167f9742ad0ebc7446b510841/',
            },
            {
                image: 'https://images.jhtassets.com/1f5849e7959d5c056d6ec85541fb6862921a89da/',
            },
            {
                image: 'https://images.jhtassets.com/bf093300923913dc81b6ffe5b5cadbafffcaaefa/',
            },
            {
                image: 'https://images.jhtassets.com/f850b5fb6e4e4354a7d1e49c8a5cc63cac01922c/',
            },
        ]
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/3286cceb20967c4fca5bbc9e0c4ed7005ecf3f6c/',
            },
            {
                image: 'https://images.jhtassets.com/4240a39726c5c2305c1a9356118c877673c4b306/',
            },
            {
                image: 'https://images.jhtassets.com/25088a8202994813381009021a4b273e4eb27823/',
            },
            {
                image: 'https://images.jhtassets.com/a1c994674befb6548c995d73bbbde16a083c0ef9/',
            },
            {
                image: 'https://images.jhtassets.com/e4e8ab3ef8595ca0ee8bedb52961f2cd6eeee709/',
            },
        ]
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/cce615655270571d142fdc15eb20029150cdfe9d/',
            },
            {
                image: 'https://images.jhtassets.com/a276b306640c9809c316b94e8ca3ae608cc116b9/',
            },
            {
                image: 'https://images.jhtassets.com/78854aaa718aeedfb0856c4463c3bfe5b0b58a4f/',
            },
            {
                image: 'https://images.jhtassets.com/034baccbb7e8b5d536dd15f041afb9545d7ea4d6/',
            },
            {
                image: 'https://images.jhtassets.com/29e81a059b4405e7aa41abab2977cc1497527015/',
            },
        ]
    },
]

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/e3c32bf568ab1ba3634b60cc58fa797ce81da16e/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'World Class ЛЕНИНСКИЙ',
        name: 'РОССИЯ',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/3286cceb20967c4fca5bbc9e0c4ed7005ecf3f6c/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'World Class АМИНЬЕВСКИЙ',
        name: 'РОССИЯ',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/cce615655270571d142fdc15eb20029150cdfe9d/transformed/h_558,w_440,c_fill',
        title: 'MOCKBA',
        subtitle: 'Ohana Fitness',
        name: 'РОССИЯ',
    },
]

const HealthClubs = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='healthClubs-page'>
        <section className='section-1 flex items-center justify-center !bg-cover !bg-center' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)', shapeOutside: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/edde7f631861df38203a7eb27cc22f751753bd12/) no-repeat'}}>
            <Container maxWidth="xl" className="md:!px-12">
                <div style={{marginTop: '-6rem'}}>
                    <div className='font-bold text-lg mb-4'>Получите больше с Matrix</div>
                    <div className='text-black text-3xl lg:text-5xl leading-none'>ФИТНЕС-ЦЕНТРЫ</div>
                </div>
            </Container>
        </section>
        <section className='section-2' style={{ marginTop: '-70px' }}>
            <div className='grid lg:grid-cols-2 gap-8'>
                <div style={{ clipPath: 'polygon(0 10%,100% 5%,100% 100%,0 100%)' }}>
                    <img className='w-full' src='https://images.jhtassets.com/caed4a3d543fc266b97da6d41b96d2efd7ccb340/transformed/w_650,h_1000,c_fill' alt='' />
                </div>
                <div className='flex items-center pl-12 py-4 !bg-cover bg-center' style={{ background: 'url(https://images.jhtassets.com/a64fb25c02ff313491baf1ecd961e372600fcefa/transformed/w_650,h_1000,c_fill) no-repeat', clipPath: 'polygon(0 5%,100% 0,100% 100%,0 100%)'}}>
                    <p className='leading-9 w-11/12 text-base' style={{color: '#191919'}}>Компания Matrix представляет оборудование для силовых нагрузок и кардиоборудование, отличающееся отличным дизайном, высокой эффективностью, долговечностью, простотой обслуживания и возможностями сетевого взаимодействия. Это достаточно много для тренировочного оборудования, но мы подходим к нашему делу именно так. В Компании Matrix привыкли превосходить ожидания, даже когда они действительно высоки. Кроме того, наши комплексные технологии вносят в тренировочный процесс бесконечный набор развлекательных возможностей, а решения в области техобслуживания позволяют вам максимально повысить качество обслуживания клиентов и рентабельность инвестиций.</p>
                </div>
            </div>
        </section>
        <section className='section-3'>
            <Container maxWidth="xl" className="md:!px-16">
                <Link to=''><div className='text-center mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>Больше, чем лучшее в мире оборудование для фитнеса</div></Link>
                <p className='leading-7 mb-4 text-center md:w-3/4 mx-auto' style={{color: '#191919'}}>Когда вы выбираете Matrix, вы получаете больше, чем лучшее в мире оборудования для фитнеса. Вы получаете партнера, который будет присутствовать на вашем торжественном открытии и каждый день после него. Нет ни одной детали вашего бизнеса, которую мы не могли бы усовершенствовать, переосмыслить или воссоздать заново. Результаты не заставят себя ждать.</p>
                <Link to='' className='block w-max mx-auto mb-12 text-red-600 font-bold hover:uppercase hover:text-red-700'>Узнать больше /</Link>
                <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-6 pb-5 md:pb-16 lg:px-12'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/946a62d49fdf1a6aa48a7634ce50c5322d57fe55/'
                        title='Планирование помещений'
                        text='Ваше пространство. Ваши цели. Ваш бюджет. От 2D-макетов до подробных 3D-рендеров и далее, мы поможем воплотить в жизнь ваш фитнес-центр от первых идей до того дня, когда вы откроете свои двери. '
                        link='Смотреть планирование тренажерного зала /'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/fae78659cc238603657bfc6c08832a82e4307586/'
                        title='Сертифицированный монтажный/сервисный комплект'
                        text='Увеличьте время безотказной работы. Оптимизируйте свои инвестиции. Мы приведем ваш тренажерный зал в состояние готовности в соответствии с вашим графиком и предоставим вам все инструменты, необходимые для поддержания в таком состоянии.'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/8dc92b0d19bacd8773d85a417d896cb8d8d43eb4/'
                        title='Продвижение и маркетинговая поддержка'
                        text='Мы предлагаем настраиваемые экраны для демонстрации промо-акций и обмена сообщениями внутри бренда, а также комплексные информационные наборы, включающие плакаты, баннеры, цифровые активы, приглашения на демонстрационные занятия, примеры решения задач и многое другое.'
                        link='Learn more /'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/93c8c8a5d26aa957e9c8e989fb756b9ca9fb01a9/'
                        title='Matrix Learning'
                        text='Обучение работе с продуктами. Советы по техническому обслуживанию. Построение карьеры. Обучающие видео по запросу. Целый новый мир услуг и поддержки. Все это у вас под рукой с Matrix Learning. '
                        link='Смотреть Matrix Learning /'
                    />
                </div>
            </Container>
        </section>
        <section className='section-4 !bg-cover !bg-bottom pb-16' style={{ background: 'url(https://images.jhtassets.com/7d2ad05aa65fa674a327139edfcad19858abc683/) no-repeat' }}>
            <Container maxWidth='xl' className='md:!px-16'>
                <div className='grid lg:grid-cols-3 gap-5 py-16'>
                    <div className='md:col-span-2'>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/abcbeaf93a4f4b0e01df77181fc590f36a0a155f/' alt='' />
                                <div className='pr-4 pt-4 px-2'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Asset Management</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Asset Management (управление активами) отличается повышенной гибкостью, расширенными возможностями отчетности и автоматическими уведомлениями, что делает поддержание всего вашего оборудования в идеальном состоянии проще, чем когда-либо прежде.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Asset Management /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/4164f0c394f11924998985a7ca5a2f4766387677/' alt='' />
                                <div className='pr-4 pt-4 px-2'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Community 360</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Единое решение, которое объединяет все фитнес-данные ваших пользователей в одном месте, Community 360 — это настраиваемое решение, которое ставит ваш бренд в центр фитнес-целей ваших пользователей.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Community 360 /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/6dae57ccded77755c1014ffe0213fdb0b114b65a/' alt='' />
                                <div className='pr-4 pt-4 px-2'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Engage 360</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Помогите тренерам установить контакт и взаимодействовать с участниками с помощью портала Engage 360 — они могут назначать тренировки, проверять прогресс и общаться практически через любое подключенное к интернету устройство, чтобы помочь участникам не выбиться из графика и достигать своих индивидуальных целей.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Asset Management /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/8325ff15bd3c29249c84df44c8bf94d19d421b97/' alt='' />
                                <div className='pr-4 pt-4 px-2'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Sprint 8</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Matrix — это единственные коммерческие кардиотренажеры с научно обоснованной программой Sprint 8®, высокоинтенсивной интервальной тренировкой (HIIT), которая сжигает больше жира, наращивает больше мышц и лучше развивает физическую форму всего за 20 минут, три раза в неделю.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Sprint 8 /</Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-3xl md:text-5xl text-white leading-none'>БОЛЬШЕ, ЧЕМ ПРИЛОЖЕНИЕ ДЛЯ ФИТНЕСА</div>
                        <p className='text-white leading-6 my-4'>Вы можете рассчитывать на наши простые в использовании интегрированные технологические решения, чтобы ваш фитнес-центр выделялся среди остальных. Наш постоянно расширяющийся портфель предложений поможет вам мотивировать участников с тем, чтобы они возвращались снова и снова, стремясь добиться большего, учить их добиваться своих целей с большей пользой, чем когда-либо прежде, и обслуживать оборудование, чтобы максимально увеличить время безотказной работы и экономический эффект.</p>
                        <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Узнать больше /</Link>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-5'>
            <Container maxWidth="xl" className="md:!px-16">
                <Link to=''><div className='text-center text-3xl font-black leading-none' style={{color: '#4c4c4c'}}>Больше, чем Boot Camp Basics</div></Link>
                <p style={{color: '#191919'}} className='leading-6 text-center md:w-4/5 mx-auto my-4'>Если вы хотите зарабатывать и удерживать новых участников с более высокой оплатой, сделать это помогут фирменные программы от Matrix. Наши оригинальные варианты тренировок под ключ и уникальные протоколы упражнений разработаны фитнес-экспертами и могут быть без труда адаптированы. чтобы удовлетворить широкий спектр индивидуальных потребностей участников. Мы также предлагаем аккредитованные курсы и расширенные тренировки, чтобы дать вашей организации еще больше возможностей для дифференциации. Когда вы начнете предлагать наши фирменные программы у себя, участники не просто вернутся за большим — они приведут друзей.</p>
                <Link to='' className='block text-center text-red-600 hover:text-red-700 font-bold'>Узнать больше /</Link>
                <div className='grid md:grid-cols-2 gap-5 my-16'>
                    <HoverBoxAnimation
                        image="https://images.jhtassets.com/eceb85d543b90bf2c1c5cfdb8c01ed5e62e89c97/"
                        title="Система тренинга MX4"
                        text='Оригинальная программа MX4 решает многие аспекты пригодности фитнеса — упражнения для сердечно-сосудистой системы, мощь, сила и выносливость — для полного опыта тренировки. Вы можете изменять программу под любые уровни подготовки, это поможет вам охватить больше участников, чем традиционные групповые тренировки. '
                        link='Смотреть систему тренинга MX4 /'
                    />
                    <HoverBoxAnimation
                        image="https://images.jhtassets.com/fd63a54eba4ec8c2586a7d9a107b19620f26af19/"
                        title='Новый яркий стимул для групповых велотренировок'
                        text='Оживите велотренировки благодаря точно спроектированной конструкции с полностью оригинальными программами. Яркие цвета, измеряющие усилия, помогут каждому упорно следовать своим целям, независимо от измеряемых показателей: ватты, частота пульса, количество оборотов в минуту, дистанция или калории. '
                        link='Обзор велотренажера для целевой тренировки CXP /'
                    />
                </div>
                <div className='font-black text-center text-3xl' style={{ color: '#4c4c4c' }}>Больше, чем репутация</div>
                <p className='md:w-4/5 text-center mx-auto leading-6 mt-4' style={{color: '#191919'}}>Компания Matrix гордится тем, что ее оборудование установлено в самых лучших фитнес-центрах по всему миру. Это лишь небольшая подборка организаций, с которыми мы сотрудничаем, и список которых растет с каждым днем. Наша глобальная сеть продаж позволяет нам обслуживать клиентов в густонаселенных городах, на отдаленных островах и в любом другом месте.</p>
                <div className='grid sm:grid-cols-3 gap-6 mt-16 mb-12'>
                    {boxes?.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                        <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                            <MdPhotoLibrary fill='#4f8493' size={32} />
                        </IconButton>
                        <img className='h-full w-full' src={box.image} alt='' />
                        <div className='center-box-absolute p-2'>
                            <div className='center-box-title'>{box.title}</div>
                            <div className='center-box-subtitle'>{box.subtitle}</div>
                            <div className='center-box-name'>{box.name}</div>
                        </div>
                    </div>
                    ))
                    }
                </div>
            </Container>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
        </section>
        <section className='section-6 !bg-cover' style={{ background: 'url(https://images.jhtassets.com/7c5aa485f01ced96d5bf5e66e1400fb9d4681660) no-repeat' }}>
            <Container maxWidth="xl" className="md:!px-16 !py-16">
                <div className='grid lg:grid-cols-3 gap-8 py-16'>
                    <div className='col-span-2'>
                        <Link to=''>
                            <img src='https://images.jhtassets.com/e5617024c8471387d45fc195dac90132fff9d8f9/' alt='' />
                            <div className='font-bold text-center text-xl' style={{color: '#4c4c4c'}}>Performance Беговая Дорожка С Сенсорной Консолью XL</div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-3xl md:text-6xl leading-none' style={{color: '#4c4c4c'}}>ЛУЧШИЕ ИЗ ЛУЧШИХ</div>
                        <p className='my-4' style={{color: '#191919'}}>Откройте для себя некоторые из наших самых популярных конструктивных решений категории «премиум». Членам клуба они нравятся благодаря превосходным впечатлениям, полученным от тренировок, тогда как владельцы клубов, тренеры и специалисты по обслуживанию любят их за легендарную надежность и простоту обслуживания.</p>
                    </div>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-12 my-5" style={{marginTop: '-5%'}}>
            <div className='grid md:grid-cols-2 gap-8'>
                <Link to='' className='grid md:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/8b69e0a2207ec6fdb320e3abb7cee7d64062aea8/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Performance Эллипс С Изменяемой Траекторией Шага С Сенсорной Консолью</div>
                </Link>
                <Link to='' className='grid md:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/daaa8ba6146347e2622ff2ba53cb7723fd3efaed/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Performance Лестница-Эскалатор С Сенсорной Консолью XL</div>
                </Link>
            </div>
        </Container>
    </section>
  )
}

export default HealthClubs;