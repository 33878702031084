import { Box, Container } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'

const GroupTraining = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className='relative md:flex-row flex-col items-end justify-center z-10 main-media-height' style={{
				height: '550px'
			}}>
				<img
					src="https://images.jhtassets.com/669a879df4f6902821625729927a9c5fab2667fc/"
					className='md:absolute top-0 left-0 w-full h-full object-cover'
					alt=""
				/>
				<p className="text-5xl relative z-10 md:pb-12 md:mt-0 mt-2 md:text-white">ОБЗОР ГРУППОВЫХ ТРЕНИРОВОК</p>
			</section>
			<Container>
				<p className="max-w-3xl leading-6 py-12" style={{color: '#333'}}>Все серии нашего оборудования для силовых тренировок по-своему уникальны, и выбор оптимального варианта для вашего бизнеса поможет вам намного эффективнее привлекать и удерживать клиентов. Серия Matrix для силовых тренировок — это мощные конструкции, бесконечные возможности для настройки, стильный дизайн, непревзойденная долговечность и многое другое. Все это можно свободно комбинировать для идеального воплощения ваших фитнес-идей.</p>
			</Container>
			<BgImageSection
				sectionHeight="700px"
				img="https://images.jhtassets.com/d076c73d319a2175b9eb4851bdf0fbf7415d8065/"
				contentWidth="450px"
				contentRight={false}
				contentBg="#e1261c"
				title="Кардио"
				titleColor="#f5f5f5"
				text="Пытаетесь ли вы включить в свое расписание интересные тренировки или же хотите расширить ассортимент кардиотренажеров — наши решения для групповых тренировок превратят каждое занятие в яркое переживание. Благодаря нашим эксклюзивным программам и оборудованию ваши клиенты получат уникальный опыт, который заставит их возвращаться к вам снова и снова."
				textColor="#f9d2d0"
				link="Узнайте больше о групповых тренировках: кардио"
				linkTo="/group-training/cardio"
			/>
			<section style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='pt-10 pb-10' maxWidth="xl">
					<CardBoxMoreImage
						title="Функционально-силовые тренировки"
						subtitle="Поддерживайте мотивацию своих клиентов с помощью решений для групповых силовых тренировок, сочетающих в себе дух товарищества и соперничества. В основе всего — регулируемый гибкий дизайн нашего эксклюзивного продукта Connexus. Это уникальный комплекс для функциональных тренировок, который удовлетворит потребности клиентов с любым уровнем подготовки."
						link="Узнайте больше о групповых тренировках: функционально-силовые тренировки"
						image="https://images.jhtassets.com/0c346c2c4461791d12124e420d2531bc68f149f8/"
						contentBgColor="linear-gradient(180deg,#fff,#f0f1f1)"
						contentRight={true}
						contentWidth="33%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						linkTo="/group-training/functional-strength"
					/>
				</Container>
				<Box className='relative'>
					<CenterContentBox
						title="Ознакомьтесь с полным ассортиментом оборудования для групповых тренировок"
						subtitle="Здесь вы найдете лучшее в мире оборудование для групповых тренировок, которое используют все: и фитнес-бутики, и учебные центры, и самые крупные фитнес-клубы."
						titleColor="#e6e5e5"
						subtitleColor="#c2c6c9"
					/>
					<img
						src="https://images.jhtassets.com/8792ec3a1f23fc4b1eaaf8cea176f5f5ae931856/"
						alt=""
						className='absolute top-0 left-0 z-10 w-full h-full'
					/>
				</Box>
			</section>
			<section className='py-14'>
				<h3 className='py-4 text-zinc-600 font-black text-3xl text-center'>Создание программ и обучение</h3>
				<Box className='grid lg:grid-cols-3 gap-3 p-4 lg:p-14'>
					<CardBox
						title="Matrix Ride"
						text="Узнайте, как яркие цвета в CXP Target Training Cycle и нашей эксклюзивной программе целевых тренировок помогают спортсменам поддерживать ритм в ходе энергичных и интенсивных тренировок на велотренажере с отслеживанием пробега."
						link="Узнать больше /"
						img="https://images.jhtassets.com/ad600527b4c0e8a20c38f1792af27e263b83d728/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
						linkTo="/education/mx4-active"
					/>
					<CardBox
						title="MX4 HIIT"
						text="MX4 сочетает в себе эксклюзивное оборудование Matrix и оригинальные программы «под ключ», что позволит вам предлагать уникальные групповые тренировки, в которые истинные фитнес-энтузиасты будут готовы вкладывать время и средства."
						link="Узнать больше /"
						img="https://images.jhtassets.com/ad600527b4c0e8a20c38f1792af27e263b83d728/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
						linkTo="/education/mx4-active"
					/>
					<CardBox
						title="MX4 Active"
						text="Система MX4 Active сочетает в себе эксклюзивное оборудование Matrix с оригинальными программными решениями, которые позволяют пожилым и малоподвижным взрослым людям выполнять движения в соответствии со своими возможностями. Участники тренировок откроют для себя вдохновляющие, наполняющие уверенностью физические упражнения, которые приносят ощутимый результат и заставляют возвращаться к вам снова и снова."
						link="Узнать больше /"
						img="https://images.jhtassets.com/ad600527b4c0e8a20c38f1792af27e263b83d728/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
						linkTo="/education/mx4-active"
					/>
				</Box>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Свяжитесь с нами"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default GroupTraining