import React, { useState } from 'react';
import '../assets/scss/_support.scss';
import { HiPlusSm } from 'react-icons/hi';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<HiPlusSm size={30} fill='#000' />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const SupportFaq = () => {
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <section className='supportFaq-page'>
        <section className='section-1 flex md:flex-col justify-end !bg-cover !bg-center main-media-height' style={{background: 'url(https://images.jhtassets.com/3f34b158c6a9c8a2d871b4c752d071bda1964ff7/) no-repeat', height: '70vh'}}>
            <Container maxWidth="xl" className='md:!px-12'>
                <div className='md:text-white leading-none md:pt-0 pt-16 text-3xl md:text-6xl pb-16 pt-4'>Часто задаваемые вопросы</div>
            </Container>
        </section>
        <section className='section-2'>
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary className='!px-2 !py-2 md:!px-12 md:!py-6' aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{fontSize: '28px', marginLeft: '10px'}}>КОНСОЛИ</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='md:!p-16 px-2 py-4'>
                    <Typography className='md:!px-10'>
                        <h4>Какие средства следует использовать для очистки консоли?</h4>
                        <p>Если ваша консоль оснащена сенсорным экраном, налейте в бутылку с пульверизатором дистиллированную воду и распылите ее на чистую и сухую мягкую ткань, после чего аккуратно протирайте экран этой тканью до чистого и сухого состояния. Если экран все еще будет грязным, добавьте в бутылку с пульверизатором немного уксуса и повторите процедуру очистки. Если же ваша консоль не оснащена сенсорным экраном, смочите ткань мягким мыльным раствором, после чего аккуратно протирайте экран этой влажной тканью до чистого и сухого состояния.</p>
                        <p>Примечание. Не используйте абразивную ткань, бумажные полотенца и средства для очистки, содержащие спирт и/или аммиак.</p>
                        <h4>Почему консоль не включается?</h4>
                        <p>Консоль может не включаться по многим причинам. Убедитесь в том, что переключатель питания (расположенный внизу в передней части рамы) находится во включенном положении. Проверьте шнур питания и замените его, если он поврежден. Убедитесь в том, что шнур питания подключен и к тренажеру, и к источнику питания. Убедитесь в том, что характеристики шнура питания подходят для оборудования. Проверьте автоматический выключатель и сбросьте его в случае необходимости. Для начального запуска оборудования должно быть достигнуто постоянное минимальное количество оборотов в минуту, поэтому в случае автономного питания оборудования время начального запуска консоли может немного увеличиться. В случае подсоединения дополнительных принадлежностей это время может увеличиться.</p>
                        <h4>Для чего необходим USB-порт?</h4>
                        <p>В зависимости от модели и года выпуска тренажера и вашего устройства USB-порт используется для обновления программного обеспечения консоли и/или заряжания подсоединенного устройства. В консолях без сенсорного экрана (1x, 3x и 5x) USB-порт позволяет обновлять программное обеспечение. В консолях xe с сенсорным экраном USB-порт позволяет обновлять программное обеспечение и заряжать подсоединенное устройство. В консолях xi с сенсорным экраном USB-порт позволяет обновлять программное обеспечение и заряжать подсоединенное устройство. Также благодаря USB-порту пользователи получают возможность доступа к медиаданным совместимого подсоединенного устройства.</p>
                        <h4>Какие функции доступны при подсоединении устройства Apple к консоли с помощью кабеля с 30-контактным разъемом?</h4>
                        <p>С помощью кабеля с 30-контактным разъемом можно заряжать совместимые устройства iPod и iPhone. В консолях xe и xi можно отображать аудио- и видеофайлы с устройств iPod и iPhone на экране консоли и воспроизводить их. При использовании кабеля с 30-контактным разъемом вместе с адаптером некоторые функции (особенно воспроизведение видеофайлов) могут не работать или работать неправильно.</p>
                        <h4>Почему отсутствует звук или же громкость слишком низкая?</h4>
                        <p>Проверьте разъем для наушников на предмет повреждений или следов внешнего воздействия. Вставьте штекер наушников в соответствующий разъем тренажера и подвигайте штекер, проверяя, не улучшился ли звук. Убедитесь в том, что консоль не находится в беззвучном режиме, и увеличьте уровень громкости с помощью кнопки увеличения громкости. Если эти же аудиофайлы доступны и воспроизводятся на другой подобной консоли, может потребоваться заменить разъем для наушников.
Примечание. В случае использования устройства, передающего и принимающего аудиосигнал на частоте 900/863 МГц, проверьте кабели и соединения.</p>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary className='!px-2 !py-2 md:!px-12 md:!py-6' aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{fontSize: '28px', marginLeft: '10px'}}>КОНСОЛИ 7XI</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='md:!p-16 px-2 py-4'>
                    <Typography className='md:!px-10'>
                        <h4>Каким образом можно изменить настройки консоли 7xi в соответствии с потребностями моего тренажерного зала?</h4>
                        <p>В режиме менеджера у вас имеется возможность изменять максимальную длительность тренировки, длительность тренировки по умолчанию, минимальную и максимальную скорость, а также многие другие параметры для пользователей. Для перехода в режим менеджера нажмите на цифровой клавиатуре кнопки в такой последовательности: ENTER-1-0-0-1-ENTER. После этого выберите вкладку Workout (Тренировка) или Setup Default (Настройки по умолчанию), чтобы изменить параметры в соответствии с вашими потребностями.</p>
                        <h4>Могут ли пользователи публиковать данные о своих тренировках в Facebook или Twitter?</h4>
                        <p>Да! Более того, опубликованные с консоли 7xi записи можно даже редактировать. Для этого перейдите в режим менеджера, откройте вкладку General (Общее) и нажимайте стрелку, пока не дойдете до страницы Social Media (Социальные сети). Здесь можно изменить запись, добавив хэштег или ссылку на сайт вашего заведения, благодаря чему можно будет сообщить необходимую информацию широкому кругу людей.</p>
                        <h4>Почему посетители моего тренажерного зала должны регистрироваться как пользователи, а не просто нажимать кнопку использования тренажера в качестве гостя?</h4>
                        <p>Регистрация учетной записи пользователя дает посетителям вашего зала огромные преимущества. Благодаря регистрации пользователи получают возможность отслеживать текущие тренировки и просматривать данные по предыдущим тренировкам прямо с консоли. Кроме того, после регистрации пользователи могут заходить в свои учетные записи Facebook, Twitter и MyFitnessPal, а также создавать собственные списки воспроизведения музыкальных видеоклипов, что делает тренировки невероятно комфортными и интересными.</p>
                        <h4>В чем разница между проводным и беспроводным вариантами подключения оборудования к Интернету? Какая из них лучше?</h4>
                        <p>Как у проводного, так и у беспроводного вариантов подключения фитнес-оборудования к Интернету имеются свои преимущества. Проводной вариант лучше в тех случаях, когда помещение для тренажерного зала еще не готово и можно внести проводку в планы до того, как будут возведены стены. Также этот вариант хорошо подходит для помещений, где вся необходимая проводка уже была проложена. Если же помещение тренажерного зала уже готово, беспроводной вариант может быть лучше, поскольку в таком случае можно убрать провода с проходов и обеспечить больше возможностей для размещения оборудования в зале.</p>
                        <h4>Как добавить событие в приложение Facility Calendar (Календарь тренажерного зала) на консоли 7xi?</h4>
                        <p>Войдите в систему Asset Management и перейдите к странице вашего заведения. Наведите курсор на раздел Facility Management (Управление тренажерным залом) и выберите View Event Calendar (Просмотреть календарь событий). В этом меню можно добавлять события в календарь тренажерного зала и удалять их оттуда. Все изменения будут сразу же отображаться на консоли 7xi.</p>
                        <h4>Как изменить логотип или приветственное изображение на консоли 7xi?</h4>
                        <p>Войдите в систему Asset Management и перейдите к странице вашего заведения. Наведите курсор на раздел Facility Management (Управление тренажерным залом) и выберите Update Logo (Обновить логотип) или Update Splash Page (Обновить страницу приветствия). В этих меню можно загружать необходимые файлы изображений. Все изменения будут сразу же отображаться на консоли 7xi.</p>
                        <h4>Каким образом пользователи могут создавать собственные доступные по запросу списки воспроизведения музыкальных видеоклипов на консоли 7xi?</h4>
                        <p>Для добавления видеоклипа в список воспроизведения пользователю необходимо нажать кнопку со звездочкой на видеоклипе во время его проигрывания. Также пользователи могут войти на портал Workout Tracking Portal вашего заведения и составить список воспроизведения на вкладке Entertainment (Развлечения).</p>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary className='!px-2 !py-2 md:!px-12 md:!py-6' aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{fontSize: '28px', marginLeft: '10px'}}>COMMUNITY 360</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='md:!p-16 px-2 py-4'>
                    <Typography className='md:!px-10'>
                        <h4>Что делать, если участник потерял информацию для входа в систему (номер xID, пароль)?</h4>
                        <p>Участники должны перейти на портал Workout Tracking Portal вашей организации и нажать кнопку «Забыли пароль» под полем входа. Им будет предложено ввести xID или адрес электронной почты, связанный с учетной записью. При нажатии кнопки «Отправить» будет сгенерирован временный пароль, который будет доставлен на соответствующий адрес электронной почты.</p>
                        <h4>Что делать, если участник не получил подтверждение по электронной почте?</h4>
                        <p>Попросите участника войти в систему со своим xID и паролем и нажмите «повторно отправить письмо с подтверждением».</p>
                        <h4>Что такое xID?</h4>
                        <p>Система идентификации пользователя xID – это универсальный цифровой идентификатор пользователя тренажеров, мобильных приложений и веб-сайтов. Участники могут выбрать 10—14-значный номер для входа (обычно номер телефона, который легче всего запомнить). Для безопасности используется 4–значный цифровой код доступа. Вход в систему позволяет участникам отслеживать и персонализировать тренировки.</p>
                        <h4>Участник зарегистрировался на кардиотренажере перед тренировкой, но не увидел тренировку в своей истории тренировок. Могут ли участники вручную добавить недостающую тренировку?</h4>
                        <p>Участники могут добавлять отсутствующие тренировки в режиме онлайн. Участник просто входит в систему с помощью xID и нажимает ссылку «Недостающая тренировка» на вкладке «Мои тренировки». Он вводит статистику тренировок и предоставляет как можно больше информации о кардиотренажере.
Примечание: в день можно добавить только одну «недостающую» тренировку. Для «ручного ввода» тренировок в день нет ограничений.</p>
                        <h4>Как участник может отслеживать тренировки на силовом или не подключенном кардиотренажере?</h4>
                        <p>Участники могут использовать функцию «Ручной ввод» в разделе «Мои тренировки» и вводить тренировки по дням. Они также могут привязать к учетной записи сторонние приложения и устройства для отслеживания на вкладке «Мой профиль», чтобы автоматически отслеживать тренировки за пределами тренажерного зала.</p>
                        <h4>Будет ли добавлено больше сторонних приложений и устройств?</h4>
                        <p>Да, мы постоянно вносим новые добавления. Сообщите нам ваши любимые, чтобы мы могли добавить их в список.</p>
                        <h4>Можно ли привязать более одного стороннего приложения и устройства?</h4>
                        <p>Да, можно привязать несколько устройств, и все они будут вести отслеживание.</p>
                        <h4>Я не вижу информацию по стороннему приложению на странице «Dashboard». Мои учетные записи привязаны?</h4>
                        <p>Попробуйте выполнить несколько шагов, чтобы убедиться, что ваша учетная запись связана правильно:</p>
                        <p className='ml-4'>1. Войдите и войдите в свою учетную запись стороннего приложения.</p>
                        <p className='ml-4'>2. Выйдите из системы отслеживания тренировок и войдите обратно.</p>
                        <div className='ml-8'>
                            <h4>Что такое вкладка Entertainment?</h4>
                            <p>Если в вашей организации есть кардио-тренажеры, подключенные к интернету, участники могут создавать музыкальные плейлисты, чтобы создавать индивидуальные ощущения во время тренировки.</p>
                            <h4>Могут ли участники смотреть музыкальные видео за пределами тренажерного зала?</h4>
                            <p>Функция музыкального видео в настоящее время доступна только на подключенном к интернету оборудовании в тренажерном зале.</p>
                            <h4>Могут ли участники добавлять песни из MP3-плееров или телефонов в плейлисты?</h4>
                            <p>В настоящее время эта функция недоступна, но в библиотеке музыкальных видео доступно более 4000 музыкальных клипов. Чтобы просмотреть библиотеку и добавить музыкальные клипы в плейлисты, перейдите на вкладку «Entertainment».</p>
                            <h4>Что делать, если участник не хочет, чтобы тренировки выделялись в Dashboard?</h4>
                            <p>Пользователи могут перейти на вкладку «My Profile» и управлять настройками в разделе «Privacy».</p>
                            <h4>Может ли участник участвовать в более чем одном соревновании?</h4>
                            <p>Конечно! Участники могут присоединиться к любому количеству соревнований.</p>
                            <h4>Что делать, если участник переходит в другой тренажерный зал? Будет ли потеряна физическая активность?</h4>
                            <p>Нет, участники не потеряют своей физической активности. xID участников будут перенесены в новый тренажерный зал с тренажерами, совместимыми с xID.</p>
                            <h4>Что делать, если участник не может войти в систему с xID и паролем, даже если они правильные?</h4>
                            <p>После пяти неудачных попыток входа учетная запись будет заблокирована по соображениям безопасности для защиты персональной информации. В этом случае участник получит электронное письмо со ссылкой для разблокировки учетной записи и восстановления доступа.</p>
                        </div>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary className='!px-2 !py-2 md:!px-12 md:!py-6' aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{fontSize: '28px', marginLeft: '10px'}}>ASSET MANAGEMENT</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='md:!p-16 px-2 py-4'>
                    <Typography className='md:!px-10'>
                        <h4>В списке оборудования на странице Facility (Тренажерный зал) отсутствует один из тренажеров. Что мне необходимо сделать?</h4>
                        <p>Скорее всего, это произошло из-за неправильной конфигурации оборудования. Идентификатор вашего тренажерного зала в системе Asset Management должен совпадать с идентификатором клуба, указанным на консоли. Также на консоли необходимо ввести правильные серийные номера самой консоли и ее рамы. Кроме того, обязательно проверьте состояние сетевого подключения и убедитесь в том, что консоль может успешно подключиться к Интернету.</p>
                        <h4>Что делать, если тренировка проходит на подключенном к системе Asset Management тренажере, но данные о тренировке не отображаются в системе.</h4>
                        <p>Текущая версия системы Asset Management работает не в режиме реального времени. Для отображения новых записей из канала данных тренажера может потребоваться до 30 минут. Если проблема будет возникать снова, обратитесь за помощью непосредственно на странице Equipment (Оборудование).</p>
                        <h4>Тренажеру было назначено имя, состоящее из букв и цифр, но в списке оборудования тренажер находится не на том месте, на котором должен находиться в результате сортировки. Что мне необходимо сделать?</h4>
                        <p>Компьютеры сортируют файлы в соответствии с логическими правилами, а значения — в соответствии с их позицией в строке (например, A1, A10, A11, A12, A2 или A01, A02, A03, A04, A05, A06, A07, A08, A09, A10). При назначении буквенно-числовых имен тренажерам (например, A03 или B10) обязательно ставьте 0 перед цифрами (с 1 по 9).</p>
                        <h4>Каким образом можно создать отчет по использованию оборудования в тренажерном зале?</h4>
                        <p>В текущей версии системы нет уже готовой функции для этого. Однако вы можете выбрать все столбцы и строки в списке оборудования, скопировать их и вставить в электронную таблицу для более подробного анализа.</p>
                        <h4>Один из тренажеров выдал сообщение об ошибке, и его индикатор состояния загорелся красным. Проблема была устранена, но индикатор состояния до сих пор не загорелся зеленым. Как сделать так, чтобы индикатор снова загорелся зеленым?</h4>
                        <p>Если вы устранили проблему после того, как индикатор состояния тренажера загорелся красным, цвет индикатора состояния может измениться на зеленый не сразу. Если проблема не будет устранена в течение более трех дней, обратитесь за помощью непосредственно на странице Equipment (Оборудование).</p>
                        <h4>На диаграмме Average Workouts Per Hour (Среднее время тренировок в течение часа) пиковое использование тренажеров приходится на неправильное время дня. Как можно это исправить?</h4>
                        <p>Эта проблема могла возникнуть из-за неправильного выбора часового пояса на консолях. Убедитесь в том, что на всех тренажерах выбраны правильные настройки часового пояса. Зайдите в систему Asset Management и убедитесь в том, что пиковые периоды отображаются на диаграмме правильно.</p>
                        <h4>Индикатор состояния на одном из тренажеров горит серым. Что это означает?</h4>
                        <p>Такое происходит, когда на странице Equipment (Оборудование) для этого тренажера установлен флажок Online Status (Онлайн-состояние). В случае установки этого флажка индикатор состояния будет гореть серым, если тренажер не передавал данные в систему Asset Management в течение более четырех часов. Просто проведите тренировку на тренажере, и индикатор состояния снова загорится зеленым после того, как сведения о тренировке будут переданы в канал данных тренажера.</p>
                        <h4>На одном из тренажеров постоянно выдается ошибка с кодом 04A0. Как можно это исправить?</h4>
                        <p>Обычно это происходит из-за ввода в консоль неправильной модификации оборудования. Убедитесь в том, что введенная модификация совпадает с модификаци��й, которая указана на прикрепленной к консоли наклейке. Если проблема будет возникать снова, обратитесь за обслуживанием непосредственно на странице Equipment (Оборудование).</p>
                        <h4>Как скрыть в списке оборудования тренажер, который больше не используется в тренажерном зале?</h4>
                        <p>Выберите необходимый тренажер. Перейдите на страницу Equipment (Оборудование) и снимите флажок Active (Активно).</p>
                        <h4>Мне необходимо создать новую учетную запись для сотрудника тренажерного зала. Что мне необходимо сделать?</h4>
                        <p>Позвоните в службу поддержки и попросите о создании новой учетной записи. Сотрудник сможет войти в созданную учетную запись сразу же после звонка.</p>
                        <h4>С каким оборудованием совместима система Asset Management?</h4>
                        <p>Текущая версия системы Asset Management поддерживает кардиотренажеры Matrix серий 5x, 7xe и 7xi.</p>
                        <h4>Данные о восстановленной или полученной в качестве замены консоли не отображаются в системе Asset Management. Что мне необходимо сделать?</h4>
                        <p>Скорее всего, это произошло из-за того, что во время настройки консоли не была установлена статистика использования и системе Asset Management передается значение по умолчанию, то есть ноль. Убедитесь в том, что текущая статистика использования тренажера была введена в полученную в качестве замены консоль. После этого начните тренировку и убедитесь в том, что ваш тренажер появился в списке оборудования.</p>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                </div>
        </section>
    </section>
  )
}

export default SupportFaq;