import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import HoverBoxAnimation from '../components/HoverBoxAnimation';
import { IconButton } from '@mui/material';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/8575b703644f3d74fee03e6bfbd88e040f7d9334/',
            },
            {
                image: 'https://images.jhtassets.com/160dd47938fb975430e137beeda5dcd7c9d4f0e8/',
            },
            {
                image: 'https://images.jhtassets.com/15470a29754aef5f6802a0a6cfe81b80ec02ba0f/',
            },
        ]
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/e67a47de841323a9e2d68b4e40a08dab77d49ebc/',
            },
            {
                image: 'https://images.jhtassets.com/27058680ee7e2f20fb259812a99732d6423694eb/',
            },
            {
                image: 'https://images.jhtassets.com/10e5cdeb079efb79702c4b67ab501832e7ed8229/',
            },
            {
                image: 'https://images.jhtassets.com/f6197c0cf0ea60b17e6e376eca9d77d3de391bbc/',
            },
        ]
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/c4cade8d0499aa7788e55450ad9b7dc0a8577137/',
            },
        ]
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/8575b703644f3d74fee03e6bfbd88e040f7d9334/transformed/h_558,w_440,c_fill',
        title: 'Sumter',
        subtitle: 'Sumter',
        name: 'США',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/e67a47de841323a9e2d68b4e40a08dab77d49ebc/transformed/h_558,w_440,c_fill',
        title: 'Hudson',
        subtitle: 'Hudson YMCA',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/c4cade8d0499aa7788e55450ad9b7dc0a8577137/transformed/h_558,w_440,c_fill',
        title: 'Danville',
        subtitle: 'Danville Family',
        name: 'США',
    },
];

const YmcaJcc = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='ymcaJcc-page'>
        <section className='section-1 flex md:flex-row flex-col items-center justify-center !bg-cover !bg-center main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)', shapeOutside: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/106b9659dec63f1cba86d5871b782c9de6623366/) no-repeat'}}>
            <Container maxWidth="xl" className="md:!px-12 md:!py-0 !py-32">
                <div style={{marginTop: '-6rem'}} className="lg:w-2/5 ml-auto md:mr-16">
                    <div className='font-bold text-black text-lg mb-4'>Дайте своему предназначению воплотиться в жизнь</div>
                    <div className='text-black text-3xl lg:text-5xl leading-none'>YMCA</div>
                </div>
            </Container>
        </section>
        <section className='section-2' style={{ marginTop: '-70px' }}>
            <div className='grid lg:grid-cols-2 lg:gap-8'>
                <div style={{ clipPath: 'polygon(0 10%,100% 5%,100% 100%,0 100%)' }}>
                    <img className='w-full' src='https://images.jhtassets.com/194038d1e88b28100312df95fab87c6010cfa835/transformed/w_650,h_1000,c_fill' alt='' />
                </div>
                <div className='flex flex-col justify-center pl-12 py-4 !bg-cover bg-center' style={{ background: 'url(https://images.jhtassets.com/7ec3de5e1526b43be4239e98af34f3403dc49189/transformed/w_650,h_1000,c_fill) no-repeat', clipPath: 'polygon(0 5%,100% 0,100% 100%,0 100%)'}}>
                    <p className='leading-9 w-11/12 mb-8 text-base' style={{color: '#191919'}}>Со всем, что вы делаете, чтобы служить своему сообществу, вы заслуживаете партнера, который так же предан своему делу. Компания Matrix предлагает долговечные тренажеры, доступные технологии и прогрессивные программные решения, соответствующие вашим потребностям и поддерживающие ваши усилия. Вместе мы сможем реализовать ваше предназначение, как никогда прежде.</p>
                </div>
            </div>
        </section>
        <section className='section-3'>
            <Container maxWidth="xl" className="md:!px-12">
                <Link to=''><div className='text-center mt-16 mb-4 lg:px-16 text-3xl lg:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>Ваш партнер в изменении сообщества</div></Link>
                <p className='leading-7 mb-4 text-center w-3/4 mx-auto' style={{color: '#191919'}}>Выберите Matrix, и вы получите гораздо больше, чем фитнес-оборудование премиум класса, которое радует ваших участников с каждой напряженной тренировкой. Вы получите постоянного партнера, готового работать с вами в выполнении вашей задачи, служить вашему сообществу и популяризировать здоровый образ жизни. От проектирования вашего фитнес-центра до установки идеального сочетания продуктов и обучения вашего персонала, компания Matrix стремится работать с вами так, чтобы это имело значение.</p>
                <Link to='' className='block w-max mx-auto mb-12 text-red-600 font-bold hover:uppercase hover:text-red-700'>Узнать больше /</Link>
                <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-6 pb-16 lg:px-16'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/946a62d49fdf1a6aa48a7634ce50c5322d57fe55/'
                        title='Планирование помещений'
                        text='Ваше пространство. Ваши цели. Ваш бюджет. От 2D-макетов до подробных 3D-рендеров и далее, мы поможем воплотить в жизнь ваш фитнес-центр от первых идей до того дня, когда вы откроете свои двери. '
                        link='Смотреть планирование тренажерного зала /'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/fae78659cc238603657bfc6c08832a82e4307586/'
                        title='Сертифицированный монтажный/сервисный комплект'
                        text='Мы приведем ваш тренажерный зал в состояние готовности в соответствии с вашим графиком. Мы также предоставим вам все необходимые инструменты для максимального увеличения времени безотказной работы оборудования, чтобы ваше сообщество было счастливо.'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/8dc92b0d19bacd8773d85a417d896cb8d8d43eb4/'
                        title='Продвижение и маркетинговая поддержка'
                        text='Мы предлагаем настраиваемые экраны для демонстрации промо-акций и обмена сообщениями по заданиям, а также комплексные информационные наборы, включающие плакаты, баннеры, цифровые активы, приглашения на демонстрационные занятия, примеры задач и многое другое.'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/93c8c8a5d26aa957e9c8e989fb756b9ca9fb01a9/'
                        title='Matrix Learning'
                        text='В Matrix Learning вы найдете материалы для обучения работе с продуктами, советы по техническому обслуживанию, образовательные видеоролики и многое другое. Мы предлагаем целый новый мир услуг и поддержки, чтобы помочь вам популяризировать здоровый образ жизни среди своего сообщества. '
                        link='Смотреть Matrix Learning /'
                    />
                </div>
            </Container>
        </section>
        <section className='section-4 !bg-cover !bg-bottom pb-16' style={{ background: 'url(https://images.jhtassets.com/b3d7fa66775dba0d05419e06ef12627c0d9359e8) no-repeat' }}>
            <Container maxWidth='xl' className='md:!px-16'>
                <div className='grid lg:grid-cols-3 gap-5 py-16'>
                    <div className='lg:col-span-2'>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/abcbeaf93a4f4b0e01df77181fc590f36a0a155f/' alt='' />
                                <div className='pr-4 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Asset Management</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Asset Management (управление активами) отличается повышенной гибкостью, расширенными возможностями отчетности и автоматическими уведомлениями, что позволяет легко поддерживать ваше оборудование в отличном состоянии и радовать ваше сообщество.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Asset Management /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/4164f0c394f11924998985a7ca5a2f4766387677/' alt='' />
                                <div className='pr-4 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Community 360</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Помогите участникам сообщества собирать и получать информацию по тренировкам во всех своих любимых приложениях для фитнеса в одном месте. Они также могут создавать персонализированные цели, участвовать в соревнованиях, болеть за друзей через социальные каналы и многое другое.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Community 360 /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/a90dfe10553c36f5c00f1dea91d17589eacb3089/' alt='' />
                                <div className='pr-4 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Engage 360</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Подключайтесь и взаимодействуйте с вашим сообществом с помощью нашего Engage 360 — вы можете назначать тренировки, проверять прогресс и общаться практически через любое подключенное к интернету устройство, чтобы помочь участникам не выбиться из графика и достигать своих индивидуальных целей.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Asset Management /</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='grid md:grid-cols-2 gap-6 mt-4' style={{background: '#fafafacc'}}>
                                <img src='https://images.jhtassets.com/c56f13b37d7f93ee75b24f146ae64fa435c2ea2c/' alt='' />
                                <div className='pr-4 pt-4'>
                                    <div className='font-bold text-2xl mb-4' style={{color: '#4c4c4c'}}>Sprint 8</div>
                                    <p className='leading-7 mb-2' style={{color: '#191919'}}>Предложите участникам своего сообщества прогрессивные результаты с помощью научно обоснованной программы, которая использует высокоинтенсивные интервальные тренировки, чтобы стимулировать реакцию гормона роста, сжигая жир и наращивая мышцы так, как не могут сделать обычные тренировки.</p>
                                    <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Смотреть Sprint 8 /</Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-2xl md:text-5xl leading-none' style={{color: '#4c4c4c'}}>ТЕХНОЛОГИЯ, ДОСТУПНАЯ КАЖДОМУ</div>
                        <p className='leading-6 my-4' style={{color: '#191919'}}>Положитесь на наши простые в использовании интегрированные технологические решения, чтобы сделать ваш фитнес-центр местом, куда приходят жители вашей округи. Наш постоянно расширяющийся портфель предложений поможет вам мотивировать участников возвращаться за большим, научить участников сделать достижение целей более полезным, чем когда-либо, и поддерживать оборудование, чтобы все любимые тренажеры вашего сообщества работали как новые.</p>
                        <Link to='' className='block text-red-600 hover:text-red-700 font-bold'>Узнать больше /</Link>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-5'>
            <Container maxWidth='xl' className='md:!px-12'>
                <Link to=''><div className='text-center md:mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>Объедините людей чем-то новым</div></Link>
                <p className='leading-7 mb-4 text-center w-3/4 mx-auto' style={{color: '#191919'}}>Если вы хотите дать нечто особенное, что объединит ваше сообщество, фирменные программы от Matrix выполнят именно то, что вам нужно. Наши оригинальные варианты тренировочных курсов и уникальные протоколы упражнений разработаны фитнес-экспертами и могут быть без труда адаптированы, чтобы удовлетворить широкий спектр индивидуальных потребностей участников. Мы также предлагаем аккредитованные курсы и расширенные тренировки, чтобы сделать ваш центр еще привлекательнее. Благодаря нашим фирменным программам вы сможете дать семье, друзьям и соседям повод собраться вместе в веселой, активной обстановке.</p>
                <Link to='' className='block text-center text-red-600 hover:text-red-700 font-bold'>Узнать больше /</Link>
                <div className='grid md:grid-cols-2 gap-6 my-16'>
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/ce2ef126a45a7f7625ed64b314cc12b960658dfa/'
                        title='Система тренинга MX4'
                        text='Только система MX4&nbsp;сочетает в себе эксклюзивное оборудование Matrix с оригинальными программными решениями «под ключ», чтобы создать уникальный опыт групповых упражнений, который может помочь любому человеку в вашем сообществе улучшить кардиосистему, мощь, силу и выносливость. '
                        link='Узнать больше /'
                    />
                    <HoverBoxAnimation
                        image='https://images.jhtassets.com/fd63a54eba4ec8c2586a7d9a107b19620f26af19/'
                        title='Новый яркий стимул для групповых велотренировок'
                        text='Оживите велотренировки благодаря точно спроектированной конструкции с полностью оригинальными программами. Яркие цвета, измеряющие усилия, помогут каждому упорно следовать своим целям, независимо от измеряемых показателей: ватты, частота пульса, количество оборотов в минуту, дистанция или калории. '
                        link='Подробнее /'
                    />
                </div>
                <div className='text-center md:mt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase' style={{color: '#4c4c4c'}}>MAKING AN IMPACT IN COMMUNITIES LIKE YOURS</div>
                <p className='leading-7 mb-4 text-center w-3/4 mx-auto' style={{color: '#191919'}}>Компания Matrix гордится тем, что ее оборудование установлено в самых разных общественных организациях, таких как ваша, список которых растет с каждым днем. Внушительный портфель продуктов, простые в навигации технологические решения и прочные партнерские отношения позволяют нам удовлетворять практически любые потребности сообщества в спортивной подготовке.</p>
                <div className='grid md:grid-cols-3 gap-6 mt-16 mb-12'>
                    {boxes?.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                        <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                            <MdPhotoLibrary fill='#4f8493' size={32} />
                        </IconButton>
                        <img className='h-full w-full' src={box.image} alt='' />
                        <div className='center-box-absolute p-2'>
                            <div className='center-box-title'>{box.title}</div>
                            <div className='center-box-subtitle'>{box.subtitle}</div>
                            <div className='center-box-name'>{box.name}</div>
                        </div>
                    </div>
                    ))
                    }
                </div>
            </Container>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
        </section>
        <section className='section-6 !bg-cover' style={{ background: 'url(https://images.jhtassets.com/f8fee0d07c67c47ef5c2e9eaf83450625fff69a3) no-repeat' }}>
            <Container maxWidth="xl" className="md:!px-16 !py-16">
                <div className='grid md:grid-cols-3 gap-8 py-16'>
                    <div className='md:col-span-2'>
                        <Link to=''>
                            <img src='https://images.jhtassets.com/134fca1d71553f8604fa191658153f1c6363decb/' alt='' />
                            <div className='font-bold text-center text-xl' style={{color: '#4c4c4c'}}>Performance Беговая Дорожка С Сенсорной Консолью XL</div>
                        </Link>
                    </div>
                    <div>
                        <div className='text-6xl leading-none' style={{color: '#4c4c4c'}}>ЛУЧШИЕ ИЗ ЛУЧШИХ</div>
                        <p className='my-4' style={{color: '#191919'}}>Откройте для себя некоторые премиальные продукты, специально разработанные для укрепления здоровья и удовлетворения потребностей различных сообществ, таких как ваше</p>
                        <Link to=''>Узнать больше</Link>
                    </div>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16" style={{marginTop: '-5%'}}>
            <div className='grid md:grid-cols-2 gap-8 pb-12'>
                <Link to='' className='grid sm:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/8b69e0a2207ec6fdb320e3abb7cee7d64062aea8/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Performance Эллипс С Изменяемой Траекторией Шага С Сенсорной Консолью </div>
                </Link>
                <Link to='' className='grid sm:grid-cols-2 gap-4'>
                    <img src='https://images.jhtassets.com/daaa8ba6146347e2622ff2ba53cb7723fd3efaed/' alt='' />
                    <div className='font-bold m-auto text-xl leading-none' style={{color: '#4c4c4c'}}>Performance Лестница-Эскалатор С Сенсорной Консолью XL</div>
                </Link>
            </div>
        </Container>
    </section>
  )
}

export default YmcaJcc;