import React from 'react'
import { Link } from 'react-router-dom'
import "../assets/scss/_BgImageSection.scss"

const BgImageSection = (props) => {
	return (
		<section
			className='BgImageSection relative grid min-h-max xl:p-12 xl:block !pointer-events-auto'
			data-aos={props.animation === true ? "fade-zoom-in" : ""}
			data-aos-easing={props.animation === true ? "ease-in-back" : ""}
			data-aos-delay={props.animation === true ? "900" : ""}
			data-aos-offset={props.animation === true ? "0" : ""}
			style={{
				height: props.sectionHeight,
				paddingTop: props.paddingTopNull === true ? "0" : ""
			}}
      >
			<img
				className='top-0 left-0 w-full h-full object-cover xl:absolute'
		  		style={{zIndex: '-1'}}
		  		src={props.img}
				alt="Картинка"
			/>
        	<div
		  		className="bg-black px-4 py-8 lg:py-20 h-full z-10 text-white sm:px-8 BgImageSection-content"
		  		style={{
					width: props.contentWidth,
					height: 'max-content',
					background: props.contentBg,
					marginLeft: props.contentRight === true ? 'auto' : ''
				}}
			>

				<h4
					className={'uppercase font-black leading-none text-xl md:text-3xl'}
					style={{color: props.titleColor}}
				>
					{props.title}
				</h4>

				<p
					className='pt-5 pb-2 text-sm leading-6'
					style={{color: props.textColor}}
				>
					{props.text}
				</p>
				{
					props.link ?
						<Link
							to={props.linkTo ? props.linkTo : ''}
							style={{
								color: props.linkColor
							}}
							className={
								props.linkBgRed ?
								"button-red px-4 py-2 inline-block font-bold hover:bg-red-700" :
								"py-2 mt-4 inline-block font-bold hover:underline"
							}
						>
							<span className='text-base'>
								{props.link}
							</span>
						</Link>
						: ''
				}
        </div>
      </section>
	)
}

export default BgImageSection