import React from 'react'
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';

const InnovationsTouchConsole = () => {
  return (
    <section className='innovationsTouchConsole-page'>
        <section className='section-1 !bg-cover !bg-center flex items-end' style={{background: 'url(https://images.jhtassets.com/b54a0fe3268289331c747c6452022e56570b1992/) no-repeat', height: '70vh'}}>
            <Container maxWidth="xl" className="md:!px-16">
                <div className='text-5xl font-bold text-white leading-none mb-16'>СЕНСОРНЫЕ КОНСОЛИ MATRIX</div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='w-2/3 mt-16'>
                <div className='uppercase text-3xl font-black mb-4 leading-none' style={{color: '#4c4c4c'}}>РАЗРАБОТКА БОЛЕЕ СОВЕРШЕННОЙ КОНСОЛИ УВЕЛИЧЕННОГО РАЗМЕРА С БОЛЕЕ ЯРКОЙ ИНДИКАЦИЕЙ</div>
                <p className='font-bold' style={{color: '#333'}}>Несмотря на то, что компания Matrix поставляет сенсорные консоли для фитнес-индустрии на протяжении многих лет, мы решили, что именно начало нового десятилетия является наилучшим моментом для качественного прорыва в технологиях.</p>
            </div>
        </Container>
        <Container>
            <div className='text-center italic text-red-700 font-bold text-2xl leading-tight my-16 py-16'>Как разработать удобную именно для занятий на тренажерах консоль с управлением, схожим с уже привычными интерфейсами приложений для смартфонов, и предусмотреть возможность улучшать ее функционал в дальнейшем путем бесплатного обновления программного обеспечения?</div>
            <div className='text-5xl font-black leading-none text-center' style={{color: '#4c4c4c'}}>ИЗУЧЕНИЕ ОТЗЫВОВ</div>
            <p className='text-center mt-6 mb-12' style={{color: '#191919'}}>Мы начали с посещения фитнес-клубов наших клиентов, чтобы понять, что им нравится в существующих консолях с сенсорным экраном, а что они хотели бы изменить.</p>
        </Container>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='grid grid-cols-3'>
                <div>
                    <img src='https://images.jhtassets.com/9c0bf59632e642d6c1293fdb23eb71e19174b1e3/' alt='' />
                    <div className='px-6 py-16'>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#4c4c4c'}}>Расширение опыта использования</div>
                        <p style={{color: '#666'}}>Хотя сенсорные консоли с яркой индикацией определенно привлекли внимание пользователей, этой технологией пользуются менее 50 % организаций в фитнес-индустрии. В основном это связано с тем, что опыт использования таких консолей не всегда оправдывал ожидания. Пользователям приходилось учиться работать с новым интерфейсом в начале каждого занятия, поэтому многие просто решали нажать кнопку «Старт» и начать базовую тренировку. Таким образом, они не открывали для себя никаких новых возможностей и использовали такие консоли как обычные светодиодные консоли.</p>
                    </div>
                </div>
                <div>
                    <img src='https://images.jhtassets.com/058b27cdd0eff04fa69d7b3d3d3dbba417641938/' alt='' />
                    <div className='px-6 py-16'>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#4c4c4c'}}>Решение проблем устойчивости, защиты от пота и сложности обслуживания</div>
                        <p style={{color: '#666'}}>У существующих сенсорных консолей имеются такие недостатки, как плохая устойчивость и недостаточная защита от пота, из-за чего информация часто плохо считывается, а влажность может привести к повреждению как внешних элементов управления, так и внутренних компонентов оборудования. Кроме того, специалисты по техническому обслуживанию говорят о том, что им сложно в одиночку смонтировать сенсорные консоли и что для этого требуется помощь специалистов. Наконец, такие консоли было сложно обслуживать без снятия с кардиотренажера и разборки, что приводило к значительному простою.</p>
                    </div>
                </div>
                <div>
                    <img src='https://images.jhtassets.com/a1fb4db1332a1756070c6edd0beab94505c8ec6f/' alt='' />
                    <div className='px-6 py-16'>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#4c4c4c'}}>Улучшение внешнего вида</div>
                        <p style={{color: '#666'}}>Наши клиенты также отмечали, что существующие консоли с сенсорными экранами не обеспечивали соответствующую работу встроенных ТВ-приставок, которые широко используются в Соединенных Штатах для просмотра кабельного телевидения. В фитнес-клубах ТВ-приставки часто приходилось ставить на пол, что мешало проходу между тренажерами, а тянущиеся к приставкам кабели портили внешний вид помещения.</p>
                    </div>
                </div>
            </div>
        </Container>
        <Container>
            <div className='text-5xl font-black leading-none text-center' style={{color: '#4c4c4c'}}>НАШИ ИННОВАЦИИ</div>
            <p className='text-center mt-6 mb-12' style={{color: '#191919'}}>Разумеется, оказалось, что в сенсорных консолях многое нуждалось в улучшениях. Опираясь на чрезвычайно ценную обратную связь от наших клиентов, наши разработчики взялись за дело.</p>
        </Container>
        <section className='section-2 !bg-cover !bg-center' style={{background: 'url(https://images.jhtassets.com/d4eba12741201e0dd09edcbab077fb836adc17d8/) no-repeat'}}>
            <Container maxWidth="xl" className="md:!px-16 !pt-16">
                <div className='w-1/3 bg-black px-8 py-12'>
                    <div className='text-3xl leading-none font-black mb-4' style={{color: '#e6e5e5'}}>Еще более удобное использование во время движения</div>
                    <p style={{color: '#c2c6c9'}}>Мы хотели, чтобы наши сенсорные консоли располагались на оптимальной высоте, расстоянии и под удобным углом, чтобы людям любого роста было удобно ими пользоваться независимо от интенсивности тренировок. Поэтому мы привлекли к тестированию новых консолей людей разного телосложения. Основываясь на полученных результатах, мы отрегулировали работу ИК-датчика и угол наклона консоли таким образом, чтобы экран включался в момент, когда пользователь встает на тренажер. Помимо сведения к минимуму бликов это обеспечило более естественный и удобный угол обзора. Мы даже встроили беспроводную зарядную площадку с резиновыми бортиками, которые безопасно и надежно удерживают устройства пользователей, чтобы их смартфон всегда находился на виду и был легко доступен во время тренировок.</p>
                </div>
            </Container>
        </section>
        <section className='section-3 !bg-cover !bg-center' style={{background: 'url(https://images.jhtassets.com/e2fe38d830fcecb820a5cdc89436662c4a970a9a/) no-repeat'}}>
            <Container maxWidth="xl" className="md:!px-16 !pt-16">
                <div className='w-1/3 ml-auto bg-white px-8 py-12'>
                    <div className='text-3xl leading-none font-black mb-4' style={{color: '#4c4c4c'}}>Еще более удобное использование во время движения</div>
                    <p style={{color: '#666'}}>Мы хотели, чтобы наши сенсорные консоли располагались на оптимальной высоте, расстоянии и под удобным углом, чтобы людям любого роста было удобно ими пользоваться независимо от интенсивности тренировок. Поэтому мы привлекли к тестированию новых консолей людей разного телосложения. Основываясь на полученных результатах, мы отрегулировали работу ИК-датчика и угол наклона консоли таким образом, чтобы экран включался в момент, когда пользователь встает на тренажер. Помимо сведения к минимуму бликов это обеспечило более естественный и удобный угол обзора. Мы даже встроили беспроводную зарядную площадку с резиновыми бортиками, которые безопасно и надежно удерживают устройства пользователей, чтобы их смартфон всегда находился на виду и был легко доступен во время тренировок.</p>
                </div>
            </Container>
        </section>
        <Container maxWidth="xl" className="md:!px-16">
            <div className='grid grid-cols-2 gap-6 mt-16'>
                <div>
                    <img src='https://images.jhtassets.com/a73d014ece32ffd416db65d84c134ec431122069/' alt='' />
                    <div className='px-6 py-16' style={{background: '#e1261c', minHeight: '400px'}}>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#f5f5f5'}}>Решение проблемы технического обслуживания</div>
                        <p style={{color: '#f9d2d0'}}>Наши инженеры разработали систему монтажа консоли, и теперь специалистам по техническому обслуживанию нужно просто установить консоль на раму и надежно затянуть ее болтами, с чем легко может справиться даже один человек. Мы также встроили в заднюю часть сервисную панель для упрощения и ускорения ремонта без необходимости снятия консоли с тренажера. Кроме того, мы свели к минимуму количество канавок и стыков для надежной защиты консоли от пота и облегчения ее очистки.</p>
                    </div>
                </div>
                <div>
                    <img src='https://images.jhtassets.com/a690a08c49f83189dc7387b1f7ae5855d72ab790/' alt='' />
                    <div className='px-6 py-16' style={{background: '#e1261c', minHeight: '400px'}}>
                        <div className='text-3xl font-black leading-none mb-6' style={{color: '#f5f5f5'}}>Отсутствие беспорядочно тянущихся кабелей</div>
                        <p style={{color: '#f9d2d0'}}>Мы разработали принципиально новую заднюю крышку для наших консолей с сенсорными экранами для удобного размещения ТВ-приставок кабельного телевидения. Теперь владельцы фитнес-клубов могут полностью интегрировать приставки в консоли вместо не всегда удобного размещения их рядом с тренажером. К радости владельцев тренажерных залов, эта новая опция устранила неудобства из-за необходимости размещения ТВ-приставок на полу и тянущихся к ним кабелей, что значительно повысило внешнюю привлекательность залов для кардиотренировок.</p>
                    </div>
                </div>
            </div>
        </Container>
        <Container>
            <div className='text-5xl font-black leading-none text-center' style={{color: '#4c4c4c'}}>УДОБНОЕ И ЭФФЕКТИВНОЕ СЕНСОРНОЕ УПРАВЛЕНИЕ</div>
            <p className='text-center mt-6 mb-12' style={{color: '#191919'}}>Наши новые консоли, доступные теперь в типоразмерах Touch XL (56 см/22") и Touch (41 см/16"), являются самыми популярными в коммерческой фитнес-индустрии. Они имеют богатый функционал и великолепный экран с высокой разрешающей способностью, а их устойчивость, удобство обслуживания и эксплуатационная гибкость отвечают уникальным потребностям ваших посетителей и вашего фитнес-центра. Где бы любители фитнеса ни использовали наши сенсорные консоли, мы гордимся тем, что смогли предложить им удобное и эффективное сенсорное управление.</p>
        </Container>
        <section className='section-2 flex items-center justify-center py-16 !bg-cover !bg-bottom' style={{ background: 'url(https://images.jhtassets.com/31456797d73a12bc4b10c0fda0f961a05344a6ad/) no-repeat', minHeight: '70vh' }}>
            <Container>
                <img src='https://images.jhtassets.com/0a2dc44b4b8074a87a090e5fc34b2f7cba8d5c14/' alt='' />
            </Container>
        </section>
        <section className='section-3 py-16' style={{background: '#000'}}>
            <Container>
                <div className='my-10 text-center'>
                    <div className='text-5xl leading-none mb-16 font-black' style={{color: "#e6e5e5"}}>УЗНАЙТЕ БОЛЬШЕ</div>
                    <Link to='' className='inline-block text-white w-max mx-auto bg-red-600 hover:bg-red-700 duration-200 cursor-pointer py-3 px-6 font-black'>Перейти на страницу консоли</Link>
                </div>
            </Container>
        </section>
    </section>
  )
}

export default InnovationsTouchConsole;