import React from "react";
import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { BsPlayCircle } from "react-icons/bs";
import HoverBoxAnimation from "../components/HoverBoxAnimation";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $14 from "../assets/videos/1-14.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const TotalSolutionsPartners = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="totalSolutionsPartners-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$14}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 relative flex flex-col justify-end !bg-cover !bg-center main-media-height"
        style={{ height: "80vh" }}
      >
        <img
          className="md:absolute top-0 right-0 bottom-0 left-0 h-full"
          src="https://images.jhtassets.com/017543ac044dcb99b29a0c39761bb5c5d664980d/"
          alt=""
        />
        <Container>
          <div className="text-3xl lg:text-6xl text-center md:mt-0 mt-2 md:text-white pb-16">
            НАШИ ОБЯЗАТЕЛЬСТВА ПЕРЕД КЛИЕНТАМИ
          </div>
        </Container>
        <Button
          onClick={handleOpen}
          className="group !text-base !font-black !absolute !bottom-0 w-max !mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
          style={{
            color: "#e1261c",
            fontWeight: "bold",
            fontSize: "20px",
            transform: "translate(-50%,50%)",
            left: "50%",
            right: "50%",
          }}
        >
          <BsPlayCircle
            size={32}
            fill="#e1261c"
            className="group-hover:!fill-white duration-200 mr-2"
          />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </section>
      <section className="section-2">
        <Container maxWidth="xl" className="md:!px-12">
          <div
            className="text-2xl font-black leading-none md:mt-5 mt-12 lg:mt-16 mb-5 lg:mb-12"
            style={{ color: "#4c4c4c" }}
          >
            Поставщик комплексных решений
          </div>
          <p style={{ color: "#191919" }}>
            Выбирая компанию Matrix, вы получаете больше, чем просто лучшее в
            мире фитнес-оборудование — вы приобретаете верного партнера, чья
            помощь поможет вам в достижении успеха. Вместе мы сможем изменить,
            усовершенствовать и преобразовать все, что только можно себе
            представить. Включая самые важные для вас аспекты.
          </p>
          <div className="grid md:grid-cols-2 gap-5 py-16 lg:my-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/a4efd544f9d64086df09f3ba0b999ccc6bea8cba/"
              title="Планирование тренажерных залов"
              text="Ваше пространство. Ваши цели. Ваши средства. Мы поможем вам создать собственный бизнес в фитнес-индустрии, воплотив ваши самые первые идеи и оказывая поддержку вплоть до того дня, когда ваш тренажерный клуб откроет двери перед своими посетителями."
              link="Подробнее /"
              linkTo="/total-solutions-partners/facility-planning"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/2b264c7c8bb1f2e7e4036c0b99d42a1e6aef00fa/"
              title="Обучение от компании Matrix"
              text="Обучение работе с продукцией. Советы по обслуживанию. Карьерный рост. Обучающие видеоматериалы по запросу. Абсолютно новый мир обслуживания и поддержки. Благодаря обучению в компании Matrix все эти возможности находятся в вашем распоряжении."
              link="Подробнее /"
              linkTo="/total-solutions-partners/education"
            />
            <div>
              <div className="relative">
                <img
                  src="https://images.jhtassets.com/718211c6c5591529f00822e5fe411a548043d33e/"
                  alt=""
                />
                <div
                  className="absolute bottom-0 left-0 right-0 text-white text-xl font-black p-3 leading-none"
                  style={{ background: "#a5acb2" }}
                >
                  Установка оборудования сертифицированными специалистами и
                  сервисные наборы
                </div>
              </div>
              <div className="px-6 py-8" style={{ color: "#191919" }}>
                Увеличение срока службы оборудования. Максимально увеличьте
                рентабельность своих инвестиций. Мы предоставляем все
                инструменты, необходимые для создания бизнеса, его развития и
                успешного функционирования.
              </div>
            </div>
            <div>
              <div className="relative">
                <img
                  src="https://images.jhtassets.com/446acf350bca4673e4a4ded3e4bd575bacbc42f2/"
                  alt=""
                />
                <div
                  className="absolute bottom-0 left-0 right-0 text-white text-xl font-black p-3 leading-none"
                  style={{ background: "#a5acb2" }}
                >
                  Непревзойденное качество поддержки клиентов
                </div>
              </div>
              <div className="px-6 py-8" style={{ color: "#191919" }}>
                Высочайший показатель результативности после первого
                использования в 90 % случаев. Предоставление обслуживания в
                течение 48 часов после соответствующего запроса. Благодаря
                применению потрясающих высокотехнологичных консолей ми можем
                выполнять диагностику оборудования как удаленно, так и на месте
                установки.
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 gap-4 pb-16">
            <div>
              <img
                style={{ border: "1px solid #333436" }}
                src="https://images.jhtassets.com/b8030f763bfe79f5d58504d80896ea6da9493e85/"
                alt=""
              />
              <div className="p-3" style={{ background: "#eee" }}>
                <div
                  className="font-bold text-xl leading-none"
                  style={{ color: "#4c4c4c" }}
                >
                  Организация специализированных продаж и предоставление
                  поддержки
                </div>
                <p className="my-3" style={{ color: "#191919" }}>
                  Наши внутренние команды будут работать с вами для достижения
                  ваших личных целей. Идет ли речь об организации
                  специализированных продаж, предоставлении технической
                  поддержки клиентам или чем-то другом, — мы всегда готовы
                  помочь вам.
                </p>
              </div>
            </div>
            <div>
              <img
                style={{ border: "1px solid #333436" }}
                src="https://images.jhtassets.com/724b0e43fd69361dc6209b0c179f52590dd3ced1/"
                alt=""
              />
              <div className="p-3" style={{ background: "#eee" }}>
                <div
                  className="font-bold text-xl leading-none"
                  style={{ color: "#4c4c4c" }}
                >
                  Лучшие в отрасли гарантийные условия
                </div>
                <p className="my-3" style={{ color: "#191919" }}>
                  Мы гарантируем вам высочайшее качество. Мы обеспечим вам все
                  необходимые гарантии для того, чтобы вы могли уверенно сделать
                  свой выбор и не разочароваться в нем.
                </p>
              </div>
            </div>
            <div>
              <img
                style={{ border: "1px solid #333436" }}
                src="https://images.jhtassets.com/b7a1fa3b7d483e6ac11c0edc419b63c1574e0680/"
                alt=""
              />
              <div className="p-3" style={{ background: "#eee" }}>
                <div
                  className="font-bold text-xl leading-none"
                  style={{ color: "#4c4c4c" }}
                >
                  Кредитование и лизинг
                </div>
                <p className="my-3" style={{ color: "#191919" }}>
                  Всякий раз, когда это возможно, мы привлекаем наших клиентов
                  для разработки финансовых решений, которые расширяют их
                  возможности и позволяют получать максимальную отдачу от
                  инвестиций.
                </p>
              </div>
            </div>
            <div>
              <img
                style={{ border: "1px solid #333436" }}
                src="https://images.jhtassets.com/cea05ca9391f539c5bacdbf59408bede4328cc7a/"
                alt=""
              />
              <div className="p-3" style={{ background: "#eee" }}>
                <div
                  className="font-bold text-xl leading-none"
                  style={{ color: "#4c4c4c" }}
                >
                  Полный комплект маркетинговых материалов
                </div>
                <p className="my-3" style={{ color: "#191919" }}>
                  Вы хотите, чтобы ваши клиенты знали о потрясающих программах и
                  продуктах, которые позволят выделить ваш тренажерный зал среди
                  конкурентов? Мы можем помочь вам в этом. Обратитесь к нам для
                  получения специализированных рекламных материалов, которые
                  помогут вам привлечь и удержать клиентов эффективнее, чем
                  когда-либо раньше.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default TotalSolutionsPartners;
