import { Container, Button, Box } from "@mui/material";
import React from "react";
import { BsPlayCircle } from "react-icons/bs";
import BgImageSection from "../components/BgImageSection";
import CardBox from "../components/CardBox";
import CenterContentBox from "../components/CenterContentBox";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $4 from "../assets/videos/1-4.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const ConnectedSolutions = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$4}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="flex items-center justify-center relative"
        style={{ height: "100vh" }}
      >
        <img
          src="https://images.jhtassets.com/edc05c2d69b1400cafb70a9a95efb72ba9ca4b47/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container
          className="flex flex-col gap-5 items-center justify-center text-center relative z-10"
          style={{ display: "flex" }}
        >
          <img
            src="https://images.jhtassets.com/91e5ed67afe86f95aa0583ac6e159d1dc2e81066/"
            alt=""
          />
          <Button
            onClick={handleOpen}
            className="mx-auto hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
            style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
          >
            <BsPlayCircle size={32} fill="#fff" className="mr-2" />
            СМОТРЕТЬ ВИДЕО
          </Button>
        </Container>
      </section>
      <section
        className="flex flex-col item-center py-8 relative"
        style={{ background: "linear-gradient(171.76deg,#253746,#000)" }}
      >
        <img
          src="https://images.jhtassets.com/047f62579809003c817e37aba751ca81b6829c7f/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container className="relative z-10">
          <Box className="text-center px-3 md:w-2/3 mx-auto">
            <h2
              className="text-xl md:text-4xl font-black mb-4"
              style={{ color: "#e6e5e5" }}
            >
              СВЯЗЬ. ГИБКОСТЬ. ИНДИВИДУАЛЬНЫЙ ПОДХОД. ЭТО БУДУЩЕЕ ФИТНЕСА.
            </h2>
            <p className="py-8" style={{ color: "#c2c6c9" }}>
              Подход людей к выбору места и способа тренировок изменился,
              поэтому именно сейчас настало лучшее время, чтобы принять решение
              в пользу Connected Solutions. Широкий выбор наших цифровых
              продуктов позволит вам предложить такие программы, которые помогут
              привлекать именно ваших уникальных клиентов новыми впечатляющими
              способами. Даже если клиент не готов вернуться к вам прямо сейчас,
              вы сможете сформировать лояльность, которая будет сохраняться
              долгое время, в течение которого вы продолжите свою миссию по
              мотивации клиентов и достижению поставленных целей.
            </p>
          </Box>
          <Box className="text-center px-3 md:w-2/3 mx-auto">
            <h2
              className="text-4xl font-black mb-4"
              style={{ color: "#e6e5e5" }}
            >
              Ваши цели
            </h2>
            <p className="py-8" style={{ color: "#c2c6c9" }}>
              Ваше собственное представление о фитнесе. Гибкость нашей
              комплексной цифровой экосистемы позволяет вам выбирать свои методы
              улучшения бизнеса, оптимизации административных задач, воздействия
              на людей, с которыми вы работаете, и многое другое.
            </p>
          </Box>
        </Container>
      </section>
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/8afb7933627df4c5e326f4497fb1d9e858a8d133/"
        contentWidth="33%"
        contentRight={false}
        contentBg="#fff"
        title="Побуждение к приобретению и повышение лояльности"
        titleColor="#4c4c4c"
        text="Вы постоянно стремитесь привлечь новых клиентов, и мы можем помочь вам расширить ваш бренд, охватить новые целевые аудитории и произвести впечатление на всех, кто просто заглянул к вам. Желание посетителей возвращаться к вам снова играет ключевую роль в развитии вашего бизнеса. Благодаря нашим интеллектуальным цифровым технологиям они не просто вернутся - они приведут своих друзей."
        textColor="#666"
        link="Узнать больше/"
        linkColor="#e1261c"
        linkTo="/connected-solutions/acquisition-retention"
      />
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/a784bc62c99d5b432ccdaaba286668ca01c3909d/"
        title="Формирование вовлеченности и повышение дифференциации"
        text="Пусть ваши клиенты сохраняют свой энтузиазм, воодушевление и стремление к большему, благодаря набору мощных инструментов, позволяющих делать тренировки интересными и увлекательными. Наши революционные сетевые решения Connected Solutions помогут вашему уникальному объекту сформировать свой собственный бренд и выделиться на фоне конкурентов."
        contentWidth="33%"
        titleColor="#e6e5e5"
        contentRight={true}
        contentBg="#000"
        textColor="#c2c6c9"
        link="Узнать больше/"
        linkColor="#fff"
        linkTo="/connected-solutions/engagement-differentiation"
      />
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/12800b0853b5095293f7baf76dde741964fd7be6/"
        title="ФОРМИРОВАНИЕ ВОВЛЕЧЕННОСТИ И ПОВЫШЕНИЕ ДИФФЕРЕНЦИАЦИИ"
        text="Пусть ваши клиенты сохраняют свой энтузиазм, воодушевление и стремление к большему, благодаря набору мощных инструментов, позволяющих делать тренировки интересными и увлекательными. Наши революционные сетевые решения Connected Solutions помогут вашему уникальному объекту сформировать свой собственный бренд и выделиться на фоне конкурентов."
        link="Узнать больше/"
        contentWidth="33%"
        titleColor="#e6e5e5"
        contentRight={false}
        contentBg="#e1261c"
        textColor="#c2c6c9"
        linkColor="#fff"
        linkTo="/connected-solutions/roi-revenue"
      />
      <section
        style={{
          background: "linear-gradient(171.76deg,#253746,#000)",
        }}
        className="relative py-8"
      >
        <img
          src="https://images.jhtassets.com/0708643054a1670930267c3f2796c7ec45dcc8de/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container className="z-10 relative !grid lg:grid-cols-2">
          <img
            src="https://images.jhtassets.com/afe3d6f8c90d5bd2fba98270a914813554aaf17d/"
            alt=""
            className="px-5"
          />
          <Box className="mt-5">
            <h2
              className="uppercase text-2xl md:text-6xl font-black mb-5"
              style={{ color: "#e6e5e5" }}
            >
              Наша экосистема
            </h2>
            <p className="text-zinc-400 mb-5">
              В основу технологий компании Matrix заложена надежная, открытая
              платформа. Они представляют собой не просто набор оборудования,
              программного обеспечения и способов соединения, а инновационную
              цифровую экосистему, которая повышает эффективность управление
              объектом, привлекает и удерживает посетителей, а также продвигает
              ваш бренд.
            </p>
            <p className="text-zinc-400">
              ## Наши средства интеграции Мы предлагаем лучшие в своем классе
              средства интеграции во взаимодействии со сторонними организациями,
              от систем управления клиентами до средств составления расписаний и
              программного обеспечения для тренажеров, обеспечивающие
              безупречную совместную работу всех уже используемых вами решений.
              При этом наши интеграционные возможности постоянно расширяются по
              мере появления новых технологий и новых участников отрасли. Выбор
              поставщиков, партнеров и решений сегодня огромен, как никогда
              ранее. Приняв решение в пользу Matrix, вы получаете систему,
              которая наилучшим образом отвечает потребностям вашей компании и
              ваших клиентов. Ниже в качестве примера представлена лишь
              небольшая часть наших многочисленных интеграционных решений.
            </p>
            <Box className="grid sm:grid-cols-2 items-center">
              <img
                src="https://images.jhtassets.com/99f1fb493c1c7c76fdf94e5c4a7020cccd28530c/"
                alt=""
              />
              <img
                src="https://images.jhtassets.com/5570d2e0ee253f8c1b624dc9f3bba7ca3146d042/"
                alt=""
              />
              <img
                src="https://images.jhtassets.com/612cf7a26c9601433b67d90dda6f1693e16d959d/"
                alt=""
              />
              <img
                src="https://images.jhtassets.com/4194b3befada290ea9a31b9927cc977713063f8b/"
                alt=""
              />
              <img
                src="https://images.jhtassets.com/047b816535cdecf34821c42b22986fd5db1a0d2c/"
                alt=""
              />
            </Box>
          </Box>
        </Container>
        <Box className="text-zinc-400 mt-14 text-center relative z-10 lg:w-1/2 mx-auto">
          <h3 className="font-black text-xl md:text-6xl pt-5">Наши решения</h3>
          <p className="py-8">
            Наши возможности, с помощью которых мы можем помочь вам поддерживать
            энтузиазм ваших клиентов и следить за их успехами, не ограничены.
            Положитесь на нашу систему Connected Solutions для наиболее
            эффективного отслеживания тренировок, укрепления личных отношений и
            развлечения ваших клиентов в процессе напряженных тренировок.
          </p>
        </Box>
      </section>
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/e2ee3e25e621154b60b8075771fa44e3eb52dabc/"
        title="Engage 360"
        text="Предоставьте вашим инструкторам и клиентам новые увлекательные способы общения для достижения результатов и формирования лояльность на долгосрочную перспективу."
        link="Узнать больше/"
        contentWidth="33%"
        titleColor="#e6e5e5"
        contentRight={true}
        contentBg="#e1261c"
        textColor="#c2c6c9"
        linkColor="#fff"
        linkTo="/connected-solutions/engage360"
      />
      <section className="py-10">
        <Container className="!grid lg:grid-cols-2 gap-3">
          <CardBox
            boxHeight="650px"
            title="Community 360"
            text="Объедините все фитнес-данные ваших клиентов с помощью единого настраиваемого интерфейса, который поможет расширить ваш бренд и сделать ваш фитнес-центр местом притяжения для всех любителей здорового образа жизни."
            img="https://images.jhtassets.com/59c6938cfc1b0b9ba7f422e444179b1cffa05258/"
            color="#c2c6c9"
            textColor="#c2c6c9"
            contentBgColor="#000"
            contentPt="50px"
            link="Узнать больше/"
            linkColor="#fff"
            linkTo="/connected-solutions/community360"
          />
          <CardBox
            boxHeight="650px"
            title="Asset Management"
            text="Положитесь на самое надежное в отрасли решение для управления оборудованием Asset Management, чтобы оптимизировать ведение бизнеса и поддерживать все используемое оборудование в идеальном состоянии."
            img="https://images.jhtassets.com/d319b97ad9961736a72378cc376edec92d694b8a/"
            color="#c2c6c9"
            textColor="#c2c6c9"
            contentBgColor="#000"
            contentPt="50px"
            link="Узнать больше/"
            linkColor="#fff"
            linkTo="/connected-solutions/asset-management"
          />
        </Container>
      </section>
      <BgImageSection
        sectionHeight="80vh"
        img="https://images.jhtassets.com/dccc618966fa04dae1c97d8f00c986c2a58e13bc/"
        title="Целевые тренировки"
        text="Уникальные возможности программы Target Training мотивируют ваших посетителей как никогда раньше, благодаря ярким цветам, увлекательной графике и сформированным индивидуально на основе поставленных целей тренировкам, которые дают реальные результаты."
        link="Узнать больше/"
        contentWidth="33%"
        titleColor="#4c4c4c"
        contentRight={false}
        contentBg="#fff"
        textColor="#666"
        linkColor="#e1261c"
        linkTo="/connected-solutions/target-training"
      />
      <section>
        <Box className="grid lg:grid-cols-3 gap-3 p-5 lg:p-14">
          <CardBox
            title="Sprint 8"
            text="Расширьте границы высокоинтенсивных интервальных тренировок, чтобы дать клиентам результат, который они смогут увидеть в зеркале. Используйте нашу научно обоснованную, эффективную, быструю и простую программу Sprint 8."
            link="Узнать больше /"
            img="https://images.jhtassets.com/1d07dccf923fb7dfaa967ad3ddf9ad37df7b2118/"
            color="#4c4c4c"
            textColor="#666"
            contentBgColor="#fff"
            contentPt="50px"
            linkTo="/connected-solutions/sprint-8"
          />
          <CardBox
            title="Уникальные программы"
            text="Теперь каждая тренировка станет незабываемой благодаря эксклюзивным программам, которые делают занятия еще более захватывающими и мотивирующими, чем когда-либо."
            link="Узнать больше /"
            img="https://images.jhtassets.com/f688acef1ce97cbf41f54ff99416f5e7dd4d063c/"
            color="#4c4c4c"
            textColor="#666"
            contentBgColor="#fff"
            contentPt="50px"
            linkTo="/connected-solutions/unique-programs"
          />
          <CardBox
            title="Индивидуальные решения для тренажерных залов"
            text="Откройте для себя опции и дополнительные возможности, улучшающие тренировочный процесс, такие как система идентификации RFID, цифровое телевидение, дистанционная передача звука и многие другие."
            link="Узнать больше /"
            img="https://images.jhtassets.com/b4474fe31c834a82b110ea6e1dccbd871f9bd160/"
            color="#4c4c4c"
            textColor="#666"
            contentBgColor="#fff"
            contentPt="50px"
            linkTo="/connected-solutions/facility-specific-solutions"
          />
        </Box>
      </section>
      <section>
        <CenterContentBox
          title="Подключитесь к миру решений"
          link="УЗНАТЬ БОЛЬШЕ"
          titleColor="#4C4C4F"
          subtitleColor="#191919"
          linkTo="/contact-us"
        />
      </section>
    </>
  );
};

export default ConnectedSolutions;
