import React from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import "../assets/scss/_about_us.scss";
import CardBox from "../components/CardBox";
import matrixd from "../assets/images/matrixd.png";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import video from "../assets/videos/991990384.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const AboutUs = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="about-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={video}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <div className="about-header flex items-center justify-center">
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="font-bold text-xl text-center text-white">
            О НАШЕЙ КОМПАНИИ
          </div>
          <div className="text-3xl text-center my-4 text-white md:text-6xl">
            ОДИН БРЕНД. ВСЕ ГРАНИ ФИТНЕСА.
          </div>
          <p className="leading-7 text-center text-white font-bold">
            В 2001 году компания Johnson Health Tech создала бренд Matrix, чтобы
            вывести на рынок оборудование премиум-класса для фитнеса. Сегодня
            Matrix обладает самым широким портфолио тренажеров в отрасли и
            оснащает фитнес-клубы, спортивные команды, знаменитые отели и
            роскошные курорты, а также предлагает оборудование высшего качества,
            предназначенное для домашнего использования.
          </p>
          <Button
            onClick={handleOpen}
            className="mx-auto hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
            style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
          >
            <BsPlayCircle size={32} fill="#fff" className="mr-2" />
            СМОТРЕТЬ ВИДЕО
          </Button>
        </Container>
      </div>
      <Container maxWidth="xl" className="!grid py-14 lg:grid-cols-2">
        <CardBox
          link="Все оборудование для клубов/"
          title="ДЛЯ ЛУЧШИХ В МИРЕ СПОРТИВНЫХ КЛУБОВ"
          text="Целью компании Matrix, как производителя первоклассного фитнес-оборудования для коммерческого использования, является создание передовых комплексных решений, позволяющих сделать любой спортивный объект уникальным, чтобы он мог привлекать и удерживать еще больше новых посетителей."
          color="#4c4c4c"
          img="https://images.jhtassets.com/3d63fee245bfbc235ba28c1d1b468a14806c3f05/"
          linkTo="/cardio"
        />
        <CardBox
          title="ДЛЯ ВАШЕГО ДОМА Продукты"
          text="Matrix Fitness — самого быстро растущего в мире поставщика коммерческого оборудования для фитнеса — могут использоваться и у вас дома. Мы создали нашу новую коллекцию оборудования премиум класса, потому что считаем, что такие преданные любители фитнеса, как вы, не должны жертвовать комфортными домашними условиями, чтобы тренироваться также эффективно, как в фитнес-клубах."
          color="#4c4c4c"
          img="https://images.jhtassets.com/610c5084a3fd931a6c51316593cde8bf1f55a8e1/"
        />
      </Container>
      <section className="relative">
        <img className="w-full h-auto object-cover" src={matrixd} alt="" />
        <div
          className="right-0 top-0 px-3 py-14 mr-14 w-full lg:absolute lg:mt-16 lg:w-1/3"
          style={{ background: "rgba(0,0,0,.7)" }}
        >
          <img
            src="https://images.jhtassets.com/fd400f0d9ab9c4403edff146d346fc1db33df567/"
            alt=""
          />
          <div className="text-red-600 text-3xl font-black leading-none">
            ОТ СЕМЕЙНОГО БИЗНЕСА К МИРОВОЙ ФИТНЕС-СЕМЬЕ
          </div>
          <p className="leading-6 text-white my-5">
            Узнайте, как наша головная компания превратилась из видения одного
            человека в глобального игрока, который навсегда изменил фитнес и
            здоровый образ жизни.
          </p>
          <a
            href="https://www.johnsonhealthtech.com/eng/"
            className="inline-block bg-red-600 px-6 py-2 font-bold text-white mb-14 hover:bg-red-700"
          >
            Узнайте больше о JHT
          </a>
        </div>
      </section>
      <section className="section-2 relative">
        <Container maxWidth="xl" className="md:!px-14">
          <div
            className="text-3xl text-center font-black"
            style={{ color: "#4c4c4c" }}
          >
            ОБШИРНЫЙ ОПЫТ ПО ВСЕМУ МИРУ
          </div>
          <p
            className="leading-6 my-4 text-center"
            style={{ color: "#191919" }}
          >
            Нет ничего удивительного в том, что уникальный подход Matrix
            переосмыслил фитнес и здоровый образ жизни во всем мире.
            Международные инновационные достижения и приверженность к
            партнерству позволяют Matrix обслуживать потребности в фитнесе
            повсюду: от плотных городских центров до отдаленных мест.
          </p>
        </Container>
        <Container maxWidth="xl" className="!grid py-14 lg:grid-cols-2">
          <CardBox
            link="УЗНАТЬ БОЛЬШЕ /"
            title="Концепция Global by Design"
            text="Являясь частью вертикально интегрированной всемирной организации, Matrix имеет преимущества на каждом этапе создания продукта. Международный отдел исследований и разработок позволяет нам использовать лучшие умы отрасли для создания комплексных решений, а наши передовые производственные мощности обеспечивают непревзойденное качество. Лучших решений и опыта не бывает просто так - они создаются по замыслу."
            color="#4c4c4c"
            img="https://images.jhtassets.com/ca8d648937814d9458ac60cf32a97670c1c4af38/"
            href="http://globalbydesign.johnsonfitness.com/"
          />
          <CardBox
            link="НАШИ РЕШЕНИЯ /"
            title="Партнер, предлагающий комплексные решения"
            text="Выбрав Matrix, ваш фитнес-центр получит больше, чем самое лучшее оборудование в мире. Вы приобретете надежный ассортимент тренажеров, передовые технологии, возможность планировки объектов, маркетинговую поддержку, ведущие в отрасли гарантии и партнера, который будет рядом на каждом этапе пути. Работая в связке, мы всегда сможем что-то улучшить, переосмыслить или заново изобрести."
            color="#4c4c4c"
            img="https://images.jhtassets.com/f6ff869a10ed6b726ff9ffa23126c90e01d9b97d/"
            linkTo="/total-solutions-partners"
          />
        </Container>
      </section>
      <section className="section-3 relative">
        <Container maxWidth="xl" className="md:!px-14">
          <div
            className="text-2xl text-center font-black sm:text-3xl"
            style={{ color: "#e6e5e5" }}
          >
            ПЕРЕДОВЫЕ ТЕХНОЛОГИИ. БЕСКОМПРОМИССНОЕ КАЧЕСТВО.
          </div>
          <p className="leading-6 my-4 text-center" style={{ color: "#fff" }}>
            От первого эскиза до оборудования, которое установлено у вас в зале,
            мы делаем все возможное, чтобы наша продукция превосходила ожидания.
            Вертикально интегрированный процесс производства позволяет нам
            сочетать эксклюзивные технологии с передовыми программами для
            бескомпромиссного, динамичного и легкодоступного тренинга в клубе и
            дома.
          </p>
        </Container>
        <Container maxWidth="xl" className="!grid py-14 lg:grid-cols-2">
          <CardBox
            title="Исследования и разработки"
            text="Продукты Matrix созданы лучшими специалистами нашего международного отдела исследований и разработок. Процесс включает в себя более 250 человек, занимающихся опытно-конструкторскими работами, и интерфейс обратной связи с более чем 600 пользователями. Это означает, что наши сотрудники 24 часа в сутки работают над разработкой нового уникального фитнес-решения. Это гибкая инновационная модель, которая никогда не останавливается."
            color="#e6e5e5"
            textColor="#fff"
            img="https://images.jhtassets.com/149ab09b4f4ea052e4a57cdc3a833b89ad4925f1/"
          />
          <CardBox
            title="Совершенство производства"
            text="Мы производим все основные компоненты для нашего оборудования своими силами. Заводы Matrix являются одними из самых совершенных в отрасли: чистые помещения и робототехника, а также квалифицированные рабочие, отвечающие наивысшим международным стандартам. Это позволяет обеспечить нам самую низкую стоимость владения фитнес оборудования на рынке."
            color="#e6e5e5"
            textColor="#fff"
            img="https://images.jhtassets.com/e4339946d63c099bef87b2ba8bc7a8053d1dab9b/"
          />
        </Container>
      </section>
      <section className="section-4 py-8">
        <Container maxWidth="xl">
          <div
            className="text-3xl text-center font-black"
            style={{ color: "#4c4c4c" }}
          >
            НАГРАДЫ и ОТЛИЧИЯ
          </div>
          <p
            className="leading-6 my-4 text-center"
            style={{ color: "#191919" }}
          >
            Конструкторские решения тренажеров Matrix не раз признавались
            лучшими в мире за свои динамические характеристики, исключительный
            комфорт, безупречную эстетику и непревзойденную долговечность.{" "}
            <br /> Эти награды — признание исключительности бренда Matrix.{" "}
          </p>
          <div className="grid gap-4 py-14 grid-cols-2 lg:grid-cols-4">
            <img
              src="https://images.jhtassets.com/169b8faf7f220f422c24c2694a8ed177b081b70a/"
              alt=""
            />
            <img
              src="https://images.jhtassets.com/9e9afa02a3b4953d3162ae68f83f58aad040fde4/"
              alt=""
            />
            <img
              src="https://images.jhtassets.com/5129eb4be0bb53a3db0a41950e20b6f39a6e148e/"
              alt=""
            />
            <img
              src="https://images.jhtassets.com/4a77b607f42dd6194327511ebf17767ae766b3d6/"
              alt=""
            />
          </div>
          <div
            className="text-3xl text-center font-black"
            style={{ color: "#4c4c4c" }}
          >
            Хотите узнать больше о нашей компании?
          </div>
          <div className="w-full text-center py-14">
            <Link
              to="/contact-us"
              className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold px-6 py-2"
            >
              СВЯЖИТЕСЬ С НАМИ
            </Link>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default AboutUs;
