import React from "react";
import { Container, Button, Box } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import BgImageSection from "../components/BgImageSection";
import CenterContentBox from "../components/CenterContentBox";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $5 from "../assets/videos/1-5.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const ConnectedSolutionsAssetManagement = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$5}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="flex items-end justify-center relative"
        style={{ height: "65vh" }}
      >
        <img
          src="https://images.jhtassets.com/0cbe219b1ed5b43181790e37414884ce77eda0dd/"
          className="absolute top-0 left-0 w-full h-full object-cover"
          alt=""
        />
        <Container
          className="flex flex-col gap-5 items-center justify-center text-center relative z-10"
          style={{ display: "flex" }}
        >
          <h5 className="text-white uppercase font-bold">ASSET MANAGEMENT</h5>
          <p className="text-2xl lg:text-5xl uppercase text-white">
            ВАМ НЕ НУЖНО СЛЕДИТЬ ЗА ОБОРУДОВАНИЕМ. ДОВЕРЬТЕ ЭТО НАМ.
          </p>
          <Button
            onClick={handleOpen}
            className="VideoPopupButton mx-auto !bg-white hover:!bg-red-700 hover:!text-white !p-4 !rounded-none font-bold text-xl relative top-8"
            style={{ color: "#e1261c" }}
          >
            <BsPlayCircle size={32} fill="" className="mr-2 fill-red-700" />
            <span className="font-bold">СМОТРЕТЬ ВИДЕО</span>
          </Button>
        </Container>
      </section>
      <section className="py-10 lg:py-20">
        <Container maxWidth="xl" className="lg:!flex items-center">
          <p className="pr-6 border-r-2">
            Наша передовая платформа Asset Management предлагает обзор в 360
            градусов статуса вашего оборудования практически на любом
            подключенном к интернету устройстве, а также возможности для
            подробной отчетности, оптимизированное обслуживание, маркетинговые
            инструменты и многое другое.
          </p>
          <Box className="mt-5 lg:mt-0 lg:px-8">
            <p>Посетите Asset Management</p>
            <a
              href="http://am4.matrixfitness.com/"
              className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700 md:whitespace-nowrap"
            >
              <span className="text-base font-black flex items-center gap-3">
                У вас уже есть учетная запись?
                <img
                  src="https://images.jhtassets.com/28e36bd8114cbda373e04fbe368f79321f9cb8b6/transformed/w_16"
                  className="hidden lg:block"
                  alt=""
                />
              </span>
            </a>
          </Box>
        </Container>
      </section>
      <section className="lg:py-10">
        <Container maxWidth="xl">
          <h3 className="py-5 uppercase text-3xl font-black text-center text-zinc-700">
            ПРЕВОСХОДНОЕ ПРАВЛЕНИЕ ЗАПАСАМИ
          </h3>
          <Box className="grid lg:grid-cols-2 gap-5 py-5">
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/6522c61e3aae674a7671e68004931bfc8aae1a27/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">
                ОБОРУДОВАНИЕ ДЛЯ НАБЛЮДЕНИЯ
              </p>
            </article>
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/1347efd27533a8c3bafb3f9eb0c36ae86f4ab087/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">
                ИНФОРМАЦИЯ О ДОСТУПЕ ПРОДУКТА
              </p>
            </article>
          </Box>
        </Container>
      </section>
      <BgImageSection
        sectionHeight="70vh"
        img="https://images.jhtassets.com/3596c47fa36c185f62e173a2af91272a88326a9f/"
        title="МАКСИМИЗАЦИЯ СРОКА ЭКСПЛУАТАЦИИ"
        text="Community 360 следит за особенностями использования, чтобы помочь вам оптимизировать ротацию оборудования, что приведет к равномерному износу и максимизации срока эксплуатации продукта."
        contentWidth="25%"
        titleColor="#4c4c4c"
        contentRight={true}
        contentBg="hsla(0,0%,100%,.9)"
        textColor="#666"
        linkColor="#e1261c"
      />
      <section className="py-10">
        <Container maxWidth="xl">
          <div className="relative lg:p-20">
            <img
              className="lg:absolute w-full h-full object-cover top-0 left-0"
              src="https://images.jhtassets.com/54c7485c175b62a06795a72e45c69ca567cd6fb4/"
              alt=""
            />
            <Box className="relative z-10 w-full mt-10 lg:mt-0 lg:w-1/3">
              <h3 className="text-zinc-600 text-2xl font-black mb-5">
                УПРАВЛЕНИЕ ДАННЫМИ
              </h3>
              <p className="text-zinc-800">
                Настроенные отчеты позволят вам легко получать доступ к данным
                оборудования, помогая вам определить идеальный ассортимент
                продукции, чтобы участники могли всегда иметь доступ к их
                любимому снаряжению.
              </p>
            </Box>
          </div>
        </Container>
      </section>
      <section className="py-10">
        <Container maxWidth="xl">
          <h3 className="py-5 uppercase text-xl lg:text-3xl font-black text-center text-zinc-700">
            АВТОМАТИЗИРОВАННЫЕ МАРКЕТИНГОВЫЕ ИНСТРУМЕНТЫ
          </h3>
          <Box className="grid lg:grid-cols-2 gap-5 py-5">
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/adc8ef5119146c004b947df0b8f887b6533de1cb/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">
                АВТОМАТИЧЕСКИЙ ОБМЕН СООБЩЕНИЯМИ
              </p>
            </article>
            <article
              className="relative flex items-center justify-center text-center text-white"
              style={{ height: "460px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="https://images.jhtassets.com/ed4a572336ea30dd79ab23b42137b08d05bb0c54/"
                alt=""
              />
              <p className="text-2xl uppercase relative z-10">
                НАСТРОЕННЫЙ ОПЫТ
              </p>
            </article>
          </Box>
        </Container>
      </section>
      <section>
        <CenterContentBox
          title="ПРИСОЕДИНИТЕСЬ К ASSET MANAGEMENT"
          link="Обратная связь"
          titleColor="#4C4C4F"
          subtitleColor="#191919"
          linkTo="/contact-us"
        />
      </section>
    </>
  );
};

export default ConnectedSolutionsAssetManagement;
