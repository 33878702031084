import React from 'react'
import { Link } from 'react-router-dom'
import "../assets/scss/_hover_box_animation.scss"

const HoverBoxAnimation = (props) => {
	return (
		<div className='box-animation-open cursor-pointer'>
			<img
				className='w-full h-full object-cover'
				src={props.image}
				alt='Картинка'
				style={{background: '#333'}}
			/>
			<div
				className='absolute bottom-0 left-0 mb-6 ml-6 text-2xl font-bold'
				style={{
					color: '#e6e5e5'
				}}
			>{props.title}</div>
			<div className='box-animation'>
				<div className='box__animation-title'>{props.title}</div>
				{props.text ?
					<p className='box__animation-desc'>{props.text}</p>
				:
					null
				}
				{
					props.link ?
					<Link to={props.linkTo ? props.linkTo : ''} className='box__animation-link'>{props.link}</Link> :
					''
				}
			</div>
		</div>
	)
}

export default HoverBoxAnimation