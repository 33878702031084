import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid transparent',
    color: '#fff',
    borderRadius: 0,
  },
}));

const ProductItem = (props) => {
	const navigate = useNavigate();

	return (
		<article className='ProductItem px-5 py-3' style={{background: props.bgColor}}>
			<Link to={props.link}>
				<img
					src={props.img}
					alt=""
					className="ProductItemImg"
					style={{backgroundColor: '#f5f5f5', height: '250px', width: '100%', objectFit: 'contain'}}
				/>
			</Link>
			<div className="ProductItemBody py-2">
				<Link to={props.link} className="text-red-700 hover:text-red-900 hover:underline" style={{fontSize: '15px'}}>{props.title}</Link>
				<p className='text-zinc-500 py-1 text-sm'>{props.console}</p>
			</div>
			<div className='flex'>
				{props.item?.map(console => (
					<HtmlTooltip
						key={console.id}
						arrow
						placement='top'
						title={
						<>
							<Typography color="#e2e2e2" className='!text-2xl'>{console?.name}</Typography>
						</>
							}>
							<div onClick={() => navigate(`/cardio/${console?.id}`)} className='py-2 mr-2 border-t-2 border-transparent hover:border-red-700'>
							<img style={{ height: '33px', width: '33px' }} src={console?.console_image} alt='' />
						</div>
					</HtmlTooltip>
				))}
			</div>
		</article>
	)
}

export default ProductItem