import { Container } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'
import { getFilter } from '../redux/actions/filterActions'

const StrengthG1 = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/247d8c9ae13892048809fe86729fefbfc681dc26/"
					className="md:absolute top-0 left-0 w-full h-full"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-5xl relative z-10 px-5 md:text-white">СЕРИЯ G1</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Оборудование серии G1 являет собой идеальное сочетание стоимости и разнообразия и подходит для тренажерных залов с ограниченными финансовыми возможностями. Тренажеры этого модельного ряда сочетают в себе долговечность, функциональность и стильный классический дизайн. Оборудование этой серии, начиная с компактных многопозиционных тренажеров и заканчивая никогда не теряющими популярность скамьями, в течение долгих лет будет служить вам и вашим клиентам.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 10)
                    dispatch(getFilter({
                        series: 10,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии G1"
						subtitle="Серия G1 — это бюджетная линейка многоместных тренажеров и тренажеров со свободными весами."
						image="https://images.jhtassets.com/27c663f87494dc1a48c5d3b82394bba5a8318494/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Место для трех человек"
						text="Трехблочный многофункциональный комплекс серии G1 позволяет посетителям одновременно тренировать основные группы мышц, а также дает вам возможность сэкономить средства и пространство зала."
						img="https://images.jhtassets.com/5da6b80eb34c732302980ab627d363636fe1a8b5/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Притягательность вне времени"
						text="Наши скамьи и стойки с классическим дизайном являются удобными для использования поклонниками фитнеса и начинающими энтузиастами."
						img="https://images.jhtassets.com/8ccc9c1443a5695b359795a676c56bbc2009a1d0/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
				<Container className="mt-8 mb-8" maxWidth="xl">
					<CardBoxMoreImage
						title="Долговечность и эффективность"
						subtitle="Мы обещаем, что каждый тренажер, установленный в вашем фитнес-центре, сможет выдерживать интенсивные ежедневные нагрузки в течение многих лет."
						image="https://images.jhtassets.com/ffb985628dfc213feb52ec25b9e8fb389c77fcab/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="33%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default StrengthG1