import React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Education = () => {
  return (
    <section className='education-page'>
        <section 
            className='section-1 flex flex-col justify-end !bg-cover !bg-center' 
            style={{
                height: '70vh',
                background: 'url(https://images.jhtassets.com/5ba6066179da094caf3c1b08af66ae427ab3801c/) no-repeat'
            }}
        >
            <Container>
                <div className='text-3xl lg:text-6xl text-center text-white pb-8'>ПРОГРАММЫ</div>
            </Container>
        </section>
        <section className='section-2 py-16'>
            <Container>
                <div className='leading-7 text-center' style={{color: '#191919'}}>Благодаря групповым тренировкам люди будут отдавать предпочтение именно вашему тренажерному залу. Узнайте о программах, которые помогут вам не только привлечь посетителей, но и сделать так, чтобы они не уходили от вас. Наше портфолио включает лучшие варианты нашего собственного оборудования, а также предложения от наших эксклюзивных партнеров.</div>
                <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-4 py-16'>
                    <div>
                        <Link className='flex flex-col items-center text-red-600 hover:text-red-700 hover:underline' to="/education/matrix-ride">
                            <img style={{width: '80%'}} src='https://images.jhtassets.com/ac12e488bc71ca006b3d8cf4220d220651f99c09/' alt='' />
                            <p>Matrix Ride</p>
                        </Link>
                    </div>
                    <div>
                        <Link className='flex flex-col items-center text-red-600 hover:text-red-700 hover:underline' to="/education/mx4">
                            <img style={{width: '80%'}} src='https://images.jhtassets.com/053a6b7356ea6763cb2e8ec3f93f5be12447c010/' alt='' />
                            <p>MX4</p>
                        </Link>
                    </div>
                    <div>
                        <Link className='flex flex-col items-center text-red-600 hover:text-red-700 hover:underline' to="/education/mx4-active">
                            <img style={{width: '80%'}} src='https://images.jhtassets.com/2f85211e04c1f44181b709c69325c0d22f078c51/' alt='' />
                            <p>MX4 Active</p>
                        </Link>
                    </div>
                    <div>
                        <Link className='flex flex-col items-center text-red-600 hover:text-red-700 hover:underline' to="/education/connexus">
                            <img style={{width: '80%'}} src='https://images.jhtassets.com/b5ee8217e9f78e8d4f42ce210170863a72f7d3a6/' alt='' />
                            <p>Connexus</p>
                        </Link>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xl" className='md:!px-12'>
                <div className='box-animation-open mb-6'>
                    <img 
                        src='https://images.jhtassets.com/6ef7119533ce5a91b4f5096002ae2fb3b80e5df1/'
                        className='object-cover'
                        alt=''
                    />
                    <div className='absolute bottom-0 left-0 mb-6 ml-6 text-2xl font-bold' style={{color: '#e6e5e5'}}>Connexus</div>
                    <div className='box-animation'>
                        <div className='box__animation-title'>Connexus</div>
                        <p className='box__animation-desc'>Вы сможете незамедлительно начать использование системы Connexus благодаря уже готовым программам тренировок, которые включают боксерские тренинги, упражнения со стропами и другие упражнения. Или же вы можете ознакомиться с материалами нашей виртуальной библиотеки и создавать собственные комплексы для индивидуальных тренировок или тренировок в небольших группах.</p>
                        <Link to='/education/connexus' className='box__animation-link'>Подробнее /</Link>
                    </div>
                </div>
                <div className='grid md:grid-cols-2 gap-6'>
                    <div className='box-animation-open'>
                        <img src='https://images.jhtassets.com/4fe122ff7135552c29a4bf1600d064997da52f80/' alt='' />
                        <div className='absolute bottom-0 left-0 mb-6 ml-6 text-2xl font-bold' style={{color: '#e6e5e5'}}>Тренировочная система MX4 Training System</div>
                        <div className='box-animation'>
                            <div className='box__animation-title'>Тренировочная система MX4 Training System</div>
                            <p className='box__animation-desc'>Если вы хотите увеличить доход, расширить и улучшить групповые тренировки, завоевать доверие и приверженность участников, наши программы тренировочной системы MX4 Training System помогут вам в этом. Наши оригинальные готовые программы можно адаптироать под широкий ряд уникальных целей участников.</p>
                            <Link to='/education/mx4' className='box__animation-link'>Подробнее /</Link>
                        </div>
                    </div>
                    <div className='box-animation-open'>
                        <img src='https://images.jhtassets.com/7c87b7037c1bc3ea9b39c0d737a58f5508b8332b/' alt='' />
                        <div className='absolute bottom-0 left-0 mb-6 ml-6 text-2xl font-bold' style={{color: '#e6e5e5'}}>ТОЧНЫЕ ПОКАЗАТЕЛИ.ВЕЛИКОЛЕПНЫЕ ТРЕНИРОВКИ.</div>
                        <div className='box-animation'>
                            <div className='box__animation-title'>ТОЧНЫЕ ПОКАЗАТЕЛИ.ВЕЛИКОЛЕПНЫЕ ТРЕНИРОВКИ.</div>
                            <p className='box__animation-desc'>Наши эксклюзивные, полностью оригинальные программы помогут тренерам достичь нового уровня велотренировок, которые привлекут широкие ряды участников благодаря возможности уделять внимание значимым показетелям, таким как количество энергии в ваттах, частота пульса, количество оборотов в минуту, дистанция и калории.</p>
                            <Link to='/education/matrix-ride' className='box__animation-link'>Подробнее /</Link>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    </section>
  )
}

export default Education;