import React, { useState } from "react";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import HoverBoxAnimation from "../components/HoverBoxAnimation";
import { IconButton } from "@mui/material";
import { MdPhotoLibrary } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Button } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import $12 from "../assets/videos/1-12.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const array = [
  {
    id: 1,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/6f6344bee9a93b7d3d1cba28c3c4bc6b82157adc/",
      },
      {
        image:
          "https://images.jhtassets.com/decff7ac41b0aaa2e692e25744ac69e19fec45f7/",
      },
      {
        image:
          "https://images.jhtassets.com/4f9bc221737625a5e6a2f5e0cbb9a7b162c162dd/",
      },
      {
        image:
          "https://images.jhtassets.com/9c6a2f6e7f36c5166b425defda876d986956cdd4/",
      },
      {
        image:
          "https://images.jhtassets.com/600044c51f60e991af71d6b2a1f6bd8afc436014/",
      },
      {
        image:
          "https://images.jhtassets.com/a3dcce1a00dfb74cb8581284e3278c2460e9950b/",
      },
      {
        image:
          "https://images.jhtassets.com/8c1665a4c71c0bc7fac18c37a1d58476543a3664/",
      },
      {
        image:
          "https://images.jhtassets.com/d30142c3d15ea876b7d3dce333820c6e6f8ee060/",
      },
      {
        image:
          "https://images.jhtassets.com/4199da5a6070056f5a18c30b06340623ed898f63/",
      },
      {
        image:
          "https://images.jhtassets.com/6ad1978cbf711e2e09ac1323086b25729af1932c/",
      },
      {
        image:
          "https://images.jhtassets.com/9cc5f4251e73704289cc538847150e250f7ba25a/",
      },
      {
        image:
          "https://images.jhtassets.com/1f5bada7bfd5200abce2340cac3245d7ec37064d/",
      },
      {
        image:
          "https://images.jhtassets.com/ae9e982d04ee5d905eebd48fcfd8bcb4b441a0d1/",
      },
      {
        image:
          "https://images.jhtassets.com/a97207ba91dc38e9dcb83f68e0b7c67fc31560ac/",
      },
      {
        image:
          "https://images.jhtassets.com/b07889772ae5c4e22cb6def7df9fc042656acab3/",
      },
      {
        image:
          "https://images.jhtassets.com/302dcd0e021e05d2d38f61908dac4a3ea4d7e254/",
      },
      {
        image:
          "https://images.jhtassets.com/652283f4c11a0a5b032319e7d5246b7808a4ab4b/",
      },
      {
        image:
          "https://images.jhtassets.com/96a5c17570c6693f5fed517187521e3b606d0de5/",
      },
      {
        image:
          "https://images.jhtassets.com/9d756bce8e587bafb514f63b128f509a0f1f3ad8/",
      },
      {
        image:
          "https://images.jhtassets.com/5e61a66938de4410912df52d49aa45a295140e83/",
      },
      {
        image:
          "https://images.jhtassets.com/79eae1797cd6176ef22b17e42f2a12e7b5da7a6a/",
      },
      {
        image:
          "https://images.jhtassets.com/467c450cc25e5a12097038899038c5592f09e45a/",
      },
      {
        image:
          "https://images.jhtassets.com/646d0b5c1c918ab134a47b08124a01e1f40ac995/",
      },
      {
        image:
          "https://images.jhtassets.com/12f109876e3a794ee82e82ed7ef2f3a3cf7e8783/",
      },
      {
        image:
          "https://images.jhtassets.com/1ecdd5566581d88ed61d0e5461b59e2437f96c82/",
      },
      {
        image:
          "https://images.jhtassets.com/6a6dd8e63446b33ea2539a672249e5b64f659a1e/",
      },
      {
        image:
          "https://images.jhtassets.com/b02f52dae5cc00fbab87b4075f246698dc0a385b/",
      },
      {
        image:
          "https://images.jhtassets.com/4af17fa8cc31b7e3643d9af664f99582b42e47a5/",
      },
      {
        image:
          "https://images.jhtassets.com/129a44e7eb7e985eae56fd0d80106f6737a6ce3e/",
      },
      {
        image:
          "https://images.jhtassets.com/e079afe959a55aa724eabc4fcf1c2f7674ec0ff7/",
      },
      {
        image:
          "https://images.jhtassets.com/50c36be7b85999ec84d6621480e0993a21102d25/",
      },
      {
        image:
          "https://images.jhtassets.com/50ee4de6ecf5e17da04bf4256a1c8220f81b0139/",
      },
      {
        image:
          "https://images.jhtassets.com/4c8e26ab03224c3c5551781601e80b42ba9da060/",
      },
      {
        image:
          "https://images.jhtassets.com/e961d9424b22a66a233269229cccd1bb441fe728/",
      },
    ],
  },
  {
    id: 2,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/92544e18a1ee3fa685d6972954e5b31ae49827e8/",
      },
      {
        image:
          "https://images.jhtassets.com/2b8145ce882f5d4e1fff8e281317088d398c182e/",
      },
      {
        image:
          "https://images.jhtassets.com/6d2c29f2e111d2196fc10e2921900098cd55a89e/",
      },
      {
        image:
          "https://images.jhtassets.com/44c812ca4c8ae3ecc347c3bede4f6a8fa974f880/",
      },
      {
        image:
          "https://images.jhtassets.com/0675e168485b4a6450af578eb64d183d2461a637/",
      },
      {
        image:
          "https://images.jhtassets.com/e8d7cbeb95c19f94f5321166076a5955d305393a/",
      },
      {
        image:
          "https://images.jhtassets.com/36e1b4c7b23051980fbecd07b4a38fbc2c90a298/",
      },
      {
        image:
          "https://images.jhtassets.com/94b904f07576437e19c9967be46717080650cb6f/",
      },
      {
        image:
          "https://images.jhtassets.com/182544a28e1d23bcd24fc7435a6f11bc33221f4e/",
      },
      {
        image:
          "https://images.jhtassets.com/55fcb07ba1009a99185a444c10000bcbb684237e/",
      },
      {
        image:
          "https://images.jhtassets.com/7aabce1a496acb75f5bc0b1a0eea3d626d974fc2/",
      },
      {
        image:
          "https://images.jhtassets.com/ad3017b9bb6e9d5348513f7f99cb5cf432816514/",
      },
      {
        image:
          "https://images.jhtassets.com/6e93a818221c4633e8b57c840371520365bb6eaf/",
      },
      {
        image:
          "https://images.jhtassets.com/cb4993b43ae15117d755a5edffd3861947c4fc34/",
      },
      {
        image:
          "https://images.jhtassets.com/34daf161f926cfab652fa7463d1645fc566539ee/",
      },
      {
        image:
          "https://images.jhtassets.com/1f50c99ef77ec71a1ec822a97aa60750e242fcb4/",
      },
    ],
  },
  {
    id: 3,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/053772dbcd6c9693d2903f7613db7c6101a4df41/",
      },
      {
        image:
          "https://images.jhtassets.com/e4c92152aaf959ca67986d1ef96e0f0e37903693/",
      },
      {
        image:
          "https://images.jhtassets.com/512a8d90dbb2ea66d808704244e8b04e9d16c3ce/",
      },
      {
        image:
          "https://images.jhtassets.com/7f5841afca993c5b8ef8d8a20c83a4be17270100/",
      },
      {
        image:
          "https://images.jhtassets.com/aa5319c77959ca75390b984a5901924e2dad2771/",
      },
      {
        image:
          "https://images.jhtassets.com/4f117afa8be130d8227cf006ca5d1686405008ea/",
      },
    ],
  },
];

const boxes = [
  {
    id: 1,
    image:
      "https://images.jhtassets.com/6f6344bee9a93b7d3d1cba28c3c4bc6b82157adc/transformed/h_558,w_440,c_fill",
    title: "Cologne",
    subtitle: "Hotel Lindner",
    name: "Германия",
  },
  {
    id: 2,
    image:
      "https://images.jhtassets.com/92544e18a1ee3fa685d6972954e5b31ae49827e8/transformed/h_558,w_440,c_fill",
    title: "Madison",
    subtitle: "AC Hotel",
    name: "США",
  },
  {
    id: 3,
    image:
      "https://images.jhtassets.com/053772dbcd6c9693d2903f7613db7c6101a4df41/transformed/h_558,w_440,c_fill",
    title: "Chicago",
    subtitle: "Renaissance",
    name: "США",
  },
];

const Hospitality = () => {
  const [modal, setModal] = useState();
  const handleModal = array.find((obj) => {
    return obj.id === modal;
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="hospitality-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={$12}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 flex md:flex-row flex-col items-center justify-center !bg-cover !bg-center main-media-height"
        style={{
          height: "80vh",
          clipPath: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          shapeOutside: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          background:
            "url(https://images.jhtassets.com/99bb149df9bb3e7514949da55e607d4d14f1f6c7/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12 md:!py-0 !py-32">
          <div
            style={{ marginTop: "-6rem" }}
            className="lg:w-1/4 ml-auto md:mr-16"
          >
            <div className="font-bold text-white text-lg mb-4">
              An Enlightened Experience
            </div>
            <div className="text-white text-3xl lg:text-5xl leading-none">
              Hospitality
            </div>
            <Button
              onClick={handleOpen}
              className="mx-auto !mt-2 hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
              style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
            >
              <BsPlayCircle size={32} fill="#fff" className="mr-2" />
              СМОТРЕТЬ ВИДЕО
            </Button>
          </div>
        </Container>
      </section>
      <section className="section-2" style={{ marginTop: "-70px" }}>
        <div className="grid lg:grid-cols-2 lg:gap-8">
          <div style={{ clipPath: "polygon(0 10%,100% 5%,100% 100%,0 100%)" }}>
            <img
              className="w-full"
              src="https://images.jhtassets.com/f1c62651021948c34b6681386cf511ca32da7bd5/transformed/w_650,h_1000,c_fill"
              alt=""
            />
          </div>
          <div
            className="flex flex-col justify-center pl-12 py-4 !bg-cover bg-center"
            style={{
              background:
                "url(https://images.jhtassets.com/c1e7d5e94b0a7507fd91ead4eff186e6aa763d4a/transformed/w_650,h_1000,c_fill) no-repeat",
              clipPath: "polygon(0 5%,100% 0,100% 100%,0 100%)",
            }}
          >
            <p className="leading-9 w-11/12 text-white mb-8 text-base">
              Компания Matrix, которой отдают предпочтение некоторые лучшие
              отели и курорты мира, позволяет любителям физических упражнений
              легко найти свое любимое тренажерное оборудование во время отдыха.
              От кардионагрузок до силовых групповых тренировок, все наше
              оборудование обеспечивает высочайшую производительность,
              непревзойденную долговечность и привлекательный дизайн, чтобы
              улучшить внешний вид вашего пространства и встречать ваших гостей.
              Но вы можете рассчитывать на Matrix не только как на лучшее
              тренажерное оборудование в мире — когда вы сотрудничаете с Matrix,
              мы всегда готовы помочь вашей организации разработать комплексные
              фитнес-решения, которые отражают ваш бренд, максимально
              увеличивают ваши ресурсы и радуют ваших гостей.
            </p>
            <a
              href="https://images.jhtassets.com/9b84a45e312e492b092dba27f27e190a9825203c/"
              className="flex items-center text-red-600 hover:text-red-700"
            >
              <img
                className="mr-2"
                src="https://images.jhtassets.com/6e49bd73cc4f9e888587ad1eaaf204027b076c71/transformed/w_50,h_52"
                alt=""
              />
              Brochure
            </a>
          </div>
        </div>
      </section>
      <section className="section-3">
        <Container maxWidth="xl" className="md:!px-10">
          <div
            className="text-center mt-16 mb-4 lg:px-16 text-3xl lg:text-6xl leading-none uppercase"
            style={{ color: "#4c4c4c" }}
          >
            Новое партнерство
          </div>
          <p
            className="leading-7 mb-4 text-center md:w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Независимо от того, управляете вы крупным курортом или независимой
            гостиницей, от Matrix вы получите больше, чем просто лучшее
            оборудование для фитнеса в мире. Сотрудничайте с нами, и мы будем
            работать с вами от выбора идеального оборудования до планирования
            вашего пространства для установки, а также в рамках других аспектов.
            Вы всегда можете с нами связаться благодаря лучшей в отрасли
            поддержке клиентов и комплексным бизнес-решениям, которые могут
            удивительным образом изменить ваш фитнес-центр.
          </p>
          <Link
            to="/total-solutions-partners"
            className="block w-max mx-auto mb-12 text-red-600 font-bold hover:uppercase hover:text-red-700"
          >
            Узнать больше /
          </Link>
          <div className="grid md:grid-cols-3 gap-6 pb-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/946a62d49fdf1a6aa48a7634ce50c5322d57fe55/"
              title="Планирование помещений"
              text="Мы учтем особенности вашего помещения, бюджет и идеи и поможем с точностью реализовать вашу фитнес-концепцию."
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/95141232c340b7b2ca3a26987ca2af3a3114cfe8"
              title="Группа специалистов по продажам и команда поддержки"
              text="Наши специалисты по продажам и команда поддержки помогут вам создать фитнес-центр в соответствии с вашими индивидуальными потребностями."
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/fae78659cc238603657bfc6c08832a82e4307586/"
              title="Отдел по работе с клиентами"
              text="Компания Matrix обеспечивает высочайший уровень устранения неисправностей после первого обращения и оперативную работу, а современная консольная технология значительно облегчает процесс дистанционной диагностики."
            />
          </div>
          <div
            className="text-center lg:mt-16 mb-4 lg:px-16 text-3xl lg:text-6xl leading-none uppercase"
            style={{ color: "#4c4c4c" }}
          >
            ТРЕНАЖЕРЫ ДЛЯ КАЖДОГО
          </div>
          <p
            className="leading-7 mb-4 text-center md:w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Каждый тренажер Matrix отвечает трем критериям — мощность,
            долговечность и эстетичность. Динамические характеристики наших
            тренажеров не уступают оборудованию других фитнес-клубов, к которому
            привыкли ваши посетители. Высококачественные детали, предназначенные
            для долговременного использования при тяжелых нагрузках, отличаются
            износостойкостью и реже требуют замены или ремонта. Изысканный
            дизайн тренажеров придаст вашему фитнес-центру неповторимый облик.
          </p>
          <div className="grid lg:grid-cols-3 gap-6 pb-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/b9fda8b59ea968103366a173c5d686164760b5ff/"
              title="Кардио"
              text="От оздоровительных пробежек до физических упражнений с малой нагрузкой, наш портфель предложений позволяет легко удовлетворить потребности в тренировках каждого зарегистрировавшегося гостя. Наши кардиотренажеры серии 7xi обеспечивают максимальный опыт тренировок. "
              link="Смотреть кардио /"
              linkTo="/cardio"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/2127c6e1e98393bd679490000800a92e9314b9f7/"
              title="Сила"
              text="Вам требуется непревзойденная эффективность, индивидуальные параметры, компактная модель или оборудование с соотношением «цена/качество»? У нас вы найдете все, что нужно, и даже больше. "
              link="Смотреть силовые тренировки /"
              linkTo="/strength"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/fc20600ac3bb31fd4eb605ca87c53995dc2f16ad/"
              title="Групповые тренировки"
              text="Наши решения для групповых тренировок предлагают впечатляющие&nbsp;способы выделить ваш фитнес-центр среди других, давая вашим гостям новый повод возвращаться снова и снова. "
              link="Смотреть групповые тренировки /"
              linkTo="/group-training"
            />
          </div>
        </Container>
      </section>
      <section
        className="section-4 !bg-cover !bg-bottom pb-16"
        style={{
          background:
            "url(https://images.jhtassets.com/0177acaf83a1585aebb99ac11b719592273d4071) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-10">
          <div className="grid lg:grid-cols-3 gap-5 py-16">
            <div className="lg:col-span-2">
              <Link to="/">
                <div
                  className="grid md:grid-cols-2 gap-6 mt-4"
                  style={{ background: "#fafafacc" }}
                >
                  <img
                    src="https://images.jhtassets.com/72fb9ed098aeb7358dc90f0d9873d5cfe4d594d7/"
                    alt=""
                  />
                  <div className="pr-4 px-2 pt-4">
                    <div
                      className="font-bold text-2xl mb-4"
                      style={{ color: "#4c4c4c" }}
                    >
                      Параметры соединения
                    </div>
                    <p className="leading-7 mb-2" style={{ color: "#191919" }}>
                      Наша консоль 7xi предлагает бесперебойный вход в систему
                      xID, доступ к социальным сетям и широкие возможности
                      развлечений, включая любимые приложения для потоковой
                      передачи видео и музыки.
                    </p>
                    <Link
                      to="/"
                      className="block text-red-600 hover:text-red-700 font-bold"
                    >
                      Смотреть консоль 7xi /
                    </Link>
                  </div>
                </div>
              </Link>
              <Link to="/connected-solutions/asset-management">
                <div
                  className="grid md:grid-cols-2 gap-6 mt-4"
                  style={{ background: "#fafafacc" }}
                >
                  <img
                    src="https://images.jhtassets.com/abcbeaf93a4f4b0e01df77181fc590f36a0a155f/"
                    alt=""
                  />
                  <div className="pr-4 px-2 pt-4">
                    <div
                      className="font-bold text-2xl mb-4"
                      style={{ color: "#4c4c4c" }}
                    >
                      Asset Management
                    </div>
                    <p className="leading-7 mb-2" style={{ color: "#191919" }}>
                      Asset Management автоматически следит за тренажерами и
                      выполняет функции технического обслуживания, контроля
                      состояния и управления оборудованием.
                    </p>
                    <Link
                      to="/connected-solutions/asset-management"
                      className="block text-red-600 hover:text-red-700 font-bold"
                    >
                      Asset Management /
                    </Link>
                  </div>
                </div>
              </Link>
              <Link to="/connected-solutions/unique-programs">
                <div
                  className="grid md:grid-cols-2 gap-6 mt-4"
                  style={{ background: "#fafafacc" }}
                >
                  <img
                    src="https://images.jhtassets.com/f5378735bdbbdd65937ffa8de6991f0534376303/"
                    alt=""
                  />
                  <div className="pr-4 px-2 pt-4">
                    <div
                      className="font-bold text-2xl mb-4"
                      style={{ color: "#4c4c4c" }}
                    >
                      Система виртуальных ландшафтов Virtual Active
                    </div>
                    <p className="leading-7 mb-2" style={{ color: "#191919" }}>
                      Технология реалистичного погружения Virtual Active
                      синхронизирует скорость тренировки с красивыми
                      видеороликами в высоком разрешении. Ваши клиенты смогут
                      путешествовать по легендарным городам и посетить во время
                      занятий самые экзотические уголки планеты.
                    </p>
                    <Link
                      to="/connected-solutions/unique-programs"
                      className="block text-red-600 hover:text-red-700 font-bold"
                    >
                      Virtual Active /
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div>
              <div className="text-3xl lg:text-5xl leading-none text-white lg:text-zinc-700">
                ПРОСТЫЕ КОМПЛЕКСНЫЕ РЕШЕНИЯ
              </div>
              <p className="leading-6 my-4 text-white lg:text-zinc-900">
                Технологии стали ожидаемой частью опыта тренировок, и наши
                ведущие в отрасли консоли превосходят эти ожидания. Наша консоль
                7xi максимально повышает возможности подключения и развлечения.
                Все наши консоли отличаются интуитивным дизайном, который даже
                самые требовательные пользователи найдут доступным и простым в
                использовании. Мы предлагаем эксклюзивные решения, которые
                делают управление оборудованием проще, чем когда-либо.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="section-5">
        <Container maxWidth="xl" className="md:!px-16">
          <div
            className="text-center text-3xl lg:text-6xl mt-16"
            style={{ color: "#4c4c4c" }}
          >
            НАМ ДОВЕРЯЮТ ВО ВСЕМ МИРЕ
          </div>
          <p
            className="md:w-4/5 text-center mx-auto leading-6 mt-4"
            style={{ color: "#191919" }}
          >
            Компания Matrix гордится тем, что ее оборудование установлено в
            самых лучших гостиницах и курортах по всему миру. Это лишь небольшая
            подборка организаций, с которыми мы сотрудничаем, и список которых
            растет с каждым днем. Наша глобальная сеть продаж позволяет нам
            обслуживать клиентов в густонаселенных городах, на отдаленных
            островах и в любом другом месте.
          </p>
          <Link
            to="/showcases/hospitality"
            className="block text-center mt-4 text-red-600 font-bold hover:text-red-700"
          >
            Узнать больше о фитнес-клубах /
          </Link>
          <div className="grid md:grid-cols-3 gap-6 mt-16 mb-12">
            {boxes?.map((box) => (
              <div
                onClick={() => setModal(box.id)}
                key={box.id}
                className="center-box-animation"
              >
                <IconButton className="!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10">
                  <MdPhotoLibrary fill="#4f8493" size={32} />
                </IconButton>
                <img className="h-full w-full" src={box.image} alt="" />
                <div className="center-box-absolute p-2">
                  <div className="center-box-title">{box.title}</div>
                  <div className="center-box-subtitle">{box.subtitle}</div>
                  <div className="center-box-name">{box.name}</div>
                </div>
              </div>
            ))}
          </div>
        </Container>
        {modal && (
          <div
            className="fixed top-0 right-0 bottom-0 left-0 z-20"
            style={{ background: "rgba(26,26,26,0.8)" }}
          >
            <span
              onClick={() => setModal()}
              className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Swiper
              hashNavigation={{
                watchState: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper showcases-swiper"
              style={{
                height: "70%",
                width: "70%",
                transform: "translate(0, 25%)",
              }}
            >
              {handleModal?.gallery?.map((image, idx) => (
                <SwiperSlide data-hash={`slide${idx}`}>
                  <img src={image.image} className="mx-auto" alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </section>
      <section
        className="section-6 !bg-cover"
        style={{
          background:
            "url(https://images.jhtassets.com/4d16d137535c375ee4431b874aaa88d5660e1f28) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12 !py-12">
          <div className="grid lg:grid-cols-3 gap-8 py-16">
            <div className="lg:col-span-2">
              <Link to="">
                <img
                  src="https://images.jhtassets.com/aa57fce60422bdc379cf07b9c63040fdc2cbeda5/"
                  alt=""
                />
                <div
                  className="font-bold text-center text-xl"
                  style={{ color: "#4c4c4c" }}
                >
                  Virtual Training Cycle
                </div>
              </Link>
            </div>
            <div>
              <div
                className="text-3xl lg:text-6xl leading-none"
                style={{ color: "#4c4c4c" }}
              >
                ЛУЧШИЕ ИЗ ЛУЧШИХ
              </div>
              <p className="my-4" style={{ color: "#191919" }}>
                Посмотрите на наши самые популярные модели премиум-класса и
                узнайте, как в сотрудничестве с компанией Matrix вы можете
                создать уникальное фитнес-пространство, подходящее вашему
                помещению и имиджу.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <Container
        maxWidth="xl"
        className="md:!px-16"
        style={{ marginTop: "-5%" }}
      >
        <div className="grid lg:grid-cols-2 gap-8 pb-12">
          <Link to="" className="grid md:grid-cols-2 gap-4">
            <img
              src="https://images.jhtassets.com/a4100c3246d1f71747000e8294fa9bca760fe5bf/transformed/h_500"
              alt=""
            />
            <div
              className="font-bold m-auto text-xl leading-none"
              style={{ color: "#4c4c4c" }}
            >
              Versa Functional Trainer
            </div>
          </Link>
          <Link to="" className="grid md:grid-cols-2 gap-4">
            <img
              src="https://images.jhtassets.com/e5617024c8471387d45fc195dac90132fff9d8f9/"
              alt=""
            />
            <div
              className="font-bold m-auto text-xl leading-none"
              style={{ color: "#4c4c4c" }}
            >
              Performance Беговая Дорожка С Сенсорной Консолью XL
            </div>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default Hospitality;
