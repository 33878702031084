import { Container, Box } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BgImageSection from '../components/BgImageSection'
import CardBox from '../components/CardBox'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CenterContentBox from '../components/CenterContentBox'
import { getFilter } from '../redux/actions/filterActions'

const GroupTrainingFunctionalStrength = () => {
	const dispatch = useDispatch();
	return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '460px'}}>
				<img
					src="https://images.jhtassets.com/b77d6b7943bd7b5e7085cef0ef7eb2da1851ae3a/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-2xl md:text-5xl relative z-10 px-5 md:text-white">Групповые функционально-силовые тренировки</h3>
			</section>
			<section>
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Для тех, кто предпочитает групповые функционально-силовые тренировки, мы предлагаем уникальные и непревзойденные программы динамичных тренировок. Наши «умные» модульные конструкции обеспечивают гибкость и многофункциональность, что позволяет легко преобразовывать пустующее пространство в функциональные тренировочные зоны и расширять ваши предложения по мере роста интереса к такому виду тренировок.</p>
					<a
					onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 12)
                    dispatch(getFilter({
                        series: 12,
                    }))
                }}
						href="/group-training/catalog"
						className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					>
						<span className='text-base font-black'>Посмотреть все тренажеры для групповых функционально-силовых тренировок</span>
					</a>
					<Box className='my-6'>
						<CardBoxMoreImage
							title="Линейка"
							subtitle="В нашу коллекцию спортивного оборудования для функционально-силовых тренировок входят Double MEGA Rack, Connexus Functional Training System, а также множество аксессуаров и приспособлений для Connexus."
							image="https://images.jhtassets.com/3628d53484e95902416bc8e554d1ccfd4ece26f8/"
							contentBgColor="#000"
							contentRight={true}
							contentWidth="27%"
							contentTitleColor="#e6e5e5"
							contentSubtitleColor="#c2c6c9"
						/>
					</Box>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="700px"
				img="https://images.jhtassets.com/f23b12d7fc7f9011e36cba6181306c28c0b9aa8d/"
				contentWidth="450px"
				contentRight={false}
				contentBg="#e1261c"
				title="Connexus Functional Training System"
				titleColor="#f5f5f5"
				text="Connexus идеально подходит для функциональных тренировок, в ходе которых участники выполняют упражнения с отягощением, сопоставимым по нагрузке с их повседневной активностью. Connexus предлагает владельцам и клиентам фитнес-залов гибкость в использовании, неограниченные возможности расширения и опцию моментальной обратной связи для постоянного совершенствования. За счет множества точек креплений вы можете использовать различные аксессуары и тренировочные режимы, что позволит вам проводить занятия для клиентов с самыми разными уровнями подготовки."
				textColor="#f9d2d0"
			/>
			<section className='py-3 lg:p-14' style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid' maxWidth="xl">
					<Box className='grid lg:grid-cols-2 gap-3 mb-7'>
						<CardBox
							title="Connexus Step+"
							text="Step — это компактная, универсальная степ-платформа, которая может использоваться как в стандартной, так и в перевернутой конфигурации. Она идеально подходит для прокачивания мышц кора, нижней части тела и кардиотренировок. Эта прочная многоярусная конструкция снабжена текстурированным покрытием для поддержания равновесия, а ее ширина, высота и глубина оптимизированы для клиентов самых разных уровней подготовки."
							link="Узнать больше /"
							img="https://images.jhtassets.com/6a51bca770e97a6c5d6b88b7c0a732ca99e38629/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="50px"
							linkTo="/group-training/strength-flexibility/connexus-step-plus"
						/>
						<CardBox
							title="Библиотека упражнений"
							text="Получите доступ более чем к 200 упражнениям Connexus, которые могут использоваться инструкторами для создания и проработки собственных оригинальных занятий и тренировочных программ. Также при помощи библиотеки инструкторы могут менять существующие программы, находя альтернативные упражнения, чтобы повысить или снизить интенсивность тренировок для клиентов разного уровня подготовки."
							link="Изучить библиотеку упражнений /"
							img="https://images.jhtassets.com/6b5e1a9637361d86ed28bedfbc787d6cca30c143/"
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#fff"
							contentPt="50px"
							linkTo="/"
						/>
					</Box>
					<CardBoxMoreImage
						title="Double MEGA Rack"
						subtitle="Double MEGA Rack состоит из двух рам и специальных элементов крепления, что позволяет настроить оборудование под ваши конкретные цели. Благодаря стальным стойкам большого сечения и мощным рамам Double MEGA Rack выдерживает самых сильных спортсменов и самые жесткие режимы тренировки. С Double MEGA Rack спортсмены смогут использовать ваше пространство по максимуму."
						link="Узнать больше /"
						image="https://images.jhtassets.com/7c0cf222a6c6297ace85d880da59dc8c36575dd0/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="30%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						linkTo="/group-training/strength-flexibility/double-mega-rack-with-pyramid-ladder"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	)
}

export default GroupTrainingFunctionalStrength