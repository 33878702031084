import React from 'react'
import { Container, Box } from '@mui/material'
import BgImageSection from '../components/BgImageSection'
import CardBoxMoreImage from '../components/CardBoxMoreImage'
import CardBox from '../components/CardBox'


const ConnectedSolutionsCommunity360 = () => {
	return (
		<>
			<div className='relative items-end justify-center-end main-media-height' style={{
				height: '450px'
			}}>
				<img
					src="https://images.jhtassets.com/2b24b44a79bb60750f137692d3d948437ae1d09b/"
					className='md:absolute top-0 left-0 w-full h-full object-cover'
					alt=""
				/>
				<p className="md:text-5xl text-3xl md:absolute md:bottom-0 w-full relative z-10 pb-12 text-center uppercase mt-auto">COMMUNITY <span className='text-red-700'>360</span></p>
			</div>
			<Container maxWidth="xl" className='text-center py-5 lg:py-20'>
				<h3 className='text-zinc-600 font-black uppercase text-3xl mb-6'>СИЛЬНЕЕ. СТРОЙНЕЕ. ЛУЧШЕ. ВМЕСТЕ.</h3>
				<p className='text-zinc-600 md:w-2/3 mx-auto'>Наше приложение Community 360 позволяет легко сформировать активное виртуальное сообщество любителей фитнеса, объединяющее всех участников, независимо от того, где именно они тренируются. Вы сможете предложить своим клиентам ежедневные обновления тренировок, задания и конкурсы для участников сообщества, мотивирующие сообщения, ленты активности, расписания занятий, графики работы объекта, программы поощрения, бонусы за рекомендации и множество других преимуществ под вашим уникальным брендом. Система Community 360 совместима с большинством популярных приложений и портативных устройств, и при этом предлагает более широкие возможности, чем просто сбор показателей тренировок ваших клиентов. Она объединяет людей, выстраивая отношения и формируя лояльность на долгие годы.</p>
			</Container>
			<section className='mb-20'>
				<Container>
					<article className='lg:flex pb-5 lg:pb-14 gap-6'>
						<img
							className='w-full lg:w-3/5 object-cover'
							src="https://images.jhtassets.com/29a8df64e81d2cfdfee249f7ce393524a6f6e30f/"
							alt=""
						/>
						<div className='pt-10'>
							<h3 className='mb-6 text-zinc-600 text-2xl font-bold'>Создание сообщества</h3>
							<p className='text-zinc-700'>Community 360 позволяет пользователям быстро и легко присоединяться к заданиям и конкурсам для укрепления товарищеских отношений за счет соревновательного духа. Используя ленту активности сообщества, пользователи могут подбадривать своих друзей.</p>
						</div>
					</article>
					<article className='lg:flex flex-row-reverse pb-5 lg:pb-14 gap-6'>
						<img
							className='w-full lg:w-3/5 object-cover'
							src="https://images.jhtassets.com/08d7b71c375f203c236f2b7073ff7c8ded197c79/"
							alt=""
						/>
						<div className='pt-10'>
							<h3 className='mb-6 text-zinc-600 text-2xl font-bold'>Простое отслеживание тренировок</h3>
							<p className='text-zinc-700'>С помощью Community 360 клиенты смогут без труда отслеживать показатели своих тренировок и хранить все данные в одном месте. Ваши клиенты всегда будут получать заслуженное признание их усердной работы, независимо от того, каким способом были получены данные - путем ввода вручную, передачи данных с тренажеров, из других приложений или от носимых устройств.</p>
						</div>
					</article>
					<article className='lg:flex pb-5 lg:pb-14 gap-6'>
						<img
							className='w-full lg:w-3/5 object-cover'
							src="https://images.jhtassets.com/c87df0ccaf7e435624821dfc17a3f0315075f86e/"
							alt=""
						/>
						<div className='pt-10'>
							<h3 className='mb-6 text-zinc-600 text-2xl font-bold'>Более эффективная постановка целей</h3>
							<p className='text-zinc-700'>В разделе Goal Center пользователи могут легко и быстро устанавливать новые цели, а также отслеживать результаты с помощью наглядных графиков и мотивирующих функций Activity Levels (уровни активности), Gym Ranking (рейтинг зала) и BioAge (биовозраст).</p>
						</div>
					</article>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="80vh"
				img="https://images.jhtassets.com/274e808a0629cef50f02e5482ef98523fe99c24b/"
				title="Эффективное управление объектом"
				text="Community 360 позволит вам легко управлять регистрацией пользователей, цифровыми гостевыми пропусками, расположением объектов, графиками и многим другим. Вы даже сможете видеть срезы активности пользователей в режиме реального времени и получать ежемесячные отчеты для анализа реакции клиентов на ваши предложения."
				contentWidth="33%"
				titleColor="#4c4c4c"
				contentRight={true}
				contentBg="#c2c6c9"
				textColor="#666"
				linkColor="#e1261c"
			/>
			<section>
				<Container maxWidth="xl" className='py-10 !grid gap-8'>
					<CardBoxMoreImage
						title="Повышение заполняемости групповых тренировок"
						subtitle="С помощью интегрированного расписания пользователи могут легко находить и записываться на групповые занятия со своих мобильных устройств, а также своевременно получать напоминания."
						image="https://images.jhtassets.com/9d2c81563a440bf0cd692dff8e8b867241e401d3/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="26%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
					<CardBoxMoreImage
						title="Создание уникального предложения"
						subtitle="Вы можете сделать приложение уникальным, используя фирменные цвета, надписи и изображения, чтобы повысить узнаваемость своего фитнес-центра на фоне конкурентов, а также привлечь больше клиентов за счет ссылок на социальные сети и сайты."
						image="https://images.jhtassets.com/a3a34ab0cbefe48a86d29a1c493292e7ad79d843/"
						contentBgColor="#000"
						contentRight={false}
						contentWidth="26%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
					/>
				</Container>
			</section>
			<section className='py-14'>
				<Container>
					<h3 className='mb-6 text-zinc-600 text-3xl font-black text-center'>Новые способы мотивировать клиентов</h3>
					<Box className='grid lg:grid-cols-2 gap-6'>
						<CardBox
							title="Развитие новых источников дохода"
							text="Отправляйте push-уведомления с информацией о специальных предложениях для повышения популярности стратегических направлений развития вашего бизнеса, а также используйте функцию «запрос тренировки», чтобы выявлять потенциальных клиентов для персональных тренировок."
							img='https://images.jhtassets.com/8dbc69f7c6f0af952dd8680735a755ce56e266ff/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
						<CardBox
							title="Поощрение активных пользователей"
							text="Бонусные программы могут быть привязаны к персональному xID каждого пользователя для начисления баллов за регистрацию, персональные тренировки, покупки и многое другое, а продвижение через мобильное приложение программ поощрения за рекомендации позволяет стимулировать приток новых клиентов."
							img='https://images.jhtassets.com/3cdb3e6d3adf0cc4f8583b399df598bce47153cf/'
							color="#4c4c4c"
							textColor="#666"
							contentBgColor="#c2c6c9"
							contentPt="50px"
						/>
					</Box>
				</Container>
			</section>
		</>
	)
}

export default ConnectedSolutionsCommunity360