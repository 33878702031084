import React from "react";
import { Link } from "react-router-dom"
import { Box } from "@mui/material";

const CenterContentBox = (props) => {
   return (
		<div className="CenterContentBox py-52 text-center relative"
			style={{
				background: props.boxBg
			}}
		>
			{
				props.bgImage ? 
				<img className="absolute top-0 left-0 w-full h-full object-cover" src={props.bgImage} alt="" style={{zIndex: '-1'}} /> :
				''
			}
			<h4 className="font-black text-2xl text-gray-600 mb-4 md:text-3xl"
				style={{
					color: props.titleColor
				}}
			>{props.title}</h4>
			{
				props.subtitle ?
				<p className="text-gray-500 py-2 px-5 lg:px-32" style={{color: props.subtitleColor}}>{props.subtitle}</p> :
				''
			}
			<Box>
				<Link
					to={props.linkTo ? props.linkTo : ''}
					className="button-red px-6 py-1 mt-4 inline-block hover:bg-red-700 duration-200"
				>
					<span className='text-base font-black'>{props.link}</span>
				</Link>
			</Box>
		</div>
	);
};

export default CenterContentBox;
