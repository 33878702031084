const INITIAL_STATE = {
    list: [],
    categories: [],
    searchList: [],
    loading: false,
    filterLoading: false,
    searchLoading: false,
    message: null,
    data: {},
}

export default (state = INITIAL_STATE, {type, payload, ...rest}) => {
    switch(type) {

        case 'fetch_category_start':
            return {...state, loading: true, message: ''};
        case 'fetch_category_error':
            return {...state, message: payload, loading: false};
        case 'fetch_category_success':
            return {
                ...state,
                loading: false,
                categories: payload.data,
            };

        case 'fetch_filter_start':
            return {...state, filterLoading: true, message: ''};
        case 'fetch_filter_error':
            return {...state, message: payload, filterLoading: false};
        case 'fetch_filter_success':
            return {
                ...state,
                filterLoading: false,
                list: payload.data,
            };

        case 'fetch_search_start':
            return {...state, searchLoading: true, message: ''};
        case 'fetch_search_error':
            return {...state, message: payload, searchLoading: false};
        case 'fetch_search_success':
            return {
                ...state,
                searchLoading: false,
                searchList: payload.data,
            };

        default: return state;
    }
}