import React, { useState } from 'react';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, IconButton } from '@mui/material';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/ceafcb645f473b57cf894606e3a8072b848ec6af/',
            },
            {
                image: 'https://images.jhtassets.com/e3a88b06bcb962c74cf6e311be5fe74e086b9be2/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/dcbe4e56e6f65433229c9bfd1d6f849f8f2d4f34/',
            },
            {
                image: 'https://images.jhtassets.com/fdaf5c6136b16f944b2e944284df2d4270ddd234/',
            },
            {
                image: 'https://images.jhtassets.com/aa56b45e15575efe3c1135ce01e98761a76dd7e7/',
            },
            {
                image: 'https://images.jhtassets.com/95ca6118b2ffe13bdbea4efc3803bb9ce0100cd5/',
            },
            {
                image: 'https://images.jhtassets.com/86f20b87a8d7541e75df0515be79b8611b809091/',
            },
            {
                image: 'https://images.jhtassets.com/6a9ccaeb6015e91905119dd1e4acd03fce38ab8e/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/f6b80f91519b1df3425714f46caa90694587461a/',
            },
            {
                image: 'https://images.jhtassets.com/cadbbf9e1da771089506e4587f980170835deac3/',
            },
            {
                image: 'https://images.jhtassets.com/781e5b254814584f017b945adef3d96241723e1b/',
            },
        ],
    },
    {
        id: 4,
        gallery: [
            {
                image: 'https://images.jhtassets.com/24cfb0c3dc1aacf5bae5d49ad94188ec38b2d776/',
            },
            {
                image: 'https://images.jhtassets.com/9ecdcf18c34a4343b6e6804c73d0906d8e6e75fc/',
            },
            {
                image: 'https://images.jhtassets.com/26be70f5d9f051cbf566cbdcbf3ab2f3ee0d58f1/',
            },
            {
                image: 'https://images.jhtassets.com/09d8ca5e76186990bf94826895fdb2d4e35f6a23/',
            },
            {
                image: 'https://images.jhtassets.com/5a06a510f3564c1a6a0214c66e5a11d4a2d2d535/',
            },
        ],
    },
    {
        id: 5,
        gallery: [
            {
                image: 'https://images.jhtassets.com/308a6a05032eab8f923b752b9d58cc652df1d65d/',
            },
            {
                image: 'https://images.jhtassets.com/e2a3e5b9fc409133b8818b7193c0dbc6859da7e1/',
            },
            {
                image: 'https://images.jhtassets.com/a4b497c8aba1778782ded0003b6a19535b5c6116/',
            },
            {
                image: 'https://images.jhtassets.com/015968507e9e8fe3f19bf02210d424dec76830ff/',
            },
            {
                image: 'https://images.jhtassets.com/54a8b614ec3b4fc188fb712d33d5bc03ef3fb2ac/',
            },
            {
                image: 'https://images.jhtassets.com/8858584db8170b24b8af044f689a4c1b0aae2f20/',
            },
            {
                image: 'https://images.jhtassets.com/f5b3bc456fc6f154f254ad1f911ba138bf14ef8a/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/ceafcb645f473b57cf894606e3a8072b848ec6af/transformed/h_558,w_440,c_fill',
        title: 'Marana',
        subtitle: 'Encantada at Dove Mountain',
        name: 'США',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/dcbe4e56e6f65433229c9bfd1d6f849f8f2d4f34/transformed/h_558,w_440,c_fill',
        title: 'Atlanta',
        subtitle: '77 12th Street Apartments',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/f6b80f91519b1df3425714f46caa90694587461a/transformed/h_558,w_440,c_fill',
        title: 'Sunnyvale',
        subtitle: 'Renaissance Apartments',
        name: 'США',
    },
    {
        id: 4,
        image: 'https://images.jhtassets.com/24cfb0c3dc1aacf5bae5d49ad94188ec38b2d776/transformed/h_558,w_558,c_fill',
        title: 'Germantown',
        subtitle: 'Bozzuto Milestone Apartments',
        name: 'США',
    },
    {
        id: 5,
        image: 'https://images.jhtassets.com/308a6a05032eab8f923b752b9d58cc652df1d65d/transformed/h_558,w_558,c_fill',
        title: 'Duluth',
        subtitle: 'Astor Place Apartments',
        name: 'США',
    },
];

const ShowcasesMultiHousing = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='showcasesMultiHousing-page'>
        <section className='section-1 flex items-center justify-center md:py-0 pt-12 pb-32 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white text-3xl md:text-6xl text-center leading-none'>Жилые многоквартирные дома</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-2xl md:text-4xl leading-none' style={{color: '#4c4c4c'}}>ПОВЫШЕНИЕ ЭФФЕКТИВНОСТИ ПРОГРАММ ОЗДОРОВЛЕНИЯ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesMultiHousing;