import React from 'react'

const Product = (props) => {
  return (
	 <div className='Product p-7 bg-white h-full flex flex-col justify-center'>
		<img style={{maxHeight: '450px'}} src={props.image} alt="" className="Product-image max-w-full" />
		<div className="Product-title font-bold text-2xl text-red-600 mt-auto">{props.title}</div>
	 </div>
  )
}

export default Product