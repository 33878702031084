import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';
import '../assets/scss/_cardio_consoles.scss';
import BgImageSection from '../components/BgImageSection'
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import $16 from '../assets/videos/1-16.mp4';
import $17 from '../assets/videos/1-17.mp4';
import $18 from '../assets/videos/1-18.mp4';
import $19 from '../assets/videos/1-19.mp4';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const CardioConsoles = () => {
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = useState('');
  const handleOpen = ($index) => {
    setOpen(true);
    setVideo($index)
  };
  const handleClose = () => setOpen(false);

  return (
    <section className='cardioConsoles-page'>
        <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={video}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
        <section className='section-1 flex items-center justify-between'>
            <Container>
                <div className='text-3xl font-bold md:text-6xl mb-7 text-center text-white'>Консоли для кардиооборудования</div>
                <div className='text-lg font-bold text-center text-white'>Наша разнообразная коллекция консолей предоставляет на выбор различные варианты для установки в вашем зале. Технологичные и простые в использовании варианты исполнения обеспечат вашим клиентам управление собираемыми данными и доступ к вдохновляющему контенту, помогая достигать поставленных целей.</div>
            </Container>
        </section>
        <Container>
            <div className='flex items-center justify-center py-8 flex-wrap'>
                <a href='#section2' className='text-red-700 p-4 font-bold hover:text-red-900 hover:underline'>Консоли Touch</a>
                |
                <a href='#section3' className='text-red-700 p-4 font-bold hover:text-red-900 hover:underline'>Консоли Premium LED</a>
                |
                <a href='#section4' className='text-red-700 p-4 font-bold hover:text-red-900 hover:underline'>Консоли LED</a>
                |
                <a href='#section5' className='text-red-700 p-4 font-bold hover:text-red-900 hover:underline'>Сравнение консолей</a>
            </div>
        </Container>
        <section className='section-2' id='section2'>
            <Container className='py-16' maxWidth="xl">
                <div className='grid lg:grid-cols-2 gap-4 md:px-12'>
                    <div className='md:pt-12'>
                        <div className='font-black text-3xl leading-none' style={{color: '#4c4c4c'}}>Удобное и эффективное <br className='none md:block' /> сенсорное управление</div>
                        <div className='text-gray-400 mt-1 text-xl'>КОНСОЛИ TOUCH И TOUCH XL</div>
                        <label className='pt-4 pb-8 block'>Наши консоли Touch превосходят ожидания благодаря великолепному дисплею и гибкому интерфейсу на базе приложений. Клиенты смогут пользоваться развлекательными ресурсами, социальными сетями, средствами коммуникации с администрацией объекта и многими другими технологиями, а также бесплатно поучать обновления.</label>
                        <Link to='/cardio/consoles/touch' className='inline-block bg-red-600 hover:bg-red-700 text-white font-black px-4 py-2'>Узнать больше</Link>
                    </div>
                    <div>
                        <img onClick={() => handleOpen($16)} className='cursor-pointer' src='https://images.jhtassets.com/72fe3d274a8ce1fe0d4a3cd50c3b39139c4f72f5/' alt='' />
                    </div>
                </div>
                <div className='grid mt-6 lg:grid-cols-2 gap-4'>
                    <div className='grid sm:grid-cols-2 items-baseline'>
                        <img className='my-auto' src='https://images.jhtassets.com/e9dc8de351fe3c7845748613f73d0220069b429b/' alt='' />
                        <div className='pt-8'>
                            <div className='text-2xl font-bold' style={{color: '#4c4c4c'}}>Touch XL</div>
                            <p className='py-4' style={{color: '#191919'}}>Наши сенсорные консоли Touch XL с увеличенным экраном 56 см / 22" доступны исключительно для беговых дорожек и лестниц-эскалаторов всех серий кардиооборудования.</p>
                            <Link to='' className='text-red-600 hover:underline hover:text-red-700 font-bold'>Все оборудование с консолями XL /</Link>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-2 items-baseline'>
                        <img className='my-auto' src='https://images.jhtassets.com/e2755a3e9ac1e46161e7d7acc0eaee49d98de407/' alt='' />
                        <div className='pt-8'>
                            <div className='text-2xl font-bold' style={{color: '#4c4c4c'}}>Touch</div>
                            <p className='py-4' style={{color: '#191919'}}>Наши сенсорные консоли Touch с экраном 41 см / 16" доступны для тренажеров Performance Plus Treadmill, а также кардиооборудования серий Performance, Endurance и Lifestyle.</p>
                            <Link to='' className='text-red-600 hover:underline hover:text-red-700 font-bold'>Все оборудование с консолями Touch /</Link>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section
            className='section-3 py-5 lg:py-16 md:px-12'
            style={{background: 'linear-gradient(171.76deg,#253746,#000)'}}
            id='section3'
        >
            <Container maxWidth='xl'>
                <div className='grid xl:grid-cols-2'>
                    <img
                        onClick={() => handleOpen($17)}
                        className='h-full object-cover cursor-pointer'
                        src='https://images.jhtassets.com/fc6c529da8aaaf342e9014f049b481d98279a2c1/'
                        alt=''
                    />
                    <div className='p-5 lg:p-12 pb-2'>
                        <div style={{color: '#e6e5e5'}} className="font-black text-3xl leading-none">Четкие, яркие, лучше, чем когда-либо</div>
                        <div style={{color: '#c2c6c9'}}>КОНСОЛИ PREMIUM LED</div>
                        <div className='py-6' style={{color: '#c2c6c9'}}>Наши консоли Premium LED сочетают в себе интерфейс на основе пиктограмм с красочными изображениями на дисплее высокого разрешения, что делает навигацию простой, а тренировки увлекательными. Консоль может подключаться к Apple Watch, оснащена программой Sprint 8, возможностью авторизации с помощью RFID, разъемом для наушников, подставкой для устройств пользователя и подключением к WiFi для работы с Matrix Connected Solutions. Совместима со всем кардиооборудованием серий Performance, Endurance и Lifestyle.</div>
                        <div className='flex items-center flex-wrap gap-10'>
                            <Link to='/cardio/consoles/premium-led' className='bg-red-600 hover:bg-red-700 text-red-400 hover:text-white font-black p-4 transition duration-500 leading-none mr-8'>Узнать больше</Link>
                            <Link to='/cardio/consoles/premium-led' className='font-bold leading-none hover:!text-white duration-500 hover:underline' style={{color: '#ffffffcc'}}>Все оборудование с консолями Premium LED /</Link>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-4 py-5 lg:py-16 md:px-12' id='section4'>
            <Container maxWidth="xl">
                <div className='grid lg:grid-cols-2'>
                    <div className='pr-6'>
                        <div className='font-black leading-none md:text-3xl text-xl mt-16' style={{color: '#4c4c4c'}}>Синхронизированная работа для каждого пользователя</div>
                        <div className='mb-6' style={{color: '#c2c6c9'}}>КОНСОЛЬ ДЛЯ ГРУППОВЫХ ТРЕНИРОВОК GROUP TRAINING LED</div>
                        <p style={{color: '#191919'}}>Наша светодиодная консоль для групповых тренировок Group Training LED сделает занятия более комфортными и продуктивными. Простая навигация при помощи интерфейса, включающего такие функции как управление в одно касание и предустановленная интенсивность, позволяет всем участникам синхронно следовать подсказкам инструктора. Благодаря универсальной конструкции нашего тренажера на нем легко могут заниматься пользователи с различным уровнем подготовки. Оснащен опцией подключения к WiFi для работы с приложениями Connected Solutions от Matrix. Совместим со всем кардиооборудованием серий Performance, Endurance и Lifestyle.</p>
                    </div>
                    <div className=''>
                        <img className='cursor-pointer' onClick={() => handleOpen($18)} src='https://images.jhtassets.com/d971db54d31e06f9dc1bf8f3b0744f9b09096243/' alt='' />
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 pt-12'>
                    <div className=''>
                        <img className='cursor-pointer' onClick={() => handleOpen($19)} src='https://images.jhtassets.com/ed7abf9bcc4ce4e47df3b4a12204fc35c08710df/' alt='' />
                    </div>
                    <div className='pl-6'>
                        <div className='font-black leading-none text-3xl mt-16' style={{color: '#4c4c4c'}}>Для идеальных впечатлений от тренировок</div>
                        <div className='mb-6' style={{color: '#c2c6c9'}}>КОНСОЛЬ LED</div>
                        <p style={{color: '#191919'}}>С нашими светодиодными консолями ваши клиенты смогут полностью погрузиться в свои тренировки. Даже самые простые консоли могут иметь опцию подключения к WiFi для доступа к нашим самым эффективным сетевым решениям Connected Solutions, таким как Engage 360, Community 360 и Asset Management. Совместим со всем кардиооборудованием серий Performance, Endurance и Lifestyle.</p>
                    </div>
                </div>
            </Container>
        </section>
        <section className='section-5 py-16 md:px-12 overflow-auto' id='section5'>
            <Container
                maxWidth="xl"
                className=""
                style={{
                    width: '1280px'
                }}
            >
                <div className='font-black text-5xl text-center' style={{color: '#4c4c4c'}}>Сравнение консолей</div>
                <div className='flex items-center mt-12'>
                    <img className='mx-16' style={{width: '133px'}} src='https://images.jhtassets.com/dcbeb0fa0c27906e0dd622616d83f184eba0461d/' alt='' />
                    <img className='mx-16' style={{width: '133px'}} src='https://images.jhtassets.com/7f4bcbaf81fcc56e1dec7fdef9a80ab2f0082f91/' alt='' />
                    <img className='mx-16' style={{width: '133px'}} src='https://images.jhtassets.com/b6cc675b794d8c69c176fbd9ade9d15ab5bf8b3f/' alt='' />
                    <img className='mx-16' style={{width: '133px'}} src='https://images.jhtassets.com/44f4486ff6d12dcb1107354929e3fc14809155be/' alt='' />
                    <img className='mx-16' style={{width: '133px'}} src='https://images.jhtassets.com/347062079febed86e1df8b98f6d10e93e8fc1cc0/' alt='' />
                </div>
                <table className="w-full">
                    <thead>
                        <tr className='table-titles'>
                            <th>Характеристики консолей	</th>
                            <th>Консоль Touch XL</th>
                            <th>Консоль Touch</th>
                            <th>Консоль Premium LED</th>
                            <th>Консоль LED</th>
                            <th>Консоль Group Training LED</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>Данная программа может быть недоступна для этой рамы.</td>
                            <td>Данная программа может быть недоступна для этой рамы.</td>
                            <td>Данная программа может быть недоступна для этой рамы.</td>
                            <td>Данная программа может быть недоступна для этой рамы.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>ANT+</td>
                            <td>Да; частота сердечных сокращений</td>
                            <td>Да; частота сердечных сокращений</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Bluetooth</td>
                            <td>Да; смартфоны, наушники, частота сердечных сокращений</td>
                            <td>Да; смартфоны, наушники, частота сердечных сокращений</td>
                            <td>Да; частота сердечных сокращений</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>IPTV</td>
                            <td>Контент: Протоколы MPEG2/H262, AVC/H264: UDP, RTSP, HTTP, HTTPS</td>
                            <td>Контент: Протоколы MPEG2/H262, AVC/H264: UDP, RTSP, HTTP, HTTPS</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Wi-Fi</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>По спец. заказу; входит в состав приложения Asset Management и Workout Tracking Network</td>
                            <td>По спец. заказу; входит в состав приложения Asset Management и Workout Tracking Network</td>
                        </tr>
                        <tr>
                            <td>Автоматическое пробуждение</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Аналоговое телевидение</td>
                            <td>NTSC, PAL, SECAM</td>
                            <td>NTSC, PAL, SECAM</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                        </tr>
                        <tr>
                            <td>Беспроводная зарядка (Qi)</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Беспроводной вход RFID</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>По спец. заказу</td>
                            <td>По спец. заказу</td>
                            <td>По спец. заказу</td>
                        </tr>
                        <tr>
                            <td>Вентилятор</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Готовность к работе с CSAFE</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Да</td>
                        </tr>
                        <tr>
                            <td>Дисплей</td>
                            <td>Емкостный сенсорный ЖК-дисплей 56 см / 22 дюйма</td>
                            <td>Емкостный сенсорный ЖК-дисплей 41 см / 16 дюймов</td>
                            <td>8000-пиксельный цветной светодиодный</td>
                            <td>Большой цифровой светодиодный с центром сообщений</td>
                            <td>Большой цифровой светодиодный с центром сообщений</td>
                        </tr>
                        <tr>
                            <td>Подключение к Apple Watch</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>По спец. заказу</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Подключение к Samsung Galaxy Watch</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Подходит для iPhone, iPad, iPod</td>
                            <td>Да</td>
                            <td>Да</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Порт USB</td>
                            <td>Да; зарядка устройства, медиафайлы в устройстве, обновление программного обеспечения</td>
                            <td>Да; зарядка устройства, медиафайлы в устройстве, обновление программного обеспечения</td>
                            <td>Да; зарядка устройства, обновление программного обеспечения</td>
                            <td>Да; зарядка устройства, обновление программного обеспечения</td>
                            <td>Да; зарядка устройства, обновление программного обеспечения</td>
                        </tr>
                        <tr>
                            <td>Совместимость с Pro:Idiom</td>
                            <td>По спец. заказу; IPTV и коакс.</td>
                            <td>По спец. заказу; IPTV и коакс.</td>
                            <td>По спец. заказу; надстройка Pro:Idiom TV (≠ IPTV)</td>
                            <td>По спец. заказу; надстройка Pro:Idiom TV (≠ IPTV)</td>
                            <td>По спец. заказу; надстройка Pro:Idiom TV (≠ IPTV)</td>
                        </tr>
                        <tr>
                            <td>Тренировки</td>
                            <td>Ходьба, ручной режим, Sprint 8†, визуальные ориентиры†, Virtual Active (виртуальные ландшафты), целевой сердечный ритм, интервальный режим, сжигание жира, холмы, заданное значение мощности в ваттах†, тренировка Glute (ягодичных мышц)†, тренировки с заданными параметрами, тест на уровень спортивной подготовки, пользовательский режим</td>
                            <td>Ходьба, ручной режим, Sprint 8†, визуальные ориентиры†, Virtual Active (виртуальные ландшафты), целевой сердечный ритм, интервальный режим, сжигание жира, холмы, заданное значение мощности в ваттах†, тренировка Glute (ягодичных мышц)†, тренировки с заданными параметрами, тест на уровень спортивной подготовки, пользовательский режим</td>
                            <td>Ходьба, ручной режим, Sprint 8†, визуальные ориентиры†, целевой сердечный ритм, интервальный режим, сжигание жира, холмы, заданное значение мощности в ваттах†, тренировка Glute (ягодичных мышц)†, 5k/10k, тест на уровень спортивной подготовки</td>
                            <td>Ходьба, ручной режим, интервальный режим, сжигание жира, холмы, целевой сердечный ритм, тренировка Glute (ягодичных мышц)†, тест на уровень спортивной подготовки</td>
                            <td>Вручную</td>
                        </tr>
                        <tr>
                            <td>Тренировки iFIT по выбору</td>
                            <td>Опция</td>
                            <td>Опция</td>
                            <td>Нет</td>
                            <td>Нет</td>
                            <td>Нет</td>
                        </tr>
                        <tr>
                            <td>Цифровое телевидение</td>
                            <td>ATSC 1.0, QAM-B, ISDB-T, ISDB-Tb, DVB-C/S/S2/T/T2</td>
                            <td>ATSC 1.0, QAM-B, ISDB-T, ISDB-Tb, DVB-C/S/S2/T/T2</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                            <td>По спец. заказу; надстройка ТВ</td>
                        </tr>
                        <tr>
                            <td>Языки</td>
                            <td>Английский, немецкий, французский, итальянский, испанский, голландский, португальский, китайский (упрощенный), китайский (традиционный), японский, корейский, шведский, финский, русский, арабский, турецкий, польский, валлийский, баскский, вьетнамский, сомалийский, датский, тайский, малайский, каталанский</td>
                            <td>Английский, немецкий, французский, итальянский, испанский, голландский, португальский, китайский (упрощенный), китайский (традиционный), японский, корейский, шведский, финский, русский, арабский, турецкий, польский, валлийский, баскский, вьетнамский, сомалийский, датский, тайский, малайский, каталанский</td>
                            <td>Английский, немецкий, французский, итальянский, испанский, голландский, португальский, шведский, финский, турецкий, датский, польский</td>
                            <td>Английский, немецкий, французский, итальянский, испанский, голландский, португальский, шведский, финский, турецкий, датский, польский</td>
                            <td>Английский, немецкий, французский, итальянский, испанский, голландский, португальский, шведский, финский, турецкий, датский, польский</td>
                        </tr>
                    </tbody>
                </table>
            </Container>
        </section>
        <BgImageSection
            sectionHeight="80vh"
            img="https://images.jhtassets.com/089b4b225a8b21a62de073ddd52334bf80192274/"
            contentWidth="35%"
            contentRight={false}
            contentBg="#000"
            title="Связь. Гибкость. Индивидуальный подход. Это будущее фитнеса."
            titleColor="#e6e5e5"
            text="Консоли с подключением к WiFi идеально работают с нашими эффективными цифровыми решениями Connected Solutions, такими как Engage 360, Community 360, Asset Management и другими."
            textColor="#e6e5e5"
            link="Ознакомьтесь с нашими технологиями Connected Solutions /"
            linkBgRed={false}
            linkTo="/connected-solutions"
            paddingTopNull={true}
        />
        <section className="section-7 py-16 flex items-center">
            <Container maxWidth="xl" className="text-center">
                <div className='font-black text-3xl mb-8' style={{color: '#4c4c4c'}}>Узнайте все о Matrix</div>
                <div className='flex justify-center'><Link to='/cardio' className='inline-block bg-red-600 hover:bg-red-700 duration-200 text-white p-4 font-black'>Познакомьтесь со полной коллекцией кардиооборудования</Link></div>
            </Container>
        </section>
    </section>
  )
}

export default CardioConsoles;