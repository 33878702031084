import request from "../../helpers/request";

export const category = (params) => (dispatch) => {
    dispatch({type: 'fetch_category_start', payload: params})

    request
      .category(params)
      .then(({data}) => {
        dispatch({type: 'fetch_category_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'fetch_category_error'})
      })
};

export const getFilter = (params) => (dispatch) => {
    dispatch({type: 'fetch_filter_start', payload: params})

    request
      .getFilter(params)
      .then(({data}) => {
        dispatch({type: 'fetch_filter_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'fetch_filter_error'})
      })
};

export const getSearch = (params) => (dispatch) => {
    dispatch({type: 'fetch_search_start', payload: params})

    request
      .getSearch(params)
      .then(({data}) => {
        dispatch({type: 'fetch_search_success', payload: data})
      })
      .catch(({response}) => {
        dispatch({type: 'fetch_search_error'})
      })
};