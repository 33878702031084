import React, { useState } from 'react';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, IconButton } from '@mui/material';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/8575b703644f3d74fee03e6bfbd88e040f7d9334/',
            },
            {
                image: 'https://images.jhtassets.com/160dd47938fb975430e137beeda5dcd7c9d4f0e8/',
            },
            {
                image: 'https://images.jhtassets.com/15470a29754aef5f6802a0a6cfe81b80ec02ba0f/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/e67a47de841323a9e2d68b4e40a08dab77d49ebc/',
            },
            {
                image: 'https://images.jhtassets.com/27058680ee7e2f20fb259812a99732d6423694eb/',
            },
            {
                image: 'https://images.jhtassets.com/10e5cdeb079efb79702c4b67ab501832e7ed8229/',
            },
            {
                image: 'https://images.jhtassets.com/f6197c0cf0ea60b17e6e376eca9d77d3de391bbc/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/c4cade8d0499aa7788e55450ad9b7dc0a8577137/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/8575b703644f3d74fee03e6bfbd88e040f7d9334/transformed/h_558,w_440,c_fill',
        title: 'Sumter',
        subtitle: 'Sumter',
        name: 'США',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/e67a47de841323a9e2d68b4e40a08dab77d49ebc/transformed/h_558,w_440,c_fill',
        title: 'Hudson',
        subtitle: 'Hudson YMCA',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/c4cade8d0499aa7788e55450ad9b7dc0a8577137/transformed/h_558,w_440,c_fill',
        title: 'Danville',
        subtitle: 'Danville Family',
        name: 'США',
    },
];

const ShowcasesYmcaJcc = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='shocasesYmcaJcc-page'>
        <section className='section-1 flex items-center justify-center md:py-0 pt-12 pb-32 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white md:text-6xl text-4xl text-center leading-none'>Программы YMCA и JCC</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-3xl md:text-4xl leading-none' style={{color: '#4c4c4c'}}>ВАША ЦЕЛЬ — ЭТО ДВИЖЕНИЕ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesYmcaJcc;