import React from 'react'

const HoverToShowBg = (props) => {
	return (
		<article className='relative group flex items-center justify-center h-36 mx-2 mb-5'>
			<img
				className='absolute w-full h-full top-0 left-0 object-cover'
				src={props.img}
				alt=""
			/>
			<span className='relative z-20 text-white transition-all opacity-0 group-hover:opacity-100'>{props.title}</span>
			<div 
				className='absolute z-10 top-1/2 left-1/2 w-full h-0 bg-zinc-900/30 -translate-x-1/2 -translate-y-1/2 group-hover:h-full group-hover:opacity-100'
				style={{
					transition: 'all 0.3s ease'
				}}
			></div>
		</article>
	)
}

export default HoverToShowBg