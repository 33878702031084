import React from 'react'
import { Container, Box } from "@mui/material";
import CardBox from '../components/CardBox';
import BgImageSection from '../components/BgImageSection';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import { Link } from 'react-router-dom';

const InnovationsVirtualTrainingCycle = () => {
	return (
		<>
			<section
            className="flex items-end justify-center relative"
            style={{ height: "70vh" }}
         >
            <img
               src="https://images.jhtassets.com/575c9aca524357c3db66580be5b0899e3fa9711f/"
               alt=""
               className="absolute top-0 left-0 w-full h-full object-cover"
            />
            <Container className="flex-col gap-5 items-center relative z-10">
               <h5 className="text-white text-3xl md:text-5xl font-bold pb-10">ВИРТУАЛЬНОЙ СРЕДОЙ ТРЕНИРОВОК VIRTUAL TRAINING CYCLE</h5>
            </Container>
         </section>
			<section>
				<Container maxWidth="xl" className='md:!px-10 py-5 md:py-14'>
					<h3 className='font-black mb-5 text-3xl text-zinc-700'>ВЕЛОТРЕНАЖЕР ДЛЯ ВДОХНОВЕНИЯ</h3>
					<p className='max-w-5xl pb-10 md:pb-14 font-medium'>Некоторые вещи просто созданы друг для друга. Это можно сказать о технологии наших эксклюзивных консолей с полными возможностями подключения и идеально спроектированной конструкции наших велотренажеров, которые помогли нам произвести революцию в области виртуальной среды для тренировок.</p>
					<p className="text-red-600 text-xl sm:text-2xl italic mb-5 py-10 md:py-20 max-w-4xl font-bold text-center mx-auto">Как нам удалось создать высокопроизводительный велотренажер, позволяющий пользователям подключаться к мотивирующим программам в кардиозонах в соответствии с их пожеланиями?</p>
				</Container>
			</section>
			<section className='my-10'>
				<Container maxWidth="xl" className="md:!px-12">
					<h3 className="text-zinc-700 mb-4 font-black text-3xl md:text-5xl uppercase text-center">ИССЛЕДОВАНИЕ ТРЕНИРОВОК</h3>
					<p className="mb-10 max-w-5xl text-center mx-auto">Разработка началась еще в 2018 году, когда мы создали прототип велотренажера, способного устанавливать связь с другими продуктами, часто встречающимися в кардиозоне. Этот прототип получил, без преувеличения, восторженные отзывы.</p>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="80vh"
				img="https://images.jhtassets.com/59618d2e8941cd9d8dea1156f4837d56d01dc4a6/"
				title="Для любых пользователей"
				text="Наши клиенты оценили потенциал такого взаимодействия с точки зрения новых преимуществ для тренировок на велотренажерах в помещении и привлечения самых различных пользователей. Независимо от уровня подготовки, все желающие поучаствовать в увлекательных групповых велотренировках могли прийти в зал и получить желаемый опыт, не дожидаясь подходящего им занятия в студии по расписанию."
				contentWidth="33%"
				titleColor="#e6e5e5"
				contentRight={false}
				contentBg="#000"
				textColor="#c2c6c9"
				linkColor="#e1261c"
			/>
			<BgImageSection
				sectionHeight="80vh"
				img="https://images.jhtassets.com/b9fe451fcba308c601ca60c73e024ac31bcb9c92/"
				title="Больше доступных возможностей"
				text="Наши клиенты также отметили, что новый велотренажер сделал занятия фитнесом более доступными для тех, кто не решался принимать участие в высокоинтенсивных групповых занятиях, позволив им работать в кардиозоне в своем собственном темпе. Наши партнеры также стали рассматривать наш тренажер как первый шаг на пути тех, кто со временем, обретя уверенность в собственных силах, хотел бы перейти к полноценным занятиям в студии."
				contentWidth="33%"
				titleColor="#e6e5e5"
				contentRight={true}
				contentBg="#000"
				textColor="#c2c6c9"
				linkColor="#e1261c"
			/>
			<section className='py-20'>
				<Container maxWidth="xl" className='md:!px-12'>
					<CardBoxMoreImage
						title="Подключение для нового опыта"
						subtitle="Конечно же, каждый получивший возможность ознакомиться с нашей комбинацией консоли и велотренажера, был в восторге от идеи получения доступа к эксклюзивным тренировкам Matrix по выбору пользователя с возможностью подключения к популярным фитнес-приложениям и пользовательским устройствам, а также практически бесконечным развлекательным ресурсам, буквально одним касанием пальцев."
						image="https://images.jhtassets.com/e25d296e25ee28076b960651e2d24ac8ee474336/"
						contentBgColor="#e1261c"
						contentRight={false}
						contentWidth="30%"
						contentLinkColor="#fff"
						contentTitleColor="#f5f5f5"
						contentSubtitleColor="#f9d2d0"
					/>
				</Container>
			</section>
			<section className='my-10'>
				<Container maxWidth="xl" className="md:!px-10">
					<h3 className="text-zinc-700 mb-4 font-black text-3xl md:text-5xl uppercase text-center">НАШИ ИННОВАЦИИ</h3>
					<p className="mb-10 max-w-5xl text-center mx-auto">Получив все эти положительные отзывы, мы начали работу над новым устройством и создали наш велотренажер с виртуальной средой тренировок Virtual Training Cycle. Как и все прочие разработки, мы начали создание нового продукта с того, что внимательно выслушали мнения владельцев фитнес-клубов, конечных пользователей и технических специалистов, чтобы определить ключевые качества, которыми должен обладать наш тренажер.</p>
					<Box>
						<article className='md:flex py-10 gap-6'>
							<div className='pt-10 md:w-2/3 px-5'>
								<h3 className='mb-3 text-zinc-700 text-2xl font-bold'>Превосходная устойчивость на правильной высоте</h3>
								<p className='text-zinc-700'>Чтобы добиться эффекта полного погружения мы начали с того, что усилили стойку консоли промышленной сталью, чтобы полностью исключить колебания и тряску. Чтобы определить проблемные места мы работали с пользователями разного роста, использующими самый различный контент и уровни интенсивности.</p>
							</div>
							<img
								className='md:w-1/3'
								src="https://images.jhtassets.com/a3098276ef4fc3e3b0493328c4f9c1d771096997/"
								alt=""
							/>
						</article>
						<article className='md:flex py-10 gap-6'>
							<img
								className='md:w-1/3'
								src="https://images.jhtassets.com/66d6beb7f055afe1a8fc73ecf91f4545fbc31bbb/"
								alt=""
							/>
							<div className='pt-10 md:w-2/3 px-5'>
								<h3 className='mb-3 text-zinc-700 text-2xl font-bold'>Защита от пота</h3>
								<p className='text-zinc-700'>Проведя тщательные испытания продукта с точки зрения воздействия пота, наши промышленные дизайнеры изменили угол наклона консоли таким образом, чтобы пот никогда не скапливался в области экрана. Для защиты портов питания и подключения, расположенных рядом с основанием, мы разработали специальную панель, отводящую пот от электронных портов и повышающую срок службы тренажера и его электронных компонентов.</p>
							</div>
						</article>
						<article className='md:flex py-10 gap-6'>
							<div className='pt-10 md:w-2/3 px-5'>
								<h3 className='mb-3 text-zinc-700 text-2xl font-bold'>Усовершенствование интерфейса</h3>
								<p className='text-zinc-700'>Для создания эффекта полного погружения в студийную тренировку нужно было гарантировать, что пользователь будет находиться достаточно близко к экрану. Мы также разместили элементы управления нагрузкой на сенсорном экране для удобной регулировки, обеспечивающей плавные изменения и легкую повторяемость. Кроме того, мы добавили автоматическую синхронизацию изменений нагрузки, позволяющую тренажеру выполнять соответствующую регулировку при подъеме в гору или согласно указаниям инструктора.</p>
							</div>
							<img
								className='md:w-1/3'
								src="https://images.jhtassets.com/669d8e33be8ba8f980614061cec7dcd612711269/"
								alt=""
							/>
						</article>
					</Box>
				</Container>
			</section>
			<BgImageSection
				sectionHeight="80vh"
				img="https://images.jhtassets.com/54b6f7cf46acfe3ed9222cc5f60762a8408cd898/"
				title="Новая конструкция маховика для кардиозоны"
				text="Чтобы перенести наш велотренажер с виртуальной средой тренировок Virtual Training Cycle из студийной среды в кардиозону, мы отказались от конструкции с фиксированной втулкой колеса в пользу конструкции с маховиком. Такая конструкция маховика обеспечивает более безопасные кардиотренировки для начинающих, поскольку маховик продолжает вращение даже после того, как пользователь прекратил вращение педалей и педали остановились."
				contentWidth="33%"
				titleColor="#4c4c4c"
				contentRight={false}
				contentBg="#fff"
				textColor="#666"
				linkColor="#e1261c"
			/>
			<section className='lg:p-14'>
				<Container maxWidth="xl" className='!grid lg:grid-cols-2 gap-3'>
					<CardBox
						boxHeight="650px"
						title="Питание для впечатлений"
						text="Поскольку традиционные велотренажеры для групповых тренировок имеют автономное питание, нам пришлось добавить специальные порты для подключения Ethernet и питания на раму нашего нового тренажера. Благодаря этим дополнениям владельцы тренажера смогли подключать его практически в любой точке своей кардиозоны, избегая спутанных проводов и сохраняя эстетичный внешний вид оборудования."
						img="https://images.jhtassets.com/da5c2ecbbff9eadcbff2b51a163c6c55f683d932/"
						color="#f5f5f5"
						textColor="#f9d2d0"
						contentBgColor="#e1261c"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Улучшение эргономики"
						text="Принимая во внимание то, что в моделях конкурентов эргономика зачастую снижается ради добавления консолей и соединений, мы приложили особые усилия, чтобы сделать эргономичной каждую точку контакта. Оснащенный теми же рукоятками с многопозиционным захватом и контурным сидением, что и наш проверенный и испытанный велотренажер для внутренних тренировок, наш новый велотренажер смог обеспечить уровень комфорта, не достижимый для других домашних и студийных велотренажеров."
						img="https://images.jhtassets.com/1cd55dc59e61438fd66af757f75d8e83bd94c1f5/"
						color="#f5f5f5"
						textColor="#f9d2d0"
						contentBgColor="#e1261c"
						contentPt="50px"
					/>
				</Container>
			</section>
			<section
				className='py-20'
				style={{
					background: 'url(https://images.jhtassets.com/e9328009f52250e779b22604180ee578af1f419b/) center / cover no-repeat'
				}}
			>
					<h3 className="text-zinc-200 mb-4 font-black text-3xl md:text-5xl uppercase text-center md:px-32">ИНОЙ УРОВЕНЬ ЗАЕЗДОВ. КАЖДЫЙ РАЗ.</h3>
					<p className="mb-10 max-w-5xl text-zinc-200 text-center mx-auto px-10">Владельцы залов могут установить наш велотренажер с виртуальной средой тренировок Virtual Training Cycle в своей кардиозоне и быть уверенными в том, что их посетители смогут тренироваться именно так, как им хочется, в любое время. Независимо от того, ставят ли они перед собой особые задачи с эксклюзивными тренировками, наслаждаются просмотром развлекательного контента или участвуют в захватывающих групповых занятиях - наш велотренажер с виртуальной средой тренировок Virtual Training Cycle даст все, что им нужно, и даже больше.</p>
					<img className='mx-auto px-20' src="https://images.jhtassets.com/c4bc5910397d220b66ad9afbe76e365d1349cad7/" alt="" />
			</section>
			<section>
				<div
					className="CenterContentBox py-52 text-center relative"
					style={{
						background: '#000'
					}}
				>
					<h4 className="font-black text-4xl text-zinc-200 mb-4">УЗНАЙТЕ БОЛЬШЕ</h4>
					<Box className="flex gap-5 justify-center">
						<Link to="" className="button-red px-6 py-1 mt-4 hover:bg-red-700 duration-200">
							<span className='text-base font-black'>Перейти на страницу консоли</span>
						</Link>
					</Box>
				</div>
			</section>
		</>
	)
}

export default InnovationsVirtualTrainingCycle