import React, { useEffect, useState } from 'react';
import '../assets/scss/_home.scss'
import { Link } from 'react-router-dom';
import mainBannerImg from "../assets/images/main-banner.jpg"
import Container from '@mui/material/Container';
import CardBox from '../components/CardBox';
import bgImage2 from '../assets/images/homepagebg2.jpg'
import bgImage3 from '../assets/images/homepagebg3.jpg'
import 'react-tabs/style/react-tabs.css';
import Product from '../components/Product';
import productImage1 from "../assets/images/product1.png"
import logo from "../assets/images/logo.png"
import BgImageSection from '../components/BgImageSection';
import { useDispatch, useSelector } from 'react-redux';
import { getCarts } from '../redux/actions/cartActions';
import imageNew from '../assets/images/photo_2022-08-18_02-02-54.jpg'

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarts());
  },[])

  const carts = useSelector((state) => state.cart.list);
  const cardioCart = carts?.find(item => item.category === 'Кардиооборудование');
  const strengthCart = carts?.find(item => item.category === 'Сила');
  const groupCart = carts?.find(item => item.category === 'Групповые тренировки');

  return (
    <>
      <section className='main-banner media-main-banner relative' style={{minHeight: '87vh'}}>
        <img
          src={mainBannerImg}
          className='left-0 top-0 w-full h-full object-cover sm:absolute'
          style={{zIndex: '-1'}}
          alt=""
        />
        <div
          className="left-0 bottom-0 px-5 py-10 w-full lg:px-14 lg:w-1/2 sm:absolute"
          style={{backgroundColor: 'rgba(0,0,0,.8)'}}
        >
          <div className="main-banner-title text-3xl sm:text-5xl">
            <p className='text-white leading-none'>ИНОЙ УРОВЕНЬ <br /> ЗАЕЗДОВ.</p>
            <p className='text-red-700 leading-none'>КАЖДЫЙ РАЗ.</p>
          </div>
          {/* <Link href="#" underline="none" className='bg-red-700 px-4 py-3 flex max-w-max text-white'>
            {'Learn more'}
          </Link> */}
          <Link to="/cardio" className="button-red px-6 py-1 mt-4 inline-block hover:bg-red-700"><span className='text-base font-black'>Узнать больше</span></Link>
        </div>
      </section>
      <section className='py-5 sm:py-14'>
        <Container maxWidth="xl" className='mt-5 sm:mt-14'>
          <div className="CardBoxReverse grid md:grid-cols-2">
            <img className="CardBoxReverse-img md:px-2" src='https://images.jhtassets.com/d2b7e67987a4aabbb0b3f73ef0cfbe79004f1ed3/' alt='' />
            <div className="CardBoxReverse-content md:p-5">
              <div className="CardBoxReverse-name">
                <p className='font-black text-3xl leading-none md:my-0 my-4' style={{color: '#4c4c4c'}}>World Class Аминьевский</p>
                <p className='font-medium text-xl' style={{color: '#777'}}>Фитнес-центры</p>
              </div>
              <p className='mb-3 py-3'>Matrix — один из самых уважаемых и узнаваемых брендов в фитнес индустрии, который любят клиенты, владельцы фитнес-центров, тренеры и даже обслуживающий персонал. Где бы вы ни встретили тренажер Matrix, будьте уверены — вы откроете для себя новый уровень тренировок.</p>
              <Link to="/showcases/health-clubs" className='text-red-600 hover:underline hover:text-red-700'><strong>Наши проекты /</strong></Link>
            </div>
          </div>
        </Container>
      </section>
      <BgImageSection
        sectionHeight="auto"
        img={bgImage2}
        contentWidth="33%"
        contentRight={false}
        contentBg="#000"
        title="ВДОХНОВЛЯЙТЕ. ПОКАЗЫВАЙТЕ РЕЗУЛЬТАТ. И ПОЛУЧАЙТЕ ВЫСОКУЮ ПРИБЫЛЬ."
        titleColor="#e1261c"
        text="Наши оригинальные готовые программы разработаны для комплексного улучшения кардио, мощи, силы и выносливости. Программу можно модифицировать под любого клиента и группы любого размера, с легкостью масштабируя ее с ростом популярности."
        textColor="#c2c6c9"
        link="Откройте для себя MX4"
        linkBgRed={true}
        linkTo="/education/mx4"
      />
      <section
        className='pt-14'
        style={{
          background: 'linear-gradient(171.76deg,#253746,#000)',
          paddingBottom: '10rem'
        }}
      >
        <Container maxWidth="xl">
            <div className='py-5 flex justify-between items-center mb-5 gap-10 flex-wrap'>
              <h3 className='font-bold text-3xl uppercase' style={{color: '#e6e5e5'}}>РЕКОМЕНДУЕМЫЕ ПРОДУКТЫ</h3>
            </div>
            <div className='grid xl:grid-cols-3 md:grid-cols-2 gap-5'>
              {cardioCart &&
                <Link key={cardioCart?.id} to={`/cardio/${cardioCart?.id}`}>
                  <Product
                    title={cardioCart?.name}
                    image={cardioCart?.image}
                  />
                </Link>
              }
              {strengthCart &&
                <Link key={strengthCart?.id} to={`/strength/${strengthCart?.id}`}>
                  <Product
                    title={strengthCart?.name}
                    image={strengthCart?.image}
                  />
                </Link>
              }
              {groupCart &&
                <Link key={groupCart?.id} to={`/group-training/${groupCart?.id}`}>
                  <Product
                    title={groupCart?.name}
                    image={groupCart?.image}
                  />
                </Link>
              }
            </div>
        </Container>
      </section>
      <section className='py-14' style={{marginTop: '-9rem'}}>
        <Container maxWidth="xl" className='!grid !gap-6 md:!grid-cols-2'>
          <CardBox
            link="Откройте для себя Community 360 /"
            title="Система мониторинга тренировок COMMUNITY 360"
            text="Система мониторинга тренировок Community 360 позволяет собрать данные со всех любимых фитнес-приложений клиентов в одном месте с помощью единого настраиваемого интерфейса."
            color="text-red-600"
            img={imageNew}
            linkTo="/connected-solutions/community360"
          />
          <CardBox
            link="Изучите учебные материалы Matrix /"
            title="Обучающие ресурсы MATRIX"
            text="Наши обучающие ресурсы помогут вам достичь больше, чем вы могли себе представить. Откройте для себя инструменты и советы экспертов, чтобы повысить окупаемость инвестиций, открыть дополнительные источники дохода и создать уникальный фитнес-центр, который непременно привлечет новых клиентов"
            color="text-red-600"
            img='https://images.jhtassets.com/928c62801c49eb474cd1817a41699cb27f2d5cab/'
            linkTo="/total-solutions-partners"
          />
        </Container>
      </section>
      <section className='relative'>
        <img
          className='top-0 left-0 w-full h-full object-cover xl:absolute'
          style={{zIndex: '-1'}}
          src={bgImage3}
          alt=""
        />
        <div
          className='p-6 z-10 text-white w-full h-full ml-auto grid content-end xl:py-20 xl:w-96'
          style={{ backgroundColor: 'rgba(0,0,0,.7)' }}
        >
          <img src={logo} alt="" />
          <p className='py-3 mt-3 text-sm leading-6'>Matrix — это глобальный бренд премиум-класса, созданный для любителей заниматься фитнесом дома или в фитнес-центрах. Наше оборудование выводит тренировки на новый уровень благодаря динамическим характеристикам, изысканному дизайну, продвинутому функционалу и эксклюзивным программам тренировок. Вы можете положиться на надежность оборудования Matrix, которое рассчитано на ежедневное использование множеством клиентов в течение долгих лет.</p>
          <Link to="/about-us" className="font-black hover:underline duration-200 hover:!text-white py-3 inline-block" style={{color: '#ffffffcc'}}>Подробнее /</Link>
        </div>
      </section>
    </>
  )
}

export default Home;
