import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Button } from '@mui/material';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import image1 from '../assets/images/optimized---custom-branding-facility-min.jpg';
import image2 from '../assets/images/optimized---custom-branding-welcome-min.jpg';
import image3 from '../assets/images/optimized---custom-branding-logo-min.jpg';
import image4 from '../assets/images/optimized---custom-branding-messages.jpg';
import '../assets/scss/_consoles_touch.scss';
import CardBoxMoreImage from '../components/CardBoxMoreImage';
import HeaderBox from '../components/HeaderBox';
import BgImageSection from '../components/BgImageSection';

const ConsolesTouch = () => {
  return (
    <section className='consolesTouch-page'>
        <HeaderBox
            fade='fade-up'
            video='https://vod-progressive.akamaized.net/exp=1660950510~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F2780%2F22%2F563900507%2F2666496412.mp4~hmac=58904e69d565ac9576b4694a30cca9122f6669223b59ce4cd9ecb3e0bded0127/vimeo-prod-skyfire-std-us/01/2780/22/563900507/2666496412.mp4'
            center='bottom-center'
            title="Консоли Touch"
            text="Предложите своим клиентам подключение к первоклассным развлекательным ресурсам, любимым социальным сетям, средствам коммуникаций под вашим брендом и множеству других сервисов при помощи лучших консолей в своей отрасли."
            image="https://images.jhtassets.com/b40ac788d5546d3bdcd498d06888b568884226e0/"
        />
        <section className='section-2 py-16' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="900" data-aos-offset="0">
            <Container>
                <div className='font-black text-3xl leading-none text-center md:mt-14' style={{color: '#4c4c4c'}}>Удобное и эффективное сенсорное <br className='md:block none' /> управление</div>
                <div className='text-center mt-4' style={{color: '#191919'}}>Доступны для тренажеров серий Performance, Endurance и Lifestyle.</div>
                <div className='grid lg:grid-cols-2 gap-4 mt-14'>
                    <div>
                        <img src='https://images.jhtassets.com/28c3186c8e406d9992f7393c142ef6f682558a24/' alt='' />
                        <div className='text-2xl font-bold mt-4' style={{color: '#4C4C4C'}}>Консоль Touch XL</div>
                        <div className='flex items-center mt-4'>
                            <img style={{width: '60px'}} src='https://images.jhtassets.com/955e118ad3e18460fd5c9f3709b13e7341a7de25/' alt='' />
                            <div className='ml-2'>
                                <strong className='font-bold text-2xl'>22"</strong>
                                <span className='font-bold'>/ 56 см</span>
                                <div className='font-bold'>увеличенный экран</div>
                            </div>
                        </div>
                        <div className='flex flex-wrap items-center mb-8'>
                            <Tooltip className='pt-8 pr-8' title="Беговые дорожки" arrow>
                                <img src='https://images.jhtassets.com/907522f8334d8e459a41e8ffd57af0e2c20a8b1e/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Лестницы-эскалаторы" arrow>
                                <img src='https://images.jhtassets.com/186ca8ec7e00a6cc1ab3984fd0685eb12e516d66/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Эллипсы с изменяемой длиной шага Ascent Trainer" arrow>
                                <img src='https://images.jhtassets.com/4260251887e42a9c16a68613bb3e8a983df036b4/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Эллиптические тренажеры" arrow>
                                <img src='https://images.jhtassets.com/d363361409eeb049604d41b45f6ed55a18a55d87/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Велотренажеры" arrow>
                                <img src='https://images.jhtassets.com/85b72e72e1f5adc0dccd698d8b49d45f88aaf236/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Степперы" arrow>
                                <img src='https://images.jhtassets.com/a601306ce0e387c94bedc6c3d5478c4c11a1653e/' alt='' />
                            </Tooltip>
                        </div>
                        <Link to='' className='font-black text-red-600 hover:underline hover:text-red-700'>Все оборудование с консолями Touch XL /</Link>
                    </div>
                    <div className='lg:pl-12'>
                        <img className='lg:max-h-96 lg:h-full' src='https://images.jhtassets.com/2742a69777aef02dfb4ec82db97eea8913a8943b/' alt='' />
                        <div className='text-2xl font-bold mt-4' style={{color: '#4C4C4C'}}>Сенсорная консоль</div>
                        <div className='flex items-center mt-4'>
                            <img style={{width: '60px'}} src='https://images.jhtassets.com/9ddeb2e1f66cf987fe4198739a6ad0df2ec3d1f4/' alt='' />
                            <div className='ml-2'>
                                <strong className='font-bold text-2xl'>16"</strong>
                                <span className='font-bold'>/ 41 см</span>
                                <div className='font-bold'>экран</div>
                            </div>
                        </div>
                        <div className='flex flex-wrap items-center mb-8'>
                            <Tooltip className='pt-8 pr-8' title="Беговые дорожки" arrow>
                                <img src='https://images.jhtassets.com/907522f8334d8e459a41e8ffd57af0e2c20a8b1e/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Лестницы-эскалаторы" arrow>
                                <img src='https://images.jhtassets.com/186ca8ec7e00a6cc1ab3984fd0685eb12e516d66/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Эллипсы с изменяемой длиной шага Ascent Trainer" arrow>
                                <img src='https://images.jhtassets.com/fb16532ec83ec2633120324c077f5c7b41363529/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Эллиптические тренажеры" arrow>
                                <img src='https://images.jhtassets.com/9ada4280f8ce9e9538436147365ee6701b0207de/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Велотренажеры" arrow>
                                <img src='https://images.jhtassets.com/3e19914efb8ee782e1cfe917d55da71cc2683ad6/' alt='' />
                            </Tooltip>
                            <Tooltip className='pt-8 pr-8' title="Степперы" arrow>
                                <img src='https://images.jhtassets.com/c7a80d93cf146780b3a4661565d4c733991fc362/' alt='' />
                            </Tooltip>
                        </div>
                        <Link to='' className='font-black text-red-600 hover:underline hover:text-red-700'>Все оборудование с консолями Touch /</Link>
                    </div>
                </div>
            </Container>
        </section>
        <section
            className='section-3 md:px-12 py-5 md:py-16 relative'
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0"
        >
            <img
                className='top-0 left-0 w-full h-full object-cover lg:absolute'
                src="https://images.jhtassets.com/4985063d4d202ac416dfc4fb7064151710856881/"
                alt=""
            />
            <Container maxWidth="xl" className="relative !px-0 lg:px-12">
                <div className='lg:w-2/6 bg-orange-500 lg:bg-transparent px-5 pb-5'>
                    <img src='https://images.jhtassets.com/ca68c6e076f1a085be90085c22690b48b7850f75/' alt='' />
                    <div className='font-black text-3xl my-6 leading-none' style={{color: '#e6e5e5'}}>Максимальные результаты. Минимальное время.</div>
                    <p style={{color: '#c2c6c9'}}>Попробовав нашу эксклюзивную программу Sprint 8 на консолях Touch, ваши клиенты вернуться к вам, чтобы пользоваться ею снова и снова. Доказавшая свою эффективность программа помогает задействовать естественный гормон роста человека для ускоренного сжигания жира и наращивая мышц за короткую 20-минутную тренировку. Только Sprint 8 позволяет расширить границы высокоинтенсивных интервальных тренировок, чтобы дать клиентам результат, который они смогут увидеть в зеркале. </p>
                    <Link to="/connected-solutions/sprint-8" >Узнать больше /</Link>
                </div>
            </Container>
        </section>
        <section
            className='section-4 md:px-12 py-5 md:py-16 relative'
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0"
        >
            <img
                className='top-0 left-0 w-full h-full object-cover lg:absolute'
                src="https://images.jhtassets.com/18af9abda9c200e7c8ba61ff5a686b27b55918fe/"
                alt=""
            />
            <Container maxWidth="xl" className="relative !px-0 lg:px-12">
                <div className='ml-auto px-8 py-14 lg:w-2/6' style={{background: '#000'}}>
                    <div className='font-black text-3xl leading-none' style={{color: '#e6e5e5'}}>Это не тренировка. Это впечатления.</div>
                    <label className='text-base mb-4 mt-2 block'>Оборудование Matrix с функциями iFIT</label>
                    <p style={{color: '#c2c6c9'}}>Сделайте занятия еще более увлекательными и интерактивными, предложив всем желающим доступ к тренировкам iFIT в вашей кардиозоне. Пользователи смогут принять участие в одной из 15 интерактивных тренировок с синхронизированными инструкциями под руководством самых вдохновляющих тренеров со всего мира, либо пойти своим собственным путем, используя карты Google Maps и службу просмотра улиц Street View. Тренировки будут периодически обновляться, поэтому каждый раз при входе в систему с помощью персонального аккаунта iFIT пользователь будет получать доступ к дополнительным тренировкам, удостоенным различных наград, а также к живым тренировкам Live.</p>
                </div>
            </Container>
        </section>
        <section className='section-5 py-16 md:px-12' data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0">
            <Container maxWidth="xl">
                <div className='font-black text-3xl text-center' style={{color: '#4c4c4c'}}>Используйте силу своего бренда</div>
                <label className='block text-center my-6 w-3/5 mx-auto'>Консоли Touch не только предлагают потрясающие развлечения, любимые приложения и доступ к социальным сетям по желанию ваших клиентов, но также позволяют продвигать ваш бренд и использовать другие средства, делающие ваш объект уникальным и узнаваемым. Благодаря интерфейсу с элементами фирменного стиля ваши клиенты не пропустят ваши специальные предложения или интересные события, что откроет вашему бизнесу доступ к новым каналам поступления доходов.</label>
                <div className='flex'>
                    <Tabs className=' lg:!flex'>
                        <TabList className='!flex !flex-col pt-16 pl-2 lg:w-1/3' style={{background: '#000'}}>
                            <Tab className='!text-xl !text-white !font-bold p-4 mb-3 cursor-pointer' style={{background: '#e1261c'}}>Календарь</Tab>
                            <Tab className='!text-xl !text-white !font-bold p-4 mb-3 cursor-pointer' style={{background: '#e1261c'}}>Экран приветствия</Tab>
                            <Tab className='!text-xl !text-white !font-bold p-4 mb-3 cursor-pointer' style={{background: '#e1261c'}}>Логотип</Tab>
                            <Tab className='!text-xl !text-white !font-bold p-4 mb-3 cursor-pointer' style={{background: '#e1261c'}}>Уведомления</Tab>
                        </TabList>
                        <div>
                            <TabPanel>
                                <img src={image1} alt='' />
                            </TabPanel>
                            <TabPanel>
                                <img src={image2} alt='' />
                            </TabPanel>
                            <TabPanel>
                                <img src={image3} alt='' />
                            </TabPanel>
                            <TabPanel>
                                <img src={image4} alt='' />
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
            </Container>
        </section>
        <section
            className='section-6 py-16 lg:px-12'
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0"
        >
            <Container maxWidth="xl">
                <div className='font-black text-4xl text-center' style={{color: '#4c4c4c'}}>Безупречное и легкое управление</div>
                <div className='py-4 text-center mx-auto w-3/5' style={{color: '#191919'}}>Интерфейс на базе приложений, повторяющий привычные операционные системы смартфонов и планшетов, сделает навигацию по сенсорной консоли Touch простой и понятной для ваших клиентов.</div>
                <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 md:mt-12 images flex-wrap'>
                    <div><img src='https://images.jhtassets.com/16f4c1104fcba855860edc3a8a17544cadf7d7b3/' alt='' />NETFLIX</div>
                    <div><img src='https://images.jhtassets.com/640788ddbaa662154fb704e10d1809747274a947/' alt='' /></div>
                    <div><img src='https://images.jhtassets.com/36325ee867b31996950d8469f8e49a3d96ab1ab2/' alt='' />YOUTUBE</div>
                    <div><img src='https://images.jhtassets.com/6c1a5ca9168f3e3e5bdb728f35539d3a295caa57/' alt='' />KINDLE READER</div>
                    <div><img src='https://images.jhtassets.com/e2e0bc1925af27bbc356f5349dca8c94bb42b6ff/' alt='' />SPOTIFY</div>
                    <div><img src='https://images.jhtassets.com/3f87d6627dfb967e7132eedab1fcd861f915899a/' alt='' />FACEBOOK</div>
                    <div><img src='https://images.jhtassets.com/926d9fe5d164f4852cce2418e490e06afeefed32/' alt='' />TWITTER</div>
                    <div><img src='https://images.jhtassets.com/659387fcc2e55a52d4e2868547411a833b5a2d01/' alt='' />INSTAGRAM</div>
                    <div><img src='https://images.jhtassets.com/934d80c4c8fa59b892f35507ac97a4a17f2c8f4a/' alt='' />PRESSREADER</div>
                    <div><img src='https://images.jhtassets.com/b999f7ed3ff66fecb9f41cdc88d557deee647bf8/' alt='' />FLIPBOARD</div>
                    <div><img src='https://images.jhtassets.com/2311fb956d416c40de32c84982a8cc1e2acc9f32/' alt='' />WECHAT</div>
                    <div><img src='https://images.jhtassets.com/67f3f3f454972628e3ebb484f6ab3fd854db9805/' alt='' />IQIYI</div>
                    <div><img src='https://images.jhtassets.com/6ac8e87934648ddf8b83bb3faba6181bb53e87a5/' alt='' />TENCENT</div>
                    <div><img src='https://images.jhtassets.com/18915a3ab3f761e8fa9cd7359cd0fdf8e6fee62a/' alt='' />YOUKU</div>
                    <div><img src='https://images.jhtassets.com/90737368f0612845dee6f246f595844ce5fd5d89/' alt='' />ПОГОДА</div>
                    <div><img src='https://images.jhtassets.com/bd3bb9193b92c82f435d3150908d4e95d6d317e3/' alt='' />MYFITNESSPAL</div>
                    <div><img src='https://images.jhtassets.com/f8612c982adffc786693573465de7f9c65a1e388/' alt='' />SOLITAIRE SUITE</div>
                </div>
            </Container>
        </section>
        <section className='section-7 pb-16 md:px-12' data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="900"
     data-aos-offset="0">
            <Container maxWidth="xl">
                <CardBoxMoreImage
                    title="Беспроводная зарядка и порт USB"
                    subtitle="Подставка с беспроводной зарядкой и портом USB позволяет пользователям заряжать свои персональные устройства двумя способами, сохраняя экран в поле зрения."
                    image="https://images.jhtassets.com/a1f2a835479b8228138958db27f22cc5867fbef8/"
                    contentBgColor="#000"
                    contentRight={true}
                    contentWidth="25%"
                    contentTitleColor="#e6e5e5"
                    contentSubtitleColor="#c2c6c9"
                    contentLinkColor="#fff"
                />
                <div className='grid md:grid-cols-3 mt-12'>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/9cd526d58d8662a46a8e027c77b025a4514578d4/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Bluetooth и ANT+</div>
                        <p className='leading-6' style={{color: '#191919'}}>С помощью Bluetooth и ANT+ пользователи могут подключать различные персональные устройства, такие как смартфоны, беспроводные наушники, кардиомониторы и многие другие.</p>
                    </div>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="700" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="700" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/8e61d62299fc0522d00d32f902681a1444edce55/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Вход с помощью xID</div>
                        <p className='leading-6' style={{color: '#191919'}}>Единая точка регистрации пользователей в системе с помощью xID - это простой способ оптимизации тренировочного процесса. При каждом входе в систему персональные настройки загружаются автоматически, чтобы клиент мог сразу же приступить к тренировке в соответствии с его индивидуальными потребностями.</p>
                    </div>
                    <div className='p-4' data-aos="fade-right" data-aos-offset="1100" data-aos-easing="ease-in-sine">
                    <img data-aos="fade-right" data-aos-offset="1100" data-aos-easing="ease-in-sine" src='https://images.jhtassets.com/b2d1c70cf47f0fdcdb7e17c861fc5423c83e903a/' alt='' />
                        <div className='font-bold text-3xl mb-4' style={{color: '#4c4c4c'}}>Логин RFID</div>
                        <p className='leading-6' style={{color: '#191919'}}>Удобные метки RFID делают регистрацию в системе, загрузку программ и отслеживание тренировок полностью бесконтактными и практически автоматическими. Клиенты могут использовать RFID даже в имеющихся торговых автоматах, поддерживающих данную технологию, а также в киосках и точках продаж на объекте.</p>
                    </div>
                </div>
            </Container>
            <Container>
                <div className='font-black text-3xl text-center mt-16' style={{color: '#4c4c4c'}}>Ознакомьтесь с консолями Touch</div>
                <p className='text-center mt-6 w-3/4 mx-auto' style={{color: '#191919'}}>Посмотрите нашу интерактивную демонстрацию, чтобы узнать о работе наших консолей Touch и обо всех преимуществах, которые они могут дать вашему объекту.</p>
                <iframe title='not' className='mx-auto mt-10' width='80%' height='520px' src='https://embedder.wirewax.com/8223153'></iframe>
            </Container>
        </section>
        <section className='section-8 py-16' data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="900"
     data-aos-offset="0">
            <Container>
                <div className='md:w-4/5 mx-auto'>
                    <div className='font-black text-3xl text-center mt-4 leading-none' style={{color: '#e6e5e5'}}>Связь. Гибкость. Индивидуальный подход. Это <br className='md:block none' /> будущее фитнеса.</div>
                    <p className='leading-7 my-4 text-center' style={{color: '#c2c6c9'}}>Консоли с подключением к WiFi идеально работают с нашими эффективными цифровыми решениями Connected Solutions, такими как Engage 360, Community 360, Asset Management и другими.</p>
                    <Link to='/connected-solutions' className='hover:underline text-center font-bold block' style={{color: '#ffffffcc'}}>Ознакомьтесь с нашими технологиями Connected Solutions /</Link>
                </div>
                <div className='grid lg:grid-cols-2 gap-4 mt-12'>
                    <div>
                        <div className='font-black text-3xl leading-none mb-4' style={{color: '#e6e5e5'}}>Помогаем вашим продуктам найти своих потребителей</div>
                        <p className='leading-7' style={{color: '#c2c6c9'}}>Предлагаемые вами цифровые технологии подключений помогают вашим клиентам сохранять настрой и мотивируют возвращаться к вам снова, а наши решения позволяют легко воспользоваться ими из любого места. Пользователи смогут принимать участие в ваших групповых тренировках, использовать специальные приложения и получать доступ к занятиям от ваших поставщиков вдохновляющего контента, находясь как в вашей кардиозоне, так и у себя дома. Это позволит вам расширить границы своего бренда и привлекать пользователей нового типа, использующих ваш контент за пределами ваших фитнес-клубов. И это только малая часть того, как мы можем помочь вашим продуктам найти своих потребителей, чтобы изменить ваши представления о способах взаимодействия с клиентами и финансовом результате вашего бизнеса в конечном итоге.</p>
                    </div>
                    <div><img src='https://images.jhtassets.com/769c663080135114f0c39ddee73a68a815d282d9/' alt='' /></div>
                </div>
            </Container>
            <Container maxWidth="xl" className="md:!px-12">
                <div className='text-3xl font-black text-center py-12 mt-16' style={{color: '#e6e5e5'}}>Время подключаться</div>
                <div className='grid lg:grid-cols-3 gap-6'>
                    <div>
                        <img src='https://images.jhtassets.com/451bc5b8ff1d0b51e2cec6bcbed33d480476486f/' alt='' />
                        <div className='px-6 py-14' style={{background: '#f1f2f2'}}>
                            <div className='font-black text-3xl leading-none mb-4' style={{color: "#4c4c4c"}}>Подключение к Apple Watch</div>
                            <p className='leading-7' style={{color: '#666'}}>Консоли Touch могут подключаться к Apple Watch для более точного отслеживания тренировок и объединения пользовательских данных (с входом через RFID в качестве опции).</p>
                        </div>
                    </div>
                    <div>
                        <img src='https://images.jhtassets.com/eb160366b6896026e04b67993f9fc8ca743f2320/' alt='' />
                        <div className='px-6 py-14' style={{background: '#f1f2f2'}}>
                            <div className='font-black text-3xl leading-none mb-4' style={{color: "#4c4c4c"}}>Подключение к Samsung Watch</div>
                            <p className='leading-7' style={{color: '#666'}}>Консоли Touch могут подключаться к Samsung Watch для более точного отслеживания тренировок и объединения пользовательских данных (с входом через RFID в качестве опции).</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <BgImageSection
            sectionHeight="700px"
            img="https://images.jhtassets.com/089b4b225a8b21a62de073ddd52334bf80192274/"
            title="Разработка более совершенной консоли увеличенного размера с более яркой индикацией"
            text="Создавая консоли Touch и Touch XL, мы стремились превзойти существующие в этой отрасли стандарты и разработать удобную систему для тренировок, повторяющую привычные интерфейсы смартфонов и планшетов на базе приложений, которая способна постоянно совершенствоваться за счет бесплатных обновлений программного обеспечения. Узнайте, как мы это сделали, здесь."
            link="Узнать больше /"
            linkTo="/innovations/touch-console"
            contentWidth="33%"
            titleColor="#e6e5e5"
            contentRight={false}
            contentBg="#000"
            textColor="#fff"
            linkColor="#fff"
            animation={true}
            paddingTopNull={true}
        />
        <section className='section-10 py-16' data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="900"
            data-aos-offset="0">
            <Container>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <div className='pt-12'>
                        <div className='font-bold text-2xl mb-6' style={{color: '#4c4c4c'}}>Установите консоли Touch в вашем зале</div>
                        <p className='leading-7' style={{color: '#191919'}}>Совместимы со всем кардиооборудованием серий Performance, Endurance и Lifestyle.</p>
                        <Button className='!rounded-none !text-sm !my-4 !bg-red-600 hover:!bg-red-700 !duration-200 !font-black !p-4 !text-white'>Запросить коммерческое предложение</Button>
                        <Link to='' className='inline-block font-black text-red-600 hover:underline hover:text-red-800'>Все совместимое с консолями оборудование /</Link>
                    </div>
                    <div><img src='https://images.jhtassets.com/9b0296dd38425ea548b8867c1d2e3b783aad7883/' alt='' /></div>
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ConsolesTouch;