import React, { useState } from 'react';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, IconButton } from '@mui/material';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/83ad428e04d9242ded40126e4fde66db06cec7a1/',
            },
            {
                image: 'https://images.jhtassets.com/6bbad9ad89dfca63f57dd450851edcde2c80b699/',
            },
            {
                image: 'https://images.jhtassets.com/5ec60f08d8ed9dfbc6a361c222da04d90d3c6d02/',
            },
            {
                image: 'https://images.jhtassets.com/382bd8af763e6aeb361a904651c9e8624a44daf3/',
            },
            {
                image: 'https://images.jhtassets.com/8f81ee1a473caaa2413d090c8b382974068d7310/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/7cee9dfb437dc56d6efef036713e785e4e5a3d7a/',
            },
            {
                image: 'https://images.jhtassets.com/07686338c7c70b2ab4c254a8eb4f20a956934313/',
            },
            {
                image: 'https://images.jhtassets.com/56028ed0b7a88e32730782095679a7cb20bdc3dc/',
            },
            {
                image: 'https://images.jhtassets.com/c6bcfbd2e1a2bbde5057ad71f3a1bff42ebd73b9/',
            },
            {
                image: 'https://images.jhtassets.com/c90b5ca26a8a5a698b11785d594438aaf54e2039/',
            },
            {
                image: 'https://images.jhtassets.com/911c1ef66e879c11ccf316be441dede913cd1c60/',
            },
            {
                image: 'https://images.jhtassets.com/4cb10d20d1255ca8291db201c369884a8b60b4d0/',
            },
            {
                image: 'https://images.jhtassets.com/dfa7932419947fde3bd93b0289dbe6f0ae515c31/',
            },
            {
                image: 'https://images.jhtassets.com/9f440b7c9d794a2153ea7c7b62c85e0505ffaacb/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/ff718352bcd8334fbdcd800081487b542146a3e9/',
            },
            {
                image: 'https://images.jhtassets.com/12dc5288def27023399d75ed536875a13dca481e/',
            },
            {
                image: 'https://images.jhtassets.com/a9c18912352421c75e71982668f6904837367608/',
            },
            {
                image: 'https://images.jhtassets.com/628db8d31647831276c2109970baa0c4656a1a92/',
            },
            {
                image: 'https://images.jhtassets.com/fde55a9c5e031eeeb585316fe72d8d5708e294ad/',
            },
            {
                image: 'https://images.jhtassets.com/0873931323c8d9dc26e09175f1b915f5fa5c74a7/',
            },
        ],
    },
    {
        id: 4,
        gallery: [
            {
                image: 'https://images.jhtassets.com/5ee02a3d15ef3ed9a21ddbc4e2b1f4d2a336dee8/',
            },
            {
                image: 'https://images.jhtassets.com/fec55ba3319afa622ee4e662189fc25bbb0a26f2/',
            },
            {
                image: 'https://images.jhtassets.com/94b063403ceae5a8d96f395ce4a4a35bfe9ceff4/',
            },
        ],
    },
    {
        id: 5,
        gallery: [
            {
                image: 'https://images.jhtassets.com/fd061818ca173150d5c683bbb2c4ccb8b9149eb5/',
            },
            {
                image: 'https://images.jhtassets.com/592829e096a236ef15288ecaee07b6ae42d1d232/',
            },
        ],
    },
    {
        id: 6,
        gallery: [
            {
                image: 'https://images.jhtassets.com/6eb66aa76d73a37df3b24d5754a97d1670050bdb/',
            },
            {
                image: 'https://images.jhtassets.com/2d1afd13c8ed974e01602c4e95de408108c7f6ea/',
            },
            {
                image: 'https://images.jhtassets.com/59cd7bc0d484a8f72307d2ff9a67884b263d96c6/',
            },
            {
                image: 'https://images.jhtassets.com/32f70d55b4c8e1f10d277d2f0a75a1614be4a75a/',
            },
            {
                image: 'https://images.jhtassets.com/8783aad37a10de356dd90d67e6ea3c8a6ac778da/',
            },
        ],
    },
    {
        id: 7,
        gallery: [
            {
                image: 'https://images.jhtassets.com/03b982e55e3cae64bc09664e09c041775a5f810c/',
            },
            {
                image: 'https://images.jhtassets.com/4c020198cd9be676dc4ef10714afc8f70c673308/',
            },
            {
                image: 'https://images.jhtassets.com/6bab4c55968d2b31d0da60373c9e9eec9bb7d735/',
            },
            {
                image: 'https://images.jhtassets.com/fb197d9808f1d2b08b7fc9042a84b4f5c252de87/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/83ad428e04d9242ded40126e4fde66db06cec7a1/transformed/h_558,w_440,c_fill',
        title: 'Leiden',
        subtitle: 'Universiteit Leiden',
        name: 'Нидерланды',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/7cee9dfb437dc56d6efef036713e785e4e5a3d7a/transformed/h_558,w_440,c_fill',
        title: 'Kaukana',
        subtitle: 'Kaukauna HS',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/ff718352bcd8334fbdcd800081487b542146a3e9/transformed/h_558,w_440,c_fill',
        title: 'British Columbia',
        subtitle: 'University of Victoria',
        name: 'Канада',
    },
    {
        id: 4,
        image: 'https://images.jhtassets.com/5ee02a3d15ef3ed9a21ddbc4e2b1f4d2a336dee8/transformed/h_558,w_558,c_fill',
        title: 'Woodinville',
        subtitle: 'Woodinville HS',
        name: 'США',
    },
    {
        id: 5,
        image: 'https://images.jhtassets.com/fd061818ca173150d5c683bbb2c4ccb8b9149eb5/transformed/h_558,w_558,c_fill',
        title: 'Champaign',
        subtitle: 'Urbana Champaign',
        name: 'США',
    },
    {
        id: 6,
        image: 'https://images.jhtassets.com/6eb66aa76d73a37df3b24d5754a97d1670050bdb/transformed/h_558,w_558,c_fill',
        title: 'Orange',
        subtitle: 'Chapman University',
        name: 'США',
    },
    {
        id: 7,
        image: 'https://images.jhtassets.com/03b982e55e3cae64bc09664e09c041775a5f810c/transformed/h_558,w_558,c_fill',
        title: 'Deforest',
        subtitle: 'Deforest HS',
        name: 'США',
    },
];

const ShowcasesAthleticPerformance = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='showcasesAthleticPerformance-page'>
        <section className='section-1 flex items-center justify-center md:py-0 pt-12 pb-32 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white text-3xl md:text-6xl text-center leading-none'>Спортивная подготовка</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-3xl md:text-4xl leading-none' style={{color: '#4c4c4c'}}>ПРИЯТНЫЕ И ПРАВИЛЬНЫЕ ТРЕНИРОВКИ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesAthleticPerformance;