import React from "react";
import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import HoverBoxAnimation from "../components/HoverBoxAnimation";
import { IconButton } from "@mui/material";
import { MdPhotoLibrary } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import video from "../assets/videos/athletic-performance.mp4";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const array = [
  {
    id: 1,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/83ad428e04d9242ded40126e4fde66db06cec7a1/",
      },
      {
        image:
          "https://images.jhtassets.com/6bbad9ad89dfca63f57dd450851edcde2c80b699/",
      },
      {
        image:
          "https://images.jhtassets.com/5ec60f08d8ed9dfbc6a361c222da04d90d3c6d02/",
      },
      {
        image:
          "https://images.jhtassets.com/382bd8af763e6aeb361a904651c9e8624a44daf3/",
      },
      {
        image:
          "https://images.jhtassets.com/8f81ee1a473caaa2413d090c8b382974068d7310/",
      },
    ],
  },
  {
    id: 2,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/7cee9dfb437dc56d6efef036713e785e4e5a3d7a/",
      },
      {
        image:
          "https://images.jhtassets.com/07686338c7c70b2ab4c254a8eb4f20a956934313/",
      },
      {
        image:
          "https://images.jhtassets.com/56028ed0b7a88e32730782095679a7cb20bdc3dc/",
      },
      {
        image:
          "https://images.jhtassets.com/c6bcfbd2e1a2bbde5057ad71f3a1bff42ebd73b9/",
      },
      {
        image:
          "https://images.jhtassets.com/c90b5ca26a8a5a698b11785d594438aaf54e2039/",
      },
      {
        image:
          "https://images.jhtassets.com/911c1ef66e879c11ccf316be441dede913cd1c60/",
      },
      {
        image:
          "https://images.jhtassets.com/4cb10d20d1255ca8291db201c369884a8b60b4d0/",
      },
      {
        image:
          "https://images.jhtassets.com/dfa7932419947fde3bd93b0289dbe6f0ae515c31/",
      },
      {
        image:
          "	https://images.jhtassets.com/9f440b7c9d794a2153ea7c7b62c85e0505ffaacb/",
      },
    ],
  },
  {
    id: 3,
    gallery: [
      {
        image:
          "https://images.jhtassets.com/ff718352bcd8334fbdcd800081487b542146a3e9/",
      },
      {
        image:
          "https://images.jhtassets.com/12dc5288def27023399d75ed536875a13dca481e/",
      },
      {
        image:
          "https://images.jhtassets.com/a9c18912352421c75e71982668f6904837367608/",
      },
      {
        image:
          "https://images.jhtassets.com/628db8d31647831276c2109970baa0c4656a1a92/",
      },
      {
        image:
          "https://images.jhtassets.com/fde55a9c5e031eeeb585316fe72d8d5708e294ad/",
      },
      {
        image:
          "https://images.jhtassets.com/0873931323c8d9dc26e09175f1b915f5fa5c74a7/",
      },
    ],
  },
];

const boxes = [
  {
    id: 1,
    image:
      "https://images.jhtassets.com/83ad428e04d9242ded40126e4fde66db06cec7a1/transformed/h_558,w_440,c_fill",
    title: "Leiden",
    subtitle: "Universiteit Leiden",
    name: "Нидерланды",
  },
  {
    id: 2,
    image:
      "https://images.jhtassets.com/7cee9dfb437dc56d6efef036713e785e4e5a3d7a/transformed/h_558,w_440,c_fill",
    title: "Kaukana",
    subtitle: "Kaukauna HS",
    name: "США",
  },
  {
    id: 3,
    image:
      "https://images.jhtassets.com/ff718352bcd8334fbdcd800081487b542146a3e9/transformed/h_558,w_440,c_fill",
    title: "British Columbia",
    subtitle: "University of Victoria",
    name: "Канада",
  },
];

const AthleticPerformance = () => {
  const [modal, setModal] = useState();
  const handleModal = array.find((obj) => {
    return obj.id === modal;
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="athleticPerformance-page">
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="video-close-icon absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source width="auto" height="auto" src={video}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <section
        className="section-1 flex items-center justify-center !bg-cover !bg-center"
        style={{
          height: "80vh",
          clipPath: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          shapeOutside: "polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)",
          background:
            "url(https://images.jhtassets.com/22e8964db2c87515cccca6996055eabf5689ff47/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12">
          <div
            style={{ marginTop: "-6rem" }}
            className="lg:w-1/2 ml-auto md:mr-16"
          >
            <div className="font-bold text-white text-lg mb-4">
              Максимальное раскрытие спортивного потенциала
            </div>
            <div className="text-white text-3xl md:text-5xl leading-none">
              СПОРТИВНАЯ ПОДГОТОВКА
            </div>
            <Button
              onClick={handleOpen}
              className="mx-auto !mt-2 hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
              style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
            >
              <BsPlayCircle size={32} fill="#fff" className="mr-2" />
              СМОТРЕТЬ ВИДЕО
            </Button>
          </div>
        </Container>
      </section>
      <section className="section-2" style={{ marginTop: "-70px" }}>
        <div className="grid lg:grid-cols-2 lg:gap-8">
          <div style={{ clipPath: "polygon(0 10%,100% 5%,100% 100%,0 100%)" }}>
            <img
              className="w-full"
              src="https://images.jhtassets.com/2fc951833db8756cc7507282f7a4ff08d1911be0/"
              alt=""
            />
          </div>
          <div
            className="flex flex-col justify-center pl-12 py-4 !bg-cover bg-center"
            style={{
              background:
                "url(https://images.jhtassets.com/c06182f94f5309c86806191183a3283594d9a497/) no-repeat",
              clipPath: "polygon(0 5%,100% 0,100% 100%,0 100%)",
            }}
          >
            <p className="leading-9 w-11/12 text-white mb-8 text-base">
              Поскольку никто не работает больше, чем ваши спортсмены, Matrix
              поможет им извлечь максимальную пользу из каждой капли пота,
              пожертвованной во имя победы. Проверенные временем,
              высокоэффективные конструкции нашего оборудования помогут вашим
              спортсменам получить больше от каждого жима и приседания, с
              лучшими результатами, меньшей болью и страданиями для их
              достижения. Наши универсальные тренажеры высвобождают спортивную
              взрывную силу, как никогда прежде. И каждая деталь, которая
              попадает на пол вашего тренажерного зала, принесет с собой
              непревзойденную надежность, у которой никогда нет выходных, сезон
              за сезоном и год за годом. Выбирайте Matrix, чтобы помочь
            </p>
            <a
              href="https://images.jhtassets.com/eb9d0c5e8eab14b9577ec475a43ef3ea88278cf6/"
              target="_blank"
              className="font-bold text-lg flex items-center text-red-600 hover:text-red-700"
            >
              <img
                className="mr-2"
                src="https://images.jhtassets.com/6e49bd73cc4f9e888587ad1eaaf204027b076c71/transformed/w_50,h_52"
                alt=""
              />
              Brochure
            </a>
          </div>
        </div>
      </section>
      <section className="section-3">
        <Container maxWidth="xl" className="md:!px-16">
          <Link to="">
            <div
              className="text-center font-thin md:mt-16 pt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase"
              style={{ color: "#4c4c4c" }}
            >
              A Teammate for the Competition Before the Competition
            </div>
          </Link>
          <p
            className="leading-7 mb-4 text-center w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Когда вы выбираете Matrix, вы получаете больше, чем лучшие в мире
            спортивные тренажеры. Вы получаете товарища по команде, который
            будет рядом с каждым вашим шагом на пути к победе. Вместе мы можем
            по-новому посмотреть на то, как ваши спортсмены готовятся перед
            соревнованиями, и установить новый стандарт выступления, когда
            наконец наступает время игры.
          </p>
          <Link
            to=""
            className="block w-max mx-auto mb-12 text-red-600 font-bold hover:uppercase hover:text-red-700"
          >
            Узнать больше /
          </Link>
          <div className="grid md:grid-cols-2 gap-6 pb-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/405c6e36afb2bd5c6c14501ae6e99008d691dcaa/"
              title="Matrix Learning"
              text="Протоколы тренировок. Техническая документация. Обучение работе с продуктами. Советы по техническому обслуживанию. Обучающие видео по запросу. С Matrix Learning вашей команде доступен целый новый мир обучения и поддержки. "
              link="Смотреть Matrix Learning /"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/893d08537c1d12b4313e71f2318e5212b83fa5be/"
              title="Группа специалистов по продажам и команда поддержки"
              text="Ваше пространство. Ваши цели. Ваша команда. Мы поможем воплотить в жизнь ваш учебный центр от первых идей до того дня, когда ваши спортсмены будут применять знания на практике. "
              link="Смотреть планирование тренажерного зала /"
            />
          </div>
        </Container>
      </section>
      <section
        className="section-4 pb-16 flex items-center !bg-cover !bg-bottom"
        style={{
          background:
            "url(https://images.jhtassets.com/053e720f81a85ab617aa6d68f4e9da0c76ea111f/) no-repeat",
          minHeight: "160vh",
        }}
      >
        <Container maxWidth="xl" className="md:!px-10">
          <div className="lg:w-1/2 ml-auto">
            <div className="text-3xl lg:text-6xl pb-6 text-white leading-none">
              ТАКИЕ ЖЕ КРЕПКИЕ, КАК ВАШИ СПОРТСМЕНЫ.
            </div>
            <p className="text-white leading-10 text-xl">
              Вам нужны надежные силовые тренажеры, которые никогда не дрогнут
              от того, что вы бросаете в них, и это именно то, что могут
              предложить наши проверенные временем тренажеры. Прочная
              промышленная конструкция имеет такие особенности, как увеличенные
              стойки и крепкие стальные рамы, созданные для того, чтобы
              выдерживать постоянный поток спортсменов в условиях наиболее
              интенсивных тренировок. Более того, мы придерживаемся наиболее
              требовательных международных стандартов, поэтому вы можете выбрать
              Matrix, зная, что вы получите только самое высококачественное
              оборудование. После сезона изнурительных тренировок тренажеры
              Matrix всегда работают так же, как и в первый день.
            </p>
          </div>
        </Container>
      </section>
      <section className="section-5">
        <Container maxWidth="xl" className="md:!px-12">
          <div
            className="text-center font-thin lg:mt-16 pt-16 mb-4 lg:px-16 text-3xl md:text-6xl leading-none uppercase"
            style={{ color: "#4c4c4c" }}
          >
            Shattering Design Preconceptions & Athletic Limits
          </div>
          <p
            className="leading-7 mb-4 text-center w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Если вы стремитесь к спортивному преимуществу, вам нужно мыслить, не
            ограничиваясь заданными рамками. Это именно то, что Matrix делает со
            своими новейшими тренажерами, переосмысливая опыт тренировок, чтобы
            ваши спортсмены могли получить нужные тренировки в нужном месте.
            Наши эксклюзивные инновационные решения используют биомеханически
            продуманный дизайн и тщательно разработанные протоколы тренировок,
            чтобы ваши спортсмены могли сломать границы в тренажерном зале и
            преодолеть сопротивление в день игры. Откройте для себя наши
            эксклюзивные тренажеры уже сегодня и начните достигать большего.
          </p>
          <div className="grid md:grid-cols-3 gap-6 pb-16 lg:px-16">
            <HoverBoxAnimation
              image="https://images.jhtassets.com/c0e47e2ce09f09f0897d2a4e59d881da36eba485/"
              title="Тренажер S-Drive Performance"
              text="Только тренажер S-Drive Performance дает вам все необходимое от автономных беговых дорожек, саней с утяжелением, парашютов для бега, а также привязного снаряжения в одном компактном месте. "
              link="Смотреть S-Drive /"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/c48f9cd970c99dc35e03eae76f4605bbadf77a45/"
              title="Breaker Bench"
              text="В нашей эксклюзивной системе Breaker используются поворотные стойки, при этом гриф располагается непосредственно над пользователем, исключая необходимость в присутствии страхующего. Пользователи испытывают больший комфорт, легче происходит взятие штанги и достигается повышенная эффективность в каждом подходе. "
              link="Смотреть Breaker Bench /"
            />
            <HoverBoxAnimation
              image="https://images.jhtassets.com/dbc4f0f502ddc92a2b57c9d148cb2283def840ca"
              title="S-Force Performance Trainer"
              text="Тренажер S-Force Performance Trainer сочетает специально разработанную схему движения, две активные позиции и магнитное сопротивление для наращивания скорости и мощности, что выводит тренировки HIIT на новый уровень. Используйте S-Force для спортивных упражнений, чтобы получить тренировку с низкой нагрузкой и высокой интенсивностью одновременно."
              link="//Смотреть S-Force"
            />
          </div>
        </Container>
      </section>
      <section
        className="section-6 py-16 !bg-cover !bg-center"
        style={{
          background:
            "url(https://images.jhtassets.com/9e5c9a900cdb2ce4cb58b0296663a01a6b889f26/) no-repeat",
          height: "100vh",
        }}
      >
        <Container maxWidth="xl" className="md:!px-12">
          <div className="xl:w-2/5 ml-auto">
            <div className="text-red-600 text-2xl md:text-5xl leading-none">
              НЕПРЕВЗОЙДЕННЫЙ ВЫБОР
            </div>
            <p className="my-4" style={{ color: "#333436" }}>
              Тренажеры Matrix – высокоэффективное оборудование для самой
              упорной работы в тренажерном зале. Независимо от ваших
              потребностей, целей или требований к пространству, Matrix имеет
              все необходимое для оптимизации инвестиций вашей организации и
              усилий ваших спортсменов. Короче говоря, Matrix предоставляет
              наиболее конкурентоспособный и комплексный набор преимуществ,
              который вы можете найти. Мы предлагаем полный набор продуктов,
              которые не подведут в наиболее изнурительные тренировочные дни и
              никогда не теряют привлекательность вследствие износа.
            </p>
            <Link to="" className="font-bold text-red-600 hover:text-red-700">
              Узнать больше
            </Link>
          </div>
        </Container>
      </section>
      <section className="section-7">
        <Container maxWidth="xl" className="md:!px-12">
          <div className="lg:flex py-5 lg:py-16">
            <div className="lg:w-2/5 my-auto">
              <div
                className="text-3xl lg:text-6xl leading-none text-center"
                style={{ color: "#4c4c4c" }}
              >
                ВСЕ ДЛЯ ВАШЕЙ КОМАНДЫ
              </div>
              <p
                className="leading-6 my-4 text-center"
                style={{ color: "#191919" }}
              >
                Ваша команда уникальна, как и ваши цели. Именно поэтому с
                помощью наших каркасов, сделанных индивидуально для вас, а также
                с цветами наших обивок вы сможете добавить командный дух в ваши
                залы. Свяжитесь с нами сегодня, чтобы узнать, как создать
                тренажерный зал, который будет служить вашей цели также, как
                ваши спортсмены привержены ей.
              </p>
            </div>
            <img
              className="lg:w-3/5"
              src="https://images.jhtassets.com/19d71267c93bddc758668f49c2ad2b9a7cf16a1a/"
              alt=""
            />
          </div>
          <div
            className="text-center font-black md:mt-16 pt-16 mb-4 lg:px-16 text-3xl leading-none uppercase"
            style={{ color: "#4c4c4c" }}
          >
            Выберите проверенного победителя
          </div>
          <p
            className="leading-7 mb-4 text-center w-3/4 mx-auto"
            style={{ color: "#191919" }}
          >
            Мы сотрудничаем с десятками организаций, подобных вашей,
            предоставляя тренажеры, которые соответствуют строгим требованиям
            испытаний и стандартов, чтобы спортсмены могли стремиться к
            максимальной реализации своего потенциала, а командам — к достижению
            новых целей. Вы можете рассчитывать, что мы сделаем то же самое для
            вас, предоставив вам тренажеры, такие же уникальные, как и ваша
            команда.
          </p>
          <div className="grid md:grid-cols-3 gap-6 mt-16 mb-12">
            {boxes?.map((box) => (
              <div
                onClick={() => setModal(box.id)}
                key={box.id}
                className="center-box-animation"
              >
                <IconButton className="!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10">
                  <MdPhotoLibrary fill="#4f8493" size={32} />
                </IconButton>
                <img className="h-full w-full" src={box.image} alt="" />
                <div className="center-box-absolute p-2">
                  <div className="center-box-title">{box.title}</div>
                  <div className="center-box-subtitle">{box.subtitle}</div>
                  <div className="center-box-name">{box.name}</div>
                </div>
              </div>
            ))}
          </div>
        </Container>
        {modal && (
          <div
            className="fixed top-0 right-0 bottom-0 left-0 z-20"
            style={{ background: "rgba(26,26,26,0.8)" }}
          >
            <span
              onClick={() => setModal()}
              className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Swiper
              hashNavigation={{
                watchState: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper showcases-swiper"
              style={{
                height: "70%",
                width: "70%",
                transform: "translate(0, 25%)",
              }}
            >
              {handleModal?.gallery?.map((image, idx) => (
                <SwiperSlide data-hash={`slide${idx}`}>
                  <img src={image.image} className="mx-auto" alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </section>
      <section
        className="section-6 !bg-cover"
        style={{
          background:
            "url(https://images.jhtassets.com/3db9badf82342b02a518d1c35907804808ce1649/) no-repeat",
        }}
      >
        <Container maxWidth="xl" className="md:!px-16 !py-16">
          <div className="grid md:grid-cols-3 gap-8 py-16">
            <div className="md:col-span-2">
              <Link to="">
                <img
                  src="https://images.jhtassets.com/c76f21c11c373e9d1c9b1beb45b01fd9b05bc840/"
                  alt=""
                />
                <div
                  className="font-bold text-center text-xl"
                  style={{ color: "#4c4c4c" }}
                >
                  S-Drive
                </div>
              </Link>
            </div>
            <div>
              <div
                className="text-3xl md:text-6xl leading-none"
                style={{ color: "#4c4c4c" }}
              >
                ЛУЧШИЕ ИЗ ЛУЧШИХ
              </div>
              <p className="my-4" style={{ color: "#191919" }}>
                Откройте для себя некоторые из наших премиальных продуктов,
                специально разработанных, чтобы помочь вашим спортсменам по
                максимуму использовать свой спортивный потенциал и достичь
                большего, чем они когда-либо мечтали.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <Container
        maxWidth="xl"
        className="md:!px-16"
        style={{ marginTop: "-5%" }}
      >
        <div className="grid md:grid-cols-2 gap-8 pb-12">
          <Link to="" className="grid md:grid-cols-2 gap-4">
            <img
              src="https://images.jhtassets.com/73144ad8f68aaa90acdaddecc579e4c267026ec3/"
              alt=""
            />
            <div
              className="font-bold m-auto text-xl leading-none"
              style={{ color: "#4c4c4c" }}
            >
              Double MEGA Rack with Ladder
            </div>
          </Link>
          <Link to="" className="grid md:grid-cols-2 gap-4">
            <img
              src="https://images.jhtassets.com/1266ccfea4c988b987b9405bd31208de87724ae4/"
              alt=""
            />
            <div
              className="font-bold m-auto text-xl leading-none"
              style={{ color: "#4c4c4c" }}
            >
              MEGA Power Rack
            </div>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default AthleticPerformance;
