import React, { useState } from 'react';
import { MdPhotoLibrary } from 'react-icons/md';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Container, IconButton } from '@mui/material';

const array = [
    {
        id: 1,
        gallery: [
            {
                image: 'https://images.jhtassets.com/2b523c8eb46ccff4ffc359214ca0a3d80e416cdd/',
            },
            {
                image: 'https://images.jhtassets.com/bc4bf3e5dadff88935deb3e12048543cd9729ae7/',
            },
        ],
    },
    {
        id: 2,
        gallery: [
            {
                image: 'https://images.jhtassets.com/bffae05801f5359625ad33f5353eeb8c6c4fdebb/',
            },
            {
                image: 'https://images.jhtassets.com/e7a2f99e7cfc6f658398c5f209365631eddb373f/',
            },
            {
                image: 'https://images.jhtassets.com/7f01ed494b44ebfadbea9a89e3b29a94503d8204/',
            },
        ],
    },
    {
        id: 3,
        gallery: [
            {
                image: 'https://images.jhtassets.com/5dc08bb698448676cfa78d1d51b8954ae2b30cd6/',
            },
            {
                image: 'https://images.jhtassets.com/ccb5ffaef0be2fab5075bbbc8846cab8bbfe0528/',
            },
            {
                image: 'https://images.jhtassets.com/02732d4a78a863b9c45bcdd81d6fff2b1292ee1f/',
            },
        ],
    },
    {
        id: 4,
        gallery: [
            {
                image: 'https://images.jhtassets.com/7879124c35379f37ca4b85f8151674e05c2a5d71/',
            },
            {
                image: 'https://images.jhtassets.com/3abfc5aade977d37ff420db0ec160417287f2c4f/',
            },
            {
                image: 'https://images.jhtassets.com/dc35bf0a739c3d4df90eaf981bcadd8bba196a89/',
            },
        ],
    },
    {
        id: 5,
        gallery: [
            {
                image: 'https://images.jhtassets.com/bebb6d3f8132e9be888de9aeee5b2a4a8cbee086/',
            },
            {
                image: 'https://images.jhtassets.com/c9d5875f9d9c1f589585c531402f76981268199d/',
            },
            {
                image: 'https://images.jhtassets.com/ba9f8cade5dfa3befa1c42a3f69aa988d7c3b4ef/',
            },
        ],
    },
    {
        id: 6,
        gallery: [
            {
                image: 'https://images.jhtassets.com/a8bb22ac79a2bda9bf7aac667331f87020512310/',
            },
            {
                image: 'https://images.jhtassets.com/dafd58841f40ba49a2368fb1fc568edb1ab790c8/',
            },
            {
                image: 'https://images.jhtassets.com/4eed495f8fe33f14508241786d0f2ea973570a94/',
            },
            {
                image: 'https://images.jhtassets.com/cf8c03159ed63822b207854296702c7526c6dc8f/',
            },
            {
                image: 'https://images.jhtassets.com/ff3d6a4ce273bd9d4576e83b385dbc3400b3b720/',
            },
        ],
    },
];

const boxes = [
    {
        id: 1,
        image: 'https://images.jhtassets.com/2b523c8eb46ccff4ffc359214ca0a3d80e416cdd/transformed/h_558,w_440,c_fill',
        title: 'Spring',
        subtitle: 'Glennloch Farms',
        name: 'США',
    },
    {
        id: 2,
        image: 'https://images.jhtassets.com/bffae05801f5359625ad33f5353eeb8c6c4fdebb/transformed/h_558,w_440,c_fill',
        title: 'Portage',
        subtitle: 'Divine Savior Wellness Center',
        name: 'США',
    },
    {
        id: 3,
        image: 'https://images.jhtassets.com/5dc08bb698448676cfa78d1d51b8954ae2b30cd6/transformed/h_558,w_440,c_fill',
        title: 'Mt. Vernon',
        subtitle: 'Skagit Valley Hospital',
        name: 'США',
    },
    {
        id: 4,
        image: 'https://images.jhtassets.com/7879124c35379f37ca4b85f8151674e05c2a5d71/transformed/h_558,w_558,c_fill',
        title: 'Bonita Springs',
        subtitle: 'Terraces at Bonita',
        name: 'США',
    },
    {
        id: 5,
        image: 'https://images.jhtassets.com/bebb6d3f8132e9be888de9aeee5b2a4a8cbee086/transformed/h_558,w_558,c_fill',
        title: 'West Linn',
        subtitle: 'Active Aging Wellness Center (Active Edge)',
        name: 'США',
    },
    {
        id: 6,
        image: 'https://images.jhtassets.com/a8bb22ac79a2bda9bf7aac667331f87020512310/transformed/h_558,w_558,c_fill',
        title: 'Roelfarendsveen',
        subtitle: 'In-Balance',
        name: 'Нидерланды',
    },
];

const ShowcasesActiveAging = () => {
    const [modal, setModal] = useState();
    const handleModal = array.find((obj) => {
        return obj.id === modal;
    })

  return (
    <section className='showcasesActiveAging-page'>
        <section className='section-1 flex items-center justify-center md:py-0 pt-12 pb-32 md:!bg-cover !bg-contain md:!bg-center !bg-top main-media-height' style={{height: '80vh', clipPath: 'polygon(0 0,100% 0,100% calc(100% - 100px),0 100%)',background: 'url(https://images.jhtassets.com/9f99d63a8ad3560c734af3d55a151913abf4f69c/) no-repeat'}}>
            <Container>
                <div className='text-white text-3xl md:text-6xl text-center leading-none'>Центры для пожилых людей и реабилитационные центры</div>
            </Container>
        </section>
        <section className='section-2'>
            {modal &&
                <div className='fixed top-0 right-0 bottom-0 left-0 z-20' style={{background: 'rgba(26,26,26,0.8)'}}>
                    <span onClick={() => setModal()} className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer">×</span>
                    <Swiper
                        hashNavigation={{
                        watchState: true,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper showcases-swiper"
                        style={{height: '70%', width: '70%', transform: 'translate(0, 25%)'}}
                    >
                        {handleModal?.gallery?.map((image,idx) => (
                            <SwiperSlide data-hash={`slide${idx}`}><img src={image.image} className='mx-auto' alt='' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            <Container>
                <div className='text-center mt-16 mb-4 lg:px-16 text-2xl md:text-4xl leading-none' style={{color: '#4c4c4c'}}>УЛУЧШЕНИЕ СОСТОЯНИЯ ЗДОРОВЬЯ</div>
                <p className='leading-6 mb-12 text-center lg:px-16' style={{color: '#191919'}}>Превосходите ожидания клиентов и повышайте рентабельность благодаря интуитивно понятному, высокоэффективному и долговечному оборудованию из самого широчайшего портфолио продукции на рынке.</p>
                <div className='grid md:grid-cols-3 gap-6 pb-16 lg:px-16'>
                    {boxes.map(box => (
                        <div onClick={() => setModal(box.id)} key={box.id} className='center-box-animation'>
                            <IconButton className='!absolute !bottom-0 !right-0 !mb-4 !mr-4 !z-10'>
                                <MdPhotoLibrary fill='#4f8493' size={32} />
                            </IconButton>
                            <img className='h-full w-full' src={box.image} alt='' />
                            <div className='center-box-absolute p-2'>
                                <div className='center-box-title'>{box.title}</div>
                                <div className='center-box-subtitle'>{box.subtitle}</div>
                                <div className='center-box-name'>{box.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    </section>
  )
}

export default ShowcasesActiveAging;