import React from "react";
import { Container, Box } from "@mui/material";
import HoverToShowBg from "../components/HoverToShowBg";
import BgImageSection from "../components/BgImageSection";
import CenterContentBox from "../components/CenterContentBox";
import $8 from "../assets/videos/1-8.mp4";

const ConnectedSolutionsUniquePrograms = () => {
  return (
    <>
      <section
        className="flex items-end justify-center relative"
        style={{ height: "70vh" }}
      >
        <img
          src="https://images.jhtassets.com/3d162cb8ec64c125ec193d809ed5371b9c1fab11/"
          alt=""
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <Container
          className="flex flex-col gap-5 items-center justify-center text-center relative z-10"
          style={{ display: "flex" }}
        >
          <h5 className="text-white uppercase font-bold">УНИКАЛЬНЫЕ РЕШЕНИЯ</h5>
          <p className="text-2xl lg:text-5xl uppercase text-white mb-10">
            ВЫДЕЛЯЙТЕСЬ ЧЕМ-ТО ОСОБЕННЫМ
          </p>
        </Container>
      </section>
      <section className="mb-10">
        <Container maxWidth="xl">
          <p className="py-5 lg:py-20 text-zinc-700 text-center">
            Ваше собственное представление о фитнесе. Гибкость нашей комплексной
            цифровой экосистемы позволяет вам выбирать свои методы улучшения
            бизнеса, оптимизации административных задач, воздействия на людей, с
            которыми вы работаете, и многое другое.
          </p>
          <video playsinline autoPlay loop controls className="w-full">
            <source type="video/mp4" src={$8} />
          </video>
        </Container>
      </section>
      <section className="py-4 lg:py-16">
        <Container maxWidth="xl" className="">
          <h3 className="font-bold text-zinc-600 text-3xl">VIRTUAL ACTIVE</h3>
          <div className="lg:flex gap-5">
            <Box className="my-8 lg:w-2/3">
              <p className="">
                Наши тренировки Virtual Active переносят любителей упражнений в
                экзотические локации по всему миру. Прекрасное движение вперед,
                видеоматериал в высоком разрешении ускоряется или замедляется в
                зависимости от темпа пользователя, а сопротивление или наклон
                меняется в соответствии с рельефом земли. Когда речь идет о
                самой погружающей из доступных тренировок, ничто не может
                сравнить с путешествием от Virtual Active.
              </p>
              <h4 className="font-bold text-zinc-600 text-2xl my-4">
                Избранные курсы
              </h4>
              <Box className="grid md:grid-cols-2 gap-5">
                <article className="">
                  <img
                    src="https://images.jhtassets.com/f9db59853de157d595d24e7bdd3e87a22f9f0219/"
                    alt=""
                    className="mb-3 h-80 object-cover"
                  />
                  <h4 className="text-xl font-bold font-zinc-600">
                    Канадские Скалистые горы
                  </h4>
                </article>
                <article className="">
                  <img
                    src="https://images.jhtassets.com/2ab7de138606703ab8dc4556517e526dafaed63a/"
                    alt=""
                    className="mb-3 h-80 object-cover"
                  />
                  <h4 className="text-xl font-bold font-zinc-600">
                    Итальянские Альпы
                  </h4>
                </article>
                <article className="">
                  <img
                    src="https://images.jhtassets.com/8a28a3c2c2c386dcb16ef87248c33f44b7d358e2/"
                    alt=""
                    className="mb-3 h-80 object-cover"
                  />
                  <h4 className="text-xl font-bold font-zinc-600">
                    Тринити Маунтинс
                  </h4>
                </article>
                <article className="">
                  <img
                    src="https://images.jhtassets.com/f946a955b70ef013d0041d911e470d2b76b08615/"
                    alt=""
                    className="mb-3 h-80 object-cover"
                  />
                  <h4 className="text-xl font-bold font-zinc-600">
                    Калифорнийское побережье
                  </h4>
                </article>
              </Box>
            </Box>
            <Box className="lg:w-1/3 p-5 bg-zinc-300 self-start">
              <img
                src="https://images.jhtassets.com/01a5b6e77ee440cb28b9af45b8600aba7ea12154/"
                alt=""
                className="mb-5"
              />
              <h4 className="text-lg mb-5 text-zinc-600 font-bold">
                Virtual Coaching
              </h4>
              <p className="mb-5">
                В тренировках Virtual Active от компании Matrix доступна
                функция, которая реализована в виде изображения тренера в окне,
                расположенном в верхнем левом углу экрана. Этот тренер дает
                указания и поддерживает пользователей, благодаря чему они
                тренируются более эффективно.
              </p>
            </Box>
          </div>
        </Container>
      </section>
      <section>
        <img
          src="https://images.jhtassets.com/1b0c8e63d9620659fe24b81db8418729210d247b/"
          className="w-full object-cover"
          style={{ height: "420px" }}
          alt=""
        />
        <Container maxWidth="xl" className="md:!px-6">
          <div className="grid lg:grid-cols-2 gap-5 pt-14">
            <Box className="relative lg:bottom-60 max-w-md mx-auto">
              <img
                className="mx-auto w-full"
                src="https://images.jhtassets.com/d9ec8ed5fbc5a7fdfd22ff5dce59f94bbdf5148d/"
                alt=""
              />
              <img
                className="mx-auto w-full"
                src="https://images.jhtassets.com/1dae43ba5b3df6e246d033a4361146a577ffef82/"
                alt=""
              />
              <img
                className="mx-auto w-full"
                src="https://images.jhtassets.com/13ead9431e85aef1405ec22de3d5510344a87c4d/"
                alt=""
              />
            </Box>
            <Box>
              <h3 className="font-bold text-zinc-600 text-3xl">Landmarks</h3>
              <p className="py-5">
                Добавьте вызов, мотивацию и интерес к подъему по ступеням с
                помощью программы Landmarks, охватывающей более 20
                достопримечательностей по всему миру. С Landmarks, Stepper и
                ClimbMill тренировки превращаются в головокружительные
                путешествия к вершинам природных и рукотворных чудес света.
                Каждое место назначения включает потрясающую фотографию и быстро
                читаемую информацию, которая поможет участникам расширять их
                кругозор каждый раз, когда они загружают программу.
              </p>
              <Box className="grid sm:grid-cols-2">
                <HoverToShowBg
                  img="https://images.jhtassets.com/53ed2ba88c14422c3c125779611e8200f0d13cb2/"
                  title="ПИРАМИДА ХЕОПСА"
                />
                <HoverToShowBg
                  img="https://images.jhtassets.com/8723278d3ef61be87eb81f2e6ce8feb74ab71260/"
                  title="ТАДЖ-МАХАЛ"
                />
                <HoverToShowBg
                  img="https://images.jhtassets.com/ab38f88baecc528dfb045c70bbca311909ee7908/"
                  title="ПИЗАНСКАЯ БАШНЯ"
                />
                <HoverToShowBg
                  img="https://images.jhtassets.com/b4dca9ad40ec0665d3cfdea11eb8de82dcb8bb1a/"
                  title="ТАЙБЭЙ, ТАЙВАНЬ"
                />
              </Box>
            </Box>
          </div>
        </Container>
      </section>
      <BgImageSection
        sectionHeight="60vh"
        img="https://images.jhtassets.com/0201740a1093cd2b6fd7274e76e15773de5d572a/"
        title="SPRINT 8"
        link="Подробнее о Sprint 8 /"
        text="Повысьте лояльность клиентов с помощью программы Sprint 8, разработанной с приоритетом на результат, за которым участники будут возвращаться снова и снова. Sprint 8 — это научно проверенная программа, в которой используются высокоинтенсивные упражнения с целью стимулировать выработку природного гормона роста, эффективное сжигание жира и наращивание мышц."
        contentWidth="33%"
        titleColor="#4c4c4c"
        contentRight={false}
        contentBg="hsla(0,0%,100%,.9)"
        textColor="#666"
        linkColor="#e1261c"
        linkTo="/connected-solutions/sprint-8"
      />
      <section>
        <CenterContentBox
          title="ПРИСОЕДИНЯЙТЕСЬ К UNIQUE SOLUTIONS"
          link="Обратная связь"
          titleColor="#4C4C4F"
          subtitleColor="#191919"
          linkTo="/contact-us"
        />
      </section>
    </>
  );
};

export default ConnectedSolutionsUniquePrograms;
