import React from "react";
import "../assets/scss/_header_box.scss";
import { Button, Container } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
};

const HeaderBox = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section
      className="header-box-1 flex items-center"
      style={{ background: `url(${props.image})` }}
    >
      <div>
        <Modal
          className="relative overflow-y-auto"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <>
            <span
              onClick={handleClose}
              className="absolute top-0 right-0 mr-6 mt-6 text-5xl font-black text-white hover:text-slate-500 cursor-pointer"
            >
              ×
            </span>
            <Fade in={open}>
              <Box sx={style}>
                <video playsinline
                  playsInline={true}
                  height="auto"
                  width="auto"
                  autoPlay
                  controls
                >
                  <source height="auto" width="auto" src={props.video}></source>
                </video>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
      <Container
        className="text-center"
        data-aos={props.fade}
        data-aos-anchor-placement={props.center}
      >
        <div className="text-3xl md:text-6xl text-white">{props.title}</div>
        <p className="text-white font-bold py-4">{props.text}</p>
        <Button
          onClick={handleOpen}
          className="mx-auto hover:!bg-red-700 hover:!text-white !p-4 !rounded-none"
          style={{ color: "#e1261c", fontWeight: "bold", fontSize: "20px" }}
        >
          <BsPlayCircle size={32} fill="#fff" className="mr-2" />
          СМОТРЕТЬ ВИДЕО
        </Button>
      </Container>
    </section>
  );
};

export default HeaderBox;
