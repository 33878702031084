import React from "react";
import Container from '@mui/material/Container';
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import BgImageSection from "../components/BgImageSection";

import bannerImage from "../assets/images/strength/strength-banner.jpg"
import banner2 from "../assets/images/strength/banner2.jpg"
import itemImg1 from "../assets/images/strength/item1.jpg"
import itemImg2 from "../assets/images/strength/item2.jpg"
import itemImg3 from "../assets/images/strength/item3.jpg"
import itemImg4 from "../assets/images/strength/item4.jpg"
import itemImg5 from "../assets/images/strength/item5.jpg"

const Strength = () => {
   return (
		<>
			<div className='relative flex md:flex-row flex-col items-end justify-center main-media-height' style={{
				height: '550px'
			}}>
				<img src={bannerImage} className='md:absolute top-0 left-0 w-full h-full md:object-cover object-contain' alt="" />
				<p className="text-5xl relative z-10 md:pb-12 md:mt-0 mt-2 md:text-white">ОБЗОР СИЛОВЫХ ТРЕНАЖЕРОВ</p>
			</div>
			<Container className="mb-8" maxWidth="xl">
				<p className="max-w-3xl leading-6 py-12" style={{color: '#333'}}>Все серии нашего оборудования для силовых тренировок по-своему уникальны, и выбор оптимального варианта для вашего бизнеса поможет вам намного эффективнее привлекать и удерживать клиентов. Серия Matrix для силовых тренировок — это мощные конструкции, бесконечные возможности для настройки, стильный дизайн, непревзойденная долговечность и многое другое. Все это можно свободно комбинировать для идеального воплощения ваших фитнес-идей.</p>
				<CardBoxMoreImage
					title="Серия Ultra"
					subtitle="Мощные, но удобные тренажеры. Расширенный функционал и интуитивно понятное управление. Самые прочные комплектующие и уникальный дизайн. Серия Ultra превосходит все ожидания от оборудования для силовых тренировок."
					link="Узнать больше о серии Ultra /"
					image={itemImg1}
					contentBgColor="#000"
					contentRight={false}
					contentWidth="25%"
					contentTitleColor="#e6e5e5"
					contentSubtitleColor="#c2c6c9"
					contentLinkColor="#fff"
					linkTo="/strength/ultra"
				/>
				<CardBoxMoreImage
					title="Программируемая консоль ITC (опция)"
					subtitle="Выбирая серию Ultra с программируемой консолью  для тренажера, вы получаете опыт, основанный на цифровых технологиях."
					link="Discover Intelligent Training Console /"
					image={itemImg2}
					contentBgColor="#c2c6c9"
					contentRight={true}
					contentWidth="33%"
					contentTitleColor="#4c4c4c"
					contentSubtitleColor="#666"
					contentLinkColor="#861711"
					linkTo="/strength/intelligent-training-console"
				/>
			</Container>
			<BgImageSection
				sectionHeight="700px"
				img={banner2}
				title="Серия Versa"
				text="Реализуйте свои фитнес-идеи: наша серия спортивного оборудования, отличающаяся максимальным разнообразием, поможет вам организовать пространство для силовых тренировок оптимальным образом и полностью удовлетворить потребности клиентов. Серия Versa создана с учетом биомеханики, что позволяет добиться естественных, плавных и эффективных движений во время тренировки."
				link="Узнать больше о серии Versa /"
				linkTo="/strength/versa"
				contentWidth="33%"
				titleColor="#fff"
				contentRight={true}
				contentBg="#e1261c"
				textColor="#fff"
				linkColor="#fff"
			/>
			<section className="py-5" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3 md:!px-12' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Серия Aura"
						text="Благодаря превосходному дизайну серия Aura моментально стала классикой. Многие производители в отрасли пытались сымитировать оригинальный внешний вид этой линейки. Выбрав Aura, вы обнаружите, что как новичков, так и фитнес-энтузиастов привлекает неповторимость и уникальность нашего оборудования — то, что неподвластно нашим конкурентам."
						link="Узнать больше о серии Aura /"
						img="https://images.jhtassets.com/30147f63194b1629088320d6cb8bb303450a5455/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
						linkTo="/strength/aura"
					/>
					<CardBox
						boxHeight="650px"
						title="Серия Magnum"
						text="Лучший выбор для профессиональных спортсменов — это непревзойденная производительность и долговечность серии Magnum. Оборудование для опытных спортсменов, такое как Breaker Benches и MEGA Racks, поможет вашим клиентам по-новому раскрыть свой потенциал, а его безупречный дизайн выдержит испытание в самых жестких условиях."
						link="Узнать больше о серии Magnum /"
						img="https://images.jhtassets.com/7763f67dfc8c433019b69ea8d77773e1ba07c907/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
						linkTo="/strength/magnum"
					/>
				</Container>
			</section>
			<section className="py-6" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className="!grid gap-6 md:!px-12" maxWidth="xl">
					<CardBoxMoreImage
						title="Серия Varsity"
						subtitle="Серия Varsity, отличающаяся высокой эффективностью и оптимальной ценой, будет идеальным выбором для объектов с высокой нагрузкой. Стоит ли тратить время на поиски других вариантов, когда можно выбрать Varsity? Динамичность и превосходная эргономика, которыми славится Matrix, сочетаются в этой серии с максимальной долговечностью, необходимой при постоянном потоке клиентов."
						link="Узнать больше о серии Varsity /"
						image={itemImg3}
						contentBgColor="linear-gradient(180deg,#fff,#f0f1f1)"
						contentRight={false}
						contentWidth="33%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#e1261c"
						linkTo="/strength/varsity"
					/>
					<CardBoxMoreImage
						title="Серия G1"
						subtitle="Серия G1 представляет собой идеальное сочетание ценности и разнообразия для экономичных объектов. Он предлагает ряд прочных вариантов с функциональностью и элегантным классическим внешним видом. От компактных многостанционных станций до постоянно популярных скамей - эта серия будет удовлетворять потребности ваших участников на долгие годы."
						link="Узнать больше о серии G1 /"
						image={itemImg4}
						contentBgColor="#e1261c"
						contentRight={true}
						contentWidth="33%"
						contentTitleColor="#f5f5f5"
						contentSubtitleColor="#f9d2d0"
						contentLinkColor="#fff"
						linkTo="/strength/g1"
					/>
					<CardBoxMoreImage
						title="Серия Go"
						subtitle="Теперь с помощью нашей простой в использовании и компактной серии Go вы можете привлечь к занятиям клиентов, еще мало знакомых с силовыми тренировками и круговыми программами. Минимальные требования к регулировке, малый начальный вес и малые приращения создают комфортную атмосферу для всех желающих, а компактные размеры, установка встык и небольшая высота блока подойдут для любого пространства и бюджета."
						link="Узнать больше о серии Go /"
						image={itemImg5}
						contentBgColor="linear-gradient(180deg,#fff,#f0f1f1)"
						contentRight={false}
						contentWidth="33%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#e1261c"
						linkTo="/strength/go"
					/>
					<CenterContentBox
						title="Ознакомиться с полным ассортиментом оборудования для силовых тренировок"
						subtitle="Независимо от того, является ли фитнес вашим основным делом или одним из компонентов бизнеса, наш широкий ассортимент оборудования премиум-класса для силовых тренировок содержит все необходимые составляющие для вашего успеха."
						link="Узнать больше /"
						titleColor="#e6e5e5"
						subtitleColor="#c2c6c9"
						linkTo="/strength/catalog"
					/>
				</Container>
			</section>
			<section className="py-5" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						title="Добейтесь своих целей"
						text="Хотите улучшить свой бизнес или вывести его на новый уровень? Мы поможем вам реализовать любые идеи. Узнайте, как Connected Solutions помогут вам удержать и заинтересовать клиентов, выделиться на фоне конкурентов, максимизировать доходность инвестиций, увеличить выручку и привлечь новых посетителей."
						link="Узнать больше /"
						img="https://images.jhtassets.com/e6c7eb1229dfa86e2812fb0a31fcbd267fc82108/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
						linkTo="/connected-solutions"
					/>
					<CardBox
						title="Connected Solutions / Цифровые решения"
						text="Наша эффективная открытая платформа — это нечто большее, чем просто комплекс оборудования, программного обеспечения и возможностей подключения. Это полностью интегрированная цифровая экосистема, которая позволяет более эффективно управлять оборудованием, вести и мотивировать клиентов, а также продвигать ваш уникальный бренд."
						link="Узнать больше /"
						img="https://images.jhtassets.com/bb4f7ac465946047729ad0a521b901445c47c14d/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#c2c6c9"
						contentPt="50px"
						linkTo="/connected-solutions"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Свяжитесь с нами"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	);
};

export default Strength;
