import { Container } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import { getFilter } from "../redux/actions/filterActions";


const StrengthAura = () => {
	const dispatch = useDispatch();
   return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/06dfb00be02a80bfb501268370abd61fd127eecf/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 md:mt-0 mt-2 text-5xl relative z-10 px-5 md:text-white">Серия Aura</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Благодаря потрясающему внешнему виду оборудование серии Aura уже стало настоящей классикой. А характерные отличительные особенности сделали его одним из самых популярных примеров для подражания. Выберите серию Aura, и вы увидите, что все посетители вашего заведения, будь то новички или опытные спортсмены, будут в восторге от того, что не смогут повторить никакие конкуренты — безошибочно узнаваемой ауры величия.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 6)
                    dispatch(getFilter({
                        series: 6,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии Aura"
						subtitle="В серии Aura представлены одноместные и многоместные тренажеры, а также тренажеры со свободными весами. Каждый из них оснащен нашей фирменной конструкцией из круглых трубок."
						image="https://images.jhtassets.com/676eee3c9970128e2eee919140bf891d1e502b8e/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Форма, функциональности и универсальность"
						text="Функциональные тренажеры серии Aura дают пользователям возможности выполнять широкий ряд динамичных упражнений даже в небольшом пространстве."
						img="https://images.jhtassets.com/41ad052205d030ece4de99a5e592c981357e572c/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Высочайший комфорт"
						text="Наши подушки Ergo Form обеспечивают правильное выравнивание и надлежащую поддержку тела, в тоже время добавляя удачные штрихи, благодаря которым оборудование выглядит действительно привлекательно."
						img="https://images.jhtassets.com/83d93a8153daf4f02339e2f846726885e87fd421/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
				<Container className="mt-8 mb-8" maxWidth="xl">
					<CardBoxMoreImage
						title="Стиль и долговечность"
						subtitle="Оборудование этой серии отличается не только оригинальным внешним видом — его рама на основе круглых трубок обеспечивает высочайшие прочность и долговечность."
						image="https://images.jhtassets.com/1284801cb8329f36df8053d6975de1ca80bd9cbb/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="33%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						className="px-5"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Обратная связь"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	);
};

export default StrengthAura;
