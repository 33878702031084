import { Container } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CardBox from "../components/CardBox";
import CardBoxMoreImage from "../components/CardBoxMoreImage";
import CenterContentBox from "../components/CenterContentBox";
import { getFilter } from "../redux/actions/filterActions";


const StrengthVersa = () => {
	const dispatch = useDispatch();
   return (
		<>
			<section className="relative md:flex-row flex-col items-end main-media-height" style={{height: '420px'}}>
				<img
					src="https://images.jhtassets.com/fb8d3b3c29949d4d3e569c6404a7de67ddd9ac34/"
					className="md:absolute top-0 left-0 w-full h-full object-cover"
					alt=""
				/>
				<h3 className="md:mb-14 text-5xl md:mt-0 mt-2 relative z-10 px-5 md:text-white">Серия Versa</h3>
			</section>
			<section className="md:py-10 pb-10">
				<Container maxWidth="xl">
					<p className="max-w-3xl leading-6 py-6" style={{color: '#333'}}>Воплотите ваше видение фитнеса в реальность — наша самая универсальная серия обладает всем необходимым, чтобы создать зону силовых тренировок, полностью соответствующую особенностям вашего помещения и потребностям клиентов. Кроме того, оборудование серии Versa отличается улучшенной биомеханикой, благодаря чему обеспечивается плавный, естественный и эффективный тренировочный процесс.</p>
					<a onClick={() => {
                    window.localStorage.removeItem('category_id');
                    window.localStorage.setItem('seria_id', 5);
                    dispatch(getFilter({
                        series: 5,
                    }))
                }} href="/strength/catalog" className="button-red px-6 py-1 mt-2 mb-5 inline-block hover:bg-red-700"
					><span className='text-base font-black'>Узнать больше</span></a>
					<CardBoxMoreImage
						title="Линейка оборудования серии Versa"
						subtitle="В серии Versa представлены разнообразные одноместные силовые тренажеры, а также два многоместных функциональных комплекса Functional Trainer."
						image="https://images.jhtassets.com/19af85bd9535a796b7c58ed8520d79b2cf65ecb8/"
						contentBgColor="#000"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#e6e5e5"
						contentSubtitleColor="#c2c6c9"
						contentLinkColor="#fff"
					/>
				</Container>
			</section>
			<section className="py-8" style={{ background: 'linear-gradient(171.76deg,#253746,#000)'}}>
				<Container className='!grid lg:grid-cols-2 gap-3' maxWidth="xl">
					<CardBox
						boxHeight="650px"
						title="Широкий выбор опций"
						text="Для оборудования серии Versa доступен широкий ряд опций, например легкие или тяжелые блоки грузов и наборы упоров для ступней, так что вы можете создавать конфигурации, идеально отвечающие требованиям клиентов, бюджету и особенностям вашего помещения."
						img="https://images.jhtassets.com/8c45209294c2889dd9010efbd727fe11c506dd2c/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
					<CardBox
						boxHeight="650px"
						title="Более простая регулировка сиденья"
						text="Благодаря газовому лифту регулировка сиденья выполняется легко и без каких-либо усилий, а четырехзвенная система крепления обеспечивает непревзойденную устойчивость."
						img="https://images.jhtassets.com/43bae312c334ec00657572927fd8e0969ef4de84/"
						color="#4c4c4c"
						textColor="#666"
						contentBgColor="#fff"
						contentPt="50px"
					/>
				</Container>
				<Container className="mt-8 mb-8" maxWidth="xl">
					<CardBoxMoreImage
						title="Более комфортные контактные ручки"
						subtitle="Контактные ручки Action Specific Grips имеют специальную эргономичную форму, уменьшающую давление в местах соприкосновения. А их форма, функциональность и приятные на ощупь материалы делают тренировки еще комфортнее."
						image="https://images.jhtassets.com/3c9efd394638d0d921aba7177fef446f781ecf14/"
						contentBgColor="#c2c6c9"
						contentRight={true}
						contentWidth="25%"
						contentTitleColor="#4c4c4c"
						contentSubtitleColor="#666"
						contentLinkColor="#861711"
						className="px-5"
					/>
				</Container>
			</section>
			<section>
				<CenterContentBox
					title="Добейтесь большего с Matrix"
					subtitle="Свяжитесь с нашими менеджерами по продажам, чтобы получить комплексные решения для вашего бизнеса уже сегодня."
					link="Свяжитесь с нами"
					titleColor="#4C4C4F"
					subtitleColor="#191919"
					linkTo="/contact-us"
				/>
			</section>
		</>
	);
};

export default StrengthVersa;
